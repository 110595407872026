import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../configureStore";
import { AddressModel } from "../../types/AddressTypes";

export interface AddressState {
	cache: { [key: string]: AddressModel[] };
}

export function initAddressState(): AddressState {
	return {
		cache: {},
	};
}

export const addressSlice = createSlice({
	name: 'address',
	initialState: initAddressState(),
	reducers: {
		setAddressCashe(state, action: PayloadAction<{ key: string, addresses: AddressModel[]}>) {
			const {key, addresses} = action.payload;
			state.cache[key] = addresses;
		},
		
	},
});

const getAddressState = (state: RootState) => state.addressSlice;
export const getAddressCache = (key: string) => createSelector([getAddressState], state => state.cache[key] ?? null)

export const { setAddressCashe } = addressSlice.actions
export default addressSlice.reducer;