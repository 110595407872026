import { useCallback, useMemo, useState } from "react";
import ModalBase from "../../ModalBase/ModalBase";
import { Button, Form, Modal, Space, Tabs } from "antd";
import { GskModel, NewGskModel } from "../../../types/GskTypes";
import { GskService } from "../../../Services/GskService";
import { useNavigate } from "react-router-dom";
import { CreateSubscriptionModel, GskSubscriptionsModel, PaymentModel, PaymentState } from "../../../types/BillingTypes";
import useLoading from "../../../utils/hooks/useLoading";
import DeleteButton from "../../Controls/DeleteButton";
import { UserRole } from "../../../types/UserTypes";
import { ShowError, ShowSuccess } from "../../Notifications/Notifications";
import { BillingService } from "../../../Services/BillingService";
import GskPropsTab from "./GskPropsTab";
import GskSubscriptionsTab from "./GskSubscriptionsTab";
import ChooseTariffTab from "./ChooseTariffTab";
import { TabsProps } from "antd/lib";
import { setCommissionPercent, setPaymentTypes, updateCurrentGskProps } from "../../../store/common/gsk-slice";
import { useDispatch } from "react-redux";
import { useEffectOnce } from "../../../utils/hooks/useEffectOnce";
import GskPayTab from "./GskPayTab";
import './GskModal.scss'
import { useCurrentUser } from "../../../store/common/app-slice";
import GskBillingTab from "./GskBillingTab";
import { GskBillingService } from "../../../Services/GskBillingService";
import { GskBillingSettingsModel } from "../../../types/GskBillingTypes";

export type GskModalTab = 'main' | 'chooseTariff' | 'pay' | 'subscriptions' | 'gskBilling';
export type GskModalMode = 'addSubscription';

interface CurrentTabProps {
  title: string;
  handleFormSubmit?: (v: any) => void;
  backButton?: React.ReactNode;
  okButtonText: string;
  okButtonHandler: CallableFunction;
}

export interface OpenGskModalProps {
  currentGsk?: GskModel;
  initTab?: GskModalTab;
  setGsks?: React.Dispatch<React.SetStateAction<GskModel[]>>;
}

interface OwnProps extends OpenGskModalProps {
  closeDialog: CallableFunction;
}

export default function GskModal({ closeDialog, currentGsk, initTab = 'main', setGsks = () => null }: OwnProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const [form] = Form.useForm<any>();
  const [gsk, setGsk] = useState(currentGsk);
  const [isLoading, load] = useLoading();
  const [tab, setTab] = useState<GskModalTab>(initTab);
  const [mode, setMode] = useState<GskModalMode | undefined>();
  const [gskSubscriptions, setGskSubscriptions] = useState<GskSubscriptionsModel>({old: []})
  const [payment, setPayment] = useState<PaymentModel | undefined>();

  const isNewGsk = !currentGsk;
  const hideTabs = isNewGsk || tab === "chooseTariff" || tab === 'pay';

  const handleDelete = () => {
    Modal.confirm({
      title: "Удаление",
      content: "Отмерить данную операцию будет невозможно. Точно удалить?",
      okText: "Удалить",
      cancelText: 'Отмена',
      okButtonProps: { danger: true },
      onOk: async () => {
        if (!gsk) {
          return;
        }
        const resp = await load(GskService.delete(gsk.id));
        if (resp) {
          closeDialog();
          setGsks(prev => [...prev.filter(p => p.id !== gsk.id)]);
          navigate('/');
        }
      }
    })
  }
  
  const loadPayment = useCallback(async (subscriptionId: number) => {
    const resp = await load(BillingService.createSubscriptionPayment(subscriptionId));
    if (resp) {
      setPayment(resp);
      setTab('pay');
    }
  }, [load])

  const tabProps = useMemo((): CurrentTabProps => {
    switch (tab) {
      case 'main':
        const handleSaveGskClick = async (data: any) => {
          const resp = await load(GskService.editGsk(data.id, data as GskModel));
          if (resp) {
            setGsks(prev => {
              let arr = [...prev];
              arr.splice(prev.findIndex(p => p.id === resp.id), 1, resp);
              return arr;
            });
            dispatch(updateCurrentGskProps(resp))
            closeDialog();
          }
        }

        return isNewGsk ?
        {
          title: 'Регистрация ГСК',
          okButtonText: 'Дальше',
          okButtonHandler: async () => { 
            try { await form.validateFields(['name', 'address']); } catch {}
            if (form.getFieldError('name').length === 0 && form.getFieldError('address').length === 0) 
            {
              setTab("chooseTariff");
            }
          }
        }
        :
        {
          title: 'Параметры ГСК',
          handleFormSubmit: handleSaveGskClick,
          okButtonText: 'Ок',
          okButtonHandler: form.submit
        };
      case 'chooseTariff':
        if (isNewGsk) {
          return {
            title: 'Выбор тарифа',
            handleFormSubmit: async (data: any) => {
              const resp = await load(GskService.newGsk(data as NewGskModel));
              if (resp) {
                setGsk(resp);
                setGsks(prev => [...prev, resp])
                if (resp.currentSubscription?.paymentState === PaymentState.Succeeded) {
                  closeDialog();
                  navigate('/Gsk/' + resp.id);
                } else if (resp.currentSubscription?.paymentState === PaymentState.Pending) {
                  if (resp.currentSubscription.id === undefined) {
                    ShowError("Ошибка", 'Подписка не зарегистрирована');
                    return;
                  }
                  ShowSuccess(resp.name + ' зарегистрирован', 'Переход на страницу оплаты');
                  setTab('pay');
                  await loadPayment(resp.currentSubscription.id);
                } else {
                  closeDialog();
                }
              }
            },
            okButtonText: 'Зарегистрировать',
            backButton: <Button onClick={() => setTab('main')}>Назад</Button>,
            okButtonHandler: form.submit
          };
        }
        switch (mode) {
          case 'addSubscription':
            return {
              title: 'Продлить подписку',
              handleFormSubmit: async (data: any) => {
                  data.gskId = gsk!.id;
                  const resp = await load(BillingService.createSubscription(data as CreateSubscriptionModel));
                  if (resp) {
                    await loadPayment(resp.id);
                    if (!gskSubscriptions.current) {
                      setGskSubscriptions({...gskSubscriptions, current: resp});
                    } else {
                      setGskSubscriptions({...gskSubscriptions, next: resp});
                    }
                  }
                },
              backButton: <Button onClick={() => {setTab('subscriptions'); setMode(undefined);}}>Назад</Button>,
              okButtonText: 'Оплатить',
              okButtonHandler: form.submit,
            }
          default:
            throw Error(`Unexected mode: ${mode} tab: ${tab}`);
        }
      case 'pay':
        switch (mode) {
          case 'addSubscription':
            return {
              title: 'Опалата подписки',
              backButton: <Button onClick={() => {setTab('subscriptions'); setMode(undefined); }}>Назад</Button>,
              okButtonText: 'Оплатить',
              okButtonHandler: () => {
                if (payment) {
                  window.open(payment.confirmationUrl, '_self')
                }
              }
            } 
            default: 
            return {
              title: 'Опалата подписки',
              backButton: <Button onClick={() => setTab('main')}>Назад</Button>,
              okButtonText: 'Оплатить',
              okButtonHandler: () => {
                if (payment) {
                  window.open(payment.confirmationUrl, '_self')
                }
              }
            };
        }
      case 'subscriptions':
        switch (mode) {
          case "addSubscription": 
            return {
              title: 'Продление подписки',
              handleFormSubmit: async (data: any) => {
                const resp = await load(BillingService.createSubscription(data as CreateSubscriptionModel));
                if (resp) {
                  await loadPayment(resp.id);
                  if (!gskSubscriptions.current) {
                    setGskSubscriptions({...gskSubscriptions, current: resp});
                  } else {
                    setGskSubscriptions({...gskSubscriptions, next: resp});
                  }
                }
              },
              backButton: <Button onClick={() => {setTab('subscriptions'); setMode(undefined);}}>Назад</Button>,
              okButtonText: 'Дальше',
              okButtonHandler: () => setTab("pay"),
          }
          default: 
            return {
              title: 'Подписки',
              okButtonText: 'Ok',
              okButtonHandler: closeDialog
            }
        }
      case 'gskBilling':
        return {
          title: 'Настройки ЮКасса',
          handleFormSubmit: async (data: any) => {
            if (!gsk?.id) {
              ShowError('Не указан gskId');
              return;
            }
            const resp = await load(GskBillingService.saveBillingSettings(gsk.id, data as GskBillingSettingsModel));
            if (resp) {
              form.setFieldsValue(resp);
              dispatch(setCommissionPercent(resp.commissionPercent));
              dispatch(setPaymentTypes(undefined));
              closeDialog();
            }
          },
          backButton: undefined,
          okButtonText: 'Ок',
          okButtonHandler: form.submit,
      }
      default:
        throw Error(`Unexpected tab ${tab}`);
    }
  }, [closeDialog, dispatch, form, gsk, gskSubscriptions, isNewGsk, load, loadPayment, mode, navigate, payment, setGsks, tab]);


  const tabs = useMemo((): TabsProps['items'] => {
    return [
      {
        label: 'Основные',
        key: 'main',
        forceRender: true,
        children: <GskPropsTab gsk={gsk} />
      },
      {
        label: 'Подписка',
        key: 'subscriptions',
        children: gsk && <GskSubscriptionsTab
          loadPayment={loadPayment}
          gskSubscriptions={gskSubscriptions}
          setGskSubscriptions={setGskSubscriptions}
          setMode={setMode}
          setTab={setTab}
        />
      },
      {
        label: 'Биллинг',
        key: 'gskBilling',
        children: gsk && <GskBillingTab gsk={gsk} load={load} form={form} isActiveTab={tab === 'gskBilling'} />
      },
      {
        label: undefined,
        key: 'chooseTariff',
        forceRender: isNewGsk,
        children: <ChooseTariffTab form={form} gsk={gsk} load={load} />
      },
      {
        label: undefined,
        key: 'pay',
        children: gsk && <GskPayTab isNewGsk={isNewGsk} payment={payment} gsk={gsk} />
      },
    ]
  }, [form, gsk, gskSubscriptions, isNewGsk, load, loadPayment, payment, tab]);


  useEffectOnce(() => {
    if (isNewGsk) {
      return;
    }
    const fetch = async() => {
      const resp = await(load(GskService.getSubscriptions(gsk!.id)));
      if (resp) {
        setGskSubscriptions(resp);
      }
    }
    fetch();
  });

  return (
    <ModalBase 
      isLoading={isLoading}
      title={tabProps.title}
      leftFooterCell={<Space>
        {tabProps.backButton}
        {!isNewGsk && currentUser?.role === UserRole.Admin && <DeleteButton onClick={handleDelete}/>}
      </Space>}
      okButtonHandler={tabProps.okButtonHandler}
      closeDialog={closeDialog}
      okButtonText={tabProps.okButtonText}
      width={560}
    >
      <Form
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        layout={'horizontal'}
        form={form}
        initialValues={{
          id: currentGsk?.id,
          address: currentGsk?.address,
          name: currentGsk?.name,
          isPublic: currentGsk?.isPublic,
        }}
        onFinish={tabProps.handleFormSubmit}
      >
        <Tabs
          tabPosition='left'
          className={hideTabs ? 'hide-tabs' : ''}
          items={tabs}
          activeKey={tab}
          onChange={key => setTab(key as GskModalTab)}
        />
      </Form>
    </ModalBase>
  );
}
