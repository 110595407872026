import { useState } from "react";
import { Tour, TourProps } from "antd";
import IconButton from "../../IconButton/IconButton";
import { PlusCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { RefType, getAppRef } from "../../../store/enum/RefType";
import { MeterIcon } from "../../icons";

interface Props {
  noBoxes: boolean;
}
export function MetersPageTour({noBoxes}: Props) {
  const [tourOpen, setTourOpen] = useState(false);
  const menuBoxesLink = getAppRef(RefType.GskMenuBoxesLink);
  const menuAccuralsLink = getAppRef(RefType.GskMenuAccrualsLink);
  const addMeter = getAppRef(RefType.GskMetersFirstRowAddMeter);
  const firstMeterNum = getAppRef(RefType.GskMetersFirstMeterNum);
  const firstReadings = getAppRef(RefType.GskMetersFirstRowMeterReadings);
  const firstAddReadings = getAppRef(RefType.GskMetersFirstRowMeterAddReadings);

	let steps: TourProps['steps'] = [];
  if (noBoxes) {
    steps.push({
      title: 'Нет боксов',
      description: <>Сначала нужно создать боксы. После этого вы сможете добавлять к ним счетчики</>,
      target: () => menuBoxesLink.current,
    })
  } else {
    steps = [
      {
        title: 'Добавить счетчик',
        description: <>Чтобы добавить счетчик нажмите кнопку <MeterIcon/> в строке бокса и следуйте инструкциям</>,
        target: () => addMeter.current,
      },
      {
        title: 'Изменить счетчик',
        description: <>Нажмите на номер счетчика, появится окно, в котором можно его изменить или удалить
          {!firstMeterNum.current && <i> (Появится после добавления счетчика)</i>}
        </>,
        target: () => firstMeterNum.current,
      },
      {
        title: 'Передача показаний',
        description: <>Чтобы добавить показания нажмите кнопку <PlusCircleOutlined/> в колонке <b>Показания</b>
          {!firstAddReadings.current && <i> (Появится после добавления счетчика)</i>}
        </>,
        target: () => firstAddReadings.current,
      },
      {
        title: 'История показаний',
        description: <>
          Все переданные показания хранятся в системе. {firstReadings.current && <>Чтобы их просмотреть нажмите сюда</>}
        </>,
        target: () => firstReadings.current,
      },
      {
        title: 'Формирование платежей',
        description: <>Чтобы сформировать начисления по переданным показаниям перейдите на влкдаку <b>Начисления</b>, нажмите кнопку <b>Сгенерировать платежи</b></>,
        target: () => menuAccuralsLink.current,
      },
      {
        title: 'Задолженности',
        description: <>Счетчики, по которым есть не оплаченные начисления подсвечиваются <span className="late-payment">красным цветом</span></>,
      },
    ];
  }
  return (<>
    <IconButton icon={<QuestionCircleOutlined />} onClick={() => setTourOpen(true)} />
    <Tour open={tourOpen} onClose={() => setTourOpen(false)} steps={steps} />
  </>)
}