import { reduxStore } from "..";
import { BadModelRequest } from "../BadModelRequest";
import { ShowError, ShowInfo } from "../components/Notifications/Notifications";
import { setRedirect, setRefreshUserFlag } from "../store/common/app-slice";

export interface ApiFile {
	fileContents: string;
	contentType: string;
	fileDownloadName: string;
}

export const b64toBlob = (file: ApiFile, sliceSize = 512) => {
	const byteCharacters = atob(file.fileContents);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, { type: file.contentType });
	return blob;
}

export async function _fetch<T>(uri: string, method?: HTTPMethod, data?: object, alwaysQuery: boolean = false, abortSignal?: AbortSignal) {
	try {
		const response = await _fetchApi(uri, method, data, alwaysQuery, abortSignal);
		const result = (await response.json()) as ApiResultModel;
		if (result.success) {
			return result.data as T;
		} else {
			ShowError('Ошибка', result.message);
			throw result.message;
		}
	} catch { }
}

export type HTTPMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

interface ApiResultModel {
	success: boolean,
	message?: string,
	data?: any,
}

export interface QueryObject {
	[key: string]: any
}

export function getCookie(cname: string): string {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

function encodeKeyValue(key: string, value: any): string {
	return `${encodeURI(key)}=${encodeURIComponent(value.toString())}`;
}

function encode(key: string, value: any): string {
	if (Array.isArray(value)) {
		return value
			.filter(e => e !== null && e !== undefined)
			.map(e => encodeKeyValue(key, e))
			.join('&');
	} else {
		return encodeKeyValue(key, value);
	}
}

export function objectToUrlQuery<T extends QueryObject>(obj: T): string {
	let list = Object
		.keys(obj)
		.map(key => obj[key] === undefined || obj[key] === null ? undefined : encode(key, obj[key]))
		.filter(s => s);
	return list.join("&");
}

async function _fetchApi(uri: string, method?: HTTPMethod, data?: object, alwaysQuery: boolean = false, abortSignal?: AbortSignal, breakRedirect: boolean = false): Promise<Response> {
	const useQuery = method === 'GET' || alwaysQuery;
	const encodedURI = encodeURI(uri);
	const fullURI = data && useQuery ? `${encodedURI}?${objectToUrlQuery(data)}` : encodedURI;
	const accessToken = localStorage.getItem("accessToken") ?? "";
	const refreshToken = localStorage.getItem("refreshToken") ?? "";
	let headers: RequestInit['headers'] = {
		"Content-Type": "application/json; charset=utf-8"
	};
	let body: RequestInit['body'];
	if (!useQuery) {
		if (data instanceof FormData) {
			body = data;
			headers = {};
		} else {
			body = JSON.stringify(data);
		}
	} else {
		body = undefined;
	}
	try {
		const response = await fetch(fullURI, {
			method: method || "GET",
			body: body,
			headers: {
				...headers,
				"Authorization": accessToken,
				"RefreshToken": refreshToken,
			},
			signal: abortSignal
		});
		if (!response) {
			throw Error("response is empty")
		}

		const newAccessToken = response.headers.get('newAccessToken');
		if (newAccessToken) {
			if (newAccessToken === 'remove') {
				localStorage.removeItem('accessToken');
			} else {
				localStorage.setItem('accessToken', newAccessToken);
			}
		}
		const newRefreshToken = response.headers.get('newRefreshToken');
		if (newRefreshToken) {
			if (newRefreshToken === 'remove') {
				localStorage.removeItem('refreshToken');
				ShowInfo("Вы вышли из системы", "");
				await reduxStore.dispatch(setRefreshUserFlag(true));
			} else {
				localStorage.setItem('refreshToken', newRefreshToken);
			}
		}

		if (response.ok) {
			return response;
		} else {
			switch (response.status) {
				case 307:
					if (breakRedirect || !newAccessToken) {
						return response;
					}
					return await _fetchApi(uri, method, data, alwaysQuery, abortSignal, true);
				case 400:
					throw new BadRequestError((await response.json()) as BadModelRequest);
				case 404:
					throw new MyGskError('Object not found');
				case 500:
					throw new MyGskError('Internal server error');
				case 401:
					const body = await response.text();
					if (body === 'AccountIsNotConfirmed') {
						reduxStore.dispatch(setRedirect('/AccountNotCofirmed'));
					} else if (!!accessToken) {
						localStorage.removeItem("accessToken");
						reduxStore.dispatch(setRedirect('/'));
					} else {
						const redirectUri = window.location.pathname ?? '';
						reduxStore.dispatch(setRedirect('/Unauhtorized'+redirectUri));
					}
					throw new MyGskError('Unauthorized');
				default:
					throw new MyGskError('Ошибка');
			}
		}
	} catch(err) {
		if (!(err instanceof MyGskError)) {
			throw new MyGskError('Неудалось получить данные от сервера. Проверьте ваше подключение к интернету или обратитесь к администратору');
		}

		ShowError("Ошибка при запросе данных", err.message);
		throw err;
	};
}

export async function DownloadFile(uri: string, method?: HTTPMethod, data?: object) {
	const resp = await _fetch<ApiFile>(uri, method, data);
	if (resp){
		const url = URL.createObjectURL(b64toBlob(resp));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', resp.fileDownloadName);
		document.body.appendChild(link);
		link.click();
		window.URL.revokeObjectURL(url);
		link.remove();
	}
}

export function Fetch<T>(uri: string, method?: HTTPMethod, data?: object, alwaysQuery: boolean = false) {
	return _fetch<T>(uri, method, data, alwaysQuery);
}

export async function FetchNoResp(uri: string, method?: HTTPMethod, data?: object, alwaysQuery: boolean = false) {
	try {
		const response = await _fetchApi(uri, method, data, alwaysQuery);
		const result = (await response.json()) as ApiResultModel;
		if (result.success) {
			return true
		} else {
			ShowError('Ошибка', result.message);
			throw result.message;
		}
	} catch {
		return false;
	}
}

export async function FetchBlob(uri: string, method?: HTTPMethod, data?: object, alwaysQuery: boolean = false) {
	try {
		const resp = await _fetchApi(uri, method, data, alwaysQuery);
		return await resp.blob();
	} catch { }
}

class MyGskError extends Error {
}

export class BadRequestError extends MyGskError {
	public errors: { [key: string]: string[] } | null;
	public constructor(data?: BadModelRequest) {
		super();
		this.errors = data?.errors ?? null;
		this.message = data?.title ?? "";
	};
}