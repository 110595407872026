import React from 'react';
import './GroupBox.scss'

interface Props {
  label: React.ReactNode;
  children: React.ReactNode;
  style?: React.CSSProperties;
}
export default function GroupBox ({label, style, children}: Props) {

  return (<fieldset style={style}>
    <legend>{label}</legend>
    {children}
  </fieldset>)
}