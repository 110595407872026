import { Modal } from "antd";
import { UserModel, UserRole } from "../../types/UserTypes";

export function CheckAccountConfirmed(currentUser?: UserModel | null): boolean {
  if (!currentUser) {
    Modal.info({title: 'Внимание', content: 'Войдите под своей учетной записью'});
    return false;
  }

  if (currentUser.role === UserRole.None) {
    Modal.info({title: 'Внимание', content: 'Сначала подтвердите свою учетную запись учетной записью'});
    return false;
  }

  return true;
}