import { useEffect, useMemo, useState } from 'react'
import { Checkbox, Select, SelectProps } from "antd";
import { GetGskRoleName, GskRole } from "../../types/GskTypes";
import { getEnumValues } from '../../utils/enumUtils';

interface GskRolesSelectProps {
  value?: GskRole[] | null;
  onChange?: (v: GskRole[]) => void;
  disabledList?: GskRole[];
  disabled?: boolean;
  style?: React.CSSProperties;
  maxTagCount?: number;
  showSelectAll?: boolean;
}
export default function GskRolesSelect({
  value,
  onChange,
  disabledList = [],
  disabled,
  style,
  maxTagCount,
  showSelectAll = true,
}:GskRolesSelectProps) {
  const [allChecked, setAllChecked] = useState(false);
  const tooltips: {[key in GskRole]?: string} = useMemo(() => ({
    [GskRole.Admin]: 'Доступ ко всем разделам ГСК. Не может быть меньше одного администратора',
    [GskRole.Chairman]: 'Сменить председателя может только председать через меню пользователя',
    [GskRole.MapEditor]: 'Права на разметку боксов на картах ГСК',
    [GskRole.Member]: 'Обязательная роль для всех членов ГСК',
  }), []);
  

  const options: SelectProps['options'] = useMemo(() => {
    const keys: GskRole[] = getEnumValues(GskRole);
    const options = keys.map(k =>
      ({
        label: GetGskRoleName(k),
        value: k,
        disabled: disabledList?.includes(k) ?? false,
        title: tooltips ? tooltips[k] : null,
      })
    );
    const handleAllClick = (checked: boolean) => {
      if (!onChange) {
        return;
      }
      const immutableValues = options?.filter(o => o.disabled === true && value?.includes(o.value as GskRole)).map(o => o.value) ?? [];
      let newValues: GskRole[] = [];
      if (checked) {
        newValues = options.filter(o => o.disabled === false).map(o => o.value as GskRole);
      }
      
      onChange([...immutableValues, ...newValues]);
    }
    let result = [];
    if (showSelectAll) {
      result.push(
        {
          label: <Checkbox
            checked={allChecked}
            onChange={e => handleAllClick(e.target.checked)}
            style={{width: '100%'}}
          >
            Все
          </Checkbox>,
        }
      )
    }
    result.push(...options);
    return result;
  }
  , [allChecked, disabledList, onChange, showSelectAll, tooltips, value]);


  useEffect(() => {
    setAllChecked(options.filter(v => v.value && v.disabled === false).every(o => value?.includes(o.value as GskRole)));
  }, [options, value]);

  return (
    <Select
      style={{...style, minWidth: style?.minWidth ?? 150}}
      mode="multiple"
      options={options}
      value={value}
      disabled={disabled}
      onChange={onChange}
      maxTagCount={maxTagCount}
    />
  )
}