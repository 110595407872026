import { EditPostCommentModel, NewPostCommentModel, NewPostModel, PostCommentModel, PostCoverModel, PostModel, VoteModel } from "../types/PostTypes";
import { PagedResult } from "../types/dto";
import { Fetch, FetchNoResp } from "../utils/fetch";

export const PostService = {
	getPostCovers: async (page: number, pageSize: number): Promise<PagedResult<PostCoverModel> | undefined> => {
		return await Fetch<PagedResult<PostCoverModel>>('/api/posts/', "GET", { page, pageSize });
	},
	getPost: async (id: number): Promise<PostModel | undefined> => {
		return await Fetch<PostModel>(`/api/posts/${id}`, "GET");
	},
	addPost: async (data: NewPostModel): Promise<PostModel | undefined> => {
		return await Fetch<PostModel>('/api/posts', "PUT", data);
	},
	editPost: async (postId: number, data: NewPostModel): Promise<PostModel | undefined> => {
		return await Fetch<PostModel>(`/api/posts/${postId}`, "PATCH", data);
	},
	vote: async (data: VoteModel): Promise<PostModel | undefined> => {
		return await Fetch<PostModel>('/api/posts/vote', "POST", data);
	},
	likePost: async (postId: number, isLike: boolean): Promise<boolean> => {
		return await FetchNoResp(`/api/posts/${postId}/like`, "POST", { isLike });
	},
	deletePost: async (postId: number): Promise<boolean> => {
		return await FetchNoResp(`/api/posts/${postId}`, "DELETE");
	},

	addComment: async (postId: number, data: NewPostCommentModel): Promise<PostCommentModel | undefined> => {
		return await Fetch<PostCommentModel>(`/api/posts/${postId}/comments`, "PUT", data);
	},
	editComment: async (commentId: number, data: EditPostCommentModel): Promise<PostCommentModel | undefined> => {
		return await Fetch<PostCommentModel>(`/api/posts/comments/${commentId}`, "PATCH", data);
	},
	likeComment: async (commentId: number, isLike: boolean): Promise<boolean> => {
		return await FetchNoResp(`/api/posts/comments/${commentId}/like`, "POST", { isLike });
	},
	deleteComment: async (commentId: number): Promise<PostCommentModel | null | undefined> => {
		return await Fetch<PostCommentModel | null>(`/api/posts/comments/${commentId}`, "DELETE");
	},
}