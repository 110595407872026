import { GskMapModel, MapBoxPolygon, EditMapModel } from "../types/GskTypes";
import { ApiFile, Fetch, FetchNoResp, b64toBlob } from "../utils/fetch";

export const MapService = {
	getMapsList: async(gskId: number): Promise<GskMapModel[] | undefined> => {
		return await Fetch<GskMapModel[]>(`/api/gsk/${gskId}/maps`, "GET");
	},
	getMapImage: async (gskId: number, mapId: number): Promise<string | undefined> => {
		const imageFile = await Fetch<ApiFile>(`/api/gsk/${gskId}/maps/${mapId}/image`, "GET");
		if (!imageFile) {
			return undefined;
		}
		
		const blob = b64toBlob(imageFile)
		const url = URL.createObjectURL(blob);
		return url;
	},
	newMap: async (name: string, image: File, gskId: number): Promise<GskMapModel | undefined> => {
		const data = new FormData();
		data.append('data', image);
		data.append('imageName', image.name);
		data.append('name', name);
		return await Fetch<GskMapModel>(`/api/gsk/${gskId}/maps`, "PUT", data);
	},
	editMap: async(gskId: number, mapId: number, data: EditMapModel): Promise<GskMapModel | undefined> => {
		return await Fetch<GskMapModel>(`/api/gsk/${gskId}/maps/${mapId}`, 'PATCH', data);
	},
	deleteMap: async (gskId: number, mapId: number): Promise<boolean> => {
		return await FetchNoResp(`/api/gsk/${gskId}/maps/${mapId}/`, 'DELETE');
	},
	getPolygons: async (gskId: number, mapId: number) => {
		return await Fetch<MapBoxPolygon[]>(`/api/gsk/${gskId}/maps/${mapId}/polygons`, "GET");
	},
	upsertMapPolygon: async (gskId: number, mapId: number, data: MapBoxPolygon): Promise<MapBoxPolygon | undefined> => {
		return await Fetch<MapBoxPolygon>(`/api/gsk/${gskId}/maps/${mapId}/polygons/upsert`, "POST", data);
	},
}