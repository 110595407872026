import { Alert } from "antd";
import { yooKassaPartherUrl } from "../Constants";
import { toMoneyString } from "../../../utils/moneyHelper";

export default function CommissionPage() {
  return (<>
    <p>
      Комиссия ЮКасса на данный момент <b>3.5%</b> за каждый принятый платеж.
      Зарегистрироваться можно <a href={yooKassaPartherUrl} target="_blank" rel="noreferrer">тут</a>.
      {/* Если Вы зарегистриуетесь по <a href={yooKassaPartherUrl} target="_blank" rel="noreferrer">этой</a> ссылке коммиссия составит <b>2.8%</b> на первые 90 дней. */}
    </p>

    <Alert
      showIcon
      type="info" 
      message='Например'
      description={<>
        Вы создали начисление <b>{toMoneyString(1000)}</b>. При оплате через ЮКасса с платильщика спишется <b>{toMoneyString(1000)}</b>.
        На расчетный счет, указнный при регистрации прийдет <b>{toMoneyString(965)}</b> ({toMoneyString(1000)} - 3.5%)
      </>}
    />
    <p>
      Если Вы хотите, что-бы на счет приходила сумма, указанная в квитанции, необходимо задать процент, который будет добавляться к платежу. 
      Сделать это можно в настройках ГСК на вкладке ЮКасса. 
      После этой настройки итоговая сумма будет автоматически рассчитваться системой таким образом, чтобы на счет приходила указанная в платеже сумма.
    </p>

    <Alert 
      showIcon
      type="info" 
      message='Например'
      description={<>
          Для платежа <b>{toMoneyString(1000)}</b> при указанной комиссии <b>3.5%</b> с платильщика будет списано <b>{toMoneyString(1036.27)}</b> ({toMoneyString(1036.27)} - 3.5% = {toMoneyString(1000)})
      </>}
    />
  </>)
}