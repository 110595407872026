import { PagedRequest } from "./dto";

export enum GskHistoryEntity {
  ACCRUAL = 0,
  CHARGE = 1,
  PAYMENT = 2,
  GSK = 3,
  BOX = 4,
  MAP = 5,
  FILE = 6,
  POST = 7,
}

export const GetHistoryEntityName = (entity: GskHistoryEntity) => {
  switch (entity) {
    case GskHistoryEntity.ACCRUAL:
      return "Начисление";
    case GskHistoryEntity.CHARGE:
      return "Счет на оплату";
    case GskHistoryEntity.PAYMENT:
      return "Платеж";
    case GskHistoryEntity.GSK:
      return "ГСК";
    case GskHistoryEntity.BOX:
      return "Бокс";
    case GskHistoryEntity.MAP:
      return "Карта";
    case GskHistoryEntity.FILE:
      return "Файл";
    case GskHistoryEntity.POST:
      return "Новость";
    default:
      throw Error("Not implemented for GskHistoryEntity = " + entity)
  }
}

export enum HistoryOperation {
  ADD = 0,
  EDIT = 1,
  DELETE = 2,
  EVENT = 3,
}

export const GetHistoryOperationName = (value: HistoryOperation) => {
  switch (value) {
    case HistoryOperation.ADD:
      return "Новый";
    case HistoryOperation.EDIT:
      return "Правка";
    case HistoryOperation.DELETE:
      return "Удаление";
    case HistoryOperation.EVENT:
      return "Событие";
    default:
      throw Error("Not implemented for HistoryOperation = " + value)
  }
}

export interface GskHistoryModel
{
  id: number;
  gskId: number;
  entity: GskHistoryEntity;
  entityId: number | null;
  operation: HistoryOperation;
  changes?: string;
  createdBy: string;
  created: Date;
}

export interface GskHistoryRequest extends PagedRequest
{
  entityType: GskHistoryEntity;
  entityId?: number;
  includeChilds: boolean;
}

export interface GskHistoryFilter extends PagedRequest {
  entityTypes: GskHistoryEntity[];
  operations: HistoryOperation[];
  entityId: number | null;
  createdBy: string | null;
  periodStart: string | null;
  periodEnd: string | null;
}


export function getOperationName(entity: GskHistoryEntity, operation: HistoryOperation, includeChilds: boolean){
  if (!includeChilds)
  {
    return GetHistoryOperationName(operation);
  }
  switch(operation){
    case HistoryOperation.ADD:
      switch(entity) {
        case GskHistoryEntity.PAYMENT:
          return 'Платеж';
        case GskHistoryEntity.CHARGE:
          return 'Создано начисление';
      }
      break;
    case HistoryOperation.EDIT:
      switch(entity) {
        case GskHistoryEntity.CHARGE:
          return 'Изменено начисление';
        case GskHistoryEntity.BOX:
          return 'Изменены свойства бокса';
      }
  }
  
  return GetHistoryOperationName(operation) + ' ' + GetHistoryEntityName(entity).toLowerCase();
}