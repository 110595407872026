import { PlusOutlined } from "@ant-design/icons";
import { Input, InputRef, Modal, Space, Tag } from "antd"
import { useEffect, useRef, useState } from "react";

interface Props {
  value?: string[];
  editMode?: boolean;
  onChange?: (v: string[]) => void;
}

const TAGS_LIMIT = 10;
const MIN_TAG_LENGTH = 3;
const MAX_TAG_LENGTH = 15;
export default function TagsBlock({value = [], onChange = (v: string[])=>null, editMode = false}: Props) {
  
  const [tags, setTags] = useState(value);
  const [inputVisible, setInputVisible] = useState(editMode);
  const [inputValue, setInputValue] = useState('');

  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    setTags(value);
  }, [value]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
    onChange([...newTags]);
  };

  const showInput = () => {
    if (tags.length >= TAGS_LIMIT) {
      Modal.info({title: 'Внимание', content: `Нельзя добавить более ${TAGS_LIMIT} тегов`});
      return;
    }
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reg = /^-?[a-zA-Zа-яА-Я\d]*(\.[a-zA-Zа-яА-Я\d]*)?$/;
    if (reg.test(e.target.value)) {
      setInputValue(e.target.value.toLocaleLowerCase());
    }
  };

  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue) && validateTag(inputValue)) {
      setTags([...tags, inputValue]);
      onChange([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue('');
  };

  const tagInputStyle: React.CSSProperties = {
    width: 64,
    height: 22,
    marginInlineEnd: 8,
    verticalAlign: 'top',
    display: inputVisible ? 'inherit' : 'none',
  };

  const tagPlusStyle: React.CSSProperties = {
    height: 22,
    background: 'white',
    borderStyle: 'dashed',
    display: !inputVisible ? 'inherit' : 'none',
  };

  return (
    <Space size={[0, 8]} wrap>
      {tags.map(tag => <Tag key={tag} color="cyan" onClose={() => handleClose(tag)} closable={editMode}>{tag}</Tag>)}
      {editMode && <>
        <Input
          type="text"
          size="small"
          ref={inputRef}
          style={tagInputStyle}
          value={inputValue}
          maxLength={MAX_TAG_LENGTH}
          status={!validateTag(inputValue) ? 'error' : undefined}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
        <Tag style={tagPlusStyle} icon={<PlusOutlined />} onClick={showInput}>
          Новый тег
        </Tag>
      </>}
    </Space>
  );
}

const validateTag = (tag: string) => {
  if (tag.length < MIN_TAG_LENGTH) {
    return false;
  }
  if (tag.length > MAX_TAG_LENGTH) {
    return false;
  }

  return true;
}