export interface WithCaptcha {
  captchaKey: string;
  captchaCode: string;
}

export enum VerifyState
{
    None = 0,
    Ok = 1,
    WrongCode = 2,
    Expired = 3,
    KeyNotFound = 4,
    UnknownError = 5,
}

export function VerifyStateToText(state: VerifyState) {
  switch (state) {
    case VerifyState.Ok:
      return "Ok";
    case VerifyState.WrongCode:
      return "Неверный код. Попробуйте еще раз";
    case VerifyState.Expired:
      return "Капча просрочена. Попробуйте еще раз";
    case VerifyState.KeyNotFound:
      return "Ключ не найден";
    case VerifyState.UnknownError:
      return "Неизвестная ошибка";
    case VerifyState.None:
      return undefined;
  }
}

export interface CaptchaVerifyResponse {
  verifyState: VerifyState;
}

export class CaptchaError extends Error {
	public State: VerifyState;
	public constructor(state: VerifyState) {
		super();
		this.State = state;
	};
}