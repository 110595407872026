import { useCallback, useState } from "react";

type Loading = boolean;
type Starter = <T>(input: Promise<T> | (() => Promise<T>)) => Promise<T | undefined>;
type ErrorMessage = string | undefined;

interface useLoadingProps {
    reThrowErrors?: boolean,
};

const useLoading = ({reThrowErrors}: useLoadingProps = {}): [Loading, Starter, ErrorMessage] => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const startLoading = useCallback(async <T>(input: Promise<T> | (() => Promise<T>)) => {
        setIsLoading(true);
        setError(undefined);
        try {
            const result = typeof input === "function"
                ? await input()
                : await input;
            return result;
        }
        catch (e) {
            console.error(e);
            setError(`${e}`);
            if (reThrowErrors){
                throw e;
            }
            return undefined;
        }
        finally {
            setIsLoading(false);
        }
    }, [reThrowErrors]);

    return [isLoading, startLoading, error];
}

export default useLoading;