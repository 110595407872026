import { Routes, Route, useNavigate } from 'react-router-dom'
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import MarketPage from "./pages/MarketPage";
import NotFoundPage from "./pages/NotFoundPage";
import { ConfigProvider, Layout } from "antd";
import UnauhtorizedPage from "./pages/UnauhtorizedPage";
import GskPage from "./pages/Gsk/GskPage";
import GskLayout from "./components/Gsk/GskLayout";
import GskMapsPage from "./pages/Gsk/GskMapsPage";
import GskBoxesPage from "./pages/Gsk/GskBoxesPage";
import GskCashePage from "./pages/Gsk/GskCashePage";
import GskDocsPage from "./pages/Gsk/GskDocsPage";
import { ReactNotifications } from "react-notifications-component";
import PricingPage from "./pages/PricingPage";
import GskAccrualsPage from "./pages/Gsk/GskAccrualsPage";
import InvitePage from "./pages/Gsk/InvitePage";
import ConfirmRegisterPage from "./pages/ConfirmRegisterPage";
import GskLogsPage from "./pages/Gsk/GskLogsPage";
import AccountNotConfirmed from "./pages/AccountNotConfirmedPage";
import GskUsersPage from "./pages/Gsk/GskUsersPage";
import UsersPage from "./pages/Admin/UsersPage";
import BlogPage from "./pages/BlogPage";
import FilesPage from "./pages/Admin/FilesPage";
import RabbitPage from "./pages/Admin/RabbitPage";
import PostPage from "./pages/PostPage";
import GskPostPage from "./pages/Gsk/GskPostPage";
import ChairmanInvitePage from "./pages/Gsk/ChairmanInvitePage";
import useUserBlock from "./components/User/UserBlock";
import LayoutPage from "./components/LayoutPage";
import { useDialog, useDialogWithParameter } from "./utils/useDialog";
import RestorePasswordModal from "./components/User/RestorePasswordModal";
import RestorePasswordPage from "./pages/RestorePasswordPage";
import locale from 'antd/locale/ru_RU';
import 'dayjs/locale/ru';
import GskHistoryModal, { GskHistoryModalProps } from "./components/GskHistoryModal/GskHistoryModal";
import { useAppDispatch, useAppSelector } from './store/redux';
import { getRedirect, setRedirect } from './store/common/app-slice';
import { useEffect } from 'react';
import MetersPage from './pages/Gsk/MetersPage';
import './main.scss'
import PromoPage from './pages/PromoPage';
import AgreementPage from './pages/AgreementPage';
import ContactsPage from './pages/ContactsPage';
import TariffsPage from './pages/Admin/TariffsPage';
import DiscountsPage from './pages/Admin/DiscountsPage';
import YooKassaPage from './pages/Help/GskBilling/YooKassaPage';
import CommissionPage from './pages/Help/GskBilling/CommissionPage';
import HelpLayoutPage from './pages/Help/HelpLayoutPage';
import MailingPage from './pages/Admin/MailingPage';
import GskListPage from './pages/GskListPage';
import HistoryPage from './pages/Admin/HistoryPage';
import { YandexLoginPage } from './pages/User/YandexLoginPage';
import { VkLoginPage } from './pages/User/VkLoginPage';

const App = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const redirect = useAppSelector(getRedirect);
  useEffect(() => {
    if (redirect) {
      navigate(redirect);
      dispatch(setRedirect(null));
    }
  });
  const [restorePasswordModal, openRestorePasswordModal] = useDialog(close => <RestorePasswordModal closeDialog={close} />);
	const [renderUserItems, openLoginModal, openProfileModal, openGskModal, userBlockForms] = useUserBlock(openRestorePasswordModal);
  const [gskHistoryModal, openGskHistoryModal] = useDialogWithParameter<GskHistoryModalProps>(
		(params, closeDialog) => <GskHistoryModal 
      entity={params.entity}
      includeChilds={params.includeChilds}
      gskId={params.gskId}
      entityId={params.entityId}
      closeDialog={closeDialog} />
	);

  return (
    <Layout>
      <ConfigProvider locale={locale}>
        <Routes>
          <Route path="/" element={<LayoutPage renderUserItems={renderUserItems}/>}>
            <Route index element={<HomePage openLoginModal={() => openLoginModal(undefined, undefined)} openRegisterModal={() => openProfileModal(undefined)}/>} />
            <Route path="Market" element={<MarketPage level="All" />} />
            <Route path="Promo" element={<PromoPage />} />
            <Route path="yandex_verification_code" element={<YandexLoginPage />} />
            <Route path="vk_verification_code" element={<VkLoginPage />} />
            <Route path="About" element={<AboutPage />} />
            <Route path="Pricing" element={<PricingPage />} />
            <Route path="Agreement" element={<AgreementPage />} />
            <Route path="Contacts" element={<ContactsPage />} />
            <Route path="Blog" element={<BlogPage />} />
            <Route path="Blog/:id" element={<PostPage />} />
            <Route path="Unauhtorized/*" element={<UnauhtorizedPage openLoginModal={openLoginModal} openProfileModal={openProfileModal} />} />
            <Route path="AccountNotCofirmed" element={<AccountNotConfirmed />} />
            <Route path="*" element={<NotFoundPage />} />
            <Route path="Invite/:guid" element={<InvitePage openLoginModal={openLoginModal} openProfileModal={openProfileModal} />} />
            <Route path="chairmanInvite/:guid" element={<ChairmanInvitePage openLoginModal={openLoginModal} openProfileModal={openProfileModal} />} />
            <Route path="confirmRegister/:key" element={<ConfirmRegisterPage />} />
            <Route path="restorePassword/:key" element={<RestorePasswordPage openLoginModal={openLoginModal} />} />
            <Route path="GskList" element={<GskListPage />} />
            <Route path="Admin/Files" element={<FilesPage />} />
            <Route path="Admin/Users" element={<UsersPage openProfileModal={openProfileModal}  />} />
            <Route path="Admin/Rabbit" element={<RabbitPage />} />
            <Route path="Admin/Mailing" element={<MailingPage />} />
            <Route path="Admin/History" element={<HistoryPage />} />
            <Route path="Admin/Billing/Tariffs" element={<TariffsPage />} />
            <Route path="Admin/Billing/Discounts" element={<DiscountsPage />} />
            <Route path='help' element={<HelpLayoutPage/>}>
              <Route path='gskBilling'>
                <Route path='commission' element={<CommissionPage/>}/>
                <Route path='yookassa' element={<YooKassaPage/>}/>
              </Route>
            </Route>
          </Route>
          <Route path="Gsk/:id" element={<GskLayout renderUserItems={renderUserItems} openGskModal={openGskModal}/>} >
            <Route path="Post/:postId" element={<GskPostPage />} />
            <Route index element={<GskPage openGskModal={openGskModal} />} />
            <Route path="Maps" element={<GskMapsPage />} />
            <Route path="Boxes" element={<GskBoxesPage openGskHistoryModal={openGskHistoryModal} />} />
            <Route path="Meters" element={<MetersPage />} />
            <Route path="Users" element={<GskUsersPage openProfileModal={openProfileModal}/>} />
            <Route path="Cash" element={<GskCashePage openGskHistoryModal={openGskHistoryModal} />} />
            <Route path="Cash/:boxid" element={<GskCashePage openGskHistoryModal={openGskHistoryModal}  />} />
            <Route path="Accruals" element={<GskAccrualsPage openGskHistoryModal={openGskHistoryModal} />} />
            <Route path="Pricing" element={<PricingPage />} />
            <Route path="Docs" element={<GskDocsPage />} />
            <Route path="Logs" element={<GskLogsPage />} />
          </Route>
        </Routes>
        <ReactNotifications />
        {userBlockForms}
        {restorePasswordModal}
        {gskHistoryModal}
      </ConfigProvider>
    </Layout>
  )
}

export default App;