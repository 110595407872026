
import { Input, Table, Flex, Button } from 'antd'
import React, { useMemo, useState } from 'react';
import { ContactData, Gsk, MainPageData } from '../../../types/GskTypes';
import ModalBase from '../../ModalBase/ModalBase';
import { ColumnsType } from 'antd/lib/table';
import { DndContext, type DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DeleteOutlined, MenuOutlined, UserAddOutlined } from '@ant-design/icons';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import IconButton from '../../IconButton/IconButton';
import { ShowWarning } from '../../Notifications/Notifications';
import { GskService } from '../../../Services/GskService';
import useLoading from '../../../utils/hooks/useLoading';


interface OwnProps {
  gsk: Gsk;
  mainPageData: MainPageData | undefined;
  onSaved: (v: MainPageData) => void;
  closeDialog: CallableFunction;
}

export default function EditContactsModal({ closeDialog, mainPageData, onSaved, gsk }: OwnProps) {
  const [isLoading, load] = useLoading();
  const contactsInit = useMemo(() => [...mainPageData?.contacts.list ?? []], [mainPageData])
  const [dataSource, setDataSource] = useState(contactsInit)

  const handleCellChange = (newVal: string, row: ContactData, dataIndex: string) => {
    setDataSource((prev) => {
      const rowIndex = prev.findIndex(p => p === row);
      let result = [...prev];
      result[rowIndex] = {...row, [dataIndex]: newVal}
      return result;
    });
  }

  const columns: ColumnsType<ContactData> = [
    {
      key: 'sort',
    },
    {
      title: 'ФИО',
      dataIndex: 'name',
      render: (v: string, row) => <Input 
        style={{width: 300}}
        value={v}
        onChange={e => handleCellChange(e.target.value, row, 'name')}
        placeholder='Петров Иван Сергеевич'
      />
    },
    {
      title: 'Должность',
      dataIndex: 'post',
      render: (v: string, row) => <Input 
        value={v}
        onChange={e => handleCellChange(e.target.value, row, 'post')}
        placeholder='Электрик'
      />
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      render: (v: string, row) => <Input 
        value={v}
        onChange={e => handleCellChange(e.target.value, row, 'phone')}
        placeholder='+7 (999) 123 45 67'
      />
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      render: (v: string, row) => <Input 
        value={v} 
        onChange={e => handleCellChange(e.target.value, row, 'email')}
        placeholder='petrov@mail.com'
      />
    },
    {
      render: (_, row) => <IconButton icon={<DeleteOutlined />} onClick={() => handlDeleteClick(row)}/>
    },
  ];

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.order === active.id);
        const overIndex = previous.findIndex((i) => i.order === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  const handlDeleteClick = (row: ContactData) => {
    setDataSource([...dataSource.filter(c => c !== row)]);
  }

  const handleOk = async () => { 
    let req: ContactData[] =  dataSource.map((val, ind) => ({
      name: val.name.trim(),
      email: val.email.trim(),
      phone: val.phone.trim(),
      post: val.post.trim(),
      order: ind + 1}))
      .filter(i => !(i.name === '' && i.phone === '' && i.email === '' && i.post === ''));
    if (req.some(r => r.name === '' || r.post === '')) {
      ShowWarning('Проверка данных', 'Заполните ФИО и должность в каждой строке');
      return;
    }
    if (req.some(r => r.phone === '' && r.email === '')) {
      ShowWarning('Проверка данных', 'Заполните телефон и/или E-mail в каждой строке');
      return;
    }
    setDataSource(req);
    if (!mainPageData) {
      throw new Error("!mainPageData");
    }
    const resp = await load(GskService.saveMainPageData(gsk.id, {...mainPageData, contacts: {...mainPageData.contacts, list: req}}));
    if (resp) {
      onSaved(resp);
      closeDialog();
    }
  }
  const handleAddRowClick = () => {
    setDataSource([...dataSource, {email: '', name: '', post: '',order: dataSource.length+1, phone: ''}])
  }
  return (
    <ModalBase 
      closeDialog={closeDialog}
      title={'Контакты'}
      okButtonHandler={handleOk}
      isLoading={isLoading}
      width={900}
    >
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={dataSource.map((i) => i.order)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: Row,
              },
            }}
            size='small'
            pagination={false}
            footer={() => <Flex justify='center'>
              <Button icon={<UserAddOutlined />} onClick={handleAddRowClick}>Добавить</Button>
            </Flex>}
            rowKey="order"
            columns={columns}
            dataSource={dataSource}
          />
        </SortableContext>
      </DndContext>
    </ModalBase>
  );
}


interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}
const Row = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};