import { useCallback, useMemo, useState } from "react";
import { GetGskRoleName, Gsk, GskRole } from "../../types/GskTypes";
import useCurrentGsk from "./useCurrentGsk";
import { Card, Form, Input, Row, Space, Spin } from "antd";
import GskRolesSelect from "../../components/Gsk/GskRolesSelect";
import { getEnumValues } from "../../utils/enumUtils";
import { UserGskData, UserModel, getUserFullName } from "../../types/UserTypes";
import UsersTable from "../../components/User/UsersTable";
import { useEffectOnce } from "../../utils/hooks/useEffectOnce";
import useLoading from "../../utils/hooks/useLoading";
import { GskService } from "../../Services/GskService";

interface Filter {
  text: string;
  gskRoles: GskRole[];
}

interface GskUsersPageProps {
  openProfileModal: (user?: UserModel, setUsers?: React.Dispatch<React.SetStateAction<UserModel[]>>) => void;
}
export default function GskUsersPage({ openProfileModal }: GskUsersPageProps) {
	const gsk = useCurrentGsk();
  return (<>{gsk ? <Page openProfileModal={openProfileModal} gsk={gsk}/> : 'Загрузка...'}</>)
}

interface PageProps {
  gsk: Gsk;
  openProfileModal: (user?: UserModel, setUsers?: React.Dispatch<React.SetStateAction<UserModel[]>>) => void;
}
const Page = ({gsk, openProfileModal}: PageProps) => {
  const [users, setUsers] = useState<UserModel[]>([]);
  const [isLoading, load] = useLoading()

  const [filter, setFilter] = useState<Filter>({text: '', gskRoles: getEnumValues(GskRole)});
  
  const usersFiltered = useMemo(() => users.filter(u => 
    (filter.text.length === 0 || getUserFullName(u).toLocaleLowerCase().includes(filter.text.toLocaleLowerCase()))
    && (filter.gskRoles.some(fr => u.gsks[gsk.id].roles.includes(fr)))
  ), [filter.gskRoles, filter.text, gsk.id, users])

  const fetchUsers = useCallback(async() => {
    const resp = await load(GskService.getGskUsers(gsk.id));
    if (resp) {
      setUsers(resp);
    }
  }
  , [gsk.id, load]);

  useEffectOnce(() => { 
    fetchUsers();
  });

  const renderRolesFunc = useCallback(
    (gsks: { [gskId: number]: UserGskData }) => {
      return (gsks[gsk.id]?.roles?.filter(r => r !== GskRole.Member).map(r => GetGskRoleName(r)).join(', '))
    }
  , [gsk.id])
  
  return (
    <Spin spinning={isLoading} style={{width: '100%'}}>
      <Space direction="vertical" style={{width: '100%'}}>
        <Card size="small">
          <Row>
            <Space>
              <Form.Item label='Фильтр'>
                <Input value={filter.text} onChange={e => setFilter({...filter, text: e.target.value.trim()})} placeholder="ФИО" />
              </Form.Item>
              <Form.Item label='Права'>
                <GskRolesSelect value={filter.gskRoles} onChange={e => setFilter({...filter, gskRoles: e})} style={{width: 230}} maxTagCount={1}/>
              </Form.Item>
            </Space>
          </Row>
        </Card>
        <UsersTable 
          isLoading={isLoading}
          usersFiltered={usersFiltered}
          renderRoles={renderRolesFunc}
          totalCount={users.length}
          openProfileModal={u => openProfileModal(u, setUsers)}
          onChangePage={v => fetchUsers()}
        />
      </Space>
    </Spin>
  )
}
