import { PagedRequest } from "./dto";
import { HistoryOperation } from "./GskHistoryTypes";

export enum HistoryEntity {
  USER = 0,
  POST = 1,
  BILLING = 2,
}

export const GetHistoryEntityName = (entity: HistoryEntity) => {
  switch (entity) {
    case HistoryEntity.USER:
      return "Пользователь";
    case HistoryEntity.POST:
      return "Новость";
    case HistoryEntity.BILLING:
      return "Биллинг";
    default:
      throw Error("Not implemented for HistoryEntity = " + entity)
  }
}

export interface HistoryModel
{
  id: number;
  entity: HistoryEntity;
  entityId: number | null;
  operation: HistoryOperation;
  changes?: string;
  createdBy: string;
  created: Date;
}

export interface HistoryRequest extends PagedRequest {
  entityTypes: HistoryEntity[];
  operations: HistoryOperation[];
  entityId: number | null;
  createdBy: string | null;
  periodStart: string | null;
  periodEnd: string | null;
}

