import { Checkbox, Form, Input } from "antd";
import { GskModel } from "../../../types/GskTypes";
import AddressInput from "../../Dadata/AddressInput";
import { AddressLevel, AddressModel } from "../../../types/AddressTypes";

const checkName = (value: string) => {
  let errors: string[] = [];
  
  if (value.length < 1) {
    errors.push("Короче 3 символов");
  }
  if (value.length > 12) {
    errors.push("Длиннее 12 символов");
  }

  if (errors.length === 0) {
    return Promise.resolve();
  }
  return Promise.reject(<>{errors.map(e => <span key={e}>{e}<br/></span>)}</>);
}

//TODO not working
const checkAddress = (value: AddressModel) => {
  let errors: string[] = [];
  
  if (value.value?.length < 3) {
    errors.push("Введите корректный адрес");
  }

  if (errors.length === 0) {
    return Promise.resolve();
  }
  return Promise.reject(<>{errors.map(e => <span key={e}>{e}<br/></span>)}</>);
}

interface PropsTabProps {
  gsk?: GskModel;
}

export default function GskPropsTab ({ gsk } : PropsTabProps) {
  return (<>
    <Form.Item noStyle label='Id' name='id'>
      <Input type="hidden" />
    </Form.Item>
    <Form.Item 
      label='Название'
      name='name'
      tooltip={''}
      rules={[{required: true, validator: (_, v) => checkName(v)}]}
    >
      <Input placeholder="ГСК-000"/>
    </Form.Item>
    <Form.Item 
      label='Адрес' 
      name='address'
      tooltip={'Начните вводить адрес и выбирайте верный из подсказок. Желательно указанть адрес с точностью до дома'}
      rules={[{validator: (_, v) => checkAddress(v)}]}
    >
      <AddressInput initAddress={gsk?.address} level={AddressLevel.House}  />
    </Form.Item>
    <Form.Item 
      label='Публичный ГСК' 
      name='isPublic'
      tooltip={'Главная страница ГСК (Новости и контакты) будут видным всем пользователям, в т.ч не зарегистрированным'}
      valuePropName="checked"
    >
      <Checkbox />
    </Form.Item>
  </>)
}