import { Store } from 'react-notifications-component';

export function ShowSuccess(title: string, message: string, duration: number = 5000) {
  Store.addNotification({
    title: title,
    message: message,
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: duration,
      onScreen: true
    }
  });
}

export function ShowError(title: string, message: string = "", duration: number = 5000) {
  Store.addNotification({
    title: title,
    message: message,
    type: 'danger',
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],

    dismiss: {
      showIcon: true,
      duration: duration,
      onScreen: true
    }
  });
}


export function ShowInfo(title: string, message: string, duration: number = 5000) {
  Store.addNotification({
    title: title,
    message: message,
    type: 'info',
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: duration,
      onScreen: true
    }
  });
}

export function ShowWarning(title: string, message: string, duration: number = 5000) {
  Store.addNotification({
    title: title,
    message: message,
    type: 'warning',
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: duration,
      onScreen: true
    }
  });
}

export function ShowErrorMessage(error: any, title: string) {
  if (error instanceof Error) {
    ShowError(title, error.message);
  } else {
    ShowError(title);
  }
}