import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';
import { setupStore } from './store/configureStore';
import './JsExtensions'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
export const reduxStore = setupStore();
reduxStore.getState();

root.render(
  <Provider store={reduxStore}>
    {/* <React.StrictMode> */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
    {/* </React.StrictMode> */}
  </Provider>
);

