import { Checkbox, Select } from "antd";
import { useEffect, useState } from "react";

interface Props<TOption, TValue> {
  options: TOption[];
  getLabel: (v: TOption) => string;
  getValue: (v: TOption) => TValue;
  mode?: 'multiple' | 'tags';
  value?: TValue[];
  onChange?: (value: TValue[] | undefined) => void;
}
export default function MultiplySelect<TOption, TValue>({
  mode = 'multiple',
  getLabel,
  getValue,
  options,
  value,
  onChange
}: Props<TOption, TValue>) {
  const [allSelected, setAllSelected] = useState(false);
  const handleCheckboxClick = () => {
    if (!onChange) {
      return;
    }
    if (allSelected) {
      onChange([]);
    } else {
      onChange(options.map(o => getValue(o)))
    }
    setAllSelected(!allSelected);
  }

  useEffect(() => {
    setAllSelected(value?.length === options.length && value?.length > 0);
  }, [options.length, value])

  return <Select
    showSearch={false}
    mode={mode}
    value={value}
    options={options.map(o => ({value: getValue(o), label: getLabel(o)}))}
    onChange={onChange}
    dropdownRender={(menu) => (
      <>
        <Checkbox
          checked={allSelected}
          onClick={handleCheckboxClick}
          style={{marginLeft: 5, marginBottom: 3}}
        >
          Все
        </Checkbox>
        
        {menu}
      </>
    )}
  />
}