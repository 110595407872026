import { Alert, Button, Card, DatePicker, Flex, Form, FormInstance, Input, InputNumber, Radio, Space, Switch } from "antd";
import GroupBox from "../../GroupBox/GroupBox";
import { GskOptionModel, GskQuestionModel } from "../../../types/GskPostTypes";
import { DeleteOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import dayjs from 'dayjs'
import { Declension } from "../../../utils/DeclensionGenerator";
import { VotePosition } from "../../../types/PostBaseTypes";

interface EditVoteConfigurationPartialProps {
  form: FormInstance;
  isGskMode?: boolean;
}

export default function EditVoteConfigurationCommon({form, isGskMode = false}: EditVoteConfigurationPartialProps){
  const voteEnabled = Form.useWatch('voteEnabled', form);
  const votedCount: number = form.getFieldValue(['voteConfiguration', 'votedCount']);
  const voteStarted = votedCount > 0;
  const voteStartedTip = voteStarted ? `Голосование начато. Уже есть ${Declension(votedCount, 'голос')}. Изменение заблокировано` : undefined;
  const validateVoteDateEnd = async (dateString?: string) => {
    if (!dateString) {
      return Promise.reject('Введите дату');
    }
    const date = dayjs(dateString, 'DD.MM.YYYY').toDate();
    if (date.getUTCDate() <= new Date().getUTCDate()) {
      return Promise.reject('Должна быть из будующего');
    }

    return Promise.resolve();
  }

  const validateQuestions = async (questions?: GskQuestionModel[]) => {
    if (!questions || questions.length < 1) {
      return Promise.reject('Должен быть минимум 1 вопрос');
    }

    return Promise.resolve();
  }

  const validateOptions = async (options?: GskOptionModel[]) => {
    if (!options || options.length < 2) {
      return Promise.reject('Должно быть минимум 2 варианта');
    }
    return Promise.resolve();
  }

  return (<>
    <Form.Item 
      label='С голосованием' 
      name='voteEnabled'
      tooltip={voteStartedTip}
      valuePropName='checked'
    >
      <Switch disabled={voteStarted} title={voteStartedTip}/>
    </Form.Item>
    {voteEnabled && !voteStarted && <Flex justify='flex-end'>
    <Alert type="info" message='Голосование нельзя будет отключить или изменить вопросы после того, как проголосует первый человек'/>
    </Flex>
    }
    
    {voteEnabled && <GroupBox label='Голосование' style={{marginBottom: 20}}>
      <Form.Item 
        label='Позиция' 
        name={['voteConfiguration','position']}
        rules={[{required: true, message: 'Выбирете расположение'}]}
      >
        <Radio.Group>
          <Radio value={VotePosition.BeforePost}>Перед текстом</Radio>
          <Radio value={VotePosition.AfterPost}>После текста</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item 
        hidden={!isGskMode}
        label='Анонимно' 
        name={['voteConfiguration','isAnonymous']}
        tooltip='Если включено, в результатах не будет отображаться кто за что голосовал'
        rules={[{required: true, message: 'Выбирете значение'}]}
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>
      <Form.Item 
        label='Результаты' 
        name={['voteConfiguration','showResultsBeforeFinish']}
        tooltip='Если включено, результаты будут видны до окончания голосования'
        rules={[{required: true, message: 'Выбирете значение'}]}
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>
      <Form.Item 
        label='Дата окончания опроса' 
        name={['voteConfiguration','dateFinish']}
        required
        rules={[{validator: (_, v) => validateVoteDateEnd(v)}]}
        tooltip='После указанной даты участие в опросе станет невозможным'
      >
        <DatePicker format="DD.MM.YYYY"/>
      </Form.Item>
      <Form.Item label='Вопросы'>
        <>
          {voteStarted && 
            <Alert 
              type='warning'
              style={{marginBottom: 10}}
              message={voteStartedTip} 
            />}
          <Form.List name={['voteConfiguration', 'questions']} rules={[{validator: (_, value) => validateQuestions(value)}]} >
            {(questions, { add, remove, move }, questionsErrors) => (
              <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                {questions.map((question) => (
                  <Card
                    size="small"
                    title={`Вопрос ${question.name + 1}`}
                    key={question.key}
                    extra={!voteStarted &&
                      <Space>
                        {questions.indexOf(question) > 0 &&<UpOutlined
                          onClick={() => {
                            const index = questions.indexOf(question);
                            if (index - 1 >= 0) {
                              form.setFieldValue(['voteConfiguration', 'questions', index, 'order'], index - 1);
                              form.setFieldValue(['voteConfiguration', 'questions', index - 1, 'order'], index);
                            }
                            move(index, index - 1);
                          }}
                        />}
                        {questions.indexOf(question) !== questions.length - 1 && <DownOutlined
                          onClick={() => {
                            const index = questions.indexOf(question);
                            if (index + 1 < questions.length) {
                              form.setFieldValue(['voteConfiguration', 'questions', index, 'order'], index + 1);
                              form.setFieldValue(['voteConfiguration', 'questions', index + 1, 'order'], index);
                            }
                            move(index, index + 1);
                          }}
                        />}
                        <DeleteOutlined onClick={() => remove(question.name)} />
                      </Space>
                    }
                  >
                    <Form.Item
                      label="Вопрос"
                      name={[question.name, 'question']}
                      rules={[{required: true, message: 'Введите значение'}]}
                      labelCol={{style: {width: 180}}}
                    >
                      <Input disabled={voteStarted} title={voteStartedTip} />
                    </Form.Item>
                    <Form.Item
                      label="Количество ответов"
                      tooltip='Сколько вариантов может выбрать голосующий'
                      name={[question.name, 'allowedToChoiceQty']}
                      required
                      labelCol={{style: {width: 180}}}
                    >
                      <InputNumber min={1} max={20} disabled={voteStarted} title={voteStartedTip} />
                    </Form.Item>
                    <Form.Item 
                      noStyle
                      name={[question.name, 'order']}
                      labelCol={{style: {width: 180}}}
                    >
                      <Input type='hidden'/>
                    </Form.Item>
                    {/* Варианты ответов */}
                    <Form.Item label="Варианты ответов" required labelCol={{style: {width: 180}}}>
                      <Form.List name={[question.name, 'options']} rules={[{validator: (_, value) => validateOptions(value)}]} >
                        {(options, subOpt, optionsErrors) => (
                          <div style={{ display: 'flex', flexDirection: 'column', rowGap: 0 }}>
                            {options.map((option) => (
                              <Space key={option.key} style={{alignItems: 'flex-start'}}>
                                <Form.Item name={[option.name, 'name']} rules={[{required: true, message: 'Введите значение'}]}>
                                  <Input placeholder="..."  disabled={voteStarted} title={voteStartedTip} />
                                </Form.Item>
                                {!voteStarted && <>
                                  <UpOutlined
                                    onClick={() => {
                                      const index = options.indexOf(option);
                                      if (index - 1 >= 0) {
                                        form.setFieldValue(['voteConfiguration', 'questions', question.name, 'options', index, 'order'], index - 1);
                                        form.setFieldValue(['voteConfiguration', 'questions', question.name, 'options', index - 1, 'order'], index);
                                      }
                                      subOpt.move(index, index - 1);
                                    }}
                                  />
                                  <DownOutlined
                                    onClick={() => {
                                      const index = options.indexOf(option);
                                      if (index + 1 < options.length) {
                                        form.setFieldValue(['voteConfiguration', 'questions', question.name, 'options', index, 'order'], index + 1);
                                        form.setFieldValue(['voteConfiguration', 'questions', question.name, 'options', index + 1, 'order'], index);
                                      }
                                      subOpt.move(index, index + 1);
                                    }}
                                  />
                                  <DeleteOutlined onClick={() => subOpt.remove(option.name)} />
                                </>
                                }
                                <Form.Item noStyle name={[option.name, 'order']}>
                                  <Input type='hidden'/>
                                </Form.Item>
                              </Space>
                            ))}
                            <Button 
                              type="dashed"
                              onClick={() => subOpt.add({order: options.length})} style={{width: 200}}
                              disabled={voteStarted} title={voteStartedTip} 
                            >
                              + Вариант ответа
                            </Button>
                            <Form.ErrorList errors={optionsErrors.errors} />
                          </div>
                        )}
                      </Form.List>
                    </Form.Item>
                  </Card>
                ))}

                <Button 
                  type="dashed" 
                  disabled={voteStarted}
                  onClick={() => add({order: questions.length, allowedToChoiceQty: 1, options: [{order: 0},{order: 1}]})} style={{width: 200}}
                >
                  + Вопрос
                </Button>
                <Form.ErrorList errors={questionsErrors.errors}></Form.ErrorList>
              </div>
            )}
          </Form.List>
        </>
      </Form.Item>
    </GroupBox>}
  </>)
}