import { useMemo, useRef, useState, useEffect, KeyboardEvent, useCallback } from 'react'

import { Stage, Layer, Image } from 'react-konva'
import PolygonAnnotation from './BoxPolygon'
import Konva from 'konva'
import { MapPolygon, MapSettings, Point, ScalseState } from './GskMapTypes';
import isEqual from 'lodash.isequal';

interface Size {
    width: number;
    height: number;
}

interface Props {
  mapImage: string;
  polygons: MapPolygon[];
  height?: number;
  width?: number;
  activePolygonId?: number;
  mapSettings: MapSettings;
  readonly?: boolean;
  savePolygon?: (v: MapPolygon) => void;
  onPolygonClick?: (value: MapPolygon | null) => void;
  onPolygonRightButtonClick?: (value: MapPolygon, point: Point) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void;
}

const checklPolygonChanged = (allPolygons: MapPolygon[], p: MapPolygon) => {
  if (p.id < 0) {
    return true;
  }

  const ps = allPolygons?.find(i => i.id === p.id);
  if (!ps) {
    return true;
  }

  if (!isEqual(p.points, ps.points)) {
    return true;
  }

  if (!isEqual(p.label, ps.label)) {
    return true;
  }

  if (p.isFinished !== ps.isFinished) {
    return true;
  }

  return false;
}

const GskMap = ({
    mapImage,
    polygons,
    height,
    width,
    activePolygonId,
    mapSettings,
    readonly = true,
    savePolygon = () => null,
    onPolygonClick = () => null,
    onPolygonRightButtonClick = () => null,
    onKeyDown = () => null,
}: Props) => {
  // console.log('render gskMap')
  const [image, setImage] = useState<HTMLImageElement | undefined>();
  const imageRef = useRef(null);
  const [size, setSize] = useState<Size>({width: width ?? 1900, height: height ?? 1200});
  const [position, setPosition] = useState<Konva.Vector2d>({x: 0, y: 0});
  const [scaleState, setScaleState] = useState<ScalseState>({ stageScale: 1, stageX: 0, stageY: 0 });
  const [currentPolygon, setCurrentPolygon] = useState<MapPolygon | null>(null);
  const [isMouseOverStartPoint, setMouseOverStartPoint] = useState(false);
  const [imageScale, setImageScale] = useState<number>(1);
  const [lockLayerClick, setLockLayerClick] = useState(false);

  const polygonsTemp = useMemo(() => {
    if (currentPolygon) {
      return [...polygons.filter(p => p.id !== currentPolygon.id), currentPolygon]
    } else {
      return polygons;
    }
  }, [currentPolygon, polygons])

  const imageElement = useMemo(() => {
    const element = new window.Image()
    element.src = mapImage;
    return element
  }, [mapImage, width, height]);


  const updateCurrentPolygon = useCallback(async (value: MapPolygon) => {
    setCurrentPolygon(value);
    
    if (value.isFinished) {
      if (checklPolygonChanged(polygons, value)) {
        await savePolygon(value);
      }
    }
  }, [polygons, savePolygon]);

  useEffect(() => {
    const onload = function () {
      setSize({
        width: width ?? imageElement.width,
        height: height ?? imageElement.height,
      });

      //вычисление масштаба изображения
      const minScale = Math.min((size.width / imageElement.width ),(size.height / imageElement.height));
      if (minScale < 1) {
        setImageScale(minScale)
      }

      //#region сохранить пропорции изображения
      const scaleH = imageElement.height / size.height;
      const scaleW = imageElement.width / size.width;
      

      if (scaleH > 1 || scaleW > 1) {
        if (scaleH > scaleW) {
          imageElement.height = size.height;
          imageElement.width = imageElement.width / scaleH;
        } else {
          imageElement.height = imageElement.height / scaleW;
          imageElement.width = size.width;
        }
      }
      //#endregion
      setImage(imageElement);
      if (!imageElement) {
        imageRef.current = imageElement;
        }
    }
    imageElement.addEventListener('load', onload);
    return () => {
      imageElement.removeEventListener('load', onload);
    }
  }, [height, size, imageElement, width]);

  useEffect(() => {
    if (currentPolygon && activePolygonId !== currentPolygon?.id) { // finish/reset polygon on switch
      if (!currentPolygon.isFinished) {
        if (currentPolygon.points.length < 3) {
          currentPolygon.points = [];
        } else {
          currentPolygon.isFinished = true;
        }
      }
      updateCurrentPolygon({...currentPolygon});
    }
    setCurrentPolygon(polygons.find(i => i.id === activePolygonId) ?? null)
  }, [activePolygonId, polygons]);

  const getMousePos = (stage: Konva.Stage):Konva.Vector2d => {
    return  {
      x: (stage.getPointerPosition()?.x ?? -1) , 
      y: (stage.getPointerPosition()?.y ?? -1)
    };
  }

  const handleMouseMove = useCallback((e: Konva.KonvaEventObject<MouseEvent>) => {
    if (!currentPolygon || currentPolygon.isFinished) {
      return;
    }

    //TODO: Уменьшить к-во рендеров.
    const stage = e.target.getStage();
    if (stage) {
      const mousePos = getMousePos(stage);
      mousePos.x = Math.round((mousePos.x - scaleState.stageX) / scaleState.stageScale / imageScale);
      mousePos.y = Math.round((mousePos.y - scaleState.stageY) / scaleState.stageScale / imageScale);
      setPosition(mousePos);
    }
  }, [currentPolygon, imageScale, scaleState.stageScale, scaleState.stageX, scaleState.stageY]);
  
  const handleMouseDown = useCallback((e: Konva.KonvaEventObject<MouseEvent>) => {
    if (readonly || !currentPolygon || currentPolygon.isFinished) {
      return;
    }
    
    if (e.evt.button === 2) { //rigth mouse click
      return;
    }

    const stage = e.target.getStage();
    if (stage) {
      const mousePos = getMousePos(stage);
      mousePos.x = Math.round((mousePos.x - scaleState.stageX) / scaleState.stageScale / imageScale);
      mousePos.y = Math.round((mousePos.y - scaleState.stageY) / scaleState.stageScale / imageScale);
      if (isMouseOverStartPoint && currentPolygon.points.length >= 3) {
        updateCurrentPolygon({...currentPolygon, isFinished: true});
      } else {
        updateCurrentPolygon({...currentPolygon, points: [...currentPolygon.points, mousePos]});
      }
    }
  }, [currentPolygon, imageScale, isMouseOverStartPoint, readonly, scaleState.stageScale, scaleState.stageX, scaleState.stageY, updateCurrentPolygon]);

  const handleMouseWheel = useCallback((e: Konva.KonvaEventObject<WheelEvent>) => {
    e.evt.preventDefault();

    const scaleBy = .7;
    const stage = e.target.getStage();
    if (!stage) {
      return;
    }
    const oldScale = stage.scaleX();
    const pos = stage.getPointerPosition();
    if (!pos) {
      return;
    }
    const mousePointTo = {
      x: pos.x / oldScale - stage.x() / oldScale,
      y: pos.y / oldScale - stage.y() / oldScale
    };

    let newScale = Math.round(e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy * 100)/100;
    let stageX = Math.round(-(mousePointTo.x - pos.x / newScale) * newScale);
    let stageY = Math.round(-(mousePointTo.y - pos.y / newScale) * newScale);
    if (newScale < 1) {
      newScale = 1;
      stageX = 0;
      stageY = 0;
    }

    setScaleState({
      stageScale: newScale,
      stageX: stageX,
      stageY: stageY        
    });
  }, []);

  const handleLayerClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
    if(currentPolygon && !currentPolygon.isFinished){
      return;
    }

    if (e.evt.button === 2) { //rigth mouse click
      return;
    }

    if (!lockLayerClick) {
      onPolygonClick(null);
    } 
  }

  //#region contextMenu



  const handleMapContexMenu = (evt: Konva.KonvaEventObject<PointerEvent>) => {
    evt.evt.preventDefault();
  }

  const polygonsRendered = useMemo(() => 
  {
    const handlePolygonRightButtonClick = (polygon: MapPolygon, point: Point) => {
      setLockLayerClick(true);
      onPolygonRightButtonClick(polygon, point);
    }

    const handlePolygonMouseUp = () => {
      setTimeout(() => {
        setLockLayerClick(false);
      }, 100);
    }
    
    const handlePolygonClick = (polygon: MapPolygon) => {
      if (currentPolygon && !currentPolygon.isFinished) {
        return;
      }
      setLockLayerClick(true);
      onPolygonClick(polygon);
    }
    
    return polygonsTemp.filter(p => p.points.length > 0 || p.id === currentPolygon?.id).map(p => {
      return <PolygonAnnotation 
        key={p.id}
        polygon={p}
        mousePos={position}
        imageScale={imageScale}
        scaleState={scaleState}
        readOnly={readonly}
        isSelected={p.id === currentPolygon?.id}
        mapSettings={mapSettings}
        updateCurrentPolygon={updateCurrentPolygon}
        setMouseOverStartPoint={setMouseOverStartPoint}
        onClick={handlePolygonClick}
        onRightButtonClick={handlePolygonRightButtonClick}
        handleMouseUp={handlePolygonMouseUp}
      />
    });
  }
  , [currentPolygon, imageScale, mapSettings, onPolygonClick, onPolygonRightButtonClick, polygonsTemp, position, readonly, scaleState, updateCurrentPolygon])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      tabIndex={1}
      onKeyDown={onKeyDown}
    >
      <Stage
        width={size.width || 480}
        height={size.height || 360}
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onWheel={handleMouseWheel}
        scaleX={scaleState.stageScale}
        scaleY={scaleState.stageScale}
        x={scaleState.stageX}
        y={scaleState.stageY} 
        onClick={handleLayerClick}
        onContextMenu={handleMapContexMenu}
      >
        <Layer>
          <Image ref={imageRef} image={image} x={0} y={0} fillEnabled={false}/>
          {polygonsRendered}
        </Layer>
      </Stage>
    </div>
  )
}

export default GskMap