import { Steps } from "antd";
import { useState } from "react";
import { yooKassaPartherUrl } from "../Constants";
import { Link, useSearchParams } from "react-router-dom";

const gskIdDefault = '<gskId>';

export default function YooKassaPage() {
  const [searchParams] = useSearchParams();
  const gskId = searchParams.get("gskId")
  const gskName = searchParams.get("gskName");
  const [current, setCurrent] = useState(0);
  const onChange = (value: number) => {
    setCurrent(value);
  };
  return (<>
    <h1>Настройка онлайн оплаты взносов {gskName ? gskName : 'ГСК'}</h1>
    <p>Для приема платежей мы используем посредника - ЮКасса, он же ЮМани. Про коммиссию сервиса можно прочитать <Link to='/help/gskBilling/commission'>здесь</Link></p>
    <Steps
      direction="vertical"
      current={current}
      onChange={onChange}
      items={[
        {
          title: 'Зрегистрируйтесь на сайте ЮКасса',
          description: <>Откройте <a href={yooKassaPartherUrl} target="_blank" rel="noreferrer">страницу регистрации</a> и зарегистрируйтесь</>,
        },
        {
          title: 'Зарегистрируйте магазин',
          description:<ul>
            <li>В личном кабинете найдите пункт <b>Добавить магазин</b></li>
            <li>Выберите "На сайте" и укажите адрес вашего ГСК, например https://мойсгк.рф/gsk/{gskId ?? gskIdDefault}/</li>
          </ul>,
        },
        {
          title: 'Выпустите секретный ключ API',
          description:<>Внимание! Никому не сообщайте свой ключ API и код магазина</>,
        },
        {
          title: 'Настройте URL уведомлений',
          description: <>для получения информации об успешных платежах.
          В Личном кабинете ЮКасса перейдите в раздел Интеграции\HTTP-уведомления и вставьте <b>https://xn--c1alefku.xn--p1ai/gsk/{gskId ?? gskIdDefault}</b></>
        },
        {
          title: 'Настройте биллинг',
          description:<>
            Заполните код магазина, секретный ключ API, а так же введите процент комиссии в настройках Вашего ГСК
          </>,
        },
        {
          title: 'Готово',
          description: <>Теперь члены ГСК могут оплачивать взносы через сайт Вашего ГСК</>,
        },
        
      ]}
    />
  </>
  )
}
