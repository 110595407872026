import { EnumDictionary } from "../../types/EnumDictionary";

export enum RefType {
  MainPageLink = "MainPageLink",
  MainPageGskListBlock = "MainPageGskListBlock",
  MenuBlogLink = "MenuBlogLink",
  MenuAccountLink = "MenuAccountLink",

  GskMenuBoxesLink = "GskMenuBoxesLink",
  GskMenuCasheLink = "GskMenuCasheLink",
  GskMenuAccrualsLink = "GskMenuAccrualsLink",

  GskPageMyBoxes = "GskPageMyBoxes",
  GskPageNews = "GskPageNews",
  GskPageContacts = "GskPageContacts",

  GskBoxesAddButton = "GskBoxesAddButton",
  GskBoxesTable = "GskBoxesTable",
  GskBoxesFilter = "GskBoxesFilter",
  GskAccrualsAddButton = "GskAccrualsAddButton",
  GskAccrualsRenderChargesButton = "GskAccrualsRenderChargesButton",
  GskAccrualsTable = "GskAccrualsTable",
  GskAccrualsFilter = "GskAccrualsFilter",
  GskMetersFirstRowAddMeter = "GskMetersFirstRowAddMeter",
  GskMetersFirstMeterNum = "GskMetersFirstMeterNum",
  GskMetersFirstRowMeterAddReadings = "GskMetersFirstRowMeterAddReadings",
  GskMetersFirstRowMeterReadings = "GskMetersFirstRowMeterReadings",
}

export var appRefs: Partial<EnumDictionary<RefType, React.MutableRefObject<null>>> = {};
export const getAppRef = (type: RefType) => appRefs[type] ?? {} as React.MutableRefObject<null>
export const setAppRef = (type: RefType, ref: React.MutableRefObject<null>) => {
  appRefs[type] = ref;
}