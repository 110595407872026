import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Input, List, Space, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import useLoading from "../utils/hooks/useLoading";
import { EmptyPagedResult, PagedResult } from "../types/dto";
import { GskModel } from "../types/GskTypes";
import { GskService } from "../Services/GskService";
import { useEffectOnce } from "../utils/hooks/useEffectOnce";
import RowTwoCol from "../components/RowTwoCol/RowTwoCol";
import { useCurrentUser } from "../store/common/app-slice";
import { LockIcon, UnlockIcon } from "../components/icons";
import { UserRole } from "../types/UserTypes";

const PAGE_SIZE = 10;
export default function GskListPage () {
  const [isLoading, load] = useLoading();
  const [page, setPage] = useState<PagedResult<GskModel>>(EmptyPagedResult<GskModel>(PAGE_SIZE));
	const [filter, setFilter] = useState<{name?: string, address?: string}>({});
	const currentUser = useCurrentUser();



	const handleFilterTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFilter(prev => ({...prev, name: e.target.value}))
	}

	const handleFilterAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFilter(prev => ({...prev, address: e.target.value}))
	}

  const loadPage = useCallback(async (page: number, pageSize: number) => {
    const resp = await load(GskService.getGskPage({
      page,
      pageSize,
			address: filter.address,
			name: filter.name,
    }));
    if (resp) {
      setPage(resp);
    }
  }, [load, filter]);

  const onFilterEnter = (e?: React.KeyboardEvent<HTMLInputElement>) => {
    if (!e || e.key === 'Enter') {
      loadPage(1, page.pageSize);
    }
  }

  useEffectOnce(() => { loadPage(1, PAGE_SIZE); });

	return (<Space direction="vertical">
		<Card size='small'>
			<RowTwoCol
				left={
					<Space>
						<Tooltip title={currentUser ? undefined : 'Войдите под своей учетной записью'}>
							<Input disabled={!currentUser} placeholder="Название" value={filter.name} onChange={handleFilterTextChange} onKeyUp={onFilterEnter} />
						</Tooltip>
						<Tooltip title={currentUser ? undefined : 'Войдите под своей учетной записью'}>
							<Input disabled={!currentUser} placeholder="Адрес" value={filter.address} onChange={handleFilterAddressChange} onKeyUp={onFilterEnter} />
						</Tooltip>
					</Space>
				}
				right={
					<Tooltip title={currentUser ? undefined : 'Войдите под своей учетной записью'}>
						<Button disabled={!currentUser} icon={<SearchOutlined/>} onClick={() => onFilterEnter()}>Поиск</Button>
					</Tooltip>
				}
				rightSize={200}
			/>
		</Card>
		<Card size='small'>
			<List
				dataSource={page.items}
				split={true}
				loading={isLoading}
				pagination={{
					disabled: !currentUser,
					style: {display: !!currentUser ? 'unset' : 'none'},
					pageSize: page.pageSize,
					current: page.page,
					defaultPageSize: PAGE_SIZE,
					total: page.totalCount,
					onChange: (page, size) => loadPage(page, size)
				}}
				renderItem={(gsk) => <List.Item>
					<List.Item.Meta 
						title={(gsk.isPublic || !!currentUser?.gsks[gsk.id] || currentUser?.role === UserRole.Admin) ? 
							<Link to={"../Gsk/" + gsk.id}>{gsk.name} <UnlockIcon/></Link> : 
							<Tooltip title='ГСК закрыт и Вы не являетесь его членом'>
								<span style={{color: 'gray'}}>{gsk.name} <LockIcon /></span>
							</Tooltip>}
						description={gsk.address.value} />
				</List.Item>}
			/>
		</Card>
	</Space>)
}