import { useState } from "react";
import { useEffectOnce } from "../../../utils/hooks/useEffectOnce";
import { BoxesService } from "../../../Services/BoxesService";
import { Alert, Card, Flex, Form, FormInstance, Input, Radio, Space } from "antd";
import { DiscountCard, PriceCell } from "../../../pages/PricingPage";
import { DiscountModel, TariffModel, TariffType } from "../../../types/BillingTypes";
import { BillingService } from "../../../Services/BillingService";
import { GskModel } from "../../../types/GskTypes";

interface Props {
  form: FormInstance;
  gsk?: GskModel | null;
  load: <T>(input: Promise<T> | (() => Promise<T>)) => Promise<T | undefined>;
}

export default function ChooseTariffTab({form, gsk, load} : Props) {
  const [boxesCount, setBoxesCount] = useState<number>(0);
  const [tariffs, setTariffs] = useState<TariffModel[]>([]);
  const [discount, setDiscount] = useState<DiscountModel | undefined>();
  const isNewGsk = !gsk;

  useEffectOnce(() => {
    const fetch = async () => {
      const tariffsResp = await load(BillingService.getTariffs(true));
      if (tariffsResp) {
        setTariffs(tariffsResp.sort((a,b) => a.price - b.price));
      }
    }
    fetch();
  })

  useEffectOnce(() => {
    const fetch = async () => {
      const discountsResp = await load(BillingService.getDiscounts(true));
      if (discountsResp?.[0]) {
        setDiscount(discountsResp[0]);
        form.setFieldValue('discountId', discountsResp[0].id);
      }
    }
    fetch();
  })

  useEffectOnce(() => {
    if (!gsk?.id) {
      return;
    }
    const fetch = async () => {
      setBoxesCount(await load(BoxesService.getBoxesCount(gsk.id)) ?? 0);
    }
    fetch();
  })

  return (<>
    <Form.Item hidden name='discountId'><Input/></Form.Item>
    <Form.Item
      labelCol={{span: 4}}
      wrapperCol={{span: 20}}
      label='Тариф'
      name='tariffId'
      rules={[{required: true, message: 'Выберите тариф'}]}
    >
      <Radio.Group>
        <DiscountCard discount={discount} />
        {!isNewGsk && <Alert style={{marginBottom: 10, textAlign: 'center'}} type="info" message={<>В ГСК зарегистрировано <b>{boxesCount}</b> боксов</>} />}
        <Flex wrap="wrap" gap="small" justify="flex-start" align="flex-start" >
          {tariffs.map(t => 
            <Card key={t.id} size="small" style={{minWidth: 197, minHeight: 110, boxShadow: '6px 9px 29px -18px rgba(0,0,0,0.65)'}}>
              <Radio value={t.id} disabled={
                ((t.boxesLimit ?? -1) < boxesCount && t.type !== TariffType.BoxesUnlimited) || (!isNewGsk && t.type === TariffType.Trial)}>
                <Space direction='vertical'>
                  <span style={{fontSize: '1.1em'}}>{t.name}</span>
                  <span style={{fontSize: '0.8em', color: 'gray'}}>{t.description}</span>
                  <PriceCell price={t.price} discountPercent={discount?.percent} />
                </Space>
              </Radio>
            </Card>
          )}
        </Flex>
      </Radio.Group>
    </Form.Item>
  </>)
}