import { useCallback, useMemo, useState } from 'react'
import ModalBase from '../ModalBase/ModalBase';
import { BoxShortModel, GskChairmanInviteModel } from '../../types/GskTypes';
import { Form, Select, Alert, Button, Modal } from 'antd'
import { useForm } from 'antd/es/form/Form';
import { useEffectOnce } from '../../utils/hooks/useEffectOnce';
import { ShowSuccess } from '../Notifications/Notifications';
import { BoxesService } from '../../Services/BoxesService';
import useLoading from '../../utils/hooks/useLoading';
import { useCurrentUser } from '../../store/common/app-slice';
import { GskService } from '../../Services/GskService';

interface FormModel {
  newChairmanId: number;
}

interface OwnProps {
  gskId: number;
  closeDialog: CallableFunction;
}

export default function ChangeChairmanModal({ closeDialog, gskId }: OwnProps) {
  const currentUser = useCurrentUser();
  const [isLoading, load] = useLoading();
  const [boxes, setBoxes] = useState<BoxShortModel[]>([]);
  const [currentChairmanInvite, setCurrentChairmanInvite] = useState<GskChairmanInviteModel | undefined>(undefined);

  const users = useMemo(() => {
    const userIds = new Set<number>([...boxes.filter(b => !!b.userId && b.userId !== currentUser?.id).map(b => b.userId ?? -1)]);
    return Array.from(userIds).map(u => {
        const userBoxes = boxes.filter(b => b.userId === u);
        if (!userBoxes[0]) {
          throw Error("!user");
        }
        return {value: u, label: userBoxes[0].ownerName + " (бокс"+ (userBoxes.length > 1 ? "ы" : "")+" №" + userBoxes.map(b => b.num).join(', ') + ")"}
        
      });
  }, [boxes, currentUser?.id]);

  const [form] = useForm<FormModel>();


  const submit = async (data: FormModel) => {
    const result = await load(GskService.createChairmanInvite(data.newChairmanId, gskId));
    if (result) {
      ShowSuccess("Смена председателя", "Запрос отправлен");
      closeDialog();
    }
  }

  const handleRevokeInvite = useCallback(() => {
    Modal.confirm({
      title: 'Отменить запрос на смену председателя',
      content: 'Отменить запрос на смену председателя?',
      okText: 'Да',
      cancelText: 'Нет',
      onOk: async () => {
        if (currentChairmanInvite) {
          const resp = await load(GskService.deleteChairmanInvite(currentChairmanInvite.key));
          if (resp) {
            setCurrentChairmanInvite(undefined);
          }
          return resp;
        }
        return true;
      }
    })
  }, [currentChairmanInvite, load])

  useEffectOnce(() => {
    const func = async () => { 
      setCurrentChairmanInvite(await load(GskService.getChairmanInvite(gskId)));
      setBoxes(await load(BoxesService.getBoxesShort(gskId)) ?? []);
    };
    func();
  });

  return (
    <ModalBase closeDialog={closeDialog} title="Сменить председателя" okButtonHandler={() => form.submit()} isLoading={isLoading} width={500}>
      {currentChairmanInvite ? 
        <>
          <p>
            Пользователю <b>{currentChairmanInvite.invitedUserName}</b> отправлен запрос на смену председателя. 
            Ему на почту, придёт письмо с предложением принять эту роль. 
          </p>
          <center><Button onClick={handleRevokeInvite}>Отменить запрос</Button></center>
        </>
        :
        <Form
          form={form}
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          initialValues={{
            boxNum: '',
            rangeStart: 1,
            rangeEnd: 5,
          }}
          onFinish={submit}
        >
          <Alert 
            type='info' 
            style={{marginBottom: 10}}
            message='После подтверждения выбранному пользователю на почту будет отправлена ссылка
            с предложением принять права председателя. Так как в ГСК не может быть больше одного председателя с Вас 
            эта роль будет снята.' 
          />
          <Form.Item 
            label='Новый председатель'
            name='newChairmanId'
            rules={[{required: true, message: 'Выберите пользователя'}]}
          >
            <Select options={users} loading={isLoading} showSearch/>
          </Form.Item>
        </Form>
      }
    </ModalBase>
  );
}