import { useMemo, useState } from 'react'
import { Button, Card, Form, Grid, Input, Modal, Select, Space, Table} from 'antd'
import ModalBase from '../../ModalBase/ModalBase';
import { GskChargeService } from '../../../Services/GskChargeService';
import { GskAccrualModelWithStats, GskChargeModel, getPayState, getPaymentClassName } from '../../../types/GskChargeTypes';
import dayjs from 'dayjs'
import { ColumnType } from 'antd/es/table';
import { toMoneyString } from '../../../utils/moneyHelper';
import { Link } from 'react-router-dom';
import RowTwoCol from '../../RowTwoCol/RowTwoCol';
import { SearchOutlined } from '@ant-design/icons';
import DeleteButton from '../../Controls/DeleteButton';
import { EmptyPagedResult, PagedResult } from '../../../types/dto';
import useLoading from '../../../utils/hooks/useLoading';
import { useForm } from 'antd/es/form/Form';
import { useEffectOnce } from '../../../utils/hooks/useEffectOnce';
import { PaymentState } from '../../../types/BillingTypes';

interface Filter {
  dates: string[];
  text: string;
}
interface OwnProps {
  accrual: GskAccrualModelWithStats;
  closeDialog: CallableFunction;
  refreshAccruals: () => void;
}

const PAGE_SIZE = 10;
export default function GskAccrualChargesModal({closeDialog, refreshAccruals,  accrual}: OwnProps)  {
  const [filter, setFilter] = useState<Filter>({dates: accrual.chargesDates?.map(d => dayjs(d).format("DD.MM.YYYY")) ?? [], text: ''});
  const [isLoading, load] = useLoading();
  const [page, setPage] = useState<PagedResult<GskChargeModel>>(EmptyPagedResult<GskChargeModel>(PAGE_SIZE));
  const [form] = useForm();
  const {sm} = Grid.useBreakpoint();

  const dates = useMemo(() => { 
    let dates = [...(accrual.chargesDates ?? [])];
    dates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
    return dates.map(d => ({date: d}))
  }, [accrual])
  

  const columns: ColumnType<GskChargeModel>[] = useMemo(() => [
    {
      title: 'Бокс',
      dataIndex: 'boxNum',
      key: 'boxNum',
      render: (v, row) => <Link to={'../Cash/'+row.boxId}>{row.boxNum}</Link>
    },
		{
			title: 'Период',
			key: 'period',
			dataIndex: 'payEndDate',
			render: (v, row) => <>{dayjs(row.forTheDate).format("DD.MM.YYYY")} - {row.payEndDate ? dayjs(row.payEndDate).format("DD.MM.YYYY") : ''}</>
		},
    {
      title: 'Плата',
      key: 'amount',
      render: (_, record) => {
      const paymentSumm = record.payments.filter(p => p.paymentState === PaymentState.Succeeded).reduce((accum, curr) => accum + curr.amount, 0)
      return <>
        {toMoneyString(paymentSumm)} / {toMoneyString(record.amount)}
      </>}
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
      render: (v: string) => <span style={{whiteSpace: 'pre-line'}}>{v}</span>
    },
    {
      title: 'Создано',
      dataIndex: 'сreated',
      key: 'сreated',
      render: (v: Date) => dayjs(v).format("DD.MM.YYYY"),
      responsive: ['sm']
    },
  ], []);
  
  const handleDeleteAccrualCharges = () => {
    Modal.confirm({
      title: "Удаление",
      content: <>Удалить все не оплаченные платежи по начислению <b>{accrual.name}</b>?</>,
      okText: "Удалить",
      cancelText: "Нет",
      onOk: async () => {
        if (await GskChargeService.deleteAccuralUnpaidCharges(accrual.gskId, accrual.id)) {
          await applyFilter();
          await refreshAccruals();
        }
      }
    });
  }

  const handlePageChange = async (page: number, pageSize: number) => {
    const resp = await load(GskChargeService.getChargesPage(accrual.gskId, accrual.id, { page, pageSize, datesString: filter.dates, text: filter.text }));
    if (resp) {
      setPage(resp);
    }
  }

  const applyFilter = async() => {   
    await handlePageChange(1, page.pageSize);
  }

  useEffectOnce(() => { applyFilter() });

  return (
    <ModalBase 
      closeDialog={closeDialog}
      title={'Платежи по начислению "' + accrual.name +'"'}
      okButtonHandler={closeDialog}
      style={{maxWidth: 1200}}
      leftFooterCell={<DeleteButton title={sm ? 'Удалить не оплаченные начисления' : 'Удалить...'} onClick={handleDeleteAccrualCharges}/>}
    >
      <Space direction='vertical' style={{width: '100%'}}>
        <Card size='small'>
          <RowTwoCol
            left={<Form 
              layout={sm ? 'inline' : 'vertical'}
              form={form}
            >
              <Form.Item>
                <Input 
                  placeholder='№ Бокса / ФИО'
                  value={filter.text}
                  onChange={e => setFilter(prev => ({...prev, text: e.target.value}))} 
                  onKeyUp={e => { if (e.key === 'Enter') {applyFilter()}}}/>
              </Form.Item>
              <Form.Item label='За дату'>
                <Select 
                  mode='multiple'
                  maxTagCount={1}
                  style={{minWidth: 200}}
                  options={dates.map(d => ({value: dayjs(d.date).format("DD.MM.YYYY"), label: dayjs(d.date).format("DD.MM.YYYY")}))} 
                  value={filter.dates}
                  onChange={e => setFilter(prev => ({...prev, dates: e}))}
                />
              </Form.Item>
            </Form>}
            right={
              <Space>
                <Button icon={<SearchOutlined />} onClick={applyFilter}>Поиск</Button>
              </Space>
            }
            rightSize={100}
          />
        </Card>
        <Table
          loading={isLoading}
          columns={columns}
          rowKey='id' 
          size='small'
          dataSource={page.items}
          rowClassName={row => getPaymentClassName(getPayState(row))}
          pagination={{
            pageSize: page.pageSize,
            current: page.page,
            onChange: handlePageChange,
            total: page.totalCount,
            defaultPageSize: PAGE_SIZE,
          }}
        />
      </Space>
    </ModalBase>
  );
}
