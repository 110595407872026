import { Card } from "antd";
import { HeaderOneRow, WithUnderLine } from "../components/Headers/HeaderOne";
import PromoCard from "../components/Promo/PromoCard";

export default function PromoPage (){
	const promoCards = [
		(imageLeft: boolean) => <PromoCard
			title={'Членская книжка онлайн'}
			description={<>Подробная информация о начислениях</>}
			image={<img src="StaticFiles/Promo/CashPage.png" alt="Регистрация ГСК" />}
			imageLeft={imageLeft}
		/>,
		(imageLeft: boolean) => <PromoCard
			title=' '
			description={<>Каждый участник видит свои боксы на главной странице ГСК</>}
			image={<img src="StaticFiles/Promo/MyBoxes.png" alt="Мои боксы" />}
			imageLeft={imageLeft}
		/>,
		(imageLeft: boolean) => <PromoCard
			title='Оплата взносов онлайн'
			description={<>Можно подключить оплату взносов банковской картой. Деньги поступят на расчетный счет Вашего ГСК</>}
			image={<img src="StaticFiles/Promo/PaymentModal.png" alt="Оплата картой" />}
			imageLeft={imageLeft}
		/>,
		(imageLeft: boolean) => <PromoCard
			title='Учет взносов и электроэнергии'
			description={<>Заполнните значения счетчиков на сайте и система создаст начисления</>}
			image={<img src="StaticFiles/Promo/MetersPage.png" alt="Учет взносов и электроэнергии" />}
			imageLeft={imageLeft}
		/>,
		(imageLeft: boolean) => <PromoCard
			title='Работа с реестром участников и долгами'
			description={<>Реестр участников досупен для пользователя с ролью <b>председатель</b> или <b>бухгалтер</b> </>}
			image={<img src="StaticFiles/Promo/BoxesPage.png" alt="Боксы" />}
			imageLeft={imageLeft}
		/>,
		(imageLeft: boolean) => <PromoCard
			title='Новости и объявления'
			description={<>Добавляйте новости, они будут показаны на главной странице ГСК</>}
			image={<img src="StaticFiles/Promo/NewsPage.png" alt="Новости" />}
			imageColSpan={14}
			imageLeft={imageLeft}
		/>,
		(imageLeft: boolean) => <PromoCard
			title='Голосование онлайн'
			description={<>Возможность организовать голосование по важным вопросам</>}
			image={<img src="StaticFiles/Promo/VoteResults.png" alt="Голосование" />}
			imageLeft={imageLeft}
		/>,
		(imageLeft: boolean) => <PromoCard
			title='Почтовые уведомления'
			description={<>Рассылка на электронную почту напоминаний об оплате при появлении новых начислений</>}
			image={<img src="StaticFiles/Promo/email.png" alt="Уведомления" />}
			imageLeft={imageLeft}
		/>
	]
  return (
    <Card>
			<HeaderOneRow style={{textAlign: 'center', fontSize: '0.8em'}} >
				Здесь Вы можете в пару кликов создать <WithUnderLine>сайт</WithUnderLine> для <WithUnderLine underlineColor="red">ГСК</WithUnderLine>
			</HeaderOneRow>
			<p style={{fontSize: '1.1em', textAlign: 'center', marginBottom: 60}}>Быстро. Просто. Удобно.</p>
			{promoCards.map((p, index) => <span key={index}>{p(index % 2 !== 0)}</span>)}
		</Card>
  )
}