import { useState } from "react";
import { Tour, TourProps } from "antd";
import IconButton from "../../IconButton/IconButton";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { RefType, getAppRef } from "../../../store/enum/RefType";

export function GskPageTour() {
  const [tourOpen, setTourOpen] = useState(false);
  const gskMenuCasheRef = getAppRef(RefType.GskMenuCasheLink);
  const myBoxesRef = getAppRef(RefType.GskPageMyBoxes);
  const newsRef = getAppRef(RefType.GskPageNews);
  const contactsRef = getAppRef(RefType.GskPageContacts);
	const steps: TourProps['steps'] = [
    {
      title: 'Мои боксы',
      description: <>Здесь отображаются Ваши боксы и краткая информация об оплате</>,
      target: () => myBoxesRef.current,
    },
    {
      title: 'Новости',
      description: <>Блок с новостями ГСК. Добавлять новости может только председатель\администратор</>, //TODO ссылка на страницу справки с ролями
      target: () => newsRef.current,
    },
    {
      title: 'Контакты',
      description: <>Контакты ГСК</>,
      target: () => contactsRef.current,
    },
    {
      title: 'Взносы',
      description: <>Подробная информация о начилсениях и оплате по Вашим боксам</>,
      target: () => gskMenuCasheRef.current,
    },
  ];
  return (<>
    <IconButton icon={<QuestionCircleOutlined />} onClick={() => setTourOpen(true)} />
    <Tour open={tourOpen} onClose={() => setTourOpen(false)} steps={steps} />
  </>)
}