import { PagedRequest } from "./dto";

export enum FileType
{
  GskMapImage = 0, //Хранится в БД
  GskDocument = 1,
  PostImage = 2,
}

export interface FileModel extends NewFileModel {
  id: number;
  size: number;
  downloadCount: number;
  created: Date;
  createdBy: string;
}

export interface FilesRequest extends PagedRequest {
  type?: FileType;
  searchString?: string;
}

export interface NewFileModel {
  paretnId?: number;
  name: string;
  type: FileType;
  data: File;
}

export interface RenameFileModel {
  name: string;
}