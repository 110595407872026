
export function roundFloat(value: number | string, fractionDigits: number): number {
  if (typeof(value) === 'string') {
    value = Number.parseFloat(value);
  }
  return Number(value.toFixed(fractionDigits));
}

export const toFloatString = (value?: number | string | null, fractionDigits: number = 3) => {
  if (value === null || value === undefined) {
    return '';
  }

  value = roundFloat(value, fractionDigits);
  const stringValue = value.toString();
  let parts = stringValue.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

export function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}