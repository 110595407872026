import { Descriptions } from "antd";
import { DescriptionsProps } from "antd/lib";

export default function ContactsPage()  {
	const items: DescriptionsProps['items'] = [
		
		{
			key: '1',
			label: 'Владелец сайта',
			children: 'Трифонов Дмитрий Владимирович',
		},
		{
			key: '2',
			label: 'ИНН',
			children: '272293184486',
		},
		{
			key: '3',
			label: 'Телефон',
			children: '+7 (924) 408 44 81',
		},
		{
			key: '4',
			label: 'E-mail',
			children: 'moygarajrf@yandex.ru',
		},
	];
	return (
		 <Descriptions title="Контакты" layout="vertical" items={items} />
	)
}