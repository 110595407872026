import { useState, useCallback } from "react";

export function useDialog(dialogCreator: (closeDialog: CallableFunction) => JSX.Element | undefined): [JSX.Element | undefined, () => void] {
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const closeDialog = useCallback(() => {
		setIsDialogOpen(false);
	}, []);

	const dialog = isDialogOpen ? dialogCreator(closeDialog) : undefined;

	const openFun = useCallback(() => setIsDialogOpen(true), []);

	return [dialog, openFun];
}

export function useDialogWithParameter<T>(dialogCreator: (p: T, closeDialog: CallableFunction) => JSX.Element | undefined): [JSX.Element | undefined, (parameter: T) => void] {

	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [parameter, setParameter] = useState<T>();

	const closeDialog = useCallback(() => {
		setIsDialogOpen(false);
	}, []);

	const dialog = isDialogOpen && parameter !== undefined ? dialogCreator(parameter, closeDialog) : undefined;
	const openFun = useCallback((parameter: T) => {
		setParameter(parameter);
		setIsDialogOpen(true);
	}, []);

	return [dialog, openFun];
}