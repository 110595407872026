import { Col, Row } from "antd";
import React, { useMemo } from "react";

interface Props {
  left?: React.ReactNode | undefined;
  right: React.ReactNode;
  leftSize?: number;
  rightSize?: number;
  style?: React.CSSProperties;
}
export default function RowTwoCol({left, right, leftSize, rightSize, style = {width: '100%'}}: Props) {
  const leftWidth = useMemo(() => leftSize !== undefined ? leftSize 
                                           : rightSize !== undefined ? 'calc(100% - ' + rightSize.toString()+'px)' : '50%', [leftSize, rightSize]);
  const rightWidth = useMemo(() => leftSize !== undefined ? 'calc(100% - '+leftSize+'px)' 
                                            : rightSize !== undefined ? rightSize : '50%', [leftSize, rightSize]);

  return (
    <Row style={style}>
      <Col style={{width: leftWidth, textAlign: 'left'}}>{left}</Col>
      <Col style={{textAlign: 'right', width: rightWidth}}><span  style={{textAlign: 'initial', height: '100%'}}>{right}</span></Col>
    </Row>
  )
}