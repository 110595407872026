import { Card, Flex } from "antd";

export default function AccountNotConfirmed() {

	return (<Flex justify="center" style={{marginTop:15}}>
		<Card>
			<h3>Ваша учетная запись не подтверждена</h3>
			<p>Перейдите по ссылке отправленной, на почту, указанную при регистрации для подтверждения учетной записи</p>
		</Card>
	</Flex>)
}

