export const NormalizeBytesSize = (bytesSize: number) => {
  let dimentionShort = '';
  let dimentionFull = '';
  let result = bytesSize;
  if (bytesSize >= 0 && bytesSize < 1024) {
    dimentionShort = 'б';
    dimentionFull = 'Байт';
  } else if (bytesSize < 1024 * 1024) {
    result = bytesSize / 1024;
    dimentionShort = 'Кб';
    dimentionFull = 'Килобайт';
  } else if (bytesSize < 1024 * 1024 * 1024) {
    result = bytesSize / 1024 / 1024 ;
    dimentionShort = 'Мб';
    dimentionFull = 'Мегабайт';
  } else if (bytesSize < 1024 * 1024 * 1024 * 1024) {
    result = bytesSize / 1024 / 1024 / 1024;
    dimentionShort = 'Гб';
    dimentionFull = 'Гигабайт';
  }

  return <span title={dimentionFull}>{result.toFixed(2).replace('.00','')} {dimentionShort}</span>;
}