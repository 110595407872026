
import { Input, Form, Switch } from 'antd'
import { Gsk } from '../../../types/GskTypes';
import ModalBase from '../../ModalBase/ModalBase';
import { EditGskPostModel, GskPostModel, NewGskPostModel } from '../../../types/GskPostTypes';
import { GskPostService } from '../../../Services/GskPostsService';
import { useForm } from 'antd/es/form/Form'; 
import dayjs from 'dayjs'
import EditVoteConfigurationCommon from '../../Post/Base/EditVoteConfigurationPartial';
import { VotePosition } from '../../../types/PostBaseTypes';
import { PostCoverEditFields } from '../../Post/Base/PostCoverBlock';
import { useState } from 'react';
import TipTapEditor from '../../RichTextEditor/TipTapEditor';
import { PagedResult } from '../../../types/dto';
import { PostCoverModel } from '../../../types/PostTypes';
import useLoading from '../../../utils/hooks/useLoading';


interface OwnProps {
  gsk: Gsk;
  post: GskPostModel | null;
  closeDialog: CallableFunction;
  setPostCoversPage?: React.Dispatch<React.SetStateAction<PagedResult<PostCoverModel>>>;
  setPost?: (v: React.SetStateAction<GskPostModel | undefined>) => void;
}

interface FormData extends EditGskPostModel{
  voteEnabled: boolean;
}

export default function EditPostModal({ gsk, post, setPost, setPostCoversPage, closeDialog }: OwnProps) {
  const [isLoading, load] = useLoading();
	const [coverImage, setCoverImage] = useState<string | undefined>(undefined);
  const [form] = useForm<FormData>();

  const submit = async (data: FormData) => {
    const req: NewGskPostModel = {
      commentsEnabled: data.commentsEnabled,
      data: data.data,
      title: data.title,
      coverImage: coverImage,
      coverText: data.coverText,
      voteConfiguration: data.voteConfiguration,
    }
    if (req.voteConfiguration) {
      req.voteConfiguration.dateFinish = dayjs(data.voteConfiguration?.dateFinish, "DD.MM.YYYY").toDate()
    }
    if (post) {
      data.coverImage = coverImage;
      const resp = await load(GskPostService.editPost(gsk.id, post.id, data));
      if (resp) {
        if (setPost) {
          setPost(resp);
        }
        closeDialog();
      }
    } else {
      const resp = await load(GskPostService.addPost(gsk.id, req));
      if (resp) {
        if (setPostCoversPage) {
          setPostCoversPage(prev => ({...prev, items: [toCover(resp), ...prev.items] }));
        }
        closeDialog();
      }
    }
  }

  return (
    <ModalBase 
      closeDialog={closeDialog}
      title={post ? 'Изменить новость' : 'Добавить новость'}
      okButtonHandler={() => form.submit()}
      isLoading={isLoading}
      width={1000}
    >
      <Form
        layout={'horizontal'}
        labelCol={{ span: 6 }}
        form={form}
        size={'small'}
        initialValues={{
          id: post?.id,
          title: post?.title ?? '',
          commentsEnabled: post?.commentsEnabled ?? true,
          coverImageId: post?.coverImageId,
          coverText: post?.coverText,
          data: post?.data ?? '',
          voteEnabled: !!post?.voteConfiguration,
          voteConfiguration: {...post?.voteConfiguration,
            dateFinish: post?.voteConfiguration?.dateFinish ? dayjs(post.voteConfiguration.dateFinish) : undefined,
            position: post?.voteConfiguration?.position ?? VotePosition.BeforePost,
            isAnonymous: post?.voteConfiguration?.isAnonymous ?? false,
            showResultsBeforeFinish: post?.voteConfiguration?.showResultsBeforeFinish ?? true,
          },
        }}
        onFinish={submit}
      >
        <Form.Item name='id' noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name='coverImageId' noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item label='Заголовок' name='title' rules={[{required: true, message: 'Введите заголовок'}]}>
          <Input />
        </Form.Item>
        <PostCoverEditFields form={form} setCoverImage={setCoverImage} />
        <Form.Item 
          label='Комментарии' 
          name='commentsEnabled'
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>
        <EditVoteConfigurationCommon form={form} isGskMode/>
        <Form.Item name='data' rules={[{required: true, message: 'Введите содержание'}]} >
          <TipTapEditor editable={true}/>
        </Form.Item>
      </Form>
    </ModalBase>
  );
}

const toCover = (post: GskPostModel): PostCoverModel => {
  return {
    created: post.created,
    createdBy: post.createdBy,
    id: post.id,
    tags: [], 
    title: post.title,
    coverImageId: post.coverImageId,
    coverText: post.coverText,
  }
}