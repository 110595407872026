import { BoxModelWithMeters, BoxesWitMetersRequest, EditElectricMeterModel, ElectricMeterModel, ElectricMeterReadingsModel, MeterReadingsRequest, NewElectricMeterModel, NewElectricMeterReadingsModel } from "../types/MeterTypes";
import { PagedResult } from "../types/dto";
import { Fetch, FetchNoResp } from "../utils/fetch";

export const ElectricMetersService = {
	getBoxMeters: async (gskId: number, boxId: number): Promise<ElectricMeterModel[]> => {
		return (await Fetch<ElectricMeterModel[]>(`/api/gsks/${gskId}/boxes/${boxId}/electricMeters`, 'GET')) ?? [];
	},
	getMeter: async (gskId: number, meterId: number): Promise<ElectricMeterModel | undefined> => {
		return await Fetch<ElectricMeterModel>(`/api/gsks/${gskId}/electricMeters/${meterId}`, 'GET');
	},
	getBoxesWithMeters: async (gskId: number, req: BoxesWitMetersRequest): Promise<PagedResult<BoxModelWithMeters> | undefined> => {
		return await Fetch<PagedResult<BoxModelWithMeters>>(`/api/gsks/${gskId}/electricMeters/boxesWithMeters`, 'POST', req);
	},
	addMeter: async (gskId: number, data: NewElectricMeterModel): Promise<ElectricMeterModel | undefined> => {
		return await Fetch<ElectricMeterModel>(`/api/gsks/${gskId}/electricMeters`, 'PUT', data);
	},
	editMeter: async (gskId: number, meterId: number, data: EditElectricMeterModel): Promise<ElectricMeterModel | undefined> => {
		return await Fetch<ElectricMeterModel>(`/api/gsks/${gskId}/electricMeters/${meterId}`, 'PATCH', data);
	},
	deleteMeter: async (gskId: number, meterId: number): Promise<boolean> => {
		return await FetchNoResp(`/api/gsks/${gskId}/electricMeters/${meterId}`, 'DELETE')
	},
	restoreMeter: async (gskId: number, meterId: number): Promise<ElectricMeterModel | undefined> => {
		return await Fetch<ElectricMeterModel>(`/api/gsks/${gskId}/electricMeters/${meterId}/restore`, 'POST');
	},
	getReadings: async (gskId: number, meterId: number, req: MeterReadingsRequest): Promise<PagedResult<ElectricMeterReadingsModel> | undefined> => {
		return await Fetch<PagedResult<ElectricMeterReadingsModel>>(`/api/gsks/${gskId}/electricMeters/${meterId}/readings`, 'POST', req);
	},
	addMeterReadings: async (gskId: number, meterId: number, data: NewElectricMeterReadingsModel): Promise<ElectricMeterReadingsModel | undefined> => {
		return await Fetch<ElectricMeterReadingsModel>(`/api/gsks/${gskId}/electricMeters/${meterId}/readings`, 'PUT', data);
	},
	deleteMeterReadings: async (gskId: number, meterId: number, readingsId: number): Promise<boolean> => {
		return await FetchNoResp(`/api/gsks/${gskId}/electricMeters/${meterId}/readings/${readingsId}`, 'DELETE');
	},
}