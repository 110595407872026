import { Button, List, Spin } from "antd";
import {  useState } from "react";
import { PostCoverModel, PostModel } from "../types/PostTypes";
import { PostService } from "../Services/PostsService";
import { useDialogWithParameter } from "../utils/useDialog";
import EditPostModal from "../components/Post/EditPostModal";
import { useEffectOnce } from "../utils/hooks/useEffectOnce";
import { PostCoverBlock } from "../components/Post/Base/PostCoverBlock";
import RowTwoCol from "../components/RowTwoCol/RowTwoCol";
import { CheckAccountConfirmed } from "../components/User/CheckAccountConfirmed";
import { EmptyPagedResult, PagedResult } from "../types/dto";
import useLoading from "../utils/hooks/useLoading";
import { useCurrentUser } from "../store/common/app-slice";

const DEFAULT_PAGE_SIZE = 10;
export default function BlogPage () {
  const currentUser = useCurrentUser();
	const [isLoading, load] = useLoading();
  const [postCoversPage, setPostCoversPage] = useState<PagedResult<PostCoverModel>>(EmptyPagedResult<PostCoverModel>(DEFAULT_PAGE_SIZE));
	
	const [editPostModal, openEditPostModal] = useDialogWithParameter<PostModel | null>(
		(post, closeDialog) => <EditPostModal closeDialog={closeDialog} post={post} setPostCoversPage={setPostCoversPage}/>
	);
  const handleAddPostClick = () => {
    if (CheckAccountConfirmed(currentUser)) {
      openEditPostModal(null);
    }
	}

	const handlePostPageChange = async (page: number, pageSize: number) => {
		const resp = await load(PostService.getPostCovers(page, pageSize));
		if (resp) {
			setPostCoversPage(resp);
		}
	}
	
	const reload = async () => {
		const resp = await load(PostService.getPostCovers(postCoversPage.page, postCoversPage.pageSize));
		if (resp) {
			setPostCoversPage(resp);
		}
	}

	useEffectOnce(() => {
		reload();
	});

	return (
	<Spin spinning={isLoading}>
		<List
			header={
				<RowTwoCol
					left={<Button onClick={handleAddPostClick}>Добавить пост</Button>}
					right={<></>}
				/>}
			dataSource={postCoversPage.items}
			pagination={{
				current: postCoversPage.page,
				onChange: handlePostPageChange,
				total: postCoversPage.totalCount,
				hideOnSinglePage: true,
				pageSize: 5,
			}}
			split={false}
			rowKey={p => p.id}
			renderItem={post => <List.Item><PostCoverBlock cover={post} urlPrefix="/blog/"/></List.Item>}
		/>
		{editPostModal}
	</Spin>)
}
