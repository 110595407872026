import { AccrualShortModel, AccrualsRequest, ChargesRequest, DeletePaymentModel, EditGskChargeModel, GskAccrualModel, GskAccrualModelWithStats, GskBoxChargeModelWithPayments, GskChargeModel, NewGskAccrualModel, NewGskChargeModel, NewPaymentModel, PaymentType, RenderGskChargesRequest } from "../types/GskChargeTypes";
import { Fetch, FetchNoResp } from "../utils/fetch";
import { PagedResult } from "../types/dto";
import { PaymentModel } from "../types/BillingTypes";
import { AppThunkAction } from "../store/configureStore";
import { GskOperationType } from "../types/GskTypes";
import OperationsService from "./OperationsService";
import { setPaymentTypes } from "../store/common/gsk-slice";

export const GskChargeService = {
	getAccrualsLPage: async (gskId: number, req: AccrualsRequest): Promise<PagedResult<GskAccrualModelWithStats> | undefined> => {
		return await Fetch<PagedResult<GskAccrualModelWithStats>>(`/api/gsks/${gskId}/accruals/page`, "POST", req);
	},
	getAllaccrualsList: async (gskId: number): Promise<AccrualShortModel[] | undefined> => {
		return await Fetch<AccrualShortModel[]>(`/api/gsks/${gskId}/accruals/all`, "GET");
	},
	addAccrual: async (gskId: number, data: NewGskAccrualModel): Promise<GskAccrualModel | undefined> => {
		return await Fetch<GskAccrualModel>(`/api/gsks/${gskId}/accruals/`, "PUT", data);
	},
	editAccrual: async (gskId: number, accrualId: number, data: NewGskAccrualModel): Promise<GskAccrualModel | undefined> => {
		return await Fetch<GskAccrualModel>(`/api/gsks/${gskId}/accruals/${accrualId}/`, "PATCH", data);
	},
	delAccrual: async (gskId: number, accrualId: number): Promise<NewGskAccrualModel | null | undefined> => {
		return await Fetch<GskAccrualModel | null>(`/api/gsks/${gskId}/accruals/${accrualId}`, "DELETE");
	},
	restoreAccrual: async (gskId: number, accrualId: number): Promise<GskAccrualModel | undefined> => {
		return await Fetch<GskAccrualModel>(`/api/gsks/${gskId}/accruals/${accrualId}/restore`, "POST");
	},
	
	addCharge: async(gskId: number, accrualId: number, data: NewGskChargeModel): Promise<boolean> => {
		return await FetchNoResp(`/api/gsks/${gskId}/accruals/${accrualId}/charges`, "PUT", data);
	},
	editCharge: async (gskId: number, accrualId: number, chargeId: number, data: EditGskChargeModel): Promise<boolean> => {
		return await FetchNoResp(`/api/gsks/${gskId}/accruals/${accrualId}/charges/${chargeId}`, "PATCH", data);
	},
	deleteCharge: async (gskId: number, accrualId: number, chargeId: number): Promise<boolean> => {
		return await FetchNoResp(`/api/gsks/${gskId}/accruals/${accrualId}/charges/${chargeId}`, "DELETE");
	},
	getChargesPage: async (gskId: number, accrualId: number, req: ChargesRequest): Promise<PagedResult<GskChargeModel> | undefined> => {
		return await Fetch<PagedResult<GskChargeModel>>(`/api/gsks/${gskId}/accruals/${accrualId}/charges/page`, "POST", req);
	},
	getBoxChargesWithPayments: async (gskId: number, boxIds: number[]): Promise<GskBoxChargeModelWithPayments[]> => {
		return await Fetch<GskBoxChargeModelWithPayments[]>(`/api/gsks/${gskId}/accruals/charges/forBoxes`, 'POST', { ids: boxIds }) ?? [];
	},
	deleteAccuralUnpaidCharges: async (gskId: number, accrualId: number): Promise<boolean> => {
		return await FetchNoResp(`/api/gsks/${gskId}/accruals/${accrualId}/charges/unpaid`, "DELETE");
	},
	renderCharges: async (gskId: number, data: RenderGskChargesRequest): Promise<GskChargeModel[] | undefined> => {
		return await Fetch<GskChargeModel[]>(`/api/gsks/${gskId}/accruals/charges/render`, "POST", data);
	},
	
	loadAvailablePaymentTypes: (gskId: number): AppThunkAction => async (dispatch, getState) => {
		if (getState().gskSlise.paymentTypes !== undefined) {
			return;
		}
		await OperationsService.run(dispatch, getState, GskOperationType.LoadPaymentTypes, gskId, false, async () => {
			const resp = await Fetch<PaymentType[]>(`/api/gsks/${gskId}/availablePaymentTypes`, "GET");
			dispatch(setPaymentTypes(resp));
		})
	},
	addPayment: async (gskId: number, data: NewPaymentModel): Promise<PaymentModel | undefined> => {
		return await Fetch<PaymentModel>(`/api/gsks/${gskId}/accruals/payments`, "PUT", data);
	},
	delPayment: async (gskId: number, paymentId: number, data: DeletePaymentModel): Promise<PaymentModel | undefined> => {
		return await Fetch<PaymentModel>(`/api/gsks/${gskId}/accruals/payments/${paymentId}`, "DELETE", data);
	},
}