import ModalBase from "../ModalBase/ModalBase";
import { DatePicker, Form, Input, InputNumber, Modal } from "antd";
import { DiscountModel } from "../../types/BillingTypes";
import useLoading from "../../utils/hooks/useLoading";
import { BillingService } from "../../Services/BillingService";
import DeleteButton from "../Controls/DeleteButton";
import dayjs from 'dayjs';

interface OwnProps {
  discount: DiscountModel | null;
  setDiscounts: React.Dispatch<React.SetStateAction<DiscountModel[]>>;
  closeDialog: CallableFunction;
}

export default function DiscountModal({ closeDialog, setDiscounts, discount }: OwnProps) {
  const [isLoading, load] = useLoading();

  const isNew = !discount;
  const [form] = Form.useForm<DiscountModel>();
  const handleSave = async (data: DiscountModel) => {
    if (isNew) {
      const resp = await load(BillingService.addDiscount(data));
      if (resp) {
        setDiscounts(prev => [resp, ...prev]);
        closeDialog();
      }
    } else {
      const resp = await load(BillingService.editDiscount(discount.id, data));
      if (resp) {
        setDiscounts(prev => {
          const r = [...prev];
          r.splice(prev.findIndex(p => p.id === resp.id), 1, resp);
          return r;
        });
        closeDialog();
      }
    }
  }

  const handleDelete = () => {
    if (!discount?.id) {
      return;
    }
    Modal.confirm({
      title: "Удаление",
      content: "Точно удалить?",
      okText: "Удалить",
      cancelText: 'Отмена',
      okButtonProps: { danger: true },
      onOk: async () => {
        const resp = await load(BillingService.deleteDiscount(discount?.id));
        if (resp) {
          closeDialog();
          setDiscounts(prev => [...prev.filter(p => p.id !== discount.id)]);
        }
      }
    });
  }

  return (
    <ModalBase 
      closeDialog={closeDialog}
      title={(isNew ? 'Новый' : 'Изменить') +' скидку'}
      okButtonHandler={() => form.submit()}
      isLoading={isLoading} width={500}
      leftFooterCell={!isNew && <DeleteButton onClick={handleDelete}/>}
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        layout={'horizontal'}
        form={form}
        initialValues={{
          id: discount?.id ?? 0,
          percent: discount?.percent ?? '',
          dateStart: discount?.dateStart ? dayjs(discount.dateStart) : undefined,
          dateEnd: discount?.dateEnd ? dayjs(discount.dateEnd) : undefined,
        }}
        onFinish={handleSave}
      >
        <Form.Item noStyle label='Id' name='id'>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item 
          label='Скидка, %'
          name='percent'
          rules={[{required: true, message: 'Введите значение'}]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label='Дата начала' name='dateStart' rules={[{required: true, message: 'Введите значение'}]}>
          <DatePicker format="DD.MM.YYYY" />
        </Form.Item>
        <Form.Item label='Дата окончания' name='dateEnd' rules={[{required: false}]}>
          <DatePicker format="DD.MM.YYYY" />
        </Form.Item>
      </Form>
    </ModalBase>
  );
}