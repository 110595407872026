import { Modal } from "antd";

export function showAgreement(sm: boolean | undefined = false) {
    Modal.info({
    title: <h1>Пользовательское соглашение</h1>,
    width: sm ? 1000 : '100%',
    content: userAgreementText,
  })
}

export const userAgreementText = <>
  <p>Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между владельцем мойгск.рф (далее Мой ГСК или Администрация) с одной стороны и пользователем сайта с другой.</p>
  <p>Сайт Мой ГСК не является средством массовой информации.</p>
  <p>После регистрации Вы получите в личное распоряжение дисковое пространство для размещения текстов, фотоматериалов, сообщений или информации, касающейся или ссылающейся на программы, музыку, звуки, фотографии, графические изображения или видео (далее — «Содержание»). Содержание размещается на серверах Администрации.</p>
  <b>Используя сайт, Вы соглашаетесь с условиями данного соглашения.</b>
  <p>Если Вы не согласны с условиями данного соглашения, не используйте сайт Мой ГСК!</p>

  <h3>1. Предмет соглашения</h3>
  Администрация предоставляет пользователю право на размещение на сайте следующей информации:
  <ul>
    <li>Текстовой информации</li>
    <li>Фотоматериалов</li>
    <li>Ссылок на материалы, размещенные на других сайтах</li>
  </ul>

  <h3>2. Права и обязанности сторон</h3>
  <h3>2.1. Пользователь имеет право:</h3>
  <ul>
    <li>осуществлять поиск информации на сайте</li>
    <li>получать информацию на сайте</li>
    <li>создавать информацию для сайта</li>
    <li>комментировать контент, выложенный на сайте</li>
    <li>копировать информацию на другие сайты с указанием источника</li>
    <li>требовать от администрации скрытия любой информации о пользователе, кроме руководства ГСК, в котором он состоит</li>
    <li>использовать информацию сайта в личных некоммерческих целях</li>
    <li>использовать информацию сайта в коммерческих целях без специального разрешения</li>
  </ul>

  <h3>2.2. Администрация имеет право:</h3>
  <ul>
    <li>по своему усмотрению и необходимости создавать, изменять, отменять правила</li>
    <li>по своему усмотрению отказать в размещении или удалить любое Содержание, которое доступно через сайт мойГСК.рф. Вы согласны с тем, что Вы должны самостоятельно оценивать все риски, связанные с использованием Содержания, включая оценку надёжности, полноты или полезности этого Содержания</li>
    <li>ограничивать доступ к любой информации на сайте</li>
    <li>создавать, изменять, удалять информацию</li>
    <li>удалять учетные записи пользователей, нарушивших настоящее соглашение, использующих нецензурные псевдонимы</li>
    <li>отказывать в регистрации без объяснения причин</li>
  </ul>

  <h3>2.3. Пользователь обязуется:</h3>
  <ul>
    <li>при копировании информации с других источников, включать в её состав информацию об авторе</li>
    <li>не распространять информацию, которая направлена на пропаганду войны, разжигание национальной, расовой или религиозной ненависти и вражды, а также иной информации, за распространение которой предусмотрена уголовная или административная ответственность</li>
    <li>не нарушать работоспособность сайта</li>
    <li>не создавать несколько учётных записей на Сайте, если фактически они принадлежат одному и тому же лицу</li>
    <li>не регистрировать ГСК, в которых не состоит пользователь</li>
    <li>не передавать в пользование свою учетную запись и/или логин и пароль своей учетной записи третьим лицам</li>
    <li>не регистрировать учетную запись от имени или вместо другого лица за исключением случаев, предусмотренных законодательством РФ</li>
    <li>не размещать материалы рекламного, эротического, порнографического или оскорбительного характера, а также иную информацию, размещение которой запрещено или противоречит нормам действующего законодательства РФ</li>
    <li>не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с Сайтом и его Сервисами</li>
  </ul>

  <h3>2.4. Администрация обязуется:</h3>
  <ul>
    <li>поддерживать работоспособность сайта за исключением случаев, когда это невозможно по независящим от Администрации причинам.</li>
    <li>осуществлять разностороннюю защиту учетной записи Пользователя</li>
  </ul>

  <h3>3. Ответственность сторон</h3>
  <ul>
    <li>пользователь лично несет полную ответственность за распространяемую им информацию</li>
    <li>администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами</li>
    <li>в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение, стихийное бедствие и т. д.) Администрация не гарантирует сохранность информации, размещённой Пользователем, а также бесперебойную работу информационного ресурса</li>
  </ul>

  <h3>4. Условия действия Соглашения</h3>
  <p>Данное Соглашение вступает в силу при регистрации на сайте.</p>
  <p>Соглашение перестает действовать при появлении его новой версии. Действующая редакция ПС располагается по адресу https://мойГСК.рф/agreement/.</p>
  <p>Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему усмотрению.</p>
  <p>При изменении соглашения, в некоторых случаях, администрация может оповестить пользователей удобным для нее способом.</p>
</>