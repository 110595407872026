import { Alert, Form, Input } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../store/redux';
import ModalBase from '../../ModalBase/ModalBase';
import { GskRole } from '../../../types/GskTypes';
import { GskChargeService } from '../../../Services/GskChargeService';
import { getPaymentTypeName, GskPaymentModel, NewPaymentModel, PaymentType } from '../../../types/GskChargeTypes';
import { ShowErrorMessage } from '../../Notifications/Notifications';
import { useEffect, useMemo, useState } from 'react';
import { Radio } from 'antd/lib';
import { GskBoxChargeModelExtended } from '../../../pages/Gsk/GskCashePage';
import FloatInput from '../../Controls/FloatInput';
import useLoading from '../../../utils/hooks/useLoading';
import { currentUserHasGskRoleSelector } from '../../../store/common/app-slice';
import { useEffectOnce } from '../../../utils/hooks/useEffectOnce';
import { PaymentState } from '../../../types/BillingTypes';
import { GskBillingService } from '../../../Services/GskBillingService';
import { toMoneyString } from '../../../utils/moneyHelper';
import { roundFloat } from '../../../utils/numberHelper';
import { gskBillingCommissionPercentSelector, gskPaymentTypesSelector } from '../../../store/common/gsk-slice';

interface OwnProps {
  gskId: number;
  charges: GskBoxChargeModelExtended[];
  refreshCallback: () => void;
  closeDialog: CallableFunction;
}

export default function GskPaymentModal({closeDialog, gskId, refreshCallback, charges}: OwnProps) {
  const dispatch = useAppDispatch();
  const paymentTypes = useAppSelector(gskPaymentTypesSelector);
  const comissionPercent = useAppSelector(gskBillingCommissionPercentSelector);
  const isAccountant = useAppSelector(currentUserHasGskRoleSelector(GskRole.Accountant));
  const [isLoading, load] = useLoading();
  const [totalSum, setTotalSumm] = useState<number>();
  const singleMode = charges.length === 1;
  const chargeIds = charges.map(c => c.id);
  const payments = charges.reduce<GskPaymentModel[]>((acc, curr) => [...acc, ...curr.payments], []);
  const [form] = Form.useForm<NewPaymentModel>();
  const paymentType = Form.useWatch('paymentType', form);
  const amount = Form.useWatch('amount', form);

  const maxAmount = useMemo(() => {
    const paidSum = payments.filter(p => p.paymentState === PaymentState.Succeeded).reduce((acc, curr) => acc + curr.amount, 0);
    const chargesSum = charges.reduce((acc, curr) => acc + curr.amount, 0);
    return chargesSum - paidSum;
  }, [charges, payments])

  const handleOk = async (data: NewPaymentModel) => {
    data.chargeIds = chargeIds;
    if (data.chargeIds.length < 1) {
      ShowErrorMessage("Ошибка", "Начисления не выбраны");
      return;
    }
    
    const payment = await load(GskChargeService.addPayment(gskId, data));
    if (payment) {
      switch (data.paymentType) {
        case PaymentType.Cash:
          refreshCallback();
          closeDialog();
          break;
        case PaymentType.Card:
          window.open(payment.confirmationUrl, '_self')
          break;
        default:
          throw Error("Unexpected PaymentType: "+data.paymentType);
      }
    }
  }

  const checkAmount = (value: number) => {
    let errors: string[] = [];
    
    if (value <= 0) {
      errors.push('Сумма должна быть больше нуля');
    }

    if (value > maxAmount) {
      errors.push('Не больше '+maxAmount.toFixed(2));
    }

    if (errors.length === 0) {
      return Promise.resolve();
    }
    return Promise.reject(<>{errors.map(e => <>{e}<br/></>)}</>);
  }

  const checkPaymentType = (v: PaymentType) => {
    if (!isAccountant && v === PaymentType.Cash) {
      return Promise.reject('Отметку об оплате наличными может сделать только председатель или бухгалтер');
    }
    return Promise.resolve();
  }

  useEffect(() => {
    if (!amount || !comissionPercent) {
      return;
    }
    const addon = roundFloat((amount * comissionPercent) / (100 - comissionPercent), 2);
    setTotalSumm(amount + addon)
  }, [amount, comissionPercent]);

  useEffectOnce(() => {
    form.setFieldValue('paymentPageUrl', window.location.href);
  });
  
  useEffectOnce(() => {
    load(async() => await dispatch(GskBillingService.loadCommissionPercent(gskId)));
  });

  useEffectOnce(() => {
    load(async () => await dispatch(GskChargeService.loadAvailablePaymentTypes(gskId)));
  });

  useEffect(() => {
    if (paymentTypes.length > 0) {
      form.setFieldValue('paymentType', paymentTypes[0])
    }
  }, [form, paymentTypes])

  const withAmounErrors = form.getFieldError('amount')?.length > 0

  return (
    <ModalBase 
      closeDialog={closeDialog}
      title="Оплата"
      okButtonHandler={form.submit}
      okButtonText={paymentType === PaymentType.Card ? 'Продолжить' : 'Ок'}
      okButtonTip={paymentType === PaymentType.Card ? 'Вы будете перенаправлены на страницу оплаты' : undefined}
      isLoading={isLoading}
      width={500}
    >
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        layout={'horizontal'}
        form={form}
        initialValues={{amount: maxAmount, chargeIds: chargeIds, comment: null}}
        onFinish={handleOk}
      >
        <Form.Item hidden name='paymentPageUrl'><Input/></Form.Item>
        
        <Form.Item
          label='Способ оплаты'
          name='paymentType'
          rules={[{required: true, message: 'Выберите занчение'}, {validator: (_, v) => checkPaymentType(v)}]}
        >
          {paymentTypes.length === 0 ? 
          <Alert
            style={{marginBottom: 10}}
            type='error'
            message='Нет доступных способов оплаты. Обратитесь к председателю или администратору.'
          />
        :
          <Radio.Group>
            {paymentTypes.map(p => 
            <Radio.Button key={p} value={p}>{getPaymentTypeName(p)}</Radio.Button>)}
          </Radio.Group>
        }
        </Form.Item>
        <Form.Item 
          label='Сумма'
          name='amount'
          tooltip={chargeIds.length === 1 ? '' : 'При оплате нескольких начислений нельзя изменять сумму'}
          validateStatus={withAmounErrors ? 'error' : comissionPercent && comissionPercent > 0 ? 'validating' : 'validating'}
          help={!withAmounErrors && comissionPercent && comissionPercent > 0 && paymentType === PaymentType.Card
            ? <><b>{toMoneyString(totalSum ?? 0, false)}₽</b>, с учетом комиссии&nbsp;<b>{comissionPercent}</b>%</> 
            : undefined
          }
          rules={[{required: true, validator: (_, v) => checkAmount(v)}]}
        >
          <FloatInput disabled={!singleMode} placeholder='1000' addonAfter='₽' />
        </Form.Item>
        <Form.Item 
          label='Комментарий'
          name='comment'
        >
          <Input placeholder="Перевод на банковску карту 15 ноября в обед" />
        </Form.Item>
      </Form>
    </ModalBase>
  );
}
