import { useMemo, useState } from "react";
import ModalBase from "../ModalBase/ModalBase";
import { UserService } from "../../Services/UserService";
import { UserModel, NewUserModel, EditUserModel, UserRole } from "../../types/UserTypes";
import { Form, Modal, Tabs } from "antd";
import { ShowSuccess } from "../Notifications/Notifications";
import GskTab from "./GskTab";
import ProfileTab from "./ProfileTab";
import useCapthcaFormItem from "./useCapthcaFormItem";
import { VerifyState } from "../../types/CaptchaTypes";
import NotificationsTab from "./NotificationsTab";
import { useCurrentUser } from "../../store/common/app-slice";
import useLoading from "../../utils/hooks/useLoading";
import DeleteButton from "../Controls/DeleteButton";
import LoginWithBlock from "./LoginWithBlock";

interface OwnProps {
  user?: UserModel;
  setUsers?: React.Dispatch<React.SetStateAction<UserModel[]>>;
  closeDialog: CallableFunction;
}

const ProfileModal = ({ closeDialog, setUsers, user }: OwnProps) => {
  const currentUser = useCurrentUser();
  const isGlobalAdmin = currentUser?.role === UserRole.Admin;
  const [isLoading, load] = useLoading();

  const [newUserAdded, setNewUserAdded] = useState(false);

  const initUser: EditUserModel | undefined = useMemo(() =>
  {
    if (user) {
      return {
        profile: {
          id: user.id,
          login: user.login,
          alias: user.alias,
          birthDate: user.birthDate,
          email: user.email,
          name: user.name,
          patronymic: user.patronymic,
          phone: user.phone,
          surname: user.surname,
          password: null,
        }
      }
    }
    return undefined;
  }
  , [user]);

  const isNewUser = !user;
  const [form] = Form.useForm<EditUserModel>();
  const [onCaptchaError, captcha] = useCapthcaFormItem(form, ['profile'], !isNewUser);
  const email = Form.useWatch('email', form);
  
  const handleOk = async () => {
    form.submit();
  }

  const handleSave = async (data: EditUserModel) => {
    if (isNewUser) {
      let registerModel = data.profile as NewUserModel;
      const resp = await load(UserService.register(registerModel));
      if (resp === VerifyState.Ok) {
        ShowSuccess("Регистрация", "Пользователь добавлен");
        setNewUserAdded(true);
      } else {
        await onCaptchaError(resp ?? VerifyState.None);
      }
    } else if (currentUser) {
      const resp = await load(UserService.edit(user.id, data));
      if (resp) {
        if (setUsers) {
          setUsers(prev => {
            const u = [...prev];
            u.splice(prev.findIndex(p => p.id === resp.id), 1, resp);
            return u;
          })
        }
        closeDialog();
      }
    }
  }

  const handleDelete = () => {
    if (!isGlobalAdmin || !user) {
      return;
    }
    if (Object.keys(user.gsks).length > 0) {
      Modal.info({title: 'Удаление пользователя', content: 'Нельзя удалить пользователя с назначенными ролями'});
      return;
    }

    Modal.confirm({
      title: 'Удаление пользователя',
      content: 'Точно удалить?',
      okText: 'Да',
      cancelText: 'Нет',
      onOk: async () => {
        const resp = await load(UserService.delete(user.id));
        if (resp) {
          if (setUsers) {
            setUsers(prev => prev.filter(u => u.id !== user.id));
          }
          closeDialog();
        }
        return !!resp;
      }
    })
  }

  const tabs = useMemo(() => {
    return [
      {
        label: 'Основные',
        key: 'main',
        children: <ProfileTab isNewUser={isNewUser}/>
      },
      {
        label: 'ГСК',
        key: 'meters',
        children: !!user ? <GskTab form={form} userId={user.id} isLoading={isLoading} load={load}/> : <></>
      },
      {
        label: 'Уведомления',
        key: 'notifications',
        children: !!user ? <NotificationsTab form={form} /> : <></>
      },
    ]
  }, [form, isLoading, isNewUser, load, user]);

  return (
    <ModalBase 
      closeDialog={closeDialog}
      title={(isNewUser ? 'Регистрация' : 'Профиль') +' пользователя'}
      leftFooterCell={!isNewUser && isGlobalAdmin && <DeleteButton onClick={handleDelete}/>}
      okButtonText={isNewUser && !newUserAdded ? 'Зарегистрироваться' : undefined}
      okButtonHandler={() => newUserAdded ? closeDialog() : handleOk()}
      isLoading={isLoading} width={500}
      withCancelButton={false}
    >
      {newUserAdded ?
        <>
          <p>Регистрация прошла успешно!</p>
          <p>На почту <b>{email}</b> отправленна ссылка для подтверждения регистрации. Перейдите по ней, чтобы подтвердить свою учтеную запись</p>
        </>
      :
        <Form
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          layout={'horizontal'}
          form={form}
          size="small"
          initialValues={initUser}
          onFinish={handleSave}
        >
          {isNewUser ? 
            <>
              <ProfileTab isNewUser={isNewUser} captcha={captcha} />
              {isNewUser && <LoginWithBlock load={load} />}
            </>
            :
            <Tabs
              tabPosition='left'
              style={{minHeight: '400px'}}
              items={tabs}
            />
          }
        </Form>
      }
    </ModalBase>
  );
}

export default ProfileModal;