import { useState } from "react";
import { Tour, TourProps } from "antd";
import IconButton from "../../IconButton/IconButton";
import { DollarOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { RefType, getAppRef } from "../../../store/enum/RefType";

export function AccrualsTour() {
  const [tourOpen, setTourOpen] = useState(false);
  const addAccrualsButton = getAppRef(RefType.GskAccrualsAddButton);
  const renderChargesButton = getAppRef(RefType.GskAccrualsRenderChargesButton);
  const accrualsTable = getAppRef(RefType.GskAccrualsTable);
  const filter = getAppRef(RefType.GskAccrualsFilter);
	const steps: TourProps['steps'] = [
    {
      title: 'Добавить начисления',
      description: <>Чтобы добавить начисление нажмите эту кнопку</>,
      target: () => addAccrualsButton.current,
    },
    {
      title: 'Создать платежи',
      description: <>
        <p>После добавления начисления нужно создать платежи. Нажмите эту кнопку и укажите период, за который хотите создать платежи.</p>
        <p>Cписок платежей можно будет увидеть нажав на ячейку "<b>Платежи</b>" в таблице ниже.</p>
        <p>Пока платеж не оплачен его можно удалить. Оплаченные платежи удалить нельзя</p>
        <p>Все платежи по боксу можно посмотреть на странице боксов (кнопка <DollarOutlined /> в строке бокса)</p>
      </>,
      target: () => renderChargesButton.current,
    },
    {
      title: 'Список начислений ГСК',
      description: <>Таблица со всеми начислениями</>,
      target: () => accrualsTable.current,
    },
    {
      title: 'Фильтры',
      description: <>Начисления, по которым были платежи не удаляются полностью, а помечаются удаленными. При необходимости вы можете их восстановить</>,
      target: () => filter.current,
    },
  ];
  return (<>
    <IconButton icon={<QuestionCircleOutlined />} onClick={() => setTourOpen(true)} />
    <Tour open={tourOpen} onClose={() => setTourOpen(false)} steps={steps} />
  </>)
}