import { Form, FormInstance, Input } from "antd"
import { useEffectOnce } from "../../utils/hooks/useEffectOnce";
import { CaptchaService } from "../../Services/CaptchaService";
import { VerifyState, VerifyStateToText } from "../../types/CaptchaTypes";
import Captcha from "../Captcha/Captcha";
import { CAPTCHA_ENABLED } from "../../constants";

export default function useCapthcaFormItem(form: FormInstance, prefix: string[] = [], hidden: boolean = false): [(state: VerifyState) => void, JSX.Element] {
  const captchaKey = Form.useWatch([...prefix, "captchaKey"], form);
  
  const onError = async (state: VerifyState) => {
    form.setFieldValue([...prefix, 'captchaCode'], '');
    await refresh();
    
    const error = VerifyStateToText(state);
    form.setFields([{
      name: [...prefix, 'captchaCode'],
      value: '',
      errors: error ? [error] : undefined,
    }]);
  }

  const refresh = async () => {
    const key = await CaptchaService.new();
    form.setFieldValue([...prefix, 'captchaKey'], key);
  }

  useEffectOnce(() => {
    if (!CAPTCHA_ENABLED || hidden) {
      form.setFieldValue([...prefix, 'captchaKey'], '00000000-0000-0000-0000-000000000000');
      form.setFieldValue([...prefix, 'captchaCode'], '');
    } else {
      refresh();
    }
  });

  return [onError, 
    <>
      <Form.Item name={[...prefix, 'captchaKey']} noStyle >
        <Input type="hidden"/>
      </Form.Item>
      <Form.Item noStyle={!CAPTCHA_ENABLED} name={[...prefix, 'captchaCode']} label='Введите число' rules={[{required: CAPTCHA_ENABLED, message: "Подтвердите, что Вы не робот"}]}>
        {CAPTCHA_ENABLED && <Captcha captchaKey={captchaKey} refreshKey={refresh}/> }
      </Form.Item>
    </>
  ];
}
