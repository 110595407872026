import { PhoneOutlined } from "@ant-design/icons";
import { useMemo } from "react"
import './CallTo.scss'

interface Props{
  phone?: string;
}
export default function CallTo({ phone }: Props){
  const callPhone = useMemo(() => 
    phone?.replaceAll(')', '').replaceAll('(', '').replaceAll(' ', '').replaceAll('-', '')
  , [phone]);

  return (<>
    {phone ? 
    <span className='phone'>{phone} <a href={'tel:'+callPhone}><PhoneOutlined /></a></span> :
    <></>}
  </>)
}