import { ShowSuccess } from "../components/Notifications/Notifications";
import { MailingRequest } from "../types/MailingTypes";
import { Fetch, FetchNoResp } from "../utils/fetch";

export const MailingService = {
	sendTestMail: async (email: string) => {
		await Fetch('/api/mailing/SendTestMail', "POST", { email });
		ShowSuccess("MailingService", "Сообщение отправлено");
	},
	manualSend: async (model: MailingRequest): Promise<boolean> => {
		return await FetchNoResp('/api/mailing/ManualSend', "POST", model);
	},
}