export enum RepeatIntervalType {
	Second = 0,
	Minute = 1,
	Hour = 2,
	Day = 3,
	Week = 4,
	Month = 5,
	Quarter = 7,
	Year = 8,
}

export const GetRepeatIntervalTypeName = (v: RepeatIntervalType) => {
	switch (v) {
		case RepeatIntervalType.Second:
			return "секунда";
		case RepeatIntervalType.Minute:
			return "минута";
		case RepeatIntervalType.Hour:
			return "час";
		case RepeatIntervalType.Day:
			return "день";
		case RepeatIntervalType.Week:
			return "неделя";
		case RepeatIntervalType.Month:
			return "месяц";
		case RepeatIntervalType.Quarter:
			return "квартал";
		case RepeatIntervalType.Year:
			return "год";
	}
}