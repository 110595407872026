import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OperationType } from "../../Services/OperationsService";
import { RootState } from "../configureStore";
import { isEqual } from 'lodash';

export enum OperationStatus {
	Running,
	Success,
	Failed,
}

export interface OperationObject {
	type: OperationType;
	objectId: any;
	operationStatus: OperationStatus;
}

export interface OperationsState {
	list: OperationObject[];
}


export function initOperationsState(): OperationsState {
	return {
		list: []
	};
}

export const operationsSlice = createSlice({
	name: 'operations',
	initialState: initOperationsState(),
	reducers: {
		startOperation(state, action: PayloadAction<{ type: OperationType, objectId: any }>) {
			if (!state.list.some(o => o.type === action.payload.type && isEqual(o.objectId, action.payload.objectId) && o.operationStatus === OperationStatus.Running)) {
				state.list.push({ type: action.payload.type, objectId: action.payload.objectId, operationStatus: OperationStatus.Running });
			}
		},
		finishOperation(state, action: PayloadAction<{ type: OperationType, objectId: any, success: boolean }>) {
			const operation = state.list.filter(l =>
				l.type === action.payload.type
				&& isEqual(l.objectId, action.payload.objectId)
				&& l.operationStatus === OperationStatus.Running
			)[0];

			if (!!operation) {
				state.list = [...state.list.filter(s => !(s.type === operation.type && isEqual(s.objectId, operation.objectId) && s.operationStatus === OperationStatus.Running))
					, { ...operation, operationStatus: action.payload.success ? OperationStatus.Success : OperationStatus.Failed }];
			}
		},
	},
});


export const getOperationsState = (state: RootState) => state.operationsSlice;
export const isOperationRunningSelector = (type: OperationType, objectId: any = null) =>
	createSelector([getOperationsState], (state) => state.list.filter(l => l.type === type && isEqual(l.objectId, objectId) && l.operationStatus === OperationStatus.Running).length > 0);

	export const isOperationsRunningSelector = (type: OperationType, objectIds: any[]) =>
	createSelector([getOperationsState], (state) => 
		state.list.filter(
			l => l.type === type && objectIds.some(objectId => isEqual(l.objectId, objectId))
			&& l.operationStatus === OperationStatus.Running
		).length > 0);

export const isOperationRunningOrSucceedSelector = (type: OperationType, objectId: any = null) =>
	createSelector([getOperationsState], (state) => 
	state.list.filter(l => 
		l.type === type 
		&& isEqual(l.objectId, objectId) 
		&& (l.operationStatus === OperationStatus.Running || l.operationStatus === OperationStatus.Success)).length > 0);
export const getOperationRunningSelector = (type: OperationType, objectId: any = null) =>
	createSelector([getOperationsState], (state) => state.list.filter(l => l.type === type && isEqual(l.objectId, objectId) && l.operationStatus === OperationStatus.Running));

export const getOperationSuccededSelector = (type: OperationType, objectId: any = null) =>
	createSelector([getOperationsState], (state) => state.list.filter(l => l.type === type && isEqual(l.objectId, objectId) && l.operationStatus === OperationStatus.Success).length > 0);


export const { startOperation, finishOperation } = operationsSlice.actions
export default operationsSlice.reducer;