import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react';
import {createRoot} from 'react-dom/client';
import { Modal, Input, Form } from 'antd';
import { Rule } from 'antd/es/form';
import { ModalProps } from 'antd/es/modal';

let div = document.getElementById('prompt-root');
if (!div) {
  div = document.createElement('div');
  div.id = 'prompt-root';
  document.getElementsByTagName('body')[0]?.appendChild(div);
}
const promptRoot = createRoot(
  div
);

interface Props {
  rules?: Rule[];
  placeholder?: string;
  ref?: any;
  value?: string;
  onPressEnter?: () => void;
  autoFocus?: boolean;
}

const PromptForm = forwardRef(
  ({ rules, placeholder, onPressEnter, value, autoFocus }: Props, ref: any) => {
    const [formInstance] = Form.useForm();

    useEffect(() => {
      formInstance.setFieldsValue({ input: value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => ({
      validate: () => {
        return formInstance.validateFields().then((res) => res.input);
      },
    }));

    return (
      <Form form={formInstance}>
        <Form.Item name="input" rules={rules}>
          <Input
            placeholder={placeholder}
            onPressEnter={onPressEnter}
            autoFocus={autoFocus}
          />
        </Form.Item>
      </Form>
    );
  }
);

interface PromptConfig {
  title: React.ReactNode;
  value?: string;
  rules?: Rule[];
  placeholder?: string;
  modalProps?: Partial<ModalProps>;
  onOk?: (value?: string) => boolean | Promise<boolean>;
  autoFocus?: boolean;
}

interface PromptProps extends Props {
  modalProps?: Partial<ModalProps>;
  visible: boolean;
  submit: (value?: string) => void;
  close: (value?: string) => void;
  title: React.ReactNode;
  afterClose?: () => void;
  okText?: string;
  cancelText?: string;
}

function Prompt({
  rules,
  placeholder,
  modalProps = {},
  visible,
  submit,
  close,
  title,
  value,
  afterClose,
  autoFocus,
  okText = 'Ок',
  cancelText = 'Отмена',
}: PromptProps) {
  const formRef = useRef<any>(null);
  const handleOk = async () => {
    try {
      const value = await formRef.current?.validate();
      submit(value);
    } catch (e) {
      // noop
    }
  };
  return (
    <Modal
      {...modalProps}
      open={visible}
      onOk={handleOk}
      zIndex={2000}
      okText={okText}
      cancelText={cancelText}
      onCancel={() => close()}
      title={title}
      getContainer={false}
      afterClose={afterClose}
    >
      <PromptForm
        ref={formRef}
        rules={rules}
        value={value}
        placeholder={placeholder}
        onPressEnter={handleOk}
        autoFocus={autoFocus}
      />
    </Modal>
  );
}

export default function prompt(
  config: PromptConfig
): Promise<string | undefined> {
  return new Promise((resolve, reject) => {
    const { onOk, ...others } = config;
    let currentConfig: PromptProps = {
      ...others,
      submit,
      close,
      visible: true,
    };

    const destroy = (value?: string) => {
      promptRoot.render(<></>)
      if (value !== undefined) {
        resolve(value);
      } 
      // else {
      //   reject(value);
      // }
    };

    function close(value?: string) {
      currentConfig = {
        ...currentConfig,
        visible: false,
        afterClose: () => destroy(value),
      };
      render(currentConfig);
    }
    async function submit(value?: string) {
      if (onOk) {
        const isClose = await onOk(value);
        if (isClose || isClose === undefined) {
          close(value);
        }
      } else {
        close(value);
      }
    }

    function render(props: PromptProps) {
      promptRoot.render(
        <React.StrictMode><Prompt {...props} /></React.StrictMode>);
    }

    render(currentConfig);
  });
}