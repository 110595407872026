import { GskPostModel, GskVoteConfigurationModel, GskVoteModel } from "../../../types/GskPostTypes"
import { useCallback } from "react";
import { GskPostService } from "../../../Services/GskPostsService";
import VoteBlockCommon from "../../Post/Base/VoteBlockCommon";

interface Props {
  gskId: number;
  postId: number;
  voteConfiguration: GskVoteConfigurationModel;
  setPost: (v: React.SetStateAction<GskPostModel | undefined>) => void;
}
export default function VoteBlock({ gskId, postId, voteConfiguration, setPost}: Props) {
  const voteHandler = useCallback(async(req: GskVoteModel) => {
    const resp = await GskPostService.vote(gskId, req);
    if (resp) {
      setPost(resp);
    }
    return !!resp;
  }, [gskId, setPost] )

  return (<VoteBlockCommon postId={postId} voteConfiguration={voteConfiguration} voteHandler={voteHandler} isGskMode/>)
}