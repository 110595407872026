import React, { useMemo, useState } from "react";
import useLoading from "../utils/hooks/useLoading";
import { DiscountModel, TariffModel } from "../types/BillingTypes";
import { useEffectOnce } from "../utils/hooks/useEffectOnce";
import { BillingService } from "../Services/BillingService";
import { Alert, Card, Table } from "antd";
import { ColumnType } from "antd/es/table";
import { toMoneyString } from "../utils/moneyHelper";
import dayjs from 'dayjs'
import { Link } from "react-router-dom";

export default function PricingPage() {
  const [isLoading, load] = useLoading();
  const [tariffs, setTariffs] = useState<TariffModel[]>([]);
  const [discount, setDiscount] = useState<DiscountModel | undefined>();
	const columns: ColumnType<TariffModel>[]  = useMemo(() => {
    return [
      {
        title: 'Тариф',
        dataIndex: 'name',
        key: 'name',
        render: (_, row) => <span style={{textDecoration: row.isActive === true ? 'unset' : 'line-through'}}>{row.name}</span>
      },
      {
        title: 'Количество боксов',
        key: 'type',
        render: (_, row) => <>{!row.boxesLimit ? 'Не ограничено' : <>До {row.boxesLimit}</>}</>,
        responsive: ['sm'],
      },
      {
        title: 'Цена',
        dataIndex: 'price',
        key: 'price',
        render: v => <PriceCell price={v} discountPercent={discount?.percent}/>,
      },
    ]
  }, [discount?.percent])


  useEffectOnce(() => {
    const fetch = async () => {
      const tariffsResp = await load(BillingService.getTariffs(true));
      if (tariffsResp) {
        setTariffs(tariffsResp.sort((a,b) => a.price - b.price));
      }
    }
    fetch();
  })

  useEffectOnce(() => {
    const fetch = async () => {
      const discountsResp = await load(BillingService.getDiscounts(true));
      if (discountsResp) {
        setDiscount(discountsResp[0]);
      }
    }
    fetch();
  })
	return (<Card>
		<DiscountCard discount={discount} />
		<Table 
			loading={isLoading}
			columns={columns}
			dataSource={tariffs}
			rowKey={'id'}
			pagination={false}
		/>
		<ul>
			<li>Все тарифы включают в себя возможности, описанные в <Link to='/Promo'>презентации</Link>. Различия в количестве обслуживаемых боксов</li>
			<li>Для каждого зарегистрированного ГСК предсотавляется дисковое пространство для хранения файлов и документов размером 1Гб</li>
		</ul>
	</Card>)
}

interface DiscountCardProps {
	discount?: DiscountModel;
}
export function DiscountCard({discount}: DiscountCardProps) {
	return(<>{discount && <Alert type="warning" style={{marginBottom: 10, textAlign: 'center'}} message={<>
		{discount.dateEnd && <>До {dayjs(discount.dateEnd).format("DD.MM.YYYY")} </>} скидка {discount.percent}% на все тарифы!</>
	}/>}</>)
}

interface PriceCellProps {
	price?: number;
	discountPercent?: number;
}

export function PriceCell({price = 0, discountPercent}: PriceCellProps) {
	return (<span>{(price ?? 0) > 0 ? <>
		{discountPercent && <span style={{textDecoration: 'line-through', color: 'gray', fontSize: '.8em'}}>{toMoneyString(price, false)}</span>}{' '}
		<span style={{fontWeight: 500, color: 'red'}}>{toMoneyString(price - (discountPercent ? price / 100 * discountPercent : 0 ))}</span> / год
	</>
	: <><span style={{fontWeight: 500, color: 'red'}}>Бесплатно</span> / 6 мес</>
}</span>)
}