import { RepeatIntervalType } from "../store/enum/repeatIntervalType";
import { PaymentState } from "./BillingTypes";
import { ElectricAccrualConfigModel } from "./MeterTypes";
import { PagedRequest } from "./dto";

export interface NewGskAccrualModel {
  accrualType: AccrualType;
  repeatType: RepeatIntervalType | null;
  repeatInterval: number | null;
  name: string;
  amount?: number;
  startPeriod: Date;
  endPeriod: Date | null;
  electricAccrualConfig?: ElectricAccrualConfigModel;
}

export interface GskAccrualModel extends NewGskAccrualModel {
  id: number;
  gskId: number;
  charges: GskChargeModel[];
  created: Date;
  isDeleted: boolean;
}

export interface GskAccrualModelWithStats extends GskAccrualModel {
  chargesTotalCount?: number;
  chargesPaidCount?: number;
  chargesTotalSum?: number;
  chargesPaidSum?: number;
  chargesDates?: Date[];
}

export interface NewGskChargeModel {
  name: string;
  boxId: number;
  forTheDate: Date;
  payEndDate: Date | null;
  description: string | null;
  amount: number;
  withEmailNotify: boolean;
}

export interface EditGskChargeModel extends NewGskChargeModel {
  id: number;
}

export interface GskChargeModel extends EditGskChargeModel {
  accrualId: number;
  boxNum: string;
  payments: GskPaymentModel[];
  created: Date;
  isDeleted: boolean;
  isMeterAccrual: boolean;
}


export enum PaymentType {
  Cash = 0,
  Card = 1,
}

export function getPaymentTypeName(val: PaymentType) {
  switch(val){
    case PaymentType.Cash:
      return 'Наличными';
    case PaymentType.Card:
      return 'Картой';
  }
}

export interface NewPaymentModel {
  chargeIds: number[];
  paymentType: PaymentType;
  paymentPageUrl: string;
  amount: number;
  comment: string | null;
}

export interface DeletePaymentModel {
  reason: string;
}

export interface GskPaymentModel {
    id: number;
    chargeId: number;
    paymentType: PaymentType;
    paymentState: PaymentState;
    createdBy: string | null;
    created: string;
    amount: number;
}

export interface RenderGskChargesRequest {
  periodStart: Date;
  periodEnd: Date;
  withEmailNotify: boolean;
  accrualIds?: number[];
  boxIds?: number[];
}

export interface GskBoxChargeModel extends GskChargeModel {
  accrualName: string;
}

export enum PayState {
	Paid,
	LatePayment,
	None,
}

export const getPayState = (c: GskChargeModel) => {
  let payState: PayState = PayState.None;
  const paySumm = c.payments.filter(p => p.paymentState === PaymentState.Succeeded).map(p => p.amount).reduce((sum, p) => sum + p, 0);
  if (c.amount <= paySumm) {
    payState = PayState.Paid;
  } else if (c.payEndDate) {
    if (new Date(c.payEndDate) < new Date(new Date().getTime() - (1000 * 60 * 60 * 24))) {
      payState = PayState.LatePayment;
    }
  } else if (c.isMeterAccrual) {
    return PayState.LatePayment;
  }
  return payState;
}

export const getPaymentClassNameByValue = (chargedSumm?: number | null, paidSumm?: number | null) => {
  let payState: PayState = PayState.None;
  chargedSumm = chargedSumm ?? 0;
  paidSumm = paidSumm ?? 0;
  if (chargedSumm > paidSumm) {
    payState = PayState.LatePayment;
  }
  if (chargedSumm > 0 && chargedSumm === paidSumm) {
    payState = PayState.Paid;
  }
  return getPaymentClassName(payState);
}

export const getPaymentClassName = (payState: PayState) => {
	switch (payState) {
		case PayState.LatePayment:
			return 'late-payment';
		case PayState.Paid:
			return 'paid';
		default:
			return '';
	}
}

export interface GskBoxChargeModelWithPayments extends GskBoxChargeModel{
  payments: GskPaymentModel[]
}

export enum AccrualType {
  ONETIME = 0,
  REPEAT = 1,
  ELECTRIC = 2,
}

export const GetAccrualTypeName = (type: AccrualType): string => {
  switch(type){
    case AccrualType.ONETIME:
      return 'Разово';
    case AccrualType.REPEAT:
      return 'Повтор';
    case AccrualType.ELECTRIC:
      return 'Электроэнергия';
    default:
      throw new Error("Unexpected AccrualType");
  }
}

export interface AccrualsRequest extends PagedRequest {
  showDeleted: boolean;
}

export interface AccrualShortModel {
  id: number;
  accrualType: AccrualType;
  name: string;
}

export interface ChargesRequest extends PagedRequest {
  datesString: string[];
  text: string;
}
