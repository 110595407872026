export enum AddressLevel
{
    None = 0,
    Region = 1,                     //Субъект РФ
    City = 2,                       //Город/Сельское/городское поселение
    Street = 3,                     //Элемент улично-дорожной сети
    House = 4,                      //Здание (сооружение)
    Apartment = 5,                  //Помещение
}

export interface AddressModel {
  value: string;
  fiasId: string | null;
  city: string | null;
  level: AddressLevel;
}