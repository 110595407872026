import { Alert, Button, Card, Checkbox, Col, Form, Modal, Progress, Radio, Row, Space, Tooltip } from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from "react";
import { QuestionCircleOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Declension } from "../../../utils/DeclensionGenerator";
import { OptionModelBase, QuestionModelBase, VoteAnswerBase, VoteConfigurationBase, VoteModelBase } from "../../../types/PostBaseTypes";
import IconButton from "../../IconButton/IconButton";
import RowTwoCol from "../../RowTwoCol/RowTwoCol";
import GroupBox from "../../GroupBox/GroupBox";

interface Question extends QuestionModelBase<OptionModelBase> {
  optionsIds: number[];
  optionId?: number;
}
interface FormData {
  questions: Question[];
}

interface Props {
  postId: number;
  voteConfiguration: VoteConfigurationBase<QuestionModelBase<OptionModelBase>>;
  voteHandler: (req: VoteModelBase<VoteAnswerBase>) => Promise<boolean>;
  isGskMode?: boolean;
}
export default function VoteBlockCommon({postId, voteConfiguration, voteHandler, isGskMode = false}: Props) {
  const formDisabled = voteConfiguration.isVoteFinished || voteConfiguration.isCurrentUserVoited;
  const [showResult, setShowResult] = useState(voteConfiguration.isVoteFinished || voteConfiguration.isCurrentUserVoited);
  const [form] = useForm<FormData>();   
  Form.useWatch('questions', form); //без этого не работает валидация выбора опций
  const voitedPercent = parseFloat((voteConfiguration.votedCount / voteConfiguration.votersTotalCount * 100).toFixed(2));

  const validateVote = (question: Question) => {
    const optionsIds = question.allowedToChoiceQty === 1 ? [question.optionId] : question.optionsIds;
    if (optionsIds.length === 0 || question.optionsIds === null) {
      return Promise.reject("Выберите значение")
    }
    if (optionsIds.length > question.allowedToChoiceQty) {
      return Promise.reject("Нельзя выбрать более " + Declension(question.allowedToChoiceQty, 'вариант'));
    } 
    
    return Promise.resolve();
  }

  const submit = async (data: FormData) => {
    let req: VoteModelBase<VoteAnswerBase> = {
      voteId: postId,
      answers: [],
    }
    data.questions.forEach(q => {
      if (!q.id) {
        throw Error("!q.id");
      }
      let ids = q.allowedToChoiceQty === 1 ? q.optionId ? [q.optionId] : [] : q.optionsIds;
      req.answers.push({questionId: q.id, optionsIds: ids})
    });
    if (await voteHandler(req)) { //PostService.vote(req)
      setShowResult(true);
    };
  }

  const getFromInitValues = useCallback((): FormData => {
    let result: FormData = {questions: []};
    voteConfiguration.questions.forEach((q) => {
      const ids = q.options.filter(o => o.isCurentUserVoted).map(o => o.id ?? -1);
      if (ids) {
        result.questions.push({...q, optionsIds: ids, optionId: ids[0]});
      }
    })
    return result;
  }, [voteConfiguration.questions]);

  const showVotes = useCallback((option: OptionModelBase) => {
    Modal.info({
      title: <>Список боксов, проголовавших за вариант <i>{option.name}</i>:</>,
      content: option.voters.length > 0 ? 
        option.voters.map((v, i) => <Row key={i}>{v}</Row>)
        :
        <>Пусто</>
    })
  }, []);

  useEffect(() => {
    form.setFieldsValue(getFromInitValues());
  }, [form, getFromInitValues, voteConfiguration]);

  return (
  <Card
    size="default"
    title={<>
      <span>Голосование {voteConfiguration.isVoteFinished && <>завершено {dayjs(voteConfiguration.dateFinish).format("DD.MM.YYYY")}</>}</span> 
      <span style={{fontWeight: 'normal', color: 'gray', fontSize: '0.9em'}}>
        &nbsp;({voteConfiguration.isCurrentUserVoited ? <>Вы проголосовали</> : <>до {dayjs(voteConfiguration.dateFinish).format('DD.MM.YYYY')}</>})
      </span>
    </>}
  >
    <Row style={{marginBottom: 10}}>
      <Radio.Group value={showResult} onChange={e => setShowResult(e.target.value)}>
        <Radio.Button value={false} >Мой выбор</Radio.Button>
        <Radio.Button value={true} >Результаты</Radio.Button>
      </Radio.Group>
    </Row>
    {showResult ? 
    <>
      <Space>
        {isGskMode && <Progress type="dashboard" style={{width: 150}} percent={voitedPercent} />}
        <>
          Приняло участие {voteConfiguration.votedCount} {isGskMode && <>из {Declension(voteConfiguration.votersTotalCount, 'бокс')}
          <Tooltip title='Если у человека 2 бокса его голос засчитывается за каждый бокс (x2) и т.д.'><QuestionCircleOutlined /></Tooltip></>}
        </>
      </Space>
      {!voteConfiguration.showResultsBeforeFinish && !voteConfiguration.isVoteFinished && <Row>
        Результаты голосования будут видны после {dayjs(voteConfiguration.dateFinish).format('DD.MM.YYYY')}
      </Row>}
      {(voteConfiguration.showResultsBeforeFinish || voteConfiguration.isVoteFinished) &&
        voteConfiguration.questions.map(q => 
          <GroupBox key={q.id} style={{maxWidth:500}} label={<span style={{fontWeight: 'bold', fontSize: '1.2em'}}>{q.question}</span>}>
            {[...q.options].sort((a, b) => (b.votesCount ?? 0)-(a.votesCount ?? 0)).map((o, index) =>
              <RowTwoCol
                key={o.id}
                left={`${index + 1}. ${o.name}`}
                rightSize={240}
                right={<Space>
                  <Tooltip title={!isGskMode && <>Проголосовало: {o.votesCount}</>}>
                    <Progress 
                      style={{ width: 200}}
                      percent={parseFloat(((o.votesCount ?? 0) / (isGskMode ? voteConfiguration.votersTotalCount : voteConfiguration.votedCount) * 100).toFixed(2))} 
                    />
                  </Tooltip>
                  {!voteConfiguration.isAnonymous && isGskMode && <IconButton icon={<UnorderedListOutlined />} onClick={() => showVotes(o)} title="Список проголосовавших" />}
                </Space>}
              />
            )}
          </GroupBox>
        )
      }
    </>
    :
    <>
      {voteConfiguration.isAnonymous && <Alert style={{marginTop: 5, marginBottom: 5}} type="info" message='Голосование анонимное - другим учаснимкам не будет виден Ваш выбор'/>}
      <Form
        form={form}
        size="small"
        layout="vertical"
        onFinish={submit}
        disabled={formDisabled}
      >
        <Form.List name={'questions'}> 
        {questionsFields => (
          
          <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
            {questionsFields.map((qField) => {
              const question: Question = form.getFieldValue(['questions', qField.name]);
              return (
                <Form.Item 
                  key={qField.key}
                  name={[qField.name, (question.allowedToChoiceQty === 1 ? 'optionId' : 'optionsIds')]}
                  label={<>
                    <span style={{fontWeight: 'bold', fontSize: '1.2em'}}>{question.question}</span>&nbsp;&nbsp;
                    {question.allowedToChoiceQty > 1 && <span style={{color: 'lightgray'}}>(Можно выбрать {Declension(question.allowedToChoiceQty, 'вариант')})</span>}
                  </>}
                  rules={[{validator: () => validateVote(question)}]}
                >
                  {
                    question.allowedToChoiceQty === 1 ?
                    <Radio.Group>
                      {question.options.map(o => <Radio key={o.id} style={{display: 'block'}} value={o.id}>{o.name}</Radio>)}
                    </Radio.Group>
                    :
                    <Checkbox.Group style={{display: 'unset'}} disabled={formDisabled}>
                      {question.options.map(o => 
                        <Row key={o.id}>
                          <Col span={24}>
                            <Checkbox 
                              value={o.id}
                              checked={o.isCurentUserVoted}
                              disabled={!question.optionsIds.some(s => s === o.id) && question.allowedToChoiceQty === question.optionsIds.length}
                            >
                              {o.name}
                            </Checkbox>
                          </Col>
                        </Row>)}
                    </Checkbox.Group>
                  }
                </Form.Item>
              )
          })}
          </div>
        )}
        </Form.List> 
        {!formDisabled && <Button onClick={() => form.submit()}>Сохранить</Button>}
      </Form>
    </>
    }
  </Card>)
}