import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "../../store/redux";
import Link from 'antd/es/typography/Link';
import { useEffect, useState } from "react";
import { GskInviteModel } from "../../types/GskTypes";
import { Alert, Button, Card, Flex, Spin } from "antd";
import { ShowError, ShowSuccess } from "../../components/Notifications/Notifications";
import { UserModel } from "../../types/UserTypes";
import { BoxesService } from "../../Services/BoxesService";
import useLoading from "../../utils/hooks/useLoading";
import { useCurrentUser } from "../../store/common/app-slice";

interface InvatePageProps{
  openLoginModal: (message?:string, redirectUrl?: string) => void;
  openProfileModal: (user?: UserModel) => void;
}
export default function InvitePage ({ openLoginModal, openProfileModal }: InvatePageProps) {
  const dispatch = useAppDispatch();
	const params = useParams();
	const key = params.guid;
  const currentUser = useCurrentUser();
  const [isLoading, load] = useLoading();
  const [invite, setInvite] = useState<GskInviteModel | undefined>(undefined);
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (!currentUser || invite || !key) {
      return;
    }

    const getInvite = async () => {
      const resp = await load(BoxesService.getInvite(key));
      setInvite(resp);
    }

    getInvite();
  }, [currentUser, dispatch, invite, key, load]);

  const handleAcceptInviteClick = async () => {
    if (!key || !invite) {
      return;
    }

    const gskId = await load(BoxesService.applyInvite(key));
    if (!gskId) {
      ShowError("Ошибка при принятии приглашения", "gskId пустой");
      return;
    }
    
    ShowSuccess("Приглашение принято", "Вы вступили в "+invite.gskName)
    navigate('/Gsk/'+gskId);
  }

	return (<Flex justify="center" align="center" style={{paddingTop: 20}}>{ currentUser ?
    <Spin spinning={isLoading}>
      <Card style={{ width: 400 }}>
        {invite && <>
          {invite.isExpired && <Alert type="warning" message="Приглашение просрочено. Запросите приглашение еще раз" />}
          <p>{invite.createdBy} приглашает Вас вступть в <b>{invite.gskName}</b> (бокс №<b>{invite.boxNum}</b>)</p>
          <p>После вступления Вам станет доступен сайт {invite.gskName}</p>
          <Flex justify="flex-end">
            <Button type="primary" disabled={invite.isExpired} onClick={handleAcceptInviteClick}>Принять</Button>
          </Flex>
        </>}
      </Card>
    </Spin>
    :
    <Card style={{ width: 400 }}>
      <Alert type="info"
        message={<>
          <p>Чтобы принять приглашение необходимо <Link onClick={() => openLoginModal(undefined, location.pathname)}>войти</Link> под своей учетной записью.</p>
          <p>Если у Вас нет учетной записи пройдите <Link onClick={() => openProfileModal(undefined)}>регистрацию</Link> и заново перейдите по ссылке-приглашению</p>
        </>}
      />
      
    </Card>
  }
  </Flex>)
}