import React, { forwardRef } from "react";
import { useMemo } from "react";
import './IconButton.scss'
import { Tooltip } from "antd";

interface IconButtonProps{
  icon: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  twoToneColor?: string | null;
  style?: React.CSSProperties;
  title?: string;
  colorDisabled?: string;
}

const IconButton = forwardRef<IconButtonProps, any>(({
  icon,
  onClick = () => null,
  disabled,
  twoToneColor = null,
  title,
  style = {},
  colorDisabled = 'lightgray',
}, ref) =>{
  const rendered = useMemo(() => {
    
    const newStyle = {...style,
      cursor: disabled ? 'auto' : 'pointer',
      color: disabled ? colorDisabled : twoToneColor,
    };

    return React.cloneElement(icon, {
      style: newStyle,
      className: 'icon-button',
      onClick: disabled ? () => null : onClick,
      disabled: disabled,
      title: title,
      ref: ref
    })
  }, [colorDisabled, disabled, icon, onClick, ref, style, title, twoToneColor])
  return (<Tooltip title={title}>
    {rendered}
    </Tooltip>
  )
})

export default IconButton;