import type { MenuProps } from "antd";
import { Dropdown } from "antd";
import { MapPolygon, Point } from "./GskMapTypes";
import { useCallback, useMemo, useState } from "react";
import { CheckOutlined, DeleteOutlined, EditOutlined, UndoOutlined } from "@ant-design/icons";

export default function PolygonMenu(
  savePolygon: (v: MapPolygon) => void,
  openEditPolygonModal: (v: MapPolygon) => void,
): [JSX.Element | undefined, (polygon: MapPolygon, pos: Point) => void] {
  const [open, setOpen] = useState(false);
  const [pos, setPos] = useState<Point>({x: 0, y: 0});
  const [polygon, setPolygon] = useState<MapPolygon | null>(null);

  const items: MenuProps["items"] = useMemo(() => {
    if (!polygon) {
      return [];
    }

    return [
      {
        key: 'edit',
        label: 'Изменить',
        icon: <EditOutlined />,
        onClick: () => openEditPolygonModal(polygon),
        style: {display: polygon.isFinished ? 'flex' : 'none'}
      },
      {
        key: 'deletePoints',
        label: 'Удалить',
        icon: <DeleteOutlined />,
        onClick: () => savePolygon({...polygon, points: [], isFinished: false})
      },
      {
        key: 'undoPoints',
        label: 'Убрать последнюю точку',
        icon: <UndoOutlined />,
        onClick: () => savePolygon({...polygon, points: [...polygon.points.slice(0, -1)], isFinished: false})
      },
      {
        key: 'finish', 
        label: 'Завершить', 
        icon: <CheckOutlined />,
        onClick: () => savePolygon({...polygon, isFinished: true}),
        style: {display: !polygon.isFinished && polygon.points.length > 2 ? 'flex' : 'none'}
      }
    ];
  }, [openEditPolygonModal, polygon, savePolygon]);

	const openFun = useCallback((polygon: MapPolygon, pos: Point) => {
    if (!open) {
      document.addEventListener(`click`, function onClickOutside() {
        setOpen(false);
        document.removeEventListener(`click`, onClickOutside);
      });
    }
		setPos(pos);
		setOpen(true);
    setPolygon(polygon)
	}, [open]);

  return [
    <Dropdown
      menu={{ items }}
      open={!!polygon && open}
      overlayStyle={{ left: `${pos.x}px`, top: `${pos.y}px` }}
    >
      <></>
    </Dropdown>, openFun]
  ;
} 

