import { Button, Form } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/es/form/Form";
import Link from "antd/es/typography/Link";
import { useState } from "react";
import { useCurrentUser } from "../../../store/common/app-slice";

interface FormData {
  comment: string;
}

interface OwnProps {
  parentCommentId?: number | null,
  submitHandler: (v: string, parentCommentId: number | null) => void;
  style?: React.CSSProperties;
}

export default function AddCommentBlock({parentCommentId = null, submitHandler, style}: OwnProps) {
  const [showFrom, setShowForm] = useState(false);
  const [form] = useForm<FormData>();
  const currentUser = useCurrentUser();

  const submit = (data: FormData) => {
    try {
      submitHandler(data.comment, parentCommentId);
      form.setFieldValue('comment','');
      setShowForm(false);
    } catch {} 
  }

  return (
    <div style={style}>
      <Link onClick={() => setShowForm(!showFrom)}>{showFrom ? 'Скрыть' : 'Ответить'}</Link><br/>
      {showFrom && <Form
        form={form}
        onFinish={submit}
      >
        {currentUser === null ?
          <>Только зарегистрированные пользователи могут оставлять комментарии</>
        :
        <>
          <Form.Item 
            name='comment'
            rules={[{required: true, message: 'Введите значение'}]}
          >
            <TextArea placeholder="Ваш комментарий"/>
          </Form.Item>
          <Button size="small" onClick={() => form.submit()}>Отправить</Button>
        </>
      }
        
      </Form>}
    </div>
  )
}