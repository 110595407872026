import { BoxShortModel, BoxesFilter, BoxesRequest, EditBoxModel, GskBoxWithUser, GskInviteModel } from "../types/GskTypes";
import { Fetch, FetchNoResp } from "../utils/fetch";
import { PagedResult } from "../types/dto";

export const BoxesService = {
	addBox: async (gskId: number, num: string): Promise<GskBoxWithUser | undefined> => {
		return await Fetch<GskBoxWithUser>(`/api/gsks/${gskId}/boxes`, "PUT", { num });
	},
	addBoxes: async (gskId: number, rangeStart: number, rangeEnd: number): Promise<GskBoxWithUser[]> => {
		return await Fetch<GskBoxWithUser[]>(`/api/gsks/${gskId}/boxes/addMultiply`, "PUT", { rangeStart, rangeEnd }) ?? [];
	},
	getAllBoxes: async (gskId: number, filter: BoxesFilter): Promise<GskBoxWithUser[] | undefined> => {
		return await Fetch<GskBoxWithUser[]>(`/api/gsks/${gskId}/boxes/all`, "POST", filter);
	},
	getBoxesCount: async (gskId: number): Promise<number | undefined> => {
		return await Fetch<number>(`/api/gsks/${gskId}/boxes/count`, "GET");
	},
	getBoxesShort: async (gskId: number, filter: BoxesFilter = {}): Promise<BoxShortModel[] | undefined> => {
		return await Fetch<BoxShortModel[]>(`/api/gsks/${gskId}/boxes/listShort`, 'POST', filter);
	},
	getBoxesPage: async (gskId: number, req: BoxesRequest): Promise<PagedResult<GskBoxWithUser> | undefined> => {
		return await Fetch<PagedResult<GskBoxWithUser>>(`/api/gsks/${gskId}/boxes/page`, 'POST', req);
	},
	editBox: async (gskId: number, boxId: number, data: EditBoxModel): Promise<GskBoxWithUser | null> => {
		return await Fetch<GskBoxWithUser>(`/api/gsks/${gskId}/boxes/${boxId}`, "PATCH", data) ?? null;
	},
	deleteBox: async (gskId: number, boxId: number): Promise<boolean> => {
		return await FetchNoResp(`/api/gsks/${gskId}/boxes/${boxId}`, "DELETE");
	},
	removeBoxUser: async (gskId: number, boxId: number): Promise<GskBoxWithUser | undefined> => {
		return await Fetch<GskBoxWithUser>(`/api/gsks/${gskId}/boxes/${boxId}/removeUser`, "POST");
	},
	createInvite: async (gskId: number, boxId: number): Promise<GskInviteModel | undefined> => {
		return await Fetch<GskInviteModel>(`/api/gsks/${gskId}/boxes/${boxId}/invite`, "PUT");
	},
	getInvite: async (key: string): Promise<GskInviteModel | undefined> => {
		return await Fetch<GskInviteModel>(`/api/boxes/invite/${key}`, "GET");
	},
	applyInvite: async (key: string): Promise<number | undefined> => {
		return (await Fetch<{gskId: number}>(`/api/boxes/invite/${key}/apply`, "GET"))?.gskId;
	},
}