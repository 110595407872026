
export function Declension (number: number, type: DeclensionType) {
  const {nominativ, genetiv, plural} = predefined[type];
  return DeclensionCustom(number, nominativ, genetiv, plural);
}
export function DeclensionCustom (number: number, nominativ: string, genetiv: string, plural: string) {
    const titles = [ nominativ, genetiv, plural ];
    const cases = [2, 0, 1, 1, 1, 2 ];
    return `${number} ${titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]}`;
}

type DeclensionType = 'вариант' | 'голос' | 'бокс' | 'день';

 /// <param name="nominativ">Именительный падеж слова. Например "день"</param>
 /// <param name="genetiv">Родительный падеж слова. Например "дня"</param>
 /// <param name="plural">Множественное число слова. Например "дней"</param>
 
const predefined: Record<DeclensionType,{nominativ: string, genetiv: string, plural: string}>  = {
  'вариант': {nominativ: 'вариант', genetiv: 'варианта', plural: 'вариантов'},
  'голос': {nominativ: 'голос', genetiv: 'голоса', plural: 'голосов'},
  'бокс': {nominativ: 'бокс', genetiv: 'бокса', plural: 'боксов'},
  'день': {nominativ: 'день', genetiv: 'дня', plural: 'дней'},
}