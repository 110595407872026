import { useCallback, useMemo, useState } from "react";
import { Card, Flex, Space, Spin, Table } from "antd";
import { useEffectOnce } from "../../utils/hooks/useEffectOnce";
import { GetTariffTypeName, TariffModel } from "../../types/BillingTypes";
import useLoading from "../../utils/hooks/useLoading";
import { BillingService } from "../../Services/BillingService";
import { ColumnType } from "antd/es/table";
import dayjs from 'dayjs';
import { toMoneyString } from "../../utils/moneyHelper";
import IconButton from "../../components/IconButton/IconButton";
import { EditOutlined, PlusCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { useDialogWithParameter } from "../../utils/useDialog";
import TariffModal from "../../components/Billing/TariffModal";

export default function TariffsPage() {
  const [tariffs, setTariffs] = useState<TariffModel[]>([]);
  const [isLoading, load] = useLoading();

  const fetchTariffs = useCallback(async () => {
    const resp = await load(BillingService.getTariffs(false));
    if (resp) {
      setTariffs(resp);
    }
  }, [load]);
  
  useEffectOnce(() => { 
    fetchTariffs();
  });

  const [tariffModal, openTariffModal] = useDialogWithParameter<TariffModel | null>(
    (param, closeDialog) => <TariffModal tariff={param} closeDialog={closeDialog} setTariffs={setTariffs} />
  );

  const columns: ColumnType<TariffModel>[]  = useMemo(() => {
    return [
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
        render: (_, row) => <span style={{textDecoration: row.isActive === true ? 'unset' : 'line-through'}}>{row.name}</span>
      },
      {
        title: 'Тип',
        key: 'type',
        render: (_, row) => <>{GetTariffTypeName(row.type)} {row.boxesLimit && <>({row.boxesLimit})</>}</>,
        responsive: ['sm'],
      },
      {
        title: 'Цена',
        dataIndex: 'price',
        key: 'price',
        render: v => toMoneyString(v),
      },
      {
        title: 'Дата создания',
        key: 'created',
        dataIndex: 'created',
        render: v => dayjs(v).format("DD.MM.YYYY"),
        responsive: ['sm']
      },
      {
        title: <Flex justify="flex-end">
          <Space>
            <IconButton icon={<PlusCircleOutlined />} onClick={() => openTariffModal(null)} disabled={isLoading}/>
            <IconButton icon={<SyncOutlined />} onClick={fetchTariffs} disabled={isLoading}/>
          </Space>
        </Flex>,
        key: 'actions',
        render: (_, row) => <Flex justify="flex-end">
          <Space>
            <IconButton icon={<EditOutlined />} onClick={() => openTariffModal(row)} />
          </Space>
        </Flex>
      }
    ]
  }, [fetchTariffs, isLoading, openTariffModal])

  return (
    <Spin spinning={isLoading} style={{width: '100%'}}>
      <Card>
        <Table
          columns={columns}
          dataSource={tariffs}
          rowKey={'id'}
          pagination={false}
        />
      </Card>
      {tariffModal}
    </Spin>
  )
}
