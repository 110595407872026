import { useEffect, useState, useMemo, useCallback } from 'react'
import { Line, Circle, Group, Text } from 'react-konva'
import Konva from 'konva'
import { MapDefaults, MapPolygon, MapPolygonLabel, MapSettings, Point, ScalseState } from './GskMapTypes'

const minMax = (points: number[]) => {
  return points.reduce((acc:number[], val) => {
    acc[0] = acc[0] === undefined || val < acc[0] ? val : acc[0];
    acc[1] = acc[1] === undefined || val > acc[1] ? val : acc[1];
    return acc;
  }, []);
};

interface Props {
    polygon: MapPolygon,
    scaleState: ScalseState,
    imageScale: number;
    isSelected: boolean,
    mousePos: Konva.Vector2d;
    mapSettings: MapSettings;
    readOnly?: boolean;
    updateCurrentPolygon: (value: MapPolygon) => void;
    setMouseOverStartPoint: (value: boolean) => void;
    onClick: (value: MapPolygon) => void;
    onRightButtonClick: (value: MapPolygon, point: Point) => void;
    handleMouseUp: () => void;
}

const pointsToFlattenedPoints = (isFinished: boolean, points: Konva.Vector2d[], mousePos: Konva.Vector2d, imageScale: number) => {
  return points
  .concat(isFinished ? [] : mousePos)
    .map(p => [Math.round(p.x * imageScale), Math.round(p.y*imageScale)]).reduce((a:number[], b) => a.concat(b), [])
}

const BoxPolygon = ({
    polygon,
    scaleState,
    imageScale,
    isSelected,
    mousePos,
    mapSettings, 
    readOnly = true,
    updateCurrentPolygon,
    setMouseOverStartPoint,
    onClick,
    onRightButtonClick,
    handleMouseUp,
}: Props) => {
  // console.log('render BoxPolygon')
  const vertexRadius = 3;
  const [flattenedPoints, setFlattenedPoints] = useState<number[]>([]);
  const [isMouseOverText, setMouseOverText] = useState(false);
  const lineColor = useMemo(() => polygon.lineColor ?? mapSettings.lineColor ?? MapDefaults.mapLineColor
    , [mapSettings.lineColor, polygon.lineColor]);

  const fillColor = useMemo(() => polygon.fillColor ?? mapSettings.fillColor ?? MapDefaults.mapFillColor
    , [mapSettings.fillColor, polygon.fillColor]);

  const activePolygonLineColor = useMemo(() => mapSettings.activePolygonLineColor ?? MapDefaults.activePolygonLineColor
    , [mapSettings.activePolygonLineColor]);

  const activePolygonFillColor = useMemo(() => mapSettings.activePolygonFillColor ?? MapDefaults.activePolygonFillColor
    , [mapSettings.activePolygonFillColor]);

  const fontSize = useMemo(() => polygon.label?.fontSize ?? mapSettings.fontSize ?? MapDefaults.mapFontSize
    , [mapSettings.fontSize, polygon.label?.fontSize]);

  const fontColor = useMemo(() => polygon.label?.color ?? mapSettings.fontColor ?? MapDefaults.mapFontColor
    , [mapSettings.fontColor, polygon.label?.color]);



  const label = useMemo(() => {
    let X = minMax(polygon.points.map((p) => p.x)??[0,0]);
    let Y = minMax(polygon.points.map((p) => p.y)??[0,0]);
    let centerX = (X[1] - X[0]) / 2;
    let centerY = (Y[1] - Y[0]) / 2;
    return {
      text: polygon.label?.text ?? '',
      color: fontColor,
      fontSize: fontSize,
      x: X[0] + centerX + (polygon.label?.offsetX ?? 0),
      y: Y[0] + centerY + (polygon.label?.offsetY ?? 0),
      offsetX: polygon.label?.offsetX ?? 0,
      offsetY: polygon.label?.offsetY ?? 0
    }
  }, [polygon.points, polygon.label?.text, polygon.label?.offsetX, polygon.label?.offsetY, fontColor, fontSize])

  //#region Mouse over start point
  const handleMouseOverStartPoint = (e:Konva.KonvaEventObject<DragEvent>) => {
    if (readOnly || polygon.isFinished || points.length < 3) {
      return;
    }
    
    e.target.scale({ x: 3, y: 3 });
    setMouseOverStartPoint(true);
  }

  const handleMouseOutStartPoint = (e:Konva.KonvaEventObject<DragEvent>) => {
    if (readOnly) {
      return;
    }
    e.target.scale({ x: 1, y: 1 });
    setMouseOverStartPoint(false);
  }
  //#endregion


  const [points, setPoints] = useState<Point[]>(polygon.points);

  useEffect(() => {
    setPoints(polygon.points);
  }, [polygon]);
  //#region Group drag
  const handlePointDragMove = (e:Konva.KonvaEventObject<DragEvent>, index: number) => {
    if (readOnly || !polygon.isFinished) {
      return;
    }
    
    const stage = e.target.getStage()
    const pos = e.target._lastPos;
    if(pos && stage) {
      if (pos.x < 0) pos.x = 0;
      if (pos.y < 0) pos.y = 0;
      if (pos.x > stage.width()) pos.x = stage.width();
      if (pos.y > stage.height()) pos.y = stage.height();
      
      pos.x = Math.round((pos.x - scaleState.stageX) / scaleState.stageScale / imageScale);
      pos.y = Math.round((pos.y - scaleState.stageY) / scaleState.stageScale / imageScale);

      setPoints([...points.slice(0, index), pos, ...points.slice(index + 1)]);
    }
  }

  const handlePointDragEnd = (e:Konva.KonvaEventObject<DragEvent>, index: number) => {
    if (readOnly) {
      return;
    }
    updateCurrentPolygon({...polygon, points: [...points]});
  }

  const handleGroupDragEnd = useCallback((e:Konva.KonvaEventObject<DragEvent>) => {
    if (readOnly || !polygon.isFinished) {
      return;
    }
    if (e.target.name() === 'polygon') {
      let result: Konva.Vector2d[] = [];
      let copyPoints = [...points]
      copyPoints.map((point) => result.push({x: Math.round(point.x + e.target.x() / imageScale), y: Math.round(point.y + e.target.y() / imageScale)}))
      e.target.position({ x: 0, y: 0 });
      
      updateCurrentPolygon({...polygon, points: result});
      setPoints([]);
    }
  }, [imageScale, points, polygon, readOnly, updateCurrentPolygon]);
  //#endregion

  //#region textMove
  const handleTextMouseOver = useCallback((e: Konva.KonvaEventObject<MouseEvent>) => {
    if(readOnly || !isSelected || !polygon.isFinished){
      return;
    }
    setMouseOverText(true);
  }, [isSelected, polygon.isFinished, readOnly]);

  const handleTextMouseOut = useCallback((e: Konva.KonvaEventObject<MouseEvent>) => {
    setMouseOverText(false);
  }, []);

  const handeTextDragEnd = useCallback((e: Konva.KonvaEventObject<DragEvent>) => {
    if (readOnly) {
      return;
    }

    if(!polygon.label){
      polygon.label = (label as MapPolygonLabel);
    }
    const pos = e.target._lastPos;
    if (pos) {
      let X = minMax(points.map((p) => p.x * scaleState.stageScale * imageScale)??[0,0]);
      let Y = minMax(points.map((p) => p.y * scaleState.stageScale * imageScale)??[0,0]);
      let centerX = (X[1] - X[0]) / 2;
      let centerY = (Y[1] - Y[0]) / 2;
      polygon.label.offsetX = Math.round((pos.x - X[0] - centerX - scaleState.stageX) / scaleState.stageScale);
      polygon.label.offsetY = Math.round((pos.y - Y[0] - centerY- scaleState.stageY) / scaleState.stageScale);
    }
    updateCurrentPolygon({...polygon});
  }, [imageScale, label, points, polygon, readOnly, scaleState.stageScale, scaleState.stageX, scaleState.stageY, updateCurrentPolygon]);
  //#endregion

  useEffect(() => {
    setFlattenedPoints(pointsToFlattenedPoints(polygon.isFinished, points, mousePos,imageScale));
  },[polygon, mousePos, points, imageScale]);

  const handleClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
    if (e.evt.button === 2) { //rigth mouse click
      return;
    }

    onClick(polygon);
  }

  const openContextMenu = (e: Konva.KonvaEventObject<PointerEvent>) => {
    e.evt.preventDefault();
    onClick(polygon);
    onRightButtonClick(polygon, {x: e.evt.x, y: e.evt.y});
  }
  
  return (
    <Group
      name="polygon"
      draggable={!readOnly && polygon.isFinished && isSelected && !isMouseOverText}
      onDragEnd={handleGroupDragEnd}
      onContextMenu={openContextMenu}
      onMouseDown={handleClick}
      onMouseUp={handleMouseUp}
    >
      <Line
        points={flattenedPoints}
        stroke={isSelected ? activePolygonLineColor : lineColor}
        strokeWidth={3}
        closed={polygon.isFinished}
        fill={isSelected ? activePolygonFillColor : fillColor}
      />
      {polygon.isFinished && label.text.length > 0 && 
        <Text 
          draggable={!readOnly && polygon.isFinished && isSelected && isMouseOverText} 
          text={label.text}
          x={label.x * imageScale} 
          y={label.y * imageScale} 
          fill={label.color} 
          fontSize={fontSize}
          onMouseOver={handleTextMouseOver}
          onMouseOut={handleTextMouseOut}
          onDragEnd={handeTextDragEnd}
          />
      }
      {!readOnly && isSelected && points.map((point, index) => {
        const x = point.x * imageScale - vertexRadius / 2
        const y = point.y * imageScale - vertexRadius / 2
        const startPointAttr =
          index === 0 ? 
          {
            hitStrokeWidth: 12,
            onMouseOver: handleMouseOverStartPoint,
            onMouseOut: handleMouseOutStartPoint,
          }
          : null
        return (
          <Circle
            key={index}
            x={x}
            y={y}
            radius={vertexRadius}
            fill="white"
            stroke="black"
            strokeWidth={1}
            draggable
            onDragMove={e => handlePointDragMove(e, index)}
            onDragEnd={e => handlePointDragEnd(e, index)}
            {...startPointAttr}
          />
        )
      })}
    </Group>
  )
}

export default BoxPolygon