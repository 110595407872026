import { Action, AnyAction, Dispatch, combineReducers } from 'redux';
import appSlice from "./common/app-slice";
import gskSlise from "./common/gsk-slice";
import addressSlice from "./common/address-slice";
import operationsSlice from "./common/operations-slice";
import { configureStore, ThunkAction } from "@reduxjs/toolkit";
import thunk from 'redux-thunk'

const rootReducer = combineReducers({
	appSlice,
	operationsSlice,
	gskSlise,
	addressSlice,
});

export const setupStore = () => {
	return configureStore({
		reducer: rootReducer,
		middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
	});
}


export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']


export type AppThunkAction<A extends Action = AnyAction, R = (Promise<void> | void)> = ThunkAction<R, RootState, void, A>;

export declare const useDispatch: <AppDispatch extends Dispatch<AnyAction> = Dispatch<AnyAction>>() => AppDispatch;