import { useEffect, useMemo, useRef, useState } from "react";
import Card from "antd/es/card/Card";
import { Alert, Button, Flex, Radio, Space, Tooltip } from "antd";
import Meta from "antd/es/card/Meta";
import { AppstoreOutlined, HomeOutlined, PlusCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { GetGskRoleName, GskModel, GskRole } from "../types/GskTypes";
import { useDialog } from "../utils/useDialog";
import GskModal from "../components/Gsk/GskModal/GskModal";
import { NewGskTour } from "../components/Tours/NewGskTour";
import { ConisIcon } from "../components/icons";
import { UserRole } from "../types/UserTypes";
import { HomePageTour } from "../components/Tours/HomePageTour";
import { RefType, setAppRef } from "../store/enum/RefType";
import { useMetaTags } from 'react-metatags-hook';
import PromoPage from "./PromoPage";
import { useCurrentUser } from "../store/common/app-slice";
import { UserService } from "../Services/UserService";

interface Props {
	openRegisterModal: () => void;
	openLoginModal: () => void;
}
const HomePage = ({openLoginModal, openRegisterModal}: Props) => {
	const currentUser = useCurrentUser();
	const [myGsks, setMyGsks] = useState<GskModel[]>([]);
	const [helpTab, setHelpTab] = useState('');
	
	useMetaTags({
		title: currentUser ? 'МойГСК.рф' : 'Создать сайт для гаражного кооператива - Мой ГСК',
		metas: [
			{name: 'keywords', content: 'сайт для гск, сайт для гаражного кооператива, сайт гаражного кооператива, создать сайт, мой гск, мой гараж, гск, сайт гск, crm, гск система'}
		]
	}, []);

  const newGskButtonRef = useRef(null);
	const myGsksBlockRef = useRef(null);

	
	const [newGskModal, openNewGskModal] = useDialog(
		closeDialog => <GskModal closeDialog={closeDialog} setGsks={setMyGsks} currentGsk={undefined}/>
	);

	const gsks = useMemo(() => myGsks.map(g => ({
		id: g.id,
		name: g.name, 
		address: g.address.value,
		roles: currentUser?.gsks[g.id]?.roles?.filter(r => r !== GskRole.Member)?.map(r => GetGskRoleName(r))?.join(', ') ?? '',
	})), [currentUser?.gsks, myGsks]);

	useEffect(() => {
		const fetch = async () => {
			if (currentUser?.id) {
				const resp = await UserService.getUserGskList(currentUser.id);
				if (resp) {
					setMyGsks(resp);
				}
			}
		}
		fetch();
	}, [currentUser?.id]);

	useEffect(() => {
		setAppRef(RefType.MainPageGskListBlock, myGsksBlockRef);
	}, [myGsksBlockRef]);

	return (<Space direction="vertical" style={{width: '100%'}}>
		{(!currentUser || !gsks) && <>
			<PromoPage />
			<Flex justify="center" style={{marginTop: 15}}>	
				<Space>
					<Button style={{background: 'lightgreen'}} onClick={openLoginModal}>Войти</Button>
					<Button onClick={openRegisterModal}>Зарегистрироваться</Button>
				</Space>
			</Flex>
		</>}
		{currentUser && <Card
			ref={myGsksBlockRef}
			title='Мои ГСК'
			extra={<Button ref={newGskButtonRef} onClick={openNewGskModal} icon={<PlusCircleOutlined />}>Регистрация ГСК</Button>}
		>
			{(gsks?.length ?? 0) > 0 ? 
			<Space size={[20, 10]} wrap>{gsks.map(g =>
				<Link key={g.id} to={'gsk/'+g.id}>
					<Meta 
						style={{marginBottom: 15}}
						avatar={<HomeOutlined />}
						title={<>{g.name} {g.roles && <span style={{fontWeight: 100}}>({g.roles})</span>}</>}
						description={g.address}
					/>
				</Link>
				)}
			</Space>
			: 
			<Alert type="info" message={<>
				<p>Вы не состоите ни в одном ГСК.</p>
				<p>Попросите председателя своего ГСК отправить Вам приглашение или зарегистрируйте ГСК, если Вы председатель</p>
				</>} 
			/>}
		</Card>}
		{currentUser && currentUser?.role !== UserRole.None && <Card>
			<h2>Что дальше?</h2>
			<Flex justify="center">
				<Space>
					<Radio.Group value={helpTab} onChange={e => setHelpTab(e.target.value)} style={{ marginBottom: 16 }}>
						<HomePageTour />
						<Radio.Button value="chairman">Я председатель</Radio.Button>
						<Radio.Button value="member">Я член ГСК</Radio.Button>
					</Radio.Group>
				</Space>
			</Flex>
			
			{helpTab === 'chairman' && <div>
				<ol>
					<li>Зарегистрируйте ГСК <NewGskTour newGskButtonRef={newGskButtonRef}/></li>
					<li>Заполните список боксов&nbsp;
						<Tooltip title={<>Вы найдете инструкции <QuestionCircleOutlined/> на вклдаке <b><AppstoreOutlined /> Боксы</b></>}>
							<QuestionCircleOutlined/>
						</Tooltip>
					</li>
					<li>Пригласите членов ГСК&nbsp;
						<Tooltip title={<>Вы найдете инструкции <QuestionCircleOutlined/> на вклдаке <b><AppstoreOutlined /> Боксы</b></>}>
							<QuestionCircleOutlined/>
						</Tooltip>
					</li>
					<li>Заполните начисления&nbsp;
						<Tooltip title={<>Вы найдете инструкции <QuestionCircleOutlined/> на вклдаке <b><ConisIcon /> Начисления</b></>}>
							<QuestionCircleOutlined/>
						</Tooltip>
					</li>
				</ol>
			</div>}
			{helpTab === 'member' && <div>
				<ol>
					<li>Ваш ГСК должен быть заргистрирован на сайте МойГСК.рф&nbsp;
						<Tooltip title={<>Обычно это делает председатель. Но можете сделать и Вы, а потом передать права управления председателю</>}>
							<QuestionCircleOutlined/>
						</Tooltip>
					</li>
					<li>Попростие председателя отправить Вам ссылку-приглашение&nbsp;
						<Tooltip title={<>На почту, по WhatsApp, Telegram или другим способом</>}>
							<QuestionCircleOutlined/>
						</Tooltip>
					</li>
			  	<li>Присоеденитесь к ГСК перейдя по полученной ссылке&nbsp;
						<Tooltip title={<>Вам станет доступен ГСК и электронная членская книжка</>}>
							<QuestionCircleOutlined/>
						</Tooltip>
					</li>
				</ol>
			</div>}
		</Card>}
		{newGskModal}
	</Space>)
}

export default HomePage;