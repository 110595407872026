import { Input } from "antd";
import { InputProps } from "antd/lib";
import { useEffect, useState } from "react";
import './FloatInput.scss'

interface FloatInputProps {
  value?: number;
  onChange?: (value: number | undefined) => void;
}
type Props = FloatInputProps & Partial<InputProps>;
export default function FloatInput(props: Props ) {
  const [value, setValue] = useState(props.value?.toString());

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value?.replace(',','.');
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      if (inputValue === '-0' && value !== '-0.')
      {
        inputValue = '-0.';
      }
      const floatVal = parseFloat(inputValue);
      if (props.onChange) {
        props.onChange(inputValue && !isNaN(floatVal) ? parseFloat(inputValue) : undefined);
      }
      setValue(inputValue);
    }
  };

  useEffect(() => {
    if (value !== '-' && value !== '-0' && !value?.endsWith('.')) {
      setValue(props.value?.toString());
    }
  }, [props.value, value])

  const handleBlur = () => {
    const propsValue = props.value?.toString();
    let valueTemp = props.value?.toString();
    
    if (propsValue?.charAt(propsValue.length - 1) === '.') {
      valueTemp = propsValue?.slice(0, -1);
    }
    
    if(props.onChange && valueTemp) {
      props.onChange(parseFloat(valueTemp.replace(/0*(\d+)/, '$1')));
    }
  };

  return (<Input
    {...props}
    className="hide-input-arrows"
    value={value}
    type="number"
    onChange={handleChange}
    onBlur={handleBlur}
  />)
}