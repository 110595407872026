import { Alert, Button } from "antd";
import { PaymentState } from "../../types/BillingTypes";
import dayjs from 'dayjs'
import { GskModel, GskRole } from "../../types/GskTypes";
import { useAppSelector } from "../../store/redux";
import { OpenGskModalProps } from "../Gsk/GskModal/GskModal";
import { Declension } from "../../utils/DeclensionGenerator";
import { currentUserHasGskRoleSelector } from "../../store/common/app-slice";

interface Props {
  gsk: GskModel;
	openGskModal: (p: OpenGskModalProps) => void;
}

export default function SubscriptionWarning({gsk, openGskModal}: Props) {
  const isChairman = useAppSelector(currentUserHasGskRoleSelector(GskRole.Chairman));
  const isAdmin = useAppSelector(currentUserHasGskRoleSelector(GskRole.Admin));
  const isAccountant = useAppSelector(currentUserHasGskRoleSelector(GskRole.Accountant));
  const showWarning = isChairman || isAdmin || isAccountant;
  const subscription = gsk.currentSubscription;

  if (!showWarning) {
    return <></>;
  }
  const style = {marginBottom: 10};
  if (!subscription) {
    return <Alert type="warning" style={style} message={<>Ваша подписка закончилась. Внесение новых данных заблокировано.&nbsp;
      <Button onClick={() => openGskModal({currentGsk: gsk, initTab: 'subscriptions'})}>Оформить подписку</Button></>}
    />;
  }

  if (subscription.paymentState !== PaymentState.Succeeded) {
    return <Alert type="warning" style={style} message={<>Подписка не оплачена. Внесение новых данных заблокировано.&nbsp;
      <Button onClick={() => openGskModal({currentGsk: gsk, initTab: 'subscriptions'})}>Оплатить</Button></>}
    />;
  }

  if (subscription.isActive) {
    if (subscription.endDate)
    {
      const daysToEnd = dayjs(subscription.endDate).diff(dayjs(new Date()), "day");
      if (daysToEnd > 0 && daysToEnd < 30 && !subscription.isExtended) {
        return <Alert type="warning" style={style} message={<>
          До окончания подписки осталось {Declension(daysToEnd, 'день')}. <Button onClick={() => openGskModal({currentGsk: gsk, initTab: 'subscriptions'})}>Продлить</Button>
        </>} />
      }
    }
  }

  if (subscription.paymentState && [PaymentState.Pending, PaymentState.WaitingForCapture].includes(subscription.paymentState)) {
    return <Alert type="warning" style={style} message={<>Подписка не оплачена. <Button>Оплатить</Button></>} />
  }

  return (<></>)
}