import { useEffect, useState } from "react";
import { Alert, Button, Card, Flex, Form, Input } from "antd";
import { useSearchParams } from "react-router-dom";
import { UserService } from "../../Services/UserService";
import useLoading from "../../utils/hooks/useLoading";
import ProfileTab from "../../components/User/ProfileTab";
import { NewVkUserModel } from "../../types/UserTypes";
import { ShowSuccess } from "../../components/Notifications/Notifications";


export function VkLoginPage() {
	const [searchParams] = useSearchParams(window.location.hash);
	const [isLoading, load] = useLoading();
	const [error, setError] = useState('');
	const [continueRegister, setContinueRegister] = useState(false);

  const [form] = Form.useForm<{profile: NewVkUserModel}>();

	useEffect(() => {
		if (searchParams.size === 0) {
			return;
		}

		if (Array.from(searchParams.keys()).includes('#error')) {
			setError(searchParams.get('#error') ?? '');
			return;
		}

		const fetch = async () => {
			const code = searchParams.get('code');
			const deviceId = searchParams.get('device_id');
			const state = searchParams.get('state');
			if (code && deviceId && state) {
				const resp = await load(UserService.loginVk(code, deviceId, state));
				if (!resp) {
					window.location.href = '/';
				} else {
					form.setFieldValue(['profile', 'state'], state);
					form.setFieldValue(['profile', 'login'], resp.userId);
					form.setFieldValue(['profile', 'password'], null);
					form.setFieldValue(['profile', 'alias'], 'vk_' + resp.userId);
					form.setFieldValue(['profile', 'email'], resp.email);
					form.setFieldValue(['profile', 'surname'], resp.surname);
					form.setFieldValue(['profile', 'name'], resp.name);
					form.setFieldValue(['profile', 'phone'], resp.phone);
					setContinueRegister(true);
				}
			}
		}

		fetch();
	}, [form, load, searchParams]);

	const handleSave = async (data: {profile: NewVkUserModel}) => {
		const resp = await load(UserService.registerVkUser(data.profile));
		if (resp) {
			ShowSuccess("Регистрация успешна", '');
			window.location.href = '/';
		}
  }

	return (<>
		{isLoading && !error && 'Получение инфорации о пользователе...'}
		{error && <Alert type="error" description={error} message='Ошибка авторизации'/>}
		{continueRegister &&
			<Card title='Регистрация через VK'>
				<Form 
					labelCol={{span: 6}}
					form={form}
					onFinish={handleSave}
				>
					<Form.Item noStyle name={['profile', 'state']}>
						<Input type="hidden" />
					</Form.Item>
					<ProfileTab isNewUser={true} passwordRequired={false} />
					<Flex justify="flex-end">
						<Button type='primary' onClick={form.submit}>Зрегистрироваться</Button>
					</Flex>
				</Form>
			</Card>
		}
	</>)
}
