import { useParams } from "react-router-dom";
import { PostService } from "../Services/PostsService";
import { PostModel } from "../types/PostTypes";
import { Spin } from "antd";
import { PostBlock } from "../components/Post/PostBlock";
import { useDialogWithParameter } from "../utils/useDialog";
import EditPostModal from "../components/Post/EditPostModal";
import { useState } from "react";
import { useEffectOnce } from "../utils/hooks/useEffectOnce";
import useLoading from "../utils/hooks/useLoading";

export default function PostPage () {
  const params = useParams();
	const postId: number = parseInt(params.id ?? "-1");
	const [isLoading, load] = useLoading();
  const [post, setPost] = useState<PostModel | undefined>(undefined);

	const [editPostModal, openEditPostModal] = useDialogWithParameter<PostModel | null>(
		(post, closeDialog) => <EditPostModal closeDialog={closeDialog} post={post} setPost={setPost}/>
	);
  const getPost = async () => {
    if (postId > 0) {
      setPost(await load(PostService.getPost(postId)));
    }
  }
  useEffectOnce(() => {
    getPost();
  });
  
  return (
    <Spin spinning={isLoading}>
      {post && <PostBlock openEditModal={openEditPostModal} post={post} setPost={setPost}/>}
      {editPostModal}
    </Spin>
  )
}