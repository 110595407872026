import { UserModel } from "../types/UserTypes";
import { Card, Flex } from "antd";
import Link from "antd/lib/typography/Link";

interface UnauhtorizedPageProps {
  openLoginModal: (message?:string, redirectUrl?: string) => void;
  openProfileModal: (user?: UserModel) => void;
}

export default function UnauhtorizedPage({openLoginModal, openProfileModal}: UnauhtorizedPageProps) {
	const redirectUrl = window.location.pathname.replace('/Unauhtorized', '');
	return (<Flex justify="center" style={{marginTop:15}}>
		<Card>
			Пожалуйсте, <Link onClick={() => openLoginModal(undefined, redirectUrl)}>войдите</Link> под 
			своей учетной записью или <Link onClick={() => openProfileModal(undefined)}>зарегистрируйтесь</Link>
		</Card>
	</Flex>)
}

