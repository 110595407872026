import { EditGskPostCommentModel, EditGskPostModel, GskPostCommentModel, GskPostModel, NewGskPostCommentModel, NewGskPostModel, GskVoteModel } from "../types/GskPostTypes";
import { PostCoverModel } from "../types/PostTypes";
import { PagedResult } from "../types/dto";
import { Fetch, FetchNoResp } from "../utils/fetch";

export const GskPostService = {
	getPost: async (gskId: number, postId: number): Promise<GskPostModel | undefined> => {
		return await Fetch<GskPostModel>(`/api/gsks/${gskId}/posts/${postId}`, "GET");
	},
	getPostCovers: async(gskId: number, page: number, pageSize: number): Promise<PagedResult<PostCoverModel> | undefined> => {
		return await Fetch<PagedResult<PostCoverModel>>(`/api/gsks/${gskId}/posts`, "GET", { page, pageSize });
	},
	addPost: async (gskId: number, data: NewGskPostModel): Promise<GskPostModel | undefined> => {
		return await Fetch<GskPostModel>(`/api/gsks/${gskId}/posts/`, "PUT", data);
	},
	editPost: async (gskId: number, postId: number, data: EditGskPostModel): Promise<GskPostModel | undefined> => {
		return await Fetch<GskPostModel>(`/api/gsks/${gskId}/posts/${postId}`, "PATCH", data);
	},
	vote: async(gskId: number, data: GskVoteModel): Promise<GskPostModel | undefined> => {
		return await Fetch<GskPostModel>(`/api/gsks/${gskId}/posts/vote`, "POST", data);
	},
	likePost: async (gskId: number, postId: number, isLike: boolean): Promise<boolean> => {
		return await FetchNoResp(`/api/gsks/${gskId}/posts/${postId}/like`, "POST", { isLike });
	},
	deletePost: async (gskId: number, postId: number): Promise<boolean> => {
		return await FetchNoResp(`/api/gsks/${gskId}/posts/${postId}`, "DELETE");
	},
	addComment: async (gskId: number, postId: number, data: NewGskPostCommentModel): Promise<GskPostCommentModel | undefined> => {
		return await Fetch<GskPostCommentModel>(`/api/gsks/${gskId}/posts/${postId}/comments`, "PUT", data);
	},
	editComment: async (gskId: number, commentId: number, data: EditGskPostCommentModel): Promise<GskPostCommentModel | undefined> => {
		return await Fetch<GskPostCommentModel>(`/api/gsks/${gskId}/posts/comments/${commentId}`, "PATCH", data);
	},
	likeComment: async (gskId: number, commentId: number, isLike: boolean): Promise<boolean> => {
		return await FetchNoResp(`/api/gsks/${gskId}/posts/comments/${commentId}/like`, "POST", { isLike });
	},
	deleteComment: async (gskId: number, commentId: number): Promise<GskPostCommentModel | null | undefined> => {
		return await Fetch<GskPostCommentModel | null>(`/api/gsks/${gskId}/posts/comments/${commentId}`, "DELETE");
	},
}