import ModalBase from '../ModalBase/ModalBase';
import { UserService } from '../../Services/UserService';
import { RestorePasswordRequest } from '../../types/UserTypes';
import { Form, Input, Radio } from "antd";
import PhoneInput from './PhoneInput';
import { ShowError, ShowSuccess } from '../Notifications/Notifications';
import { useState } from 'react';
import { VerifyState } from '../../types/CaptchaTypes';
import useCapthcaFormItem from './useCapthcaFormItem';

interface FromData extends RestorePasswordRequest {
  type: string;
}

interface OwnProps {
  closeDialog: CallableFunction;
}

export default function RestorePasswordModal ({ closeDialog }: OwnProps) {
  const [isLoading, setIsLoading] = useState(false);
  
  const [form] = Form.useForm<FromData>();
  const [onCaptchaError, captcha] = useCapthcaFormItem(form);
  const restoreType = Form.useWatch('type', form);

  const handleSave = async (data: FromData) => {
    switch(restoreType) {
      case 'login':
        if (!data.login) {
          ShowError("Введите логин");
          return;
        }
        break;
      case 'email':
        if (!data.email) {
          ShowError("Введите e-mail");
          return;
        }
        break;
      case 'phone':
        if (!data.phone) {
          ShowError("Введите телефон");
          return;
        }
        break;
      default:
        return;
    }

    setIsLoading(true);
    const resp = await UserService.restorePasswordRequest(data);
    if (resp?.verifyState === VerifyState.Ok) {
      ShowSuccess("Успешно", "На почту, указанную при регистрации оптравлено присьмо с инструкциями по восстановлению пароля");
      closeDialog();
    } else {
      await onCaptchaError(resp?.verifyState ?? VerifyState.None);
    }
    setIsLoading(false);
  }

  return (
    <ModalBase closeDialog={closeDialog} title="Восстановление пароля" okButtonHandler={() => form.submit()} isLoading={isLoading} width={450}>
      <Form
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        layout={'horizontal'}
        form={form}
        initialValues={{type: 'login'}}
        onFinish={handleSave}
      >
        <Form.Item label='Я помню' name='type'>
          <Radio.Group defaultValue='login'>
            <Radio value='login'>Логин</Radio>
            <Radio value='email'>E-mail</Radio>
            <Radio value='phone'>Телефон</Radio>
          </Radio.Group>
        </Form.Item>
        {restoreType === 'login' &&
          <Form.Item label='Логин' name='login' rules={[{required: true, message: 'Введите логин'}]}>
            <Input/>
          </Form.Item>
        }
        {restoreType === 'email' &&
          <Form.Item 
            label='Email'
            name='email'
            rules={[{required: true, type: "email", message: "Введите корректный e-mail"}]}
          >
            <Input placeholder="ivan@mail.ru"/>
          </Form.Item>
        }
        {restoreType === 'phone' &&
          <PhoneInput />
        }
        {captcha}
      </Form>
    </ModalBase>
  );
}
