import { OpenGskModalProps } from "../../components/Gsk/GskModal/GskModal";
import { useDialog, useDialogWithParameter } from "../../utils/useDialog";
import { Alert, Avatar, Button, Card, List, Space, Spin } from "antd";
import useCurrentGsk from "./useCurrentGsk";
import { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../store/redux";
import { GskService } from "../../Services/GskService";
import { Gsk, GskBoxWithUser, GskRole, MainPageData } from "../../types/GskTypes";
import Meta from "antd/es/card/Meta";
import { EditOutlined, MailOutlined, PhoneOutlined, PlusCircleOutlined, SyncOutlined } from "@ant-design/icons";
import IconButton from "../../components/IconButton/IconButton";
import EditContactsModal from "../../components/Gsk/Post/EditContactModal";
import { toMoneyString } from "../../utils/moneyHelper";
import { UserModel } from "../../types/UserTypes";
import { GskPostModel } from "../../types/GskPostTypes";
import EditPostModal from "../../components/Gsk/Post/EditPostModal";
import { GskPostService } from "../../Services/GskPostsService";
import RowTwoCol from "../../components/RowTwoCol/RowTwoCol";
import { PostCoverBlock } from "../../components/Post/Base/PostCoverBlock";
import { useEffectOnce } from "../../utils/hooks/useEffectOnce";
import { PostCoverModel } from "../../types/PostTypes";
import { EmptyPagedResult, PagedResult } from "../../types/dto";
import { Link } from "react-router-dom";
import { BoxesService } from "../../Services/BoxesService";
import useLoading from "../../utils/hooks/useLoading";
import { BoxIcon } from "../../components/icons";
import { currentUserHasGskRoleSelector, useCurrentUser } from "../../store/common/app-slice";
import { useMetaTags } from "react-metatags-hook";
import { GskPageTour } from "../../components/Tours/Gsk/GskPageTour";
import { RefType, setAppRef } from "../../store/enum/RefType";

interface Props{
	openGskModal: (p: OpenGskModalProps) => void;
}
export default function GskPage({openGskModal}: Props) {
	const gsk = useCurrentGsk();

	const currentUser = useCurrentUser();
	return (<Spin spinning={!gsk}>
		{gsk && <Page gsk={gsk} currentUser={currentUser} openGskModal={openGskModal}/>}
	</Spin>)
}

interface PageProps {
	gsk: Gsk;
	openGskModal: (p: OpenGskModalProps) => void;
	currentUser?: UserModel;
}
const PAGE_SIZE = 5;
const Page = ({ gsk, currentUser, openGskModal}: PageProps) => {
	const [isLoading, load] = useLoading();
	const [myBoxes, setMyBoxes] = useState<GskBoxWithUser[]>([]);
	const myBoxesLateSumm = myBoxes.reduce((acc, curr) => acc + (curr.latePaymentSum ?? 0), 0);

	const myBoxesRef = useRef(null);
	const newsRef = useRef(null);
	const contactsRef = useRef(null);

	useEffect(() => { setAppRef(RefType.GskPageMyBoxes, myBoxesRef); }, [myBoxesRef]);
	useEffect(() => { setAppRef(RefType.GskPageNews, newsRef); }, [newsRef]);
	useEffect(() => { setAppRef(RefType.GskPageContacts, contactsRef); }, [contactsRef]);

  const [postCoversPage, setPostCoversPage] = useState<PagedResult<PostCoverModel>>(EmptyPagedResult<PostCoverModel>(PAGE_SIZE));
	const [mainPageData, setMainPageData] = useState<MainPageData | undefined>();
	const isChairman = useAppSelector(currentUserHasGskRoleSelector(GskRole.Chairman));
	const isMember = useAppSelector(currentUserHasGskRoleSelector(GskRole.Member));
	
	useMetaTags({
		title: gsk.name + ' - Мой ГСК',
		metas: [
			{name: 'keywords', content: gsk.name+','+gsk.address.city+',сайт гск, сайт гаражного кооператива'}
		]
	}, []);

	const [editGskContactsModal, openEditGskContactsModal] = useDialog(
		closeDialog => <EditContactsModal mainPageData={mainPageData} onSaved={setMainPageData} closeDialog={closeDialog} gsk={gsk}/>
	);

	const [editGskPostModal, openEditGskPostModal] = useDialogWithParameter<GskPostModel | null>(
		(post, closeDialog) => <EditPostModal closeDialog={closeDialog} gsk={gsk} post={post} setPostCoversPage={setPostCoversPage}/>
	);

	const refreshData = async () => {
		setMainPageData(await load(GskService.loadMainPageData(gsk.id)));
		if (isMember) {
			setMyBoxes(await load(BoxesService.getAllBoxes(gsk.id, {userId: currentUser!.id})) ?? []);
		}
	};


	const handlePostPageChange = async (page: number, pageSize: number) => {
		const resp = await load(GskPostService.getPostCovers(gsk.id, page, pageSize));
		if (resp) {
			setPostCoversPage(resp);
		}
	}
	
	useEffectOnce(() => {
		refreshData();
		handlePostPageChange(postCoversPage.page, postCoversPage.pageSize);
	});

	return (
		<Space direction="vertical" style={{width: '100%'}}>
			{isMember && <Card 
				ref={myBoxesRef}
				title={<>Мои боксы {myBoxesLateSumm > 0 && <Link style={{color: 'unset'}} to='Cash/'> -&nbsp;
						<Alert type="error" style={{borderRadius: 3, padding: 3, display: 'inline-block'}} message={<span style={{fontWeight: 'normal'}}>долг {toMoneyString(myBoxesLateSumm)}</span>} />
					</Link>}
				</>}
				extra={<Space>
					<IconButton 
						icon={<SyncOutlined />}
						onClick={refreshData}
						title="Обновить" 
						/>
						<GskPageTour/>
					</Space>}
				loading={isLoading}
			>
				<Avatar src={<BoxIcon width='2em' height='2em'/>} size={32} />
				<Space size={[20, 10]} wrap>{myBoxes.map(b =>
					<Meta 
						key={b.id}
						style={{marginBottom: 15, minHeight: 64}}
						avatar={<BoxIcon style={{fontSize: '30px'}}/>}
						title={<Link style={{color: 'unset'}} to={'Cash/' + b.id}>{b.latePaymentSum != null && b.latePaymentSum > 0 
							? <Alert type="error" style={{borderRadius: 3, padding: 3}} message={<>№{b.num}<span style={{fontWeight: 'normal'}}> - долг {toMoneyString(b.latePaymentSum)}</span></>} />
							: <Alert type="success" style={{borderRadius: 3, padding: 3}} message={<>№{b.num}<span style={{fontWeight: 'normal'}}> - нет долгов</span></>} />
							}</Link>
						}
						description={<>
							{b.areaM2 != null && b.areaM2 > 0 && <>Площадь: {b.areaM2}м<sup>2</sup></>}
						</>}
					/>
				)}
				</Space>
			</Card>}

			<div ref={newsRef}>
				<List
					loading={isLoading}
					header={
						<RowTwoCol
							left={<div style={{fontSize: '1.7em', fontWeight: 'bold'}}>Новости ГСК</div>}
							right={<Button 
								icon={<PlusCircleOutlined />} 
								title={isChairman ? '' : 'Доступно пользователям с ролью "Председатель" или "Администратор"'}
								onClick={() => openEditGskPostModal(null)}
								disabled={!isChairman}
							>
								Добавить
							</Button>}
						/>}
					dataSource={postCoversPage.items}
					pagination={{
						size: 'small',
						current: postCoversPage.page,
						onChange: handlePostPageChange,
						total: postCoversPage.totalCount,
						hideOnSinglePage: true,
						pageSize: PAGE_SIZE,
						style: {paddingBottom: 20}
					}}
					split={false}
					rowKey={p => p.id}
					renderItem={post => <List.Item><PostCoverBlock cover={post} urlPrefix="post/" /></List.Item>}
				/>
			</div>
			<Card 
				ref={contactsRef}
				title={<Space>
					<>Контакты</>
					{isChairman && <IconButton icon={<EditOutlined />} onClick={() => openEditGskContactsModal()} />}
				</Space>}
			>
				<List
					grid={{
						gutter: 16,
						xs: 1,
						sm: 2,
						md: 4,
						lg: 4,
						xl: 6,
						xxl: 3,
					}}
					dataSource={mainPageData?.contacts.list}
					renderItem={c => <List.Item>
						<Meta
							key={c.order}
							style={{marginBottom: 15}}
							title ={<>{c.post} {c.name}</>}
							description={<Space>
								{c.phone.length > 0 && <a href={'tel:'+c.phone}><PhoneOutlined /> {c.phone}</a>}
								{c.email.length > 0 && <a href={"mailto:"+c.email}><MailOutlined /> {c.email}</a>}
							</Space>}
						/>
					</List.Item>}
				/>
				<Meta
					title={<Space>
						<>{gsk.name}</>
						{isChairman && <IconButton icon={<EditOutlined />} onClick={() => openGskModal({currentGsk: gsk})} />}
					</Space>}
					description={gsk.address.value}
					style={{marginBottom: 15}}
					/>
			</Card>
			{editGskContactsModal}
			{editGskPostModal}
		</Space>
	)
}
