import { Card } from "antd";

export default function AboutPage()  {
	return (<Card >
		<p>Привет, я Дима, мне {new Date().getFullYear() - 1989} и я написал этот сайт</p>
		<p></p>
		<p>Однажды я увидел как казначей ГСК, в котором я состою, ведет учет взносов и электроэнергии. 
			В тетрадке в клеточку. Это меня сильно удивило.
			Я бы мог понять если бы это был Excel или какие-нибудь специализированные приложения, но оказалось таких предложений нет.
		</p>
		<p>В тот момент я подумал: было-бы неплохо написать сайт для своего ГСК. 
			Дальше появилась идея добавить возможность другим людям регистрировать свои ГСК и аналогично вести учет,
			добавлять новости и голосования.
		</p>
		<p>
			Идеи о развитии возникают гораздо быстрее, чем я успеваю их релизовывать. Поэтому на данный момент работает 
			только основной функционал.
			Сейчас вы можете бесплатно (в течении пробного периода 6 месяцев) регистрировать свои ГСК на сайте, пользоваться всеми функциями.
		</p>
		<p>
			Идеи по развитию, предложения о новом функционале и любые другие вопросы Вы можете отправить на почтовый 
			ящик <a href="mailto:moygarajrf@yandex.ru">moygarajrf@yandex.ru</a>
		</p>
		
	</Card>)
}