import { Flex, Space } from "antd";
import IconButton from "../IconButton/IconButton";
import { VkIcon, YandexIcon } from "../icons";
import { UserService } from "../../Services/UserService";

interface Props {
  load: <T>(input: Promise<T> | (() => Promise<T>)) => Promise<T | undefined>;
}
export default function LoginWithBlock({load} : Props) {
  const handleYandexLoginClick = async() => {
    const clientId = await load(UserService.getYandexClientId());
    if (clientId) {
      window.location.href = 'https://oauth.yandex.ru/authorize?response_type=token&client_id=' + clientId +
        '&redirect_uri=' + encodeURIComponent(window.location.origin + '/yandex_verification_code');
    }
  }

  const handleVkLoginClick = async() => {
    const vkAuthData = await load(UserService.getVkAuthData());
    if (vkAuthData) {
      window.location.href = 'https://id.vk.com/authorize?response_type=code&client_id=' + vkAuthData.clientId +
        '&state=' + vkAuthData.state +
        '&code_challenge=' + vkAuthData.codeChallenge +
        '&code_challenge_method=s256' +
        '&scope=email%20phone%20vkid.personal_info' +
        '&redirect_uri=' + encodeURIComponent(window.location.origin + '/vk_verification_code');
    }
  }
  return (
    <>
    <Flex justify='center'>
      <b>Войти с помощью</b>
    </Flex>
    <Flex justify='center'>
      <Space>
        <IconButton icon={<YandexIcon />} onClick={handleYandexLoginClick}/>
        <IconButton icon={<VkIcon />} onClick={handleVkLoginClick}/>
      </Space>
    </Flex>
    </>
  )
}