import ModalBase from '../ModalBase/ModalBase';
import { UserService } from '../../Services/UserService';
import { useNavigate } from 'react-router-dom';
import { LoginUserModel } from '../../types/UserTypes';
import { Alert, Flex, Form, Input } from "antd";
import Link from 'antd/es/typography/Link';
import { VerifyState } from '../../types/CaptchaTypes';
import useCapthcaFormItem from './useCapthcaFormItem';
import useLoading from '../../utils/hooks/useLoading';
import LoginWithBlock from './LoginWithBlock';


interface OwnProps {
  closeDialog: CallableFunction;
  openRestorePasswordModal: () => void;
  redirectUrl?: string;
  message?: string;
}

const LoginModal = ({ closeDialog, openRestorePasswordModal, redirectUrl = undefined, message = undefined }: OwnProps) => {
  const navigate = useNavigate();
  const [isLoading, load] = useLoading();
  
  const [form] = Form.useForm<LoginUserModel>();
  const [onCaptchaError, captcha] = useCapthcaFormItem(form);
  const handleSave = async (data: LoginUserModel) => {
    const resp = await load(UserService.login(data));
    if (resp === VerifyState.Ok) {
      closeDialog();
      navigate(redirectUrl ?? '/');
    } else {
      await onCaptchaError(resp ?? VerifyState.None);
    }
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      form.submit();
    }
  }

  return (
    <ModalBase closeDialog={closeDialog} title="Вход" okButtonText='Войти' okButtonHandler={() => form.submit()} isLoading={isLoading} width={350}>
      <Form
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        layout={'horizontal'}
        form={form}
        initialValues={{login: '', password: ''}}
        onFinish={handleSave}
        onKeyUp={handleKeyUp}
      >
        {message && <Alert type='success' message={message} style={{marginBottom: 15}}/>}
        <Form.Item label='Логин' name='login' rules={[{required: true, message: 'Введите логин'}]}>
          <Input autoComplete='login'/>
        </Form.Item>
        <Form.Item 
          label='Пароль' 
          name='password'
          rules={[{required: true, message: 'Введите пароль'}]}
        >
          <Input.Password placeholder="******" autoComplete='password'/>
        </Form.Item>
        {captcha}
      </Form>
      <LoginWithBlock load={load} />
      <br/>
      <Flex justify='center'><Link onClick={openRestorePasswordModal}>Забыли пароль?</Link></Flex>
    </ModalBase>
  );
}

export default LoginModal;