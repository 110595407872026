import { useState } from "react";
import { Tour, TourProps } from "antd";
import IconButton from "../../IconButton/IconButton";
import { EditOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { RefType, getAppRef } from "../../../store/enum/RefType";

export function BoxesTour() {
  const [tourOpen, setTourOpen] = useState(false);
  const addBoxButtonRef = getAppRef(RefType.GskBoxesAddButton);
  const boxesTable = getAppRef(RefType.GskBoxesTable);
  const boxesFilter = getAppRef(RefType.GskBoxesFilter);
	const steps: TourProps['steps'] = [
    {
      title: 'Добавить бокс',
      description: <>Чтобы добавить бокс (или несколько боксов сразу) нажмите эту кнопку</>,
      target: () => addBoxButtonRef.current,
    },
    {
      title: 'Боксы ГСК',
      description: <>Таблица со всеми боксами и их владельцами. Сначала добавьте боксы. 
        После, в окне редактирования бокса <EditOutlined /> можно пригласить владельца, заполнить информацию о счетчиках и назначить Роли.
      </>,
      target: () => boxesTable.current,
    },
    {
      title: 'Фильтры',
      description: <>Используйте фильтры для поиска боксов</>,
      target: () => boxesFilter.current,
    },
  ];
  return (<>
    <IconButton icon={<QuestionCircleOutlined />} onClick={() => setTourOpen(true)} />
    <Tour open={tourOpen} onClose={() => setTourOpen(false)} steps={steps} />
  </>)
}