import ModalBase from "../ModalBase/ModalBase"
import { GetHistoryEntityName, GskHistoryEntity, GskHistoryModel, getOperationName } from "../../types/GskHistoryTypes";
import { GskHistoryService } from "../../Services/GskHistoryService";
import { useAppDispatch } from "../../store/redux";
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Grid, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from 'dayjs';
import { EmptyPagedResult, PagedResult } from "../../types/dto";
import RowTwoCol from "../RowTwoCol/RowTwoCol";
import IconButton from "../IconButton/IconButton";
import { SyncOutlined } from "@ant-design/icons";
import useLoading from "../../utils/hooks/useLoading";

export interface GskHistoryModalProps {
  entityId: number;
  gskId: number;
  entity: GskHistoryEntity;
  includeChilds?: boolean;
}
interface Props extends GskHistoryModalProps {
  closeDialog: CallableFunction;
}
const PAGE_SIZE = 10;
export default function GskHistoryModal({
  entityId,
  gskId,
  entity,
  includeChilds = false,
  closeDialog
}: Props) {
  const dispatch = useAppDispatch();
  const {sm, md, lg} = Grid.useBreakpoint();
  const [isLoading, load] = useLoading();
  const [historyPage, setHistoryPage] = useState<PagedResult<GskHistoryModel>>(EmptyPagedResult<GskHistoryModel>(PAGE_SIZE));

  const loadHistory = useCallback(async (page: number) => {
    const resp = await load(GskHistoryService.getPage(gskId, {entityType: entity, entityId, includeChilds, pageSize: PAGE_SIZE, page})); 
    if (resp) {
      setHistoryPage(resp);
    }
  }, [entity, entityId, gskId, includeChilds, load])

  const columns: ColumnsType<GskHistoryModel> = useMemo(() => [
    {
      title: 'Операция',
      key: 'operation',
      dataIndex: 'operation',
      render: (_, row) => <>{getOperationName(row.entity, row.operation, includeChilds)}</>
    },
    {
      title: 'Подробно',
      key: 'changes',
      dataIndex: 'changes',
      render: (v: string) => <div style={{width: lg ? 680 : md ? 300 : sm ? 200 : 100, fontSize: lg ? '1em' : md ? '.9em' : sm ? '.8em' : '.7em',  overflowX: 'auto', whiteSpace: 'pre'}}>{v}</div>,
    },
    {
      title: 'Пользователь',
      key: 'createdBy',
      dataIndex: 'createdBy',
    },
    {
      title: <RowTwoCol
        left={'Дата'}
        right={
          <IconButton icon={<SyncOutlined/>} onClick={() => loadHistory(historyPage.page)} title="Обновить" />
        }
        rightSize={40}
      />
        ,
      key: 'created',
      dataIndex: 'created',
      responsive: ['sm'],
      render: v => dayjs(v).format("DD.MM.YYYY hh:mm:ss") 
    },
  ]
  , [historyPage.page, includeChilds, lg, loadHistory, md, sm])

  useEffect(() => {
    loadHistory(historyPage.page);
  }, [dispatch, entity, entityId, historyPage.page, includeChilds, loadHistory])
  
  return (<ModalBase
    closeDialog={closeDialog}
    title={GetHistoryEntityName(entity) + " id = "+ entityId + " История изменений"}
    width={1200}
  >
    <Table
      loading={isLoading}
      size="small"
      columns={columns}
      dataSource={historyPage.items}
      rowKey='id'
      pagination={{
        current: historyPage.page,
        pageSize: PAGE_SIZE,
        total: historyPage.totalCount,
        onChange: (page) => loadHistory(page)
      }}
    />
  </ModalBase>)
}