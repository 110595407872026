import { Button, Modal, Space, Table, Tooltip } from "antd";
import { ElectricMeterModel, ElectricMeterReadingsModel } from "../../../types/MeterTypes";
import ModalBase from "../../ModalBase/ModalBase";
import { useCallback, useMemo, useState } from "react";
import { ColumnsType } from "antd/es/table";
import dayjs from 'dayjs';
import NewElectricMeterReadingModal from "./NewElectricMeterReadingModal";
import { useDialogWithParameter } from "../../../utils/useDialog";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import IconButton from "../../IconButton/IconButton";
import { ElectricMetersService } from "../../../Services/ElectricMetersService";
import { toFloatString } from "../../../utils/numberHelper";
import useLoading from "../../../utils/hooks/useLoading";
import { EmptyPagedResult, PagedResult } from "../../../types/dto";
import { useEffectOnce } from "../../../utils/hooks/useEffectOnce";
import { toMoneyString } from "../../../utils/moneyHelper";
import { getPaymentClassNameByValue } from "../../../types/GskChargeTypes";
import { GskBox } from "../../../types/GskTypes";

interface Props {
  box: GskBox;
  meter: ElectricMeterModel;
  refreshMeter: () => void;
  closeDialog: CallableFunction;
}
const PAGE_SIZE = 10;
export default function ElectricMetersReadingsModal({box, meter, refreshMeter, closeDialog}: Props) {
  const [isReadingsLoading, load] = useLoading();
  const [readingsPage, setReadingsPage] = useState<PagedResult<ElectricMeterReadingsModel>>(EmptyPagedResult<ElectricMeterReadingsModel>(PAGE_SIZE));
  
  const [editElectricMeterModal, openEditElectricMeterModal] = useDialogWithParameter<ElectricMeterModel>(
    (param, closeEditMeterModal) => <NewElectricMeterReadingModal
      closeDialog={closeEditMeterModal}
      meter={meter}
      box={box}
      refreshMeter={refreshMeter}
      setReadings={setReadingsPage}
    />
  );

  const loadReadings = useCallback(async (page: number) => {
    const resp = await load(ElectricMetersService.getReadings(box.gskId, meter.id, { page, pageSize: PAGE_SIZE})) // TODO implemetent get readings request
    if (resp) {
      setReadingsPage(resp);
    }
  }, [box.gskId, load, meter.id]);

  const columns: ColumnsType<ElectricMeterReadingsModel> = useMemo(() =>{
    const handleDelete = (r: ElectricMeterReadingsModel) => {
      if (!!r.chargeId) {
        Modal.info({title: 'Удаление', content: 'Нельзя удалить показание, по которому сформировано начисление'});
        return;
      }
      
      Modal.confirm({
        title: 'Удаление показания',
        content: 'Вы действительно хотите удалить это показание?',
        okText: 'Удалить',
        cancelText: 'Отмена',
        onOk: async () => {
          if (await ElectricMetersService.deleteMeterReadings(box.gskId, meter.id, r.id)) {
            loadReadings(readingsPage.page);
            refreshMeter();
          }
        }
      })
    }

    return [
      {
        title: 'День',
        key: 'dayValue',
        dataIndex: 'dayValue',
        render: (v: number) => <>{toFloatString(v)}</>
      },
      {
        title: 'Ночь',
        key: 'nightValue',
        dataIndex: 'nightValue',
        render: (v: number) => <>{toFloatString(v)}</>
      },
      {
        title: 'Начисление',
        key: 'charge',
        render: (v: any, row: ElectricMeterReadingsModel) => {
          if (row.chargedSumm === null) {
            return <>-</>
          }
          return <>
            <Tooltip title="Оплачено">{toMoneyString(row.paidSumm ?? 0)}</Tooltip> /&nbsp;
            <Tooltip title="Начислено">{toMoneyString(row.chargedSumm)}</Tooltip>
          </>
        },
        onCell: (row: ElectricMeterReadingsModel) => ({
          className: getPaymentClassNameByValue(row.chargedSumm, row.paidSumm)
        }),
      },
      {
        title: 'Добавил',
        key: 'createdByFio',
        dataIndex: 'createdByFio',
      },
      {
        title: 'Дата',
        key: 'created',
        dataIndex: 'created',
        render: (v: Date) => <span title={dayjs(v).format("HH:mm")}>{dayjs(v).format("DD.MM.YYYY")}</span>
      },
      {
        title: '',
        key: 'actions',
        render: (_: any, row: ElectricMeterReadingsModel) => <Space>
          <IconButton
            icon={<DeleteOutlined />}
            disabled={!!row.chargeId}
            title={!!row.chargeId ? 'Нельзя удалить показание, по которому сформировано начисление' : 'Удалить'}
            onClick={() => handleDelete(row)}
          />
        </Space>
      },
    ].filter(c => meter.withNightTarif || c.key !== 'nightValue')
  }, [box.gskId, loadReadings, meter.id, meter.withNightTarif, readingsPage.page, refreshMeter]);
  
  useEffectOnce(() => {
    loadReadings(1);
  })

  return <ModalBase
    closeDialog={closeDialog}
    leftFooterCell={<Button icon={<PlusCircleOutlined />}  onClick={() => openEditElectricMeterModal(meter)}>Добавить показание</Button>}
    title={<>Показания счетчика электроэнергии №{meter.num}</>}
    okButtonHandler={closeDialog}
    withCancelButton={false}
    width={600}
  >
    <Table
      loading={isReadingsLoading}
      size="small"
      columns={columns}
      dataSource={readingsPage.items}
      rowKey='id'
      pagination={{
        current: readingsPage.page,
        pageSize: PAGE_SIZE,
        total: readingsPage.totalCount,
        onChange: (page) => loadReadings(page)
      }}
    />
    {editElectricMeterModal}
  </ModalBase>
}