import { useCallback, useMemo, useState } from 'react'
import ModalBase from '../ModalBase/ModalBase';
import { useAppSelector } from '../../store/redux';
import { EditBoxModel, GskBox, GskBoxWithUser, GskRole } from '../../types/GskTypes';
import { InputNumber, Form, Input, Modal, Tooltip, Button, Row, Col, Tabs } from 'antd'
import { useForm } from 'antd/es/form/Form';
import { ShowErrorMessage } from '../Notifications/Notifications';
import TextArea from 'antd/es/input/TextArea';
import { CheckCircleTwoTone, DeleteOutlined } from '@ant-design/icons';
import CallTo from '../Phone/CallTo';
import { MaskedInput } from 'antd-mask-input';
import IconButton from '../IconButton/IconButton';
import { toMoneyString } from '../../utils/moneyHelper';
import GskRolesSelect from './GskRolesSelect';
import BoxMetersList from './Meters/BoxMetersList';
import { getUserFullName } from '../../types/UserTypes';
import { BoxesService } from '../../Services/BoxesService';
import useLoading from '../../utils/hooks/useLoading';
import DeleteButton from '../Controls/DeleteButton';
import { currentUserHasGskRoleSelector } from '../../store/common/app-slice';

interface OwnProps {
  boxInput: GskBoxWithUser;
  closeDialog: CallableFunction;
  onSaved: (box: GskBoxWithUser | null) => void;
}

export function EditBoxModal ({ closeDialog, boxInput, onSaved }: OwnProps) {
  const [box, setBox] = useState(boxInput);  
  const isChairman = useAppSelector(currentUserHasGskRoleSelector(GskRole.Chairman));
  const [isLoading, load] = useLoading();
  const [form] = useForm<EditBoxModel>();

  const updateBox = useCallback((val: GskBoxWithUser | null) => {
    onSaved(val);
    if (val) {
      setBox(val);
    }
  }, [onSaved]);

  const submit = async (data: EditBoxModel) => {
    if (!data.areaM2) {
      data.areaM2 = 0;
    }
    const result = await load(BoxesService.editBox(box.gskId, boxInput.id, data));
    if (result) {
      closeDialog();
      updateBox(result);
    }
  }

  const handelDeleteBox = async () => {
    Modal.confirm({
      title: "Удаление бокса",
      content: "Точно удалить бокс " + box.num + "?",
      okText: "Удалить",
      cancelText: 'Отмена',
      okButtonProps: { color: "red" },
      onOk: async () => {
        const resp = await load(BoxesService.deleteBox(box.gskId, box.id));
        if (resp) {
          closeDialog();
          updateBox(null);
        }
      }
    });
  }

  const tabs = useMemo(() => {
    const checkNum = (value?: string) => {
      if (!value || value === '') {
        return Promise.reject('Введите значение');
      }
      if (value.includes(' ')) {
        return Promise.reject('Не должен содеражть пробелы и спецсимволы');
      }

      if (value.length > 6) {
        return Promise.reject('Должен быть короче 6 символов');
      }

      return Promise.resolve();
    }

    const handelDeleteUser = async (v: GskBox) => {
      const box = {...v};
      if(!box.user) {
        return;
      }
      Modal.confirm({
        title: "Удаление члена ГСК",
        content: <>
          Пользователь <b>{getUserFullName(box.user)}</b> потеряет доступ к боксу №<b>{box.num}</b>
          .
          {box.latePaymentSum && box.latePaymentSum > 0 && 
            <>
              <br/><br/>На данный момент не оплачен долг <b>{toMoneyString(box.latePaymentSum)}</b>.&nbsp;
              Погасите его или он будет передан следующему владельцу бокса.
            </>
          }
        </>,
        okText: "Удалить",
        cancelText: 'Отмена',
        okButtonProps: { color: "red" },
        onOk: async () => {
          const resp = await load(BoxesService.removeBoxUser(box.gskId, box.id))
          if (resp) {
            form.setFieldValue('user', undefined);
            updateBox(resp);
          }
        }
      });
    }
    return [
      {
        label: 'Основные',
        key: 'main',
        children: <>
          <Form.Item noStyle name={['user','id']}>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item 
            label='№'
            name='num'
            rules={[{required: true, validator: (_, v) => checkNum(v)}]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={<>Площадь, м<sup>2</sup></>} name='areaM2'>
            <InputNumber />
          </Form.Item>
          <Form.Item 
            label={box.user ? <>Пользователь</> : <>Нет пользователя</>}
            tooltip={box.user && 'Чтобы сменить пользователя нужно сначала удалить пользователя, затем отправить приглашение другому'}
          >
            <Row>
              <Col span={22}>
                {/* //TODO после удаления не обновляется */}
                <AssignedUser box={box} showBoxOwner={false}  />
              </Col>
              <Col span={2}>
                {box.user && <IconButton 
                  icon={<DeleteOutlined />}
                  onClick={() => handelDeleteUser(box)}
                  title='Удалить пользователя'
                  disabled={!isChairman}
                />}
              </Col>
            </Row>
          </Form.Item>
          {box.user && <Form.Item label='Роли' name={['user','gskRoles']}>
            <GskRolesSelect  
              disabledList={[GskRole.Member, GskRole.Chairman]}
              disabled={!isChairman}
              showSelectAll={false}
            />
          </Form.Item>}
          <Form.Item label='Владелец' name='ownerName' tooltip='Можно не заполнять, если ФИО присоединенного пользователя верное'>
            <Input />
          </Form.Item>
          <Form.Item label='Телефон' name='ownerPhone' tooltip='Можно не заполнять, если телефон присоединенного пользователя верный'>
            <MaskedInput mask={[{mask: '+7 (000) 000 00 00', lazy: false}]}/>
          </Form.Item>
          <Form.Item label='Комментарий' name='comment'>
            <Input />
          </Form.Item>
        </>
      },
      {
        label: 'Счетчики',
        key: 'meters',
        children: <BoxMetersList box={box} canEdit={isChairman}/>
      },
    ]
  }, [box, form, isChairman, load, updateBox]);

  return (
    <ModalBase 
      closeDialog={closeDialog} 
      title='Изменить бокс' 
      leftFooterCell={isChairman && <DeleteButton onClick={() => handelDeleteBox()} />}
      okButtonHandler={() => form.submit()} isLoading={isLoading} width={550}
    >
      <Form
        form={form}
        size='small'
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        initialValues={{
          id: box.id,
          num: box.num,
          areaM2: box.areaM2,
          ownerName: box.ownerName,
          ownerPhone: box.ownerPhone,
          comment: box.comment,
          user: box.user,
          isDeleted: box.isDeleted
        }}
        disabled={!isChairman || box.isDeleted}
        onFinish={submit}
      >
        <Tabs
          tabPosition='left'
          style={{minHeight: '400px'}}
          items={tabs}
        />
      </Form>
    </ModalBase>
  );
}

interface AssignedUserProps {
  box: GskBox;
  showBoxOwner: boolean;
}
export const AssignedUser = ({ box, showBoxOwner }: AssignedUserProps) => {
  const [isLoading, load] = useLoading();
  const isChairman = useAppSelector(currentUserHasGskRoleSelector(GskRole.Chairman));

  const handleGetInviteClick = async () => {
    const invite = await load(BoxesService.createInvite(box.gskId, box.id));
    if (!invite) {
      ShowErrorMessage('Не удалось создать приглашение', 'Ошибка');
      return;
    }
    
    Modal.info({
      title: "Приглашение для бокса №"+box.num,
      content: <>
        <TextArea 
          readOnly={true}
          rows={3}
          value={window.location.origin.replace('xn--c1alefku.xn--p1ai','МойГСК.рф')+'/Invite/'+invite.key}
        />
        <p>Скопируйте эту ссылку и отправьте ее владельцу бокса <b>№{box.num}</b>, например, по электронной почте, WhatsApp или Telegram</p>
        <p>После перехода по ссылке ему будет предложено зарегистрироваться или войти под своей учетной записью и вступить в <b>{invite.gskName}</b></p>
      </>
    })
  }


  const hideBoxOwner = box.user 
    && getUserFullName(box.user).toLowerCase() === box.ownerName?.toLowerCase()
    && box.ownerPhone === box.user.phone;
  return (<>
    {showBoxOwner && !hideBoxOwner && box.ownerName && <>{box.ownerName} {box.ownerPhone &&  <CallTo phone={box.ownerPhone}/>} <br/></>}
    {box.user ? <>
      <Tooltip title='Зарегистрирован'><CheckCircleTwoTone twoToneColor={"green"} type="message" /></Tooltip>&nbsp;
      {getUserFullName(box.user)}&nbsp;
      {box.user.phone && <CallTo phone={box.user.phone}/>}
    </> :
    <>
      {isChairman && <Button size='small' onClick={handleGetInviteClick} loading={isLoading}>Пригласить</Button>}
    </>}
</>)
}