import { AutoComplete } from "antd";
import { useEffect, useState } from "react";
import { AddressService } from "../../Services/AddressService";
import highlightWords from "highlight-words";
import { AddressLevel, AddressModel } from "../../types/AddressTypes";
import { useDebounce } from "../../utils/useDebounce";
import { useAppDispatch } from "../../store/redux";
import './AddressInput.scss'

interface AddressInputProps {
  initAddress?: AddressModel;
  level?: AddressLevel;
  onChange?: (value: AddressModel) => void;
}

export default function AddressInput({ initAddress, onChange, level = AddressLevel.None }: AddressInputProps) {
  const dispatch = useAppDispatch();
  const [address, setAddress] = useState<AddressModel>(initAddress ?? {fiasId: null, value: '', city: null, level: AddressLevel.None})
  const [suggestions, setSuggestions] = useState<{value: string, label: JSX.Element, addr: AddressModel}[]>([]);
  const [className, setClassName] = useState('');
  const [tip, setTip] = useState('');

  const debouncedValue = useDebounce<string>(address.value, 1000);

  useEffect(() => 
  { 
    const handleSearch = async (value: string) => {
    if (value.length < 3){
      return;
    }
    if (address.level === level) {
      return;
    }
    
    const resp = await dispatch(AddressService.suggest(value));
    const vv = resp.map(r =>{
      const chunks = highlightWords({ text: r.value, query: address.value })
      const value = <span>
        {chunks.map((chunk) => {
          if (!chunk.match) {
            return <span key={chunk.key} aria-hidden>{chunk.text}</span>;
          }

          return (
            <span key={chunk.key} style={{fontWeight: 'bold'}} aria-hidden>
              {chunk.text}
            </span>
          );
        })}
        </span>;
      return {label: value, value: r.value, addr: r}
    });
    setSuggestions(vv);
    }
    handleSearch(debouncedValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const onSelect = (value: string) => {
    setAddress({...suggestions.filter(s => s.value === value)[0].addr});
  }

  const handleChange = (value: string) => {
    setAddress({value: value, fiasId: null, city: null, level: AddressLevel.None});
  }

  useEffect(() => {
    if (onChange) {
      onChange(address);
    }
  }, [address, onChange]);


  useEffect(() => {
    
    if (level === AddressLevel.None) {
      setClassName('');
      setTip('')
    } else if (level === address.level) {
      setClassName('success');
      setTip('')
    } else {
      setClassName('warning');
      let postfix = '';
      switch (level) {
        case AddressLevel.Region:
          postfix = 'региона';
          break;
        case AddressLevel.City:
          postfix = 'города';
          break;
        case AddressLevel.Street:
          postfix = 'улицы';
          break;
        case AddressLevel.House:
          postfix = 'дома';
          break;
        case AddressLevel.Apartment:
          postfix = 'квартиры';
          break;
        default: 
          throw Error('unexpected AddressLevel')
      }
      setTip('Укажите адрес с точностью до '+ postfix);
    }
  }, [address.level, level])

  return (
    <AutoComplete
      value={address.value}
      className={className}
      options={suggestions}
      style={{ width: '100%' }}
      onSelect={v => onSelect(v)}
      onChange={handleChange}
      onSearch={handleChange}
      title={tip}
    />
  )
}