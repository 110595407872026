import { GskBoxWithUser } from "./GskTypes";
import { PagedRequest } from "./dto";

export const METER_VALUE_LIMIT = 9999999;

export interface BoxMeterBase {
  id: number;
  boxId: number;
  num: string;
  debt: number;
  type: string;
  lastReadingDate: Date | null;
  isDeleted: boolean;
}

export interface ElectricMeterModel extends BoxMeterBase {
  initDayValue: number;
  initNightValue: number;
  withNightTarif: boolean;
  lastDayValue: number;
  lastNightValue: number;
}

export const GetMeterTypeLabel = (type?: string) => {
  if (!type) {
    return '';
  }
  switch (type) {
    case 'Electric':
      return 'Электроэнергия';
    default:
      throw Error("Unexpected meter type: " + type);
  }
}

export interface NewElectricMeterModel {
  boxId: number;
  num: string | null;
  withNightTarif: boolean;
	initDayValue: number;
	initNightValue: number;
}

export interface EditElectricMeterModel {
  num: string;
  initDayValue: number;
  initNightValue: number;
  withNightTarif: boolean;
}

export interface MeterReadingsRequest extends PagedRequest{}

export interface ElectricMeterReadingsModel {
  id: number;
  idMeter: number;
  dayValue: number;
  nightValue: number | null;
  chargeId: number | null;
  chargedSumm: number | null;
  paidSumm: number | null;
  created: string;
  createdByFio: string;
  createdById: number;
}

export interface NewElectricMeterReadingsModel {
  dayValue: number;
  nightValue: number | null;
}

export interface ElectricAccrualConfigModel {
  withNightTarif: boolean;
  initDayValue: number;
  initNightValue?: number;
  dayMeterValue: number;
  nightMeterValue?: number;
  dayUnitPrice: number;
  nightUnitPrice?: number;
}

export interface BoxesWitMetersRequest extends PagedRequest {
  filterText?: string;
}

export interface BoxModelWithMeters extends GskBoxWithUser {
  meters: ElectricMeterModel[];
} 