import { UserBase, UserRole } from "./UserTypes";
import { AddressModel } from "./AddressTypes";
import { MapPolygonLabel, MapSettings, Point } from "../components/GskMap/GskMapTypes";
import { ElectricMeterModel } from "./MeterTypes";
import { PagedRequest } from "./dto";
import { CurrentSubscriptionModel } from "./BillingTypes";

export enum GskOperationType {
  Load = 'Load',
  LoadCommissionPercent = 'LoadCommissionPercent',
  LoadPaymentTypes = 'LoadPaymentTypes',
}

export enum GskRole {
  Admin = 1,
  Chairman = 2,
  Accountant = 3,
  MapEditor = 4,
  Member = 8,
}

export const GetGskRoleName = (entity: GskRole) => {
  switch (entity) {
    case GskRole.Admin:
      return "Администратор";
    case GskRole.Chairman:
      return "Председатель";
    case GskRole.Accountant:
      return "Бухгалтер";
    case GskRole.MapEditor:
      return "Редактор карт";
    case GskRole.Member:
      return "Член ГСК";
    default:
      throw Error("Not implemented for GskRole = " + entity)
  }
}

export const HasGskRole = (gskRoles: GskRole[], role: GskRole, userRole: UserRole): boolean => {
  if (userRole === UserRole.Admin) {
    return true;
  }

  return gskRoles.some(r => GskRoleIsInclude(r, role));
}

const GskRoleIsInclude = (role: GskRole, include: GskRole) => {
  switch (role) {
    case GskRole.Admin:
    case GskRole.Chairman:
      return true;
    default:
      return role === include;
  }
}

export interface GskBase {
  name: string;
  address: AddressModel;
  isPublic: boolean;
}

export interface NewGskModel extends GskBase {
  tariffId: number;
  discountId?: number;
}

export interface GskModel extends GskBase {
  id: number;
  currentSubscription?: CurrentSubscriptionModel;
}

export interface GskUserPermissionsModel extends GskModel {
  gskRoles: GskRole[];
  boxes: GskBox[];
}
export interface GskUserModel extends UserBase {
  gskRoles: GskRole[];
}

export interface GskBoxBase {
  id: number;
  gskId: number;
  num: string;
  areaM2: number | null;
  ownerName: string | null;
  ownerPhone: string | null;
  comment: string | null;
  userId: number | null;
  isDeleted: boolean;
  electricMeters: ElectricMeterModel[];
  latePaymentSum?: number;
}

export interface WithUser {
  user: GskUserModel | null;
}

export interface GskBoxWithUser extends GskBoxBase, WithUser {}

export interface BoxesFilter{
  boxIds?: number[];
  userId?: number;
}

export interface BoxShortModel {
  id: number;
  num: string;
  userId?: number;
  ownerName?: string;
}

export interface GskBox extends GskBoxBase, WithUser {
}

export interface EditBoxModel {
  id: number;
  num: string;
  areaM2: number | null;
  ownerName: string | null;
  ownerPhone: string | null;
  comment: string | null;
  user: GskUserModel | null;
  isDeleted: boolean;
}


export interface MapBoxPolygon {
  polygonId?: number;
  boxId: number;
  isFinished: boolean;
  points: Point[];
  label?: MapPolygonLabel;
  lineColor: string | null;
  fillColor: string | null;
}

export interface GskMapModel {
  id: number;
  gskId: number;
  name: string;
  settings: MapSettings;
}

export interface EditMapModel {
  name: string;
  settings: MapSettings;
}

export interface Gsk {
  id: number;
  name: string;
  address: AddressModel;
  isPublic: boolean;
}

export interface GskInviteModel {
  key: string;
  gskName: string;
  boxNum: string;
  createdBy: string;
  isExpired: boolean;
}

export interface GskChairmanInviteModel {
  key: string;
  gskName: string;
  invitedUserId: number;
  invitedUserName: string;
  createdBy: string;
  isExpired: boolean;
}

export interface PageBlock {
  order: number;
  isVisible: boolean;
}

export interface ContactsBlock extends PageBlock {
  list: ContactData[];
}

export interface ContactData {
  post: string;
  name: string;
  phone: string;
  email: string;
  order: number;
}

export interface MainPageData {
  contacts: ContactsBlock;
}

export interface UpdateMainPageDataRequest {
  gskId: number;
  data: MainPageData;
}

export interface BoxesRequest extends PagedRequest
{
  filterText?: string;
  gskRoles: GskRole[];
  withDebt: boolean;
}

export interface GsksRequest extends PagedRequest
{
  name?: string;
  address?: string;
}