import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../store/redux";
import { BoxShortModel, Gsk, GskRole } from "../../types/GskTypes";
import { GskChargeService } from "../../Services/GskChargeService";
import { Button, Card, Checkbox, Flex, Grid, Radio, Space, Spin, Table, Tooltip } from "antd";
import { GskBoxChargeModel, GskChargeModel, PayState, getPayState, getPaymentClassName } from "../../types/GskChargeTypes";
import { ColumnsType } from "antd/es/table";
import dayjs from 'dayjs'
import './GskCashePage.scss'
import useCurrentGsk from "./useCurrentGsk";
import { useDialogWithParameter } from "../../utils/useDialog";
import GskPaymentModal from "../../components/Gsk/Charges/GskPaymentModal";
import { GskHistoryEntity } from "../../types/GskHistoryTypes";
import { GskHistoryModalProps } from "../../components/GskHistoryModal/GskHistoryModal";
import { CheckCircleTwoTone, DollarOutlined, EditOutlined, FieldTimeOutlined, ScheduleOutlined } from "@ant-design/icons";
import { toMoneyString } from "../../utils/moneyHelper";
import { useParams } from "react-router-dom";
import { UserModel } from "../../types/UserTypes";
import RowTwoCol from "../../components/RowTwoCol/RowTwoCol";
import IconButton from "../../components/IconButton/IconButton";
import Unique from "../../utils/ArrayUtils";
import GskChargeModal from "../../components/Gsk/Charges/GskChargeModal";
import { useEffectOnce } from "../../utils/hooks/useEffectOnce";
import useLoading from "../../utils/hooks/useLoading";
import { BoxesService } from "../../Services/BoxesService";
import { currentUserHasGskRoleSelector, useCurrentUser } from "../../store/common/app-slice";
import { PaymentState } from "../../types/BillingTypes";
import RenderGskChargesModal from "../../components/Gsk/Charges/RenderGskChargesModal";

export interface GskBoxChargeModelExtended extends GskBoxChargeModel {
  payState: PayState;
  paySumm: number;
  payNotConfirmedSum: number;
  year: number;
  checked: boolean;
}
interface GskCashePageProps {
  openGskHistoryModal: (props: GskHistoryModalProps) => void;
}
export default function GskCashePage({openGskHistoryModal} : GskCashePageProps) {
  const gsk = useCurrentGsk();
  const currentUser = useCurrentUser();
  const params = useParams();
  const boxId = params.boxid ? parseInt(params.boxid) : null;
  return (<>
    {gsk && currentUser && <Page gsk={gsk} boxId={boxId} currentUser={currentUser} openGskHistoryModal={openGskHistoryModal} />}
  </>)
}

interface PageProps {
  gsk: Gsk;
  currentUser: UserModel;
  boxId: number | null;
  openGskHistoryModal: (props: GskHistoryModalProps) => void;
}
const Page = ({gsk, currentUser, boxId, openGskHistoryModal}: PageProps) => {
  const [boxes, setBoxes] = useState<BoxShortModel[]>([]);
  const [isLoading, load] = useLoading() ;
  const [allCharges, setAllCharges] = useState<GskBoxChargeModelExtended[]>([]);
  const [years, setYears] = useState<number[]>([])
  const [selectedYear, setSelectedYear] = useState<number>(-1);

  const allChargesSorted = useMemo(() => allCharges.sort((a, b) => new Date(a.forTheDate).getTime() - new Date(b.forTheDate).getTime() || a.id - b.id)
  , [allCharges]);

  const checkCharge = useCallback((charge: GskBoxChargeModelExtended, checked: boolean) => {
    setAllCharges(prev => [...prev.filter(p => p.id !== charge.id), {...charge, checked}])
  }, []);

  const [renderChargesModal, openRenderChargesModal] = useDialogWithParameter<BoxShortModel[]>(
    (boxes, closeDialog) => <RenderGskChargesModal gskId={gsk.id} boxes={boxes} onFinished={() => reload(boxes.map(b => b.id))} closeDialog={closeDialog} />
  );

  const reload = useCallback(async (boxIds: number[]) => {
    if (boxIds.length === 0) {
      return;
    }
    let charges = await load(GskChargeService.getBoxChargesWithPayments(gsk.id, boxIds));
    if (charges) {
      const transformedCharges = charges.map(c => {
        const year = new Date(c.forTheDate).getFullYear()
        const paySumm = c.payments.filter(p => p.paymentState === PaymentState.Succeeded).map(p => p.amount).reduce((sum, p) => sum + p, 0);
        const payNotConfirmedSum = c.payments.filter(p => p.paymentState === PaymentState.WaitingForCapture).map(p => p.amount).reduce((sum, p) => sum + p, 0);
        return {...c, payState: getPayState(c), paySumm, payNotConfirmedSum, year} as GskBoxChargeModelExtended
      });
      setAllCharges(prev => [...prev.filter(p => !boxIds.includes(p.boxId)), ...transformedCharges]);

      const years = Unique(transformedCharges.map(c => c.year)).sort((a, b) => a - b);
      setYears(years);
      if (years.length > 0) {
        setSelectedYear(Math.max(...years))
      }
    }
  }, [gsk.id, load]);

  useEffect(() => {
    reload(boxes.map(b => b.id));
  }, [boxes, reload])
  
  const getBoxes = async () => {
    let resp: BoxShortModel[] | undefined;
    if (boxId) {
      resp = await load(BoxesService.getBoxesShort(gsk.id, {boxIds: [boxId]}));
    } else {
      resp = await load(BoxesService.getBoxesShort(gsk.id, {userId: currentUser.id}));
    }
    if (resp){
      setBoxes(resp);
    }
  };

  useEffectOnce(() => {
    getBoxes();
  });

  const charges = useMemo(() => allChargesSorted.filter(c => c.year === selectedYear), [allChargesSorted, selectedYear]);
  const chargesCards = useBoxCards(isLoading, boxes, openRenderChargesModal, charges, reload, checkCharge, gsk, currentUser, openGskHistoryModal) ;

  const yearsBlock = useMemo(() => {
    return <Radio.Group value={selectedYear} onChange={v => setSelectedYear(v.target.value)}>
      {
        years.map(year => {
          const yearPayStates = Unique(allCharges.filter(c => c.year === year).map(c => c.payState));
          
          let payState = PayState.None;
          if (yearPayStates.some(p => p === PayState.LatePayment)) {
            payState = PayState.LatePayment;
          } else if (yearPayStates.every(p => p === PayState.Paid)) {
            payState = PayState.Paid;
          }
          
          return <Radio.Button key={year} className={getPaymentClassName(payState)} value={year}>
            {year}
          </Radio.Button>
        })
      }
    </Radio.Group> 
  }, [allCharges, selectedYear, years]);

  return (
    <div style={{width: '100%'}}>
      <Spin spinning={isLoading}>
        <Space direction="vertical" style={{width: '100%'}}>
          <Flex justify="center">
            {yearsBlock}
          </Flex>
          {chargesCards}
        </Space>
      </Spin>
      {renderChargesModal}
    </div>)
}

const useBoxCards = (
  isLoading: boolean,
  boxes: BoxShortModel[],
  openRenderChargesModal: (v: BoxShortModel[]) => void,
  charges: GskBoxChargeModelExtended[],
  reload: (boxIds: number[]) => void,
  checkCharge: (charge: GskBoxChargeModelExtended, checked: boolean) => void,
  gsk: Gsk,
  currentUser: UserModel,
  openGskHistoryModal: (props: GskHistoryModalProps) => void,
) => {
  const gskId = gsk.id;
  const {sm, md} = Grid.useBreakpoint();
  const currentUserIsAccountant = useAppSelector(currentUserHasGskRoleSelector(GskRole.Accountant));

  const openHistory = (entity: GskHistoryEntity, id: number) => openGskHistoryModal({entity, gskId: gsk.id, entityId: id, includeChilds: true});

  const [paymentModal, openPaymentModal] = useDialogWithParameter<{charges: GskBoxChargeModelExtended[], boxIds: number[]}>(
    (param, closeDialog) => <GskPaymentModal closeDialog={closeDialog} gskId={gskId} charges={param.charges} refreshCallback={() => reload(param.boxIds)}/>
  );

  const [chargeModal, openChargeModal] = useDialogWithParameter<{charge: GskChargeModel | null, boxId: number}>(
    (param, closeDialog) => <GskChargeModal 
      closeDialog={closeDialog} 
      gsk={gsk}
      charge={param.charge}
      refreshCallback={() => reload([param.boxId])}
      boxId={param.boxId} />
  );

  const columns: ColumnsType<GskBoxChargeModelExtended> = [
    {
      key: 'checkbox',
      render: (_, row) => 
        <Checkbox
          checked={row.checked}
          disabled={row.payState === PayState.Paid}
          onChange={(e) => checkCharge(row, e.target.checked)}
        />
    },
    {
      title: 'Начисление',
      key: 'accrualName',
      dataIndex: 'accrualName',
    },
    {
      title: 'Описание',
      key: 'description',
      dataIndex: 'description',
      render: (v: string) => <span style={{whiteSpace: 'pre-line'}}>{v}</span>,
      responsive: ['sm']
    },
    {
      title: 'Дата',
      key: 'forTheDate',
      dataIndex: 'forTheDate',
      render: (v: Date) => <>{dayjs(v).format("DD.MM.YYYY")}</>,
			responsive: ['sm'],
    },
    {
      title: 'Оплатить до',
      key: 'payEndDate',
      dataIndex: 'payEndDate',
      render: (v: Date | null) => <>{v ? dayjs(v).format("DD.MM.YYYY") : '-'}</>,
			responsive: ['md'],
    },
    {
      title: 'Сумма',
      key: 'amount',
      render: (_, row) => <>
        <Tooltip title='Оплачено'>{toMoneyString(row.paySumm ?? 0, false)}</Tooltip>
        {row.payNotConfirmedSum > 0 &&<Tooltip title='Ожидает подтверждения'><span style={{color: 'gray'}}> +{toMoneyString(row.payNotConfirmedSum, false)}</span></Tooltip>}
        {' / '}<Tooltip title='Начислено'>{toMoneyString(row.amount)}</Tooltip>
      </>,
    },
    {
      key: 'payState',
      fixed: 'right',
      width: (sm ? 180 : 70) + (currentUserIsAccountant ? 20 : 0),
      render: (_, charge) => 
        <RowTwoCol 
          style={{alignItems: 'center'}}
          left={<center>
            {
              charge.paySumm === charge.amount ? 
              <>{sm ? 'Оплачено' : <CheckCircleTwoTone twoToneColor={"green"}/>}</> :
              <Button icon={<DollarOutlined />} onClick={() => openPaymentModal({charges: [charge], boxIds: [charge.boxId]})}>{md && 'Оплатить'}</Button>
            }
            </center>}
          right={
            <Space>
              { currentUserIsAccountant && 
              <IconButton 
                onClick={() => openChargeModal({charge: charge, boxId: charge.boxId})}
                disabled = {charge.paySumm > 0}
                icon={<EditOutlined />}
                title={charge.paySumm > 0 ? 'Нельзя изменить оплаченное начисление' :'Изменить'}
              />}
              <IconButton 
                onClick={() => openHistory(GskHistoryEntity.CHARGE, charge.id)}
                icon={<FieldTimeOutlined />}
                title='История'
              />
            </Space>
          }
          rightSize={20 + (currentUserIsAccountant ? 20 : 0)}
        />
    }
  ]

  const handleSelectAll = (select: boolean) => {
    charges.filter(c => c.payState !== PayState.Paid).forEach(charge => {
      checkCharge(charge, select);
    });
  }

  const allChecked = useMemo(() => 
    charges.filter(c => c.payState !== PayState.Paid).every(c => c.checked)
  , [charges]);

  const selectedChargesSum = useMemo(() => 
    charges.filter(c => c.checked).reduce((sum, curr) => sum + curr.amount - curr.paySumm, 0)
  , [charges])

  return <>
    <RowTwoCol
      left={
        <Space>
        <Checkbox checked={allChecked} onChange={(e) => handleSelectAll(e.target.checked)}>Выбрать все</Checkbox>
        <Button 
          disabled={selectedChargesSum === 0}
          icon={<DollarOutlined />}
          onClick={() => openPaymentModal({charges: charges.filter(c => c.checked), boxIds: charges.map(c => c.boxId)})}
        >
          Оплатить {toMoneyString(selectedChargesSum)}
        </Button>	
      </Space>
      }
      right={
        <Space>
          {currentUserIsAccountant && <Button
              icon={<ScheduleOutlined />}
              title=""
              onClick={() => openRenderChargesModal(boxes)}
            >
              {md && 'Создать начисления'}
            </Button>
          }
        </Space>
      }
    />
    {boxes.map(box => 
      {
        const boxCharges = charges.filter(c => c.boxId === box.id);
        const boxChargesActive = boxCharges.filter(c => !c.isDeleted);

        const chargedSum = boxChargesActive.reduce((accum, value) => accum + value.amount, 0);
        const paidSum = boxChargesActive.reduce((accum, value) => accum + value.paySumm, 0);
        // const latePaimentdSum = boxChargesActive.filter(b => b.payState === PayState.LatePayment)
        // 	.reduce((accum, value) => accum + value.amount, 0) ;
        // const toPaySum =  boxChargesActive.filter(b => b.payState === PayState.LatePayment ||  b.payState === PayState.None)
        // 	.reduce((accum, value) => accum + value.amount, 0) ;
        
        return <Card 
          loading={isLoading}
          key={box.id} 
          title={<>
              Бокс №{box.num} 
              {!box.userId && !box.ownerName && ' - Нет владельца'}
              {!!box.userId && box.userId !== currentUser.id && <> - {box.ownerName}</>}
              {!box.userId && box.ownerName && <> - {box.ownerName}</>}
            </>}
          extra={
            <Space>
              {currentUserIsAccountant && <Button onClick={() => openChargeModal({charge: null, boxId: box.id})}>Добавить начисление вручную</Button>}
              
              <IconButton 
                onClick={() => openHistory(GskHistoryEntity.BOX, box.id)}
                icon={<FieldTimeOutlined />}
                title='История'
              />
            </Space>
          }
        >
          <Table 
            rowClassName={(row, _) => getPaymentClassName(row.payState)}
            columns={columns}
            dataSource={boxCharges}
            size="small"
            pagination={false}
            rowKey='id'
            footer={() => <>Начислено: {toMoneyString(chargedSum)} &nbsp; &nbsp; Оплачено: {toMoneyString(paidSum)}</>}
          />
        </Card>
      })
    }
    {paymentModal}
    {chargeModal}
  </>
}