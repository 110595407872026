import { Alert, Checkbox, DatePicker, Form, Input, Modal, Select } from 'antd'
import ModalBase from '../../ModalBase/ModalBase';
import { GskChargeService } from '../../../Services/GskChargeService';
import { AccrualShortModel, GskChargeModel } from '../../../types/GskChargeTypes';
import { useState } from 'react';
import FloatInput from '../../Controls/FloatInput';
import { Gsk } from '../../../types/GskTypes';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs'
import useLoading from '../../../utils/hooks/useLoading';
import { useEffectOnce } from '../../../utils/hooks/useEffectOnce';
import DeleteButton from '../../Controls/DeleteButton';
import { NumInputValidator } from '../../../utils/NumInputValidator';
import { useWatch } from 'antd/es/form/Form';

interface OwnProps {
  gsk: Gsk;
  boxId: number;
  charge: GskChargeModel | null;
  refreshCallback: () => void;
  closeDialog: CallableFunction;
}
const MAX_AMOUNT = 999999;
export default function GskChargeModal({closeDialog, gsk, boxId, refreshCallback, charge}: OwnProps) {
  const [form] = Form.useForm<GskChargeModel>();
  const [isLoading, load] = useLoading();
  const [accruals, setAccruals] = useState<AccrualShortModel[]>([]);
  const isNew = !charge;

  const accrualId = useWatch('accrualId', form);

  const handleOk = async (data: GskChargeModel) => {
    let success = false;
    if (charge) {
      var req = {...data, boxId: charge.boxId};
      success = await load(GskChargeService.editCharge(gsk.id, charge.accrualId, charge.id, req)) ?? false;
    } else {
      data.boxId = boxId;
      success = await load(GskChargeService.addCharge(gsk.id, accrualId, data)) ?? false;
    }
    
    if (success) {
      refreshCallback();
      closeDialog();
    }
  }

  const handleDeleteCharge = () => {
    if (!charge) {
      return
    }
    Modal.confirm({
      title: "Удаление",
      content: <>Удалить начисление?</>,
      okText: "Удалить",
      cancelText: "Нет",
      onOk: async () => {
        if (await load(GskChargeService.deleteCharge(gsk.id, accrualId, charge.id))){
          refreshCallback();
          closeDialog();
        }
      }
    });
  }

  useEffectOnce(() => { 
    const loadAccuralsFunc = async () => setAccruals(await load(GskChargeService.getAllaccrualsList(gsk.id)) ?? []);
    loadAccuralsFunc(); 
  });

  return (
    <ModalBase 
      closeDialog={closeDialog}
      title={charge ? 'Изменить начисление' : 'Новое начисление' }
      okButtonHandler={() => form.submit()}
      width={500}
      leftFooterCell={!isNew && <DeleteButton onClick={handleDeleteCharge}/>}
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        layout={'horizontal'}
        form={form}
        initialValues={{
          accrualId: charge?.accrualId,
          name: charge?.name,
          boxId: charge?.boxId ?? boxId,
          forTheDate: charge?.forTheDate ? dayjs(charge?.forTheDate) : undefined,
          payEndDate: charge?.payEndDate ? dayjs(charge?.payEndDate) : undefined,
          description: charge?.description,
          amount: charge?.amount,
          withEmailNotify: true,
        }}
        onFinish={handleOk}
      >
        {!charge && <Alert 
          style={{marginBottom: 10}}
          type='info' 
          closable
          message={<>Это окно предназначено для добавления индивидуальных начислений.<br/>
          Чтобы сформировать начисления:
          <ul>
            <li>для выбраного бокса, воспользуйтесь кнопкой <b>Создать начисления</b> на этой странице</li>
            <li>для всех пользователей сразу, перейдите в раздел <b>Начисления</b> и нажмите кнопку <b>Начислить...</b></li>
          </ul>
          </>}
        />}
        <Form.Item
          label='Начисление'
          name='accrualId'
          rules={[{required: true, message: 'Выберите начисление'}]}
        >
          <Select
            disabled={!!charge}
            options={accruals.map(a => ({value: a.id, label: a.name}))}
            loading={isLoading} 
            onChange={id => form.setFieldValue('name', "Платеж по начислению '"+accruals?.filter(f => f.id === id)[0].name+"'")}
          />
        </Form.Item>
        {!isLoading && !accruals && 
          <Alert type='warning' message={<>Начисления не созданы. Вы можете создать их в разделе <Link to='../Accruals'>начисления</Link></>} /> 
        }
        <Form.Item
          label='Название'
          name='name'
          rules={[{required: true, message: 'Введите значение'}]}
        >
          <Input placeholder='Платеж по начислению...' />
        </Form.Item>
        <Form.Item
          label='Описание'
          name='description'
        >
          <Input placeholder='Долг за электроэнергию' />
        </Form.Item>
        <Form.Item 
          label='Сумма'
          name='amount'
          rules={[NumInputValidator(0, MAX_AMOUNT, true)]}
        >
          <FloatInput placeholder='1000' addonAfter='₽' />
        </Form.Item>
        <Form.Item 
          label='За дату'
          name='forTheDate'
          rules={[{required: true, message: 'Введите значение'}]}
        >
					<DatePicker disabled={!!charge} format="DD.MM.YYYY" />
        </Form.Item>
        <Form.Item 
          label='Оплатить до'
          name='payEndDate'
        >
					<DatePicker format="DD.MM.YYYY" />
        </Form.Item>
        {!charge &&
        <Form.Item name='withEmailNotify' label='С рассылкой' tooltip="Отправить на почту платильщику уведомление о новом начислении" valuePropName='checked'>
          <Checkbox />
        </Form.Item>}
      </Form>
    </ModalBase>
  );
}
