import { useState } from "react";
import './ModalBase.scss'
import { Button, Modal, Space, Spin, Tooltip } from "antd";
import ModalHeader from "./ModalHeader/ModalHeader";
import ModalFooter from "./ModalFooter/ModalFooter";
import RowTwoCol from "../RowTwoCol/RowTwoCol";

export interface ModalBaseProps {
  closeDialog: CallableFunction;
  children: React.ReactNode;
  title?: React.ReactNode;
  leftFooterCell?: React.ReactNode;
  customClassName?: string;
  okButtonHandler?: CallableFunction;
  okButtonText?: string;
  okButtonTip?: string;
  withCancelButton?: boolean;
  isLoading?: boolean;
  width?: string | number;
  style?: React.CSSProperties;
}

const ModalBase = ({ 
  closeDialog,
  title,
  leftFooterCell,
  children,
  customClassName,
  okButtonHandler,
  okButtonText = 'Ok',
  okButtonTip,
  withCancelButton = true,
  isLoading = false,
  width = "auto",
  style = undefined,
}: ModalBaseProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const _closeDialog = () => {
    setIsOpen(false);
    setTimeout(() => closeDialog(), 500);
  };

  return (
    <Modal open={isOpen} onCancel={_closeDialog} className={customClassName} footer={null} width={width} style={style}>
      <Spin spinning={isLoading}>
        {title && <ModalHeader>{title}</ModalHeader>}
        <div className="content">
          {children}
        </div>
        {(!!okButtonHandler || withCancelButton) && <ModalFooter>
          <RowTwoCol
            left={leftFooterCell}
            right={
              <Space>
                {okButtonHandler && <Tooltip title={okButtonTip}>
                  <Button type="primary" onClick={() => okButtonHandler()}>{okButtonText}</Button>
                </Tooltip>}
                {withCancelButton && <Button onClick={() => _closeDialog()}>Отмена</Button>}
              </Space>
            }
            rightSize={251}
          />
        </ModalFooter>}
      </Spin>
    </Modal>
  );
}

export default ModalBase;