import { useForm } from "antd/es/form/Form";
import { EditElectricMeterModel, ElectricMeterModel, METER_VALUE_LIMIT, NewElectricMeterModel } from "../../../types/MeterTypes";
import ModalBase from "../../ModalBase/ModalBase";
import { Alert, Form, Input, Modal, Switch } from "antd";
import { ElectricMetersService } from "../../../Services/ElectricMetersService";
import GroupBox from "../../GroupBox/GroupBox";
import FloatInput from "../../Controls/FloatInput";
import RowTwoCol from "../../RowTwoCol/RowTwoCol";
import useLoading from "../../../utils/hooks/useLoading";
import DeleteButton from "../../Controls/DeleteButton";
import { useAppSelector } from "../../../store/redux";
import { GskBox, GskRole } from "../../../types/GskTypes";
import { currentUserHasGskRoleSelector } from "../../../store/common/app-slice";
import { NumInputValidator } from "../../../utils/NumInputValidator";

interface Props {
  meter: ElectricMeterModel | null;
  box: GskBox,
  setMeters: React.Dispatch<React.SetStateAction<ElectricMeterModel[]>>;
  closeDialog: CallableFunction;
}
export default function EditElectricMeterModal ({ closeDialog, box, meter, setMeters }: Props ) {
  const [form] = useForm<ElectricMeterModel>();
  const [isLoading, load] = useLoading();
  const isChairman = useAppSelector(currentUserHasGskRoleSelector(GskRole.Chairman));


  const handelDelete = async () => {
    if (!meter) {
      return;
    }
    Modal.confirm({
      title: "Удаление счетчика",
      content: <>Удалить счетчик электроэнергии <b>№{meter.num}</b></>,
      okText: "Удалить",
      cancelText: 'Отмена',
      onOk: async () => {
        if (await ElectricMetersService.deleteMeter(box.gskId, meter.id)) {
          setMeters(prev => prev.filter(p => p.id !== meter.id));
          closeDialog();
        }
      }
    });
  }

  const isDeletable = meter?.id && !meter.isDeleted && isChairman;

  const handleOk = async (data: ElectricMeterModel) => {
    if (meter) {
      const req: EditElectricMeterModel = {
        ...data,
      }
      const resp = await load(ElectricMetersService.editMeter(box.gskId, meter.id, req))
      if (resp) {
        setMeters(prev => [...prev.filter(p => p.id !== meter.id), resp]);
        closeDialog();
      }
    } else {
      const req: NewElectricMeterModel = {
        boxId: box.id,
        num: data.num,
        withNightTarif: data.withNightTarif,
        initDayValue: data.initDayValue,
        initNightValue: data.initNightValue,
      };
      const resp = await load(ElectricMetersService.addMeter(box.gskId, req))
      if (resp) {
        setMeters(prev => [...prev, resp]);
        closeDialog();
      }
    }
  }

  const withNightTarif = Form.useWatch('withNightTarif', form);
  return (<ModalBase
    closeDialog={closeDialog}
    title={(meter ? 'Изменить' : 'Новый') + ' счетчик'}
    leftFooterCell={isDeletable && <DeleteButton onClick={handelDelete}/>}
    okButtonHandler={() => form.submit()} isLoading={isLoading} width={470}
  >
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
      size="small"
      initialValues={{
        id: meter?.id,
        num: meter?.num ?? box.num,
        withNightTarif: meter?.withNightTarif ?? false,
        initDayValue: meter?.initDayValue ?? 0,
        initNightValue: meter?.initNightValue ?? 0,
      }}
      onFinish={handleOk}
    >
      <Form.Item 
        label='Номер'
        name='num'
        rules={[{required: true, message: 'Введите занчение'}]}
        tooltip='Не должен повторяться для разных боксов'
      >
        <Input maxLength={20}/>
      </Form.Item>
      <Form.Item label='Двухтарифный' tooltip='С ночным тарифом' name='withNightTarif' valuePropName="checked">
        <Switch />
      </Form.Item>
      <GroupBox label="Начальные показания">
        <Alert type="warning" style={{marginBottom: 10}} message={<>Указанные значения <b>считаются оплаченными</b> и не будут включены в квитанцию для оплаты. 
          Если переданные показания меньше указанных ниже значений за них не будет создано начисление</>} />
        <RowTwoCol
          left={<Form.Item label='День' name='initDayValue' rules={[NumInputValidator(0, METER_VALUE_LIMIT, true)]} >
                  <FloatInput addonAfter='кВт' />
                </Form.Item>}
          right={withNightTarif && 
                <Form.Item 
                  label='Ночь'
                  name='initNightValue'
                  rules={[NumInputValidator(0, METER_VALUE_LIMIT, withNightTarif)]}
                >
                  <FloatInput addonAfter='кВт' />
                </Form.Item>}
        />
      </GroupBox>
    </Form>
  </ModalBase>)
}