import { toCamalCaseProps } from "../utils/ObjectHelper";
import { CurrentSubscriptionModel } from "./BillingTypes";
import { WithCaptcha } from "./CaptchaTypes";
import { GskRole } from "./GskTypes";

export interface UserBase {
  id: number;
  name: string | null;
  surname: string | null;
  alias: string;
  patronymic: string | null;
  phone: string | null;
  email: string;
}

export const getUserFullName = (user: UserBase) => {
  const result = (`${user.surname ?? ''} ${user.name ?? ''} ${user.patronymic ?? ''}`).replaceAll('  ',' ');
  return result.length === 0 ? 'ФИО скрыто' : result;
}

export const parseUserModelFromAccessToken = (json: string):UserModel => {
  const parsed = JSON.parse(json);
  return toCamalCaseProps(parsed) as UserModel;
}

export interface UserGskData {
  roles: GskRole[];
  currentSubscription?: CurrentSubscriptionModel;
}

export interface UserModel extends UserBase {
  login: string;
  birthDate: Date | null;
  role: UserRole;
  gsks: { [gskId: number]: UserGskData};
}

export interface UserTokens {
  accessToken?: string | undefined;
  refreshToken?: string | undefined;
}

export interface LoginUserModel extends WithCaptcha {
  login: string;
  password: string;
}

export interface VkUserModel {
  userId: string;
  name: string;
  surname: string;
  avatar: string | null;
  email: string;
  sex: number;
  verified: boolean;
  birthday: string | null;
}

export enum UserRole {
  None = 0,
  Admin = 1,
  User = 2,
}

export interface NewUserModel extends UpdateUserProfileModel, WithCaptcha { }
export interface UpdateUserProfileModel extends UserBase {
  password: string | null;
  birthDate: Date | null;
}

export interface VkAuthData {
  clientId: string;
  codeChallenge: string;
  state: string;
}

export interface VkUserModel {
  userId: string;
  name: string;
  phone?: string;
  surname: string;
  avatar: string | null;
  email: string;
  sex: number;
  verified: boolean;
  birthday: string | null;
}

export interface NewVkUserModel extends UpdateUserProfileModel {
  state: string;
}

export enum NotificationEvent
{
  AccrualNew = 1,
  NewsNew = 2,
  NewPaymentGskMember = 3,
  PortalNews = 4,
  ImportantNews = 5,
}

export function GetNotificationEventName(event: NotificationEvent){
  switch (event) {
    case NotificationEvent.AccrualNew:
      return 'Начисление добавлено';
    case NotificationEvent.NewsNew:
      return 'Новость добавлена';
    case NotificationEvent.NewPaymentGskMember:
      return 'Платежи членов ГСК (только для Предстедателя\\Бухгалтера)';
    case NotificationEvent.PortalNews:
      return 'Новости портала (только для Предстедателя\\Администратора)';
    case NotificationEvent.ImportantNews:
      return 'Важные новости портала';
    default:
      const typeguard: never = event;
      throw Error("unexpexted NotificationEvent: " + typeguard);
  }
}

export interface NotificationRule {
  id: number | null;
  userId: number;
  event: NotificationEvent;
  enabled: boolean;
}

export interface EditUserModel {
  profile: UpdateUserProfileModel;
  notificationRules?: NotificationRule[];
}

export interface UsersFilterModel {
  text?: string;
  roles: GskRole[];
  gskId?: number;
  page: number;
  pageSize: number;
}

export interface RestorePasswordRequest extends WithCaptcha {
  login?: string;
  email?: string;
  phone?: string;
}

export interface RestorePasswordConfirmModel {
  key: string;
  newPassword: string;
}