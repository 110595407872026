import { CreateSubscriptionModel, DiscountModel, NewDiscountModel, NewTariffModel, PaymentModel, SubscriptionModel, TariffModel } from "../types/BillingTypes";
import { Fetch, FetchNoResp } from "../utils/fetch";

export const BillingService = {
	addTariff: async (req: NewTariffModel): Promise<TariffModel | undefined> => {
		return await Fetch<TariffModel>('/api/billing/tariffs', "PUT", req);
	},
	editTariff: async (tariffId: number, req: NewTariffModel): Promise<TariffModel | undefined> => {
		return await Fetch<TariffModel>(`/api/billing/tariffs/${tariffId}`, "PATCH", req);
	},
	deleteTariff: async (tariffId: number): Promise<boolean> => {
		return await FetchNoResp(`/api/billing/tariffs/${tariffId}`, "DELETE");
	},
	getTariffs: async (isActiveOnly: boolean): Promise<TariffModel[] | undefined> => {
		return await Fetch<TariffModel[]>(`/api/billing/tariffs`, "GET", { isActiveOnly });
	},
	
	addDiscount: async (req: NewDiscountModel): Promise<DiscountModel | undefined> => {
		return await Fetch<DiscountModel>(`/api/billing/discounts`, "PUT", req);
	},
	editDiscount: async (discountId: number, req: NewDiscountModel): Promise<DiscountModel | undefined> => {
		return await Fetch<DiscountModel>(`/api/billing/discounts/${discountId}`, "PATCH", req);
	},
	deleteDiscount: async (discountId: number): Promise<boolean> => {
		return await FetchNoResp(`/api/billing/discounts/${discountId}`, "DELETE");
	},
	getDiscounts: async (isActiveOnly: boolean): Promise<DiscountModel[] | undefined> => {
		return await Fetch<DiscountModel[]>('/api/billing/discounts', "GET", { isActiveOnly });
	},

	createSubscription: async (data: CreateSubscriptionModel): Promise<SubscriptionModel | undefined> => {
		return await Fetch<SubscriptionModel>(`/api/billing/subscriptions`, "PUT", data);
	},
	createSubscriptionPayment: async (subscriptionId: number): Promise<PaymentModel | undefined> => {
		return await Fetch<PaymentModel>(`/api/billing/subscriptions/${subscriptionId}/payments`, "PUT");
	},
	deleteSubscription: async (subscriptionId: number): Promise<boolean> => {
		return await FetchNoResp(`/api/billing/subscriptions/${subscriptionId}`, "DELETE");
	},
}