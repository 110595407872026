import { QuestionCircleOutlined } from "@ant-design/icons";
import IconButton from "../IconButton/IconButton";
import { useState } from "react";
import { Tour, TourProps } from "antd";

interface Props {
  newGskButtonRef: React.MutableRefObject<null>;
}
export function NewGskTour({newGskButtonRef} : Props) {
  const [openNewGskTour, setOpenNewGskTour] = useState(false);
	const steps: TourProps['steps'] = [
    {
      title: 'Регистрация нового ГСК',
      description: <>Нажмите кнопку <b>Регистрация ГСК</b></>,
      target: () => newGskButtonRef.current,
    },
    {
      title: 'Ввод данных',
			cover:<img src="StaticFiles/Tours/NewGsk/newGskModal.jpg" alt="Регистрация ГСК" />,
      description: <>
				Заполните все поля в появившемся окне и нажмите <b>Ок</b>
			</>,
    },
    {
      title: 'Выбор тарифа',
			cover:<img src="StaticFiles/Tours/NewGsk/tariff.png" alt="Выбор тарифа" />,
      description: <>
				Выберите тариф и нажмите <b>Зарегистрировать</b>
			</>,
    },
    {
      title: 'Вот и всё!',
      description: <>Регистрация ГСК завершена. Вы сразу попадете на страницу своего ГСК. Пока что Вы единственный участник с Ролями
			<b> Председатель</b> и <b>Администратор</b></>
    },
  ];
  return (<>
    <IconButton icon={<QuestionCircleOutlined />} onClick={() => setOpenNewGskTour(true)} />
    <Tour open={openNewGskTour} onClose={() => setOpenNewGskTour(false)} steps={steps} />
  </>)
}