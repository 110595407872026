
type AnyObject = { [index: string]: unknown };

type CamalCaseProps<T extends AnyObject> = {
    [K in keyof T as Lowercase<string & K>]: T[K]
};

export function toCamalCaseProps<T extends AnyObject>(obj: T): CamalCaseProps<T> | T {
  if (Array.isArray(obj)){
    return obj;
  }
  if (obj === null || obj === undefined || Object.entries(obj).length === 0 || typeof obj === 'string') {
    return obj;
  }
  return Object.entries(obj).reduce((a, [key, val]) => {
      a[key[0].toLocaleLowerCase() + key.substring(1)] = toCamalCaseProps(val as any);
      return a;
  }, {} as AnyObject) as CamalCaseProps<T>;
}