import { Alert, Button, Card, DatePicker, Flex, Form, Grid, Input, InputNumber, Select, Space, Spin } from "antd";
import { GetHistoryOperationName, HistoryOperation } from "../../types/GskHistoryTypes";
import { useAppSelector } from "../../store/redux";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import dayjs from 'dayjs';
import { SearchOutlined, SyncOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { Table } from "antd/lib";
import { getEnumValues } from "../../utils/enumUtils";
import Link from "antd/es/typography/Link";
import { EmptyPagedResult, PagedResult } from "../../types/dto";
import RowTwoCol from "../../components/RowTwoCol/RowTwoCol";
import { currentUserIsGlobalAdminSelector } from "../../store/common/app-slice";
import { GetHistoryEntityName, HistoryEntity, HistoryModel, HistoryRequest } from "../../types/HistoryTypes";
import useLoading from "../../utils/hooks/useLoading";
import { HistoryService } from "../../Services/HistoryService";

export default function HistoryPage() {
  const currUserIsGlobalAdmin = useAppSelector(currentUserIsGlobalAdminSelector)
  return (<>
    {!currUserIsGlobalAdmin ?
      <Flex justify="center"><Alert type='error' message='Доступно только Администраторам портала'/></Flex>
      :
      <Page/>
    }
  </>)
}

const dateFormat = 'DD.MM.YYYY HH:mm:ss';

const PAGE_SIZE = 15;

function Page()
{
  const {sm, md, lg} = Grid.useBreakpoint();
  const [currentPage, setCurrentPage] = useState<PagedResult<HistoryModel>>(EmptyPagedResult<HistoryModel>(PAGE_SIZE));
  const [form]= useForm<HistoryRequest>();
  const [isLoading, load] = useLoading();
  const columns: ColumnsType<HistoryModel> = useMemo(() =>{
    const tableLinkStyle: React.CSSProperties = {color: 'black'};
    return [
    {
      title: 'Раздел',
      key: 'entity',
      dataIndex: 'entity',
      width: 120,
      render: (v: HistoryEntity) => <Link 
          style={tableLinkStyle} 
          onClick={() => form.setFieldValue('entityTypes', [v])}
        >
          {GetHistoryEntityName(v)}
        </Link>
    },
    {
      title: 'Операция',
      key: 'operation',
      width: 90,
      dataIndex: 'operation',
      render: (v: HistoryOperation) => <Link 
          style={tableLinkStyle} 
          onClick={() => form.setFieldValue('operations', [v])}
        >
          {GetHistoryOperationName(v)}
        </Link>,
      responsive: ['md']
    },
    {
      title: 'Id',
      key: 'entityId',
      width: 70,
      dataIndex: 'entityId',
      render: (v: number) =>  <Link 
          style={tableLinkStyle} 
          onClick={() => form.setFieldValue('entityId', v)}
        >
          {v}
        </Link>,
      responsive: ['md']
    },
    {
      title: 'Подробно',
      key: 'changes',
      dataIndex: 'changes',
      render: (v: string) => <div style={{width: lg ? 680 : md ? 300 : sm ? 200 : 100, fontSize: lg ? '1em' : md ? '.9em' : sm ? '.8em' : '.7em',  overflowX: 'auto', whiteSpace: 'pre'}}>{v}</div>,
    },
    {
      title: 'Пользователь',
      key: 'createdBy',
      width: 100,
      dataIndex: 'createdBy',
      render: (v: string) => <Link 
          style={tableLinkStyle} 
          onClick={() => form.setFieldValue('createdBy', v)}
        >
          {v}
        </Link>
    },
    {
      title: 'Дата',
      key: 'created',
      width: 100,
      dataIndex: 'created',
      render: (v: string) => <Link 
          style={tableLinkStyle} 
          onClick={() => {form.setFieldValue('periodStart', dayjs(v));form.setFieldValue('periodEnd', dayjs(v)); }}
        >
          {dayjs(v).format(dateFormat) }
        </Link>
    },
  ]
  }, [form, lg, md, sm])
  
  const applyFilter = async (data: HistoryRequest) => {
    data.pageSize = PAGE_SIZE;
    const resp = await load(HistoryService.getPage(data));
    if (resp) {
      setCurrentPage(resp);
    }
  }

  const onPageChange = (page: number, pageSize: number) => {
    form.setFieldValue('page', page);
    form.submit();
  }

  useEffect(() => { form.submit() }, [form])

  const entityOptions = useMemo(() => {
    return getEnumValues(HistoryEntity).map(val => ({value: val, label: GetHistoryEntityName(val)}))
  }, []);

  const operationOptions = useMemo(() => {
    return getEnumValues(HistoryOperation).map(val => ({value: val, label: GetHistoryOperationName(val)}))
  }, []);

  const formItemStyle: React.CSSProperties={width: 300, paddingBottom: 10};

  const handleFindClick =() => {
    form.setFieldValue('page', 1);
    form.submit()
  }

  return (<Spin spinning={isLoading}>
    <Space direction="vertical" style={{width: '100%'}}>
      <Card size="small">
        <RowTwoCol
          left={
            <Form
              form={form}
              size="small"
              layout='inline'
              initialValues={{
                entityTypes: getEnumValues(HistoryEntity),
                operations: getEnumValues(HistoryOperation),
                entityId: null,
                createdBy: null,
                periodStart: null,
                periodEnd: null,
                page: 1,
              }}
              onFinish={applyFilter}
            >
              <Form.Item noStyle label='page' name='page' rules={[{required: true}]}>
                <Input type="hidden" />
              </Form.Item>
              <Form.Item label='Раздел' name='entityTypes'style={formItemStyle} labelCol={{span: 10}}>
                <Select<HistoryEntity> 
                  mode={"multiple"}
                  options={entityOptions} 
                  maxTagCount={1}
                />
              </Form.Item>
              <Form.Item label='Операция' name='operations'style={formItemStyle} labelCol={{span: 10}}>
                <Select<HistoryOperation> 
                  mode={"multiple"}
                  options={operationOptions} 
                  maxTagCount={1}
                />
              </Form.Item>
              <Form.Item label='ID' name='entityId' style={formItemStyle} labelCol={{span: 10}}>
                <InputNumber/>
              </Form.Item>
              <Form.Item label='Пользователь' name='createdBy' style={formItemStyle} labelCol={{span: 10}}>
                <Input/>
              </Form.Item>
              <Form.Item label='Дата начала' name='periodStart' style={formItemStyle} labelCol={{span: 10}}>
                <DatePicker showTime format={dateFormat} placeholder=''  />
              </Form.Item>
              <Form.Item label='Дата окончания' name='periodEnd' style={formItemStyle} labelCol={{span: 10}}>
                <DatePicker showTime format={dateFormat} placeholder='' />
              </Form.Item>
            </Form>
          }
          right={
            <Flex style={{height: 'calc(100% - 12px)', minHeight: 65}} align="end">
              <Space direction="vertical">
                <Button icon={<SyncOutlined />} size="small" onClick={() => form.resetFields()} style={{width: '100%'}}>Сбросить</Button>
                <Button icon={<SearchOutlined />} onSubmit={handleFindClick} style={{width: '100%'}} onClick={handleFindClick}>Поиск</Button>
              </Space>
            </Flex>
          }
          rightSize={110}
        />
      </Card>
      <Card>
        <Table
          size="small"
          columns={columns}
          dataSource={currentPage.items}
          rowKey='id'
          pagination={{
            pageSize: PAGE_SIZE,
            current: currentPage.page,
            onChange: onPageChange,
            total: currentPage.totalCount,
          }}
        />
      </Card>
    </Space>
  </Spin>)
}