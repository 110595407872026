export interface Point {
	x: number;
	y: number;
}

export interface ScalseState {
	stageScale: number;
	stageX: number;
	stageY: number;
}

export interface MapPolygonLabel {
	text: string;
	fontSize?: number | null;
	color?: string;
	offsetX?: number;
	offsetY?: number;
}

export interface MapSettings {
  fontSize: number;
  fontColor: string | null;
  fillColor: string | null;
  lineColor: string | null;
  activePolygonLineColor: string | null;
  activePolygonFillColor: string | null;
}

export interface MapPolygon {
	id: number;
	boxId: number;
	points: Point[];
	isFinished: boolean;
	label?: MapPolygonLabel;
	lineColor: string | null;
	fillColor: string | null;
}

export class MapDefaults {
	static mapFontSize: number = 20;
	static mapFontColor: string = 'black';
	static mapLineColor: string = '#8E8B8B';
	static mapFillColor: string = 'rgb(193, 193, 193)'
	static activePolygonLineColor = 'red';
	static activePolygonFillColor = 'rgb(0, 128, 0, 0.5)';
}