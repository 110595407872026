import { useCallback, useMemo, useState } from "react";
import { Card, Flex, Space, Spin, Table } from "antd";
import { useEffectOnce } from "../../utils/hooks/useEffectOnce";
import useLoading from "../../utils/hooks/useLoading";
import { BillingService } from "../../Services/BillingService";
import { ColumnType } from "antd/es/table";
import dayjs from 'dayjs';
import IconButton from "../../components/IconButton/IconButton";
import { EditOutlined, PlusCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { useDialogWithParameter } from "../../utils/useDialog";
import { DiscountModel } from "../../types/BillingTypes";
import DiscountModal from "../../components/Billing/DiscountModal";

export default function DiscountsPage() {
  const [discounts, setDiscounts] = useState<DiscountModel[]>([]);
  const [isLoading, load] = useLoading();

  const fetchDiscounts = useCallback(async () => {
    const resp = await load(BillingService.getDiscounts(false));
    if (resp) {
      setDiscounts(resp);
    }
  }, [load]);
  
  useEffectOnce(() => { 
    fetchDiscounts();
  });

  const [discountModal, openDiscountModal] = useDialogWithParameter<DiscountModel | null>(
    (param, closeDialog) => <DiscountModal discount={param} closeDialog={closeDialog} setDiscounts={setDiscounts} />
  );

  const columns: ColumnType<DiscountModel>[]  = useMemo(() => {
    const currentDate = dayjs(new Date());
    return [
      {
        title: 'Скидка, %',
        dataIndex: 'percent',
        key: 'percent',
        render: (_, row) => 
          <span style={
            {textDecoration: (dayjs(row.dateStart) < currentDate && (!row.dateEnd || dayjs(row.dateEnd) > currentDate)) 
              ? 'unset' : 'line-through'}}>
            {row.percent}
          </span>
      },
      {
        title: 'Дата начала',
        dataIndex: 'dateStart',
        key: 'dateStart',
        render: v => dayjs(v).format("DD.MM.YYYY"),
      },
      {
        title: 'Дата окончания',
        dataIndex: 'dateEnd',
        key: 'dateEnd',
        render: v => v ? dayjs(v).format("DD.MM.YYYY") : '-',
      },
      {
        title: <Flex justify="flex-end">
          <Space>
            <IconButton icon={<PlusCircleOutlined />} onClick={() => openDiscountModal(null)} disabled={isLoading}/>
            <IconButton icon={<SyncOutlined />} onClick={fetchDiscounts} disabled={isLoading}/>
          </Space>
        </Flex>,
        key: 'actions',
        render: (_, row) => <Flex justify="flex-end">
          <Space>
            <IconButton icon={<EditOutlined />} onClick={() => openDiscountModal(row)} />
          </Space>
        </Flex>
      }
    ]
  }, [fetchDiscounts, isLoading, openDiscountModal])

  return (
    <Spin spinning={isLoading} style={{width: '100%'}}>
      <Card>
        <Table
          columns={columns}
          dataSource={discounts}
          rowKey={'id'}
          pagination={false}
        />
      </Card>
      {discountModal}
    </Spin>
  )
}
