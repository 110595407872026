import { Checkbox, DatePicker, Form} from 'antd'
import ModalBase from '../../ModalBase/ModalBase';
import { GskChargeService } from '../../../Services/GskChargeService';
import { AccrualShortModel, RenderGskChargesRequest } from '../../../types/GskChargeTypes';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs'
import { AddYears } from '../../../utils/DateHelper';
import useLoading from '../../../utils/hooks/useLoading';
import { useState } from 'react';
import { useEffectOnce } from '../../../utils/hooks/useEffectOnce';
import MultiplySelect from '../../Controls/MultiplySelect';
import { BoxShortModel } from '../../../types/GskTypes';

interface OwnProps {
  gskId: number;
  boxes?: BoxShortModel[];
  onFinished: () => void;
  closeDialog: CallableFunction;
}

const RenderGskChargesModal = ({closeDialog, gskId, boxes, onFinished}: OwnProps) => {
  const [isLoading, load] = useLoading();
  const [allAccruals, setAllAccruals] = useState<AccrualShortModel[]>([]);

  const [form] = useForm<RenderGskChargesRequest>();
  const startDate = Form.useWatch('periodStart', form);

  const handleOk = async (data: RenderGskChargesRequest) => {
    data.boxIds = boxes?.map(b => b.id);
    
    const result = await load(GskChargeService.renderCharges(gskId, data));
    if (result) {
      closeDialog();
      onFinished();
    }
  }


  useEffectOnce(() => {
    const fetch = async () => 
    {
      const resp = await load(GskChargeService.getAllaccrualsList(gskId)) ?? [];
      await setAllAccruals(resp);
      form.setFieldValue('accrualIds', resp.map(a => a.id))
    }

    fetch();
  })

  return (
    <ModalBase
      closeDialog={closeDialog}
      title={"Создать начисления" + (boxes && ` (Бокс${boxes!.length > 1 ? 'ы' : ''}: ${boxes?.map(b => '№'+b.num).join(', ')})`)}
      okButtonHandler={form.submit}
      isLoading={isLoading}
      width={450}
    >
      <Form
        form={form}
				labelCol={{span: 8}}
				wrapperCol={{span: 16}}
				size='small'
        initialValues={{
          periodStart: dayjs(new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1)),
          periodEnd: dayjs(new Date()),
          withEmailNotify: true,
        }}
        onFinish={handleOk}
      >
        <Form.Item name='accrualIds' label='Начисления' rules={[{required: true, message: 'Выберите хотя бы одно начисление'}]}>
          <MultiplySelect options={allAccruals} getLabel={a => a.name} getValue={a => a.id}/>
        </Form.Item>
        <Form.Item name='periodStart' label='Начало периода' rules={[{required: true, message: 'Введите значение'}]}>
          <DatePicker format="DD.MM.YYYY" minDate={dayjs(AddYears(new Date(), -2))}/>
        </Form.Item>
        <Form.Item name='periodEnd' label='Конец периода' rules={[{required: true, message: 'Введите значение'}]}>
          <DatePicker format="DD.MM.YYYY" minDate={dayjs(startDate)} maxDate={dayjs(AddYears(new Date(), 2))} />
        </Form.Item>
        <Form.Item name='withEmailNotify' label='С рассылкой' tooltip="Отправить на почту платильщикам уведомления о новом начислении" valuePropName='checked'>
          <Checkbox />
        </Form.Item>
      </Form>
    </ModalBase>
  );
}

export default RenderGskChargesModal;