import { setCommissionPercent } from "../store/common/gsk-slice";
import { AppThunkAction } from "../store/configureStore";
import { GskBillingSettingsModel } from "../types/GskBillingTypes";
import { GskOperationType } from "../types/GskTypes";
import { Fetch } from "../utils/fetch";
import OperationsService from "./OperationsService";

export const GskBillingService = {
	getBillingSettings: async (gskId: number): Promise<GskBillingSettingsModel | undefined> => {
		return await Fetch<GskBillingSettingsModel | undefined>(`/api/gsks/${gskId}/billing/Settings`, "GET");
	},
	saveBillingSettings: async (gskId: number, req: GskBillingSettingsModel): Promise<GskBillingSettingsModel | undefined> => {
		return await Fetch<GskBillingSettingsModel>(`/api/gsks/${gskId}/billing/Settings`, "PATCH", req);
	},
	loadCommissionPercent: (gskId: number): AppThunkAction => async (dispatch, getState) => {
		if (getState().gskSlise.commissionPercent !== undefined) {
			return;
		}

		await OperationsService.run(dispatch, getState, GskOperationType.LoadCommissionPercent, null, false, async () => {
			const resp = await Fetch<number | undefined>(`/api/gsks/${gskId}/billing/commissionPercent`, "GET");
			if (resp !== undefined) {
				dispatch(setCommissionPercent(resp));
			}
		})
	},
}