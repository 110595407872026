import { Dispatch } from "@reduxjs/toolkit";
import { finishOperation, isOperationRunningSelector, startOperation } from "../store/common/operations-slice";
import { RootState } from "../store/configureStore";
import { GskOperationType } from "../types/GskTypes";

export interface GetState {
	(): RootState;
}

export default class OperationsService {
	public static async run(dispatch: Dispatch, getState: GetState, type: OperationType, objectId: any, force: boolean, fun: () => Promise<any>) {
		let success = false;

		const isRunnging = isOperationRunningSelector(type, objectId)(getState());
		if (isRunnging) {
			return true;
		}

		dispatch(startOperation({ type, objectId }));
		try {
			await fun();
			success = true;
		} finally {
			dispatch(finishOperation({ type, objectId, success }));
		}
		return success;
	}
}

export type OperationType = 
	GskOperationType
;
