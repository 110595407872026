import ModalBase from "../ModalBase/ModalBase";
import {  Checkbox, Form, Input, InputNumber, Modal, Select } from "antd";
import { GetTariffTypeName, TariffModel, TariffType } from "../../types/BillingTypes";
import useLoading from "../../utils/hooks/useLoading";
import { BillingService } from "../../Services/BillingService";
import { useMemo } from "react";
import { getEnumValues } from "../../utils/enumUtils";
import FloatInput from "../Controls/FloatInput";
import DeleteButton from "../Controls/DeleteButton";

interface OwnProps {
  tariff: TariffModel | null;
  setTariffs: React.Dispatch<React.SetStateAction<TariffModel[]>>;
  closeDialog: CallableFunction;
}

export default function TariffModal({ closeDialog, setTariffs, tariff }: OwnProps) {
  const [isLoading, load] = useLoading();

  const isNew = !tariff;
  const [form] = Form.useForm<TariffModel>();
  const type = Form.useWatch('type', form);
  const handleSave = async (data: TariffModel) => {
    if (isNew) {
      const resp = await load(BillingService.addTariff(data));
      if (resp) {
        setTariffs(prev => [resp, ...prev]);
        closeDialog();
      }
    } else {
      const resp = await load(BillingService.editTariff(tariff.id, data));
      if (resp) {
        setTariffs(prev => {
          const r = [...prev];
          r.splice(prev.findIndex(p => p.id === resp.id), 1, resp);
          return r;
        });
        closeDialog();
      }
    }
  }

  const typeOptions = useMemo(() => {
    const keys: TariffType[] = getEnumValues(TariffType);
    return keys.map(k =>
      ({
        label: GetTariffTypeName(k),
        value: k,
      })
    );
  }, []);

  const handleDelete = () => {
    if (!tariff?.id) {
      return;
    }
    Modal.confirm({
      title: "Удаление",
      content: "Точно удалить?",
      okText: "Удалить",
      cancelText: 'Отмена',
      okButtonProps: { danger: true },
      onOk: async () => {
        const resp = await load(BillingService.deleteTariff(tariff?.id));
        if (resp) {
          closeDialog();
          setTariffs(prev => [...prev.filter(p => p.id !== tariff.id)]);
        }
      }
    });
  }

  return (
    <ModalBase 
      closeDialog={closeDialog}
      title={(isNew ? 'Новый' : 'Изменить') +' тариф'}
      okButtonHandler={() => form.submit()}
      isLoading={isLoading} width={500}
      leftFooterCell={!isNew && <DeleteButton onClick={handleDelete}/>}
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        layout={'horizontal'}
        form={form}
        initialValues={{
          id: tariff?.id ?? 0,
          name: tariff?.name ?? '',
          description: tariff?.description,
          type: tariff?.type ?? TariffType.Trial,
          boxesLimit: tariff?.boxesLimit,
          price: tariff?.price ?? 0,
          isActive: tariff?.isActive ?? false,
        }}
        onFinish={handleSave}
      >
        <Form.Item noStyle label='Id' name='id'>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item 
          label='Название'
          name='name'
          tooltip={''}
          rules={[{required: true, message: 'Введите значение'}]}
        >
          <Input />
        </Form.Item>
        <Form.Item label='Описание' name='description' >
          <Input />
        </Form.Item>
        <Form.Item label='Тип' name='type'rules={[{required: true}]}>
          <Select options={typeOptions} />
        </Form.Item>
        {[TariffType.BoxesCount, TariffType.Trial].includes(type) && 
        <Form.Item label='Лимит боксов' name='boxesLimit' >
          <InputNumber />
        </Form.Item>}
        <Form.Item label='Цена' name='price' rules={[{required: true}]}>
          <FloatInput addonAfter='₽'/>
        </Form.Item>
        <Form.Item label='Активен' name='isActive' rules={[{required: true}]} valuePropName="checked">
          <Checkbox />
        </Form.Item>
      </Form>
    </ModalBase>
  );
}