import { useLocation, useNavigate, useParams } from "react-router-dom";
import Link from 'antd/es/typography/Link';
import { useEffect, useState } from "react";
import { GskChairmanInviteModel } from "../../types/GskTypes";
import { Alert, Button, Card, Flex, Space, Spin } from "antd";
import { ShowError, ShowSuccess } from "../../components/Notifications/Notifications";
import { UserModel } from "../../types/UserTypes";
import useLoading from "../../utils/hooks/useLoading";
import { useCurrentUser } from "../../store/common/app-slice";
import { GskService } from "../../Services/GskService";

interface Props {
  openLoginModal: (message?:string, redirectUrl?: string) => void;
  openProfileModal: (user?: UserModel) => void;
}
export default function ChairmanInvitePage ({ openLoginModal, openProfileModal }: Props) {
	const params = useParams();
	const key = params.guid;
  const currentUser = useCurrentUser();
  const [isLoading, load] = useLoading();
  const [invite, setInvite] = useState<GskChairmanInviteModel | undefined>(undefined);
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    if (!currentUser || invite || !key) {
      return;
    }

    const getInvite = async () => {
      const resp = await load(GskService.getChairmanInviteByKey(key));
      setInvite(resp);
    }

    getInvite();
  }, [currentUser, invite, key, load]);

  const handleAcceptInviteClick = async (accept: boolean) => {
    if (!key || !invite) {
      return;
    }

    const gskId = await load(GskService.acceptChairmanInvite(key, accept));
    if (!accept) {
      ShowSuccess("Приглашение отклонено", "");
      navigate('/');
      return;
    }

    if (accept) {
      if (!gskId) {
        ShowError("Ошибка при принятии приглашения", "gskId пустой");
        return;
      }
      ShowSuccess("Приглашение принято", "Теперь Вы председатель " + invite.gskName);
      navigate('/Gsk/' + gskId);
    }
  }

	return (<Flex justify="center" align="center" style={{paddingTop: 20}}>
    { currentUser ?
    <Spin spinning={isLoading}>
      <Card style={{ width: 400 }}>
        {invite ? 
        <>
          {invite.isExpired && <Alert type="warning" message="Приглашение просрочено. Запросите приглашение еще раз" />}
          <p>{invite.createdBy} предлагает Вам стать председателем <b>{invite.gskName}</b></p>
          <Flex justify="flex-end">
            <Space>
              <Button type="primary" disabled={invite.isExpired} onClick={() => handleAcceptInviteClick(true)}>Принять</Button>
              <Button disabled={invite.isExpired} onClick={() => handleAcceptInviteClick(false)}>Отклонить</Button>
            </Space>
          </Flex>
        </>:
        <>Приглашение не найдено</>}
      </Card>
    </Spin>
    :
    <Card style={{ width: 400 }}>
      <Alert type="info"
        message={<>
          <p>Чтобы принять приглашение необходимо <Link onClick={() => openLoginModal(undefined, location.pathname)}>войти</Link> под своей учетной записью.</p>
          <p>Если у Вас нет учетной записи пройдите <Link onClick={() => openProfileModal(undefined)}>регистрацию</Link> и заново перейдите по ссылке-приглашению</p>
        </>}
      />
    </Card>
  }
  </Flex>)
}