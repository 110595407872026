import ModalBase from '../ModalBase/ModalBase';
import { Button, ColorPicker, Flex, Form, InputNumber, Switch } from "antd";
import { MapDefaults, MapPolygon } from './GskMapTypes';
import { useState } from 'react';
import { getColorValue } from '../../utils/colorUtils';
import { ColorFactory } from 'antd/es/color-picker/color';

interface FormData {
  fillColor: ColorFactory;
  lineColor: ColorFactory;
  fontSize: number;
  labelColor: ColorFactory;
}

interface OwnProps {
  closeDialog: CallableFunction;
  polygon: MapPolygon;
  isLoading: boolean;
  savePolygon: (v: MapPolygon) => void;
}

export default function EditPolygonModal ({ closeDialog, isLoading, polygon, savePolygon }: OwnProps) {
  const [defaults, setDefaults] = useState(!polygon.fillColor && !polygon.lineColor && !polygon.label?.color && !polygon.label?.fontSize);

  const [form] = Form.useForm<FormData>();

  const handleSave = async (data: FormData) => {
    if (!polygon.label) {
      return;
    }
    const req: MapPolygon = {
      ...polygon,
      label: {...polygon.label,
        fontSize: defaults ? null : data.fontSize,
        color: defaults ? undefined : getColorValue(data.labelColor)
      },
      lineColor: defaults ? null : getColorValue(data.lineColor),
      fillColor: defaults ? null : getColorValue(data.fillColor),
    }
    await savePolygon(req);
    closeDialog();
  }

  const handleDefaults = () => {
    form.setFields([
      { name: 'fontSize', value: MapDefaults.mapFontSize },
      { name: 'fillColor', value: MapDefaults.mapFillColor },
      { name: 'lineColor', value: MapDefaults.mapLineColor },
      { name: 'labelColor', value: MapDefaults.mapFontColor },
    ]);
  }

  return (
    <ModalBase closeDialog={closeDialog} title="Полигон" okButtonHandler={() => form.submit()} isLoading={isLoading} width={300}>
      <Form
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        layout={'horizontal'}
        form={form}
        initialValues={{
          fontSize: polygon.label?.fontSize ?? MapDefaults.mapFontSize,
          fillColor: polygon.fillColor ?? MapDefaults.mapFillColor,
          lineColor: polygon.lineColor ?? MapDefaults.mapLineColor,
          labelColor: polygon.label?.color ?? MapDefaults.mapFontColor,
        }}
        onFinish={handleSave}
      >
        <Form.Item label='По умолчанию'>
          <Switch checked={defaults} onChange={setDefaults}/>
        </Form.Item>
        {!defaults && <>
          <Form.Item label='Шрифт' name='fontSize' rules={[{required: true, message: 'Введите значение'}]}>
            <InputNumber/>
          </Form.Item>
          <Form.Item label='Цвет шрифта' name='labelColor'>
            <ColorPicker />
          </Form.Item>
          <Form.Item label='Цвет линий' name='lineColor'>
            <ColorPicker />
          </Form.Item>
          <Form.Item label='Заполнение' name='fillColor'>
            <ColorPicker />
          </Form.Item>
          <Flex justify='flex-end'>
            <Button onClick={handleDefaults}>По умлочанию</Button>
          </Flex>
        </>}
      </Form>
    </ModalBase>
  );
}