import { Outlet, useLocation } from 'react-router-dom'
import 'react-notifications-component/dist/theme.css'
import { Content } from 'antd/es/layout/layout';
import { Button, Flex, Grid, Layout } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import RowTwoCol from '../../components/RowTwoCol/RowTwoCol';
import HelpTree from './HelpTree';
import { BackwardOutlined, UnorderedListOutlined } from '@ant-design/icons';

export default function HelpLayoutPage () {
	const { sm } = Grid.useBreakpoint();
	const [showTree, setShowTree] = useState<boolean | undefined>(undefined);
	
	let location = useLocation();
  const current = useMemo(() => {
    const parts = location.pathname.split('/').filter(p => p !== '');
    if (parts.length > 0) {
      return parts.reverse()[0].toLowerCase();
    }
    return '';

  }, [location]);

	useEffect(() => {
		if (current === 'help') {
			setShowTree(true);
		}
	}, [current]);

	const hideOutlet = !sm && showTree;

	const handleMenuItemClick = () => {
		setShowTree(false);
	}

	return (
		<Content style={{paddingTop:10}}>
			<Flex justify='center'> 
				<Layout style={{maxWidth: 1200}}>
					{!sm && current !== 'help' && <>
						{showTree ?
							<Button icon={<BackwardOutlined />} onClick={() => setShowTree(false)}>К статье</Button>
							:
							<Button icon={<UnorderedListOutlined />} onClick={() => setShowTree(true)}>Содержание</Button>}
					</>}
					<RowTwoCol
						left={(sm || showTree) && <HelpTree onClick={handleMenuItemClick}/>}
						right={!hideOutlet && <Outlet />}
						leftSize={sm ? 200 : showTree ? undefined : 0}
						rightSize={showTree ? 0 : undefined}
					/>
				</Layout>
			</Flex>
		</Content>
	)
}