export enum TariffType {
  Trial = 1,
  BoxesCount = 2,
  BoxesUnlimited = 3
}

export enum PaymentState
{
  Pending = 0,
  WaitingForCapture = 1,
  Succeeded = 2,
  Canceled = 3,
}

export function getPaymentStateName (value: PaymentState): string {
  switch (value) {
    case PaymentState.Canceled:
      return 'Платеж отменен';
    case PaymentState.Succeeded:
      return 'Оплачено';
    case PaymentState.Pending:
      return 'Ожидает оплаты';
    case PaymentState.WaitingForCapture:
      return 'Ожидает подтверждения';
  }
}

export interface CurrentSubscriptionModel {
  id: number;
  isActive: boolean;
  boxesLimit?: number;
  endDate?: Date;
  paymentState?: PaymentState;
  isExtended: boolean;
}

export interface PaymentModel {
  description: string;
  confirmationUrl: string;
  price: number;
}

export function GetTariffTypeName(type: TariffType) {
  switch (type) {
    case TariffType.BoxesCount:
      return 'Ограничение по боксам';
    case TariffType.BoxesUnlimited:
      return 'Без ограничения по боксам';
    case TariffType.Trial:
      return 'Пробный период';
    default:
      const t: never = type;
      throw Error('unexpexted type: ' + t);
  }
}
export interface CreateSubscriptionModel {
  gskId: number;
  tariffId: number;
  discountId?: number;
}

export interface SubscriptionModel {
  id: number;
  isActive: boolean;
  startDate?: Date;
  endDate?: Date;
  paymentState?: PaymentState | null;
  tariffId: number;
  discountId?: number;
  gskId: number;
  description: string;
  discount?: DiscountModel;
  tariff: TariffModel;
}

export interface GskSubscriptionsModel {
  old: SubscriptionModel[];
  current?: SubscriptionModel;
  next?: SubscriptionModel;
}

export interface NewTariffModel {
  name: string;
  description?: string;
  type: TariffType;
  boxesLimit: number | null;
  price: number;
  isActive: boolean;
}

export interface TariffModel extends NewTariffModel {
  id: number;
  created: Date;
}

export interface NewDiscountModel {
  percent: number;
  dateStart: Date;
  dateEnd?: Date;
}

export interface DiscountModel extends NewDiscountModel {
  id: number;
}