import { Form, Input } from "antd";
import { useState } from "react";

interface Props {
  required: boolean;
  path?: string[] | string;
  label?: string;
}

export default function PasswordInput({ required, path = 'password', label = 'Пароль' } : Props) {
  const checkPassword = (_: any, password: string | null ) => {
    if (password === null || password === '') {
      return Promise.resolve();
    }

    let errors: string[] = [];
    if (password.length < 6) {
      errors.push("Быть длиннее 5 символов");
    }
    if (password.length > 20) {
      errors.push("Быть короче 20 символов");
    }
    if (!password.match("\\d")) {
      errors.push("Содержать хотя бы одну цифру");
    }
    if (!password.match("[a-zа-я]")) {
      errors.push("Содержать хотя бы одну сточную букву");
    }
    if (!password.match("[A-ZА-Я]")) {
      errors.push("Содержать хотя бы одну заглавную букву");
    }

    if (errors.length === 0) {
      return Promise.resolve();
    }
    return Promise.reject(<><b>Пароль должен:</b><br/>{errors.map(e => <span key={e}>{e}<br/></span>)}</>);
  };

  const [readonly, setReadonly] = useState(true);

  return (<Form.Item 
    label={label}
    name={path}
    tooltip={
      <>Пароль должен содержать:<br/><br/>
      - от 6 до 20 символов<br/>
      - хотя бы одну цифру<br/> 
      - хотя бы одну сточную букву<br/> 
      - хотя бы одну заглавную букву<br/> 
      </>
    }
    rules={[{required, validator: checkPassword}]}
  >
    <Input.Password placeholder="******" autoComplete='new-password' readOnly={readonly} onFocus={e => setReadonly(false) } onBlur={e => setReadonly(true)} />
  </Form.Item>
  )
}