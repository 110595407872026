import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import { Editor, EditorContent, useEditor } from '@tiptap/react'
import FontSize from 'tiptap-extension-font-size'
import StarterKit from '@tiptap/starter-kit'
import { useCallback, useEffect } from 'react'
import { AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined, BgColorsOutlined, BoldOutlined, FileImageOutlined, FontColorsOutlined, ItalicOutlined, LinkOutlined, OrderedListOutlined, RedoOutlined, StrikethroughOutlined, UnderlineOutlined, UndoOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { JustifyIcon, QuoteIcon, UnlinkIcon } from '../icons'
import TextAlign from '@tiptap/extension-text-align'
import './TipTap.scss'
import Link from '@tiptap/extension-link'
import prompt from '../AntdExtensions/prompt'
import Highlight from '@tiptap/extension-highlight'
import { Upload } from 'antd'
import Underline from '@tiptap/extension-underline';
import Image from '@tiptap/extension-image';
import { FetchBlob } from '../../utils/fetch';
import { ReadFileAsString } from '../../utils/FileHelper'


interface MenuBarProps {
  editable: boolean;
  editor: Editor | null;
}
const MenuBar = ({editable, editor}: MenuBarProps) => {
  const insertImage = useCallback((file: File | undefined) => {
    if (!file) {
      return;
    }
    ReadFileAsString(file).then(base64 => 
    {
      if (base64) {
        editor?.chain().focus().setImage({ src: base64 }).run()
      }
    });
  }, [editor]);

  const setLink = useCallback(async () => {
    const previousUrl = editor?.getAttributes('link').href
    const url = await prompt({
      title: <>Введите ссылку?</>,
      placeholder: 'http://МойГСК.рф',
      value: previousUrl ?? 'http://',
      rules: [
        {
          required: true,
          message: "Введите ссылку"
        }
      ],
    });

    if (!url) {
      return;
    }

    if (url === '') {
      editor?.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }

    // update link
    editor?.chain().focus().extendMarkRange('link').setLink({ href: url })
      .run()
  }, [editor])

  if (!editor) {
    return null;
  }
  if (!editable) {
    return null;
  }

  return (
    <div className='toolbar'>
      <select onChange={v => editor.chain().focus().setFontSize(v.target.value).run()}>
        <option value='22px'>Большой</option>
        <option value='18px'>Больше среднего</option>
        <option value='14px' defaultChecked>Средний</option>
        <option value='10px'>Мелкий</option>
      </select>
      <button
        type='button'
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={editor.isActive('bold') ? 'is-active' : ''}
      >
        <BoldOutlined width={'18px'} height={'18px'} />
      </button>
      <button
        type='button'
        className={editor.isActive("underline") ? 'is-active' : ''}
        onClick={() => editor.chain().focus().toggleUnderline().run()}
      >
        <UnderlineOutlined width={'18px'} height={'18px'} />
      </button>
      <button
        type='button'
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={editor.isActive('italic') ? 'is-active' : ''}
      >
        <ItalicOutlined width={'18px'} height={'18px'} />
      </button>
      <button
        type='button'
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        className={editor.isActive('strike') ? 'is-active' : ''}
      >
        <StrikethroughOutlined width={'18px'} height={'18px'}/>
      </button>
      <button
        type='button'
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
      >
        <UnorderedListOutlined width={'18px'} height={'18px'} />
      </button>
      <button
        type='button'
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
      >
        <OrderedListOutlined width={'18px'} height={'18px'} />
      </button>
      <button
        type='button'
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={editor.isActive('blockquote') ? 'is-active' : ''}
      >
        <QuoteIcon width={'18px'} height={'18px'}/>
      </button>

      <button
        type='button'
        onClick={() => editor.chain().focus().setTextAlign('left').run()}
        disabled={editor.isActive({ textAlign: 'left' })}
        className={editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''}
      >
        <AlignLeftOutlined width={'18px'} height={'18px'} />
      </button>
      <button
        type='button'
        onClick={() => editor.chain().focus().setTextAlign('center').run()}
        disabled={editor.isActive({ textAlign: 'center' })}
        className={editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''}
      >
        <AlignCenterOutlined width={'18px'} height={'18px'} />
      </button>
      <button
        type='button'
        onClick={() => editor.chain().focus().setTextAlign('right').run()}
        disabled={editor.isActive({ textAlign: 'right' })}
        className={editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''}
      >
        <AlignRightOutlined width={'18px'} height={'18px'} />
      </button>
      <button
        type='button'
        onClick={() => editor.chain().focus().setTextAlign('justify').run()}
        disabled={editor.isActive({ textAlign: 'justify' })}
        className={editor.isActive({ textAlign: 'justify' }) ? 'is-active' : ''}
      >
        <JustifyIcon width={'18px'} height={'18px'} />
      </button>

      
      <button
        type='button'
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().chain().focus().undo().run()}
      >
        <UndoOutlined width={'18px'} height={'18px'} />
      </button>
      <button
        type='button'
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().chain().focus().redo().run()}
      >
        <RedoOutlined width={'18px'} height={'18px'} />
      </button>
      <button 
        type='button'
        onClick={() => !editor.isActive('link') ? setLink() : editor.chain().focus().unsetLink().run()}
        className={editor.isActive('link') ? 'is-active' : ''}
      >
        {!editor.isActive('link') ? <LinkOutlined width={'18px'} height={'18px'}/> : <UnlinkIcon width={'18px'} height={'18px'}/>}
      </button>
      <label>
        <input
          style={{display: 'none'}}
          type="color"
          onChange={e => editor.chain().focus().setColor(e.target.value).run()}
          value={editor.getAttributes('textStyle').color}
        />
        <FontColorsOutlined style={{color:editor.getAttributes('textStyle').color}} width={'18px'} height={'18px'} />
      </label>
      <label>
        <input
          style={{display: 'none'}}
          type="color"
          onChange={e => { editor.chain().focus().toggleHighlight({ color: e.target.value }).run(); console.log(editor.getAttributes('highlight'))}}
          value={editor.getAttributes('highlight').color}
          data-testid="setColor"
        />
        <BgColorsOutlined style={{color: editor.getAttributes('highlight').color}} width={'18px'} height={'18px'} />
      </label>
      <Upload
        accept="image/*"
        multiple={false}
        beforeUpload={file => {
          insertImage(file)
          return false; // Prevent upload
        }}
        maxCount={1}
        showUploadList={false}
      >
        <FileImageOutlined width={'18px'} height={'18px'} />
      </Upload>
    </div>
  )
}

const extensions = (editable: boolean) => [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  // TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
  Link.configure({
    openOnClick: !editable,
  }),
  Highlight.configure({
    multicolor: true
  }),
  Image.configure({
    allowBase64: true,
    inline: true,
  })
  .extend(
    {
      addAttributes() {
        return {
          src: {
            parseHTML: (a: HTMLImageElement) => displayProtectedImage(a)
          },
          accessKey: {
            default: null,
          },
          style: {
            default: null,
            parseHTML: element => {
              const width = element.getAttribute('max-width');
              return width
                ? `max-width: min(100%, ${width}px); height: auto;`
                : `${element.style.cssText}`;
            },
          },
          alt: {
            default: null,
          },
          title: {
            default: null,
          },
          loading: {
            default: null,
          },
          srcset: {
            default: null,
          },
          sizes: {
            default: null,
          },
          crossorigin: {
            default: null,
          },
          usemap: {
            default: null,
          },
          ismap: {
            default: null,
          },
          width: {
            default: null,
          },
          height: {
            default: null,
          },
          referrerpolicy: {
            default: null,
          },
          longdesc: {
            default: null,
          },
          decoding: {
            default: null,
          },
          class: {
            default: null,
          },
          id: {
            default: null,
          },
          name: {
            default: null,
          },
          draggable: {
            default: true,
          },
          tabindex: {
            default: null,
          },
          'aria-label': {
            default: null,
          },
          'aria-labelledby': {
            default: null,
          },
          'aria-describedby': {
            default: null,
          },
        };
      },
      addNodeView() {
        return ({ node, editor, getPos }) => {
          const {
            view,
            options: { editable },
          } = editor;
          const { style } = node.attrs;
          const $wrapper = document.createElement('div');
          const $container = document.createElement('div');
          const $img = document.createElement('img');
          displayProtectedImage($img);
          const iconStyle = 'width: 24px; height: 24px; cursor: pointer;';
    
          const dispatchNodeView = () => {
            if (typeof getPos === 'function') {
              const newAttrs = {
                ...node.attrs,
                style: `${$img.style.cssText}`,
                src: `${$img.src}`
              };
              view.dispatch(view.state.tr.setNodeMarkup(getPos(), null, newAttrs));
            }
          };
          const paintPositionContoller = () => {
            const $postionController = document.createElement('div');
    
            const $leftController = document.createElement('img');
            const $centerController = document.createElement('img');
            const $rightController = document.createElement('img');
            const $clear = document.createElement('img');
    
            const controllerMouseOver = (e: any) => {
              e.target.style.opacity = 0.3;
            };
    
            const controllerMouseOut = (e: any) => {
              e.target.style.opacity = 1;
            };
    
            $postionController.setAttribute(
              'style',
              'position: absolute; top: 0%; left: 50%; width: 100px; height: 25px; z-index: 999; background-color: rgba(255, 255, 255, 0.7); border-radius: 4px; border: 2px solid #6C6C6C; cursor: pointer; transform: translate(-50%, -50%); display: flex; justify-content: space-between; align-items: center; padding: 0 10px;',
            );
    
            $leftController.setAttribute(
              'src',
              '/Tiptap/format_align_left_20px.svg',
            );
            $leftController.setAttribute('style', iconStyle);
            $leftController.addEventListener('mouseover', controllerMouseOver);
            $leftController.addEventListener('mouseout', controllerMouseOut);
    
            $centerController.setAttribute(
              'src',
              '/Tiptap/format_align_center_20px.svg',
            );
            $centerController.setAttribute('style', iconStyle);
            $centerController.addEventListener('mouseover', controllerMouseOver);
            $centerController.addEventListener('mouseout', controllerMouseOut);
    
            $rightController.setAttribute(
              'src',
              '/Tiptap/format_align_right_20px.svg',
            );
            $rightController.setAttribute('style', iconStyle);
            $rightController.addEventListener('mouseover', controllerMouseOver);
            $rightController.addEventListener('mouseout', controllerMouseOut);
    
            $clear.setAttribute(
              'src',
              '/Tiptap/clear-style_20px.svg',
            );
            $clear.setAttribute('style', iconStyle);
            $clear.addEventListener('mouseover', controllerMouseOver);
            $clear.addEventListener('mouseout', controllerMouseOut);
            
            $leftController.addEventListener('click', () => {
              $img.setAttribute('style', `${$img.style.cssText} margin: 0 auto 0 0;`);
              dispatchNodeView();
            });
            $centerController.addEventListener('click', () => {
              $img.setAttribute('style', `${$img.style.cssText} margin: 0 auto;`);
              dispatchNodeView();
            });
            $rightController.addEventListener('click', () => {
              $img.setAttribute('style', `${$img.style.cssText} margin: 0 0 0 auto;`);
              dispatchNodeView();
            });
            $clear.addEventListener('click', () => {
              $img.removeAttribute('style')
              dispatchNodeView();
            });
    
            $postionController.appendChild($leftController);
            $postionController.appendChild($centerController);
            $postionController.appendChild($rightController);
            $postionController.appendChild($clear);
    
            $container.appendChild($postionController);
          };
    
          $wrapper.setAttribute('style', `display: flex;`);
          $wrapper.appendChild($container);
    
          $container.setAttribute('style', `width: 100%; display: flex;`);
          $container.appendChild($img);
    
          Object.entries(node.attrs).forEach(([key, value]) => {
            if (value === undefined || value === null) return;
            $img.setAttribute(key, value);
          });
    
          if (!editable) return { dom: $wrapper };
    
          const dotsPosition = [
            'top: -4px; left: -4px; cursor: nwse-resize;',
            'top: -4px; right: -4px; cursor: nesw-resize;',
            'bottom: -4px; left: -4px; cursor: nesw-resize;',
            'bottom: -4px; right: -4px; cursor: nwse-resize;',
          ];
    
          let isResizing = false;
          let startX: number, startWidth: number, startHeight: number;
    
          $container.addEventListener('click', () => {
            //remove remaining dots and position controller
            if ($container.childElementCount > 3) {
              for (let i = 0; i < 5; i++) {
                $container.removeChild($container.lastChild as Node);
              }
            }
    
            paintPositionContoller();
    
            $container.setAttribute(
              'style',
              `position: relative; border: 1px dashed #6C6C6C; ${style}`,
            );
    
            Array.from({ length: 4 }, (_, index) => {
              const $dot = document.createElement('div');
              $dot.setAttribute(
                'style',
                `position: absolute; width: 9px; height: 9px; border: 1.5px solid #6C6C6C; border-radius: 50%; ${dotsPosition[index]}`,
              );
    
              $dot.addEventListener('mousedown', e => {
                e.preventDefault();
                isResizing = true;
                startX = e.clientX;
                startWidth = $container.offsetWidth;
                startHeight = $container.offsetHeight;
    
                const onMouseMove = (e: MouseEvent) => {
                  if (!isResizing) return;
                  const deltaX = index % 2 === 0 ? -(e.clientX - startX) : e.clientX - startX;
    
                  const aspectRatio = startWidth / startHeight;
                  const newWidth = startWidth + deltaX;
                  const newHeight = newWidth / aspectRatio;
    
                  $container.style.maxWidth = newWidth + 'px';
                  $container.style.maxHeight = newHeight + 'px';
    
                  $img.style.maxWidth = 'min(100%,'+ newWidth + 'px)';
                  $img.style.maxHeight = 'min(100%,'+ newHeight + 'px)';
                };
    
                const onMouseUp = () => {
                  if (isResizing) {
                    isResizing = false;
                  }
                  dispatchNodeView();
    
                  document.removeEventListener('mousemove', onMouseMove);
                  document.removeEventListener('mouseup', onMouseUp);
                };
    
                document.addEventListener('mousemove', onMouseMove);
                document.addEventListener('mouseup', onMouseUp);
              });
              $container.appendChild($dot);
              return 0;
            });
          });
    
          document.addEventListener('click', (e: MouseEvent) => {
            const $target = e.target as HTMLElement;
            const isClickInside = $container.contains($target) || $target.style.cssText === iconStyle;
    
            if (!isClickInside) {
              const containerStyle = $container.getAttribute('style');
              const newStyle = containerStyle?.replace('border: 1px dashed #6C6C6C;', '');
              $container.setAttribute('style', newStyle as string);
    
              if ($container.childElementCount > 3) {
                for (let i = 0; i < 5; i++) {
                  $container.removeChild($container.lastChild as Node);
                }
              }
            }
          });
          
          return {
            dom: $wrapper,
          };
        };
      },
    })
    ,
  FontSize,
  TextStyle,
  Underline,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
]

var loadingStarted = new Map<number, string>();

function displayProtectedImage(
  image: HTMLImageElement
) {
  const fileId = parseInt(image.accessKey);
  if (!fileId) {
    return;
  }

  if (loadingStarted.has(fileId)) {
    const url = loadingStarted.get(fileId);
    if (url) {
      image.src = url;
      setTimeout(() => {
        const images: HTMLImageElement[] = Array.prototype.slice.call(document.querySelectorAll('[accessKey="'+fileId+'"]'));
        images.forEach(img => {
          img.src = url;
        });
      }, 100);
    }
    return; 
  }

  loadingStarted.set(fileId, '');
  FetchBlob(`/api/files/${fileId}/blob`, 'GET').then(blob => {
    if (blob) {
      const imageElements: HTMLImageElement[] = Array.prototype.slice.call(document.querySelectorAll('[accessKey="'+fileId+'"]'));
      const url = URL.createObjectURL(blob);
      loadingStarted.set(fileId, url);
      image.src = url;
      imageElements.forEach((img) => {
        img.src = url;
        //img.onload = () => URL.revokeObjectURL(url); // https://alphahydrae.com/2021/02/how-to-display-an-image-protected-by-header-based-authentication/
      })
    }
  });
}


interface TipTapProps {
  editable?: boolean;
  onChange?: (value: string) => void;
  value?: string;
}
export default function TipTapEditor({
  value,
  editable = false,
  onChange,

}: TipTapProps) {
  const editor = useEditor({
    editable,
    extensions: extensions(editable),
    content: value,
    onUpdate: editor => onChange ? onChange(editor.editor.getHTML()) : null,
  });

  useEffect(() => {
    if (!editable) {
      editor?.commands.setContent(value ?? '')
    }
  }, [value, editor, editable]);

  return (
    <div className={'tiptap-container '+ (editable ? 'editable' : '')}>
      <MenuBar editable={editable} editor={editor} />
      <EditorContent editor={editor}/>
    </div>
  )
}