import ModalBase from '../ModalBase/ModalBase';
import { GskMapModel, EditMapModel } from '../../types/GskTypes';
import { MapService } from '../../Services/MapService';
import { Button, ColorPicker, Flex, Form, Input, InputNumber, Modal, Switch } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { MapDefaults } from '../GskMap/GskMapTypes';
import { useState } from 'react';
import { getColorValue } from '../../utils/colorUtils';
import { ColorFactory } from 'antd/es/color-picker/color';
import useLoading from '../../utils/hooks/useLoading';
import DeleteButton from '../Controls/DeleteButton';
import useCurrentGsk from '../../pages/Gsk/useCurrentGsk';

interface FormData {
  name: string;
  fontSize: number;
  fontColor: ColorFactory;
  fillColor: ColorFactory;
  lineColor: ColorFactory;
  activePolygonLineColor: ColorFactory;
  activePolygonFillColor: ColorFactory;
}

interface OwnProps {
	map: GskMapModel;
	onMapChanged: (v: GskMapModel) => void;
	onMapDeleted: (v: number) => void;
	closeDialog: CallableFunction;
}
export default function EditMapModal({ closeDialog, onMapChanged, onMapDeleted, map }: OwnProps) {
	const gsk = useCurrentGsk();
	const [isLoading, load] = useLoading();
	const [form] = useForm<FormData>();
	const [defaults, setDefaults] = useState(!map.settings.fillColor && !map.settings.lineColor
		&& !map.settings.activePolygonLineColor && !map.settings.activePolygonLineColor && !map.settings.fontSize);

	const submit = async (data: FormData) => {
		const req: EditMapModel = {
			name: data.name,
			settings: {
				fontSize: data.fontSize,
				fontColor: defaults ? null : getColorValue(data.fontColor),
				activePolygonFillColor: defaults ? null : getColorValue(data.activePolygonFillColor),
				activePolygonLineColor: defaults ? null : getColorValue(data.activePolygonLineColor),
				fillColor: defaults ? null : getColorValue(data.fillColor),
				lineColor: defaults ? null : getColorValue(data.lineColor),
			}
		}
		const resp = await load(MapService.editMap(gsk?.id!, map.id, req));
		if (resp) {
			onMapChanged(resp);
			closeDialog();
		}
	}

  const handleDefaults = () => {
    form.setFields([
      { name: 'fontSize', value: MapDefaults.mapFontSize },
      { name: 'fontColor', value: MapDefaults.mapFontColor },
      { name: 'fillColor', value: MapDefaults.mapFillColor },
      { name: 'lineColor', value: MapDefaults.mapLineColor },
      { name: 'activePolygonLineColor', value: MapDefaults.activePolygonLineColor },
      { name: 'activePolygonFillColor', value: MapDefaults.activePolygonFillColor },
    ]);
  }

  const handleMapDelete = () => {
    Modal.confirm({
      title: "Удаление карты",
      content: <>Вы действительно хотите удалить карту <b>{map?.name}</b>?</>,
      okText: "Удалить",
      cancelText: 'Отмена',
      onOk: async () => {
        await load(MapService.deleteMap(gsk?.id!, map.id));
				onMapDeleted(map.id);
				closeDialog();
      }
    });
  }

	return (
		<ModalBase
		  closeDialog={closeDialog}
			title="Настройки карты"
			okButtonHandler={() => form.submit()}
			leftFooterCell={<DeleteButton onClick={handleMapDelete}/>}
			isLoading={isLoading}
			width={500}
		>
			<Form
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
				form={form}
        initialValues={{
					name: map.name,
					fontSize: map.settings.fontSize ?? MapDefaults.mapFontSize,
					fontColor: map.settings.fontColor ?? MapDefaults.mapFontColor,
          fillColor: map.settings.fillColor ?? MapDefaults.mapFillColor,
          lineColor: map.settings.lineColor ?? MapDefaults.mapLineColor,
          activePolygonLineColor: map.settings.activePolygonLineColor ?? MapDefaults.activePolygonLineColor,
          activePolygonFillColor: map.settings.activePolygonFillColor ?? MapDefaults.activePolygonFillColor,
        }}
				onFinish={submit}
			>
				<Form.Item label='Название карты' name='name' rules={[{required: true, message: 'Введите название'}]}>
					<Input/>
				</Form.Item>
				<Form.Item label='По умолчанию'>
					<Switch checked={defaults} onChange={setDefaults}/>
				</Form.Item>
				{!defaults && <>
					<Form.Item label='Размер шрифта' name='fontSize' rules={[{required: true, message: 'Введите размер'}]}>
						<InputNumber/>
					</Form.Item>
					<Form.Item label='Цвет шрифта' name='fontColor'>
						<ColorPicker />
					</Form.Item>
					<Form.Item label='Цвет линий' name='lineColor'>
						<ColorPicker />
					</Form.Item>
					<Form.Item label='Заполнение' name='fillColor'>
						<ColorPicker />
					</Form.Item>
					<Form.Item label='Цвет линий выделенного бокса' name='activePolygonLineColor'>
          	<ColorPicker />
					</Form.Item>
					<Form.Item label='Цвет заливки выделенного бокса' name='activePolygonFillColor'>
						<ColorPicker />
					</Form.Item>
					<Flex justify='flex-end'>
						<Button onClick={handleDefaults}>По умлочанию</Button>
					</Flex>
				</>}
			</Form>
		</ModalBase>
	);
}
