import { Button, Card, Form, Input, Row, Upload } from "antd";
import { PostCoverModel } from "../../../types/PostTypes";
import { Link } from "react-router-dom";
import { PostBlockEditButtons } from "./PostBlockEditButtons";
import TagsBlock from "../TagsBlock";
import { useState } from "react";
import { FormInstance } from "antd/lib";
import { InboxOutlined } from "@ant-design/icons";
import { ReadFileAsString } from "../../../utils/FileHelper";
import ProtectedImage from "../../ProtectedImage";

interface PostCoverBlockProps {
  cover: PostCoverModel;
  urlPrefix: string;
}

export function PostCoverBlock({cover, urlPrefix} : PostCoverBlockProps) {
  return (<Card
    title={<Link to={urlPrefix + cover.id} style={{color: 'unset'}}>{cover.title}</Link>}
    style={{width: '100%'}}
    extra={
      <PostBlockEditButtons post={cover} canEdit={false} handleDelete={() => null} openEditModal={() => null} />
		}
  >
    {cover.coverImageId && 
      <ProtectedImage fileId={ cover.coverImageId} style={{maxWidth: '100%', maxHeight: 576}}/>
    }
    <Row style={{paddingBottom: 10}}>{cover.coverText}</Row>
    {cover.tags && <Row style={{paddingBottom: 10}} title="Тэги">
      <TagsBlock value={cover.tags} />
    </Row>}
    <Link to={urlPrefix + cover.id}>Читать дальше...</Link>
  </Card>)
}

interface PostCoverEditFieldProps {
  form: FormInstance;
  setCoverImage: (v: string | undefined) => void;
}
export function PostCoverEditFields({form, setCoverImage }: PostCoverEditFieldProps) {
  const coverText = Form.useWatch('coverText', form);
  const [isLoading, setIsLoading] = useState(false);
  const coverImageId = Form.useWatch('coverImageId', form)
	const [coverImageUrl, setCoverImageUrl] = useState<string | undefined>(undefined);

	const handleCoverUpload = async (file: File | undefined) => {
    if (file) {
      setIsLoading(true);
      const objectUrl = URL.createObjectURL(file);
      ReadFileAsString(file).then(data => {
        if (data) {
          setCoverImage(data);
        }
        setIsLoading(false);
      }, () => setIsLoading(false))
      setCoverImageUrl(objectUrl);
    } else {
      setCoverImageUrl(undefined);
      setCoverImage(undefined);
    }
    form.setFieldValue('coverImageId', undefined);
	}

  const validateCover = () => {
    if (!coverImageId && !coverImageUrl && !coverText) {
      return Promise.reject('Введите текст и\\или загрузите изображение обложки');
    }

    return Promise.resolve();
  }
  return (<>
    <Form.Item name='coverImageId' noStyle>
      <Input type="hidden" />
    </Form.Item>
    <Form.Item label='Обложка' rules={[{validator: (_, v) => validateCover()}]}>
      <div style={{display: 'block'}}> 
        {coverImageUrl || coverImageId ? 
        <>
          <ProtectedImage style={{maxWidth: '100%'}} src={coverImageUrl} fileId={coverImageId} />
          <Button disabled={isLoading} onClick={() => handleCoverUpload(undefined)} >Удалить</Button>
        </>
        :
        <Upload.Dragger
          disabled={isLoading}
          accept='image/png, image/gif, image/jpeg'
          multiple={false}
          beforeUpload={file => {
            handleCoverUpload(file)
            return false; // Prevent upload
          }}
          maxCount={1}
          showUploadList={false}
          onRemove={() => handleCoverUpload(undefined)}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Нажмите или перетащите картинку обложки в эту область для загрузки</p>
        </Upload.Dragger>
        }
      </div>
    </Form.Item>
    <Form.Item label='Текст обложки' name='coverText'  rules={[{validator: (_, v) => validateCover()}]}>
      <Input />
    </Form.Item> 
  </>)
}