import { Button, Spin } from "antd";
import { FileService } from "../../Services/FileService";
import useLoading from "../../utils/hooks/useLoading";


export default function FilesPage () {
	const [isLoading, load] = useLoading();
	const handleDelete = async () => {
		await load(FileService.removeTempFiles());
	}

	return (<Spin spinning={isLoading} style={{width: '100%'}}>
		<Button onClick={handleDelete}>Удалить временные файлы</Button>
	</Spin>)
}