import { useAppSelector } from "../../store/redux";
import { Gsk, GskRole } from "../../types/GskTypes";
import { FileModel, FileType } from "../../types/FileTypes";
import { useState} from "react";
import { Card, Col, Flex, Input, List, Modal, Row, Space, Spin, Upload } from "antd";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, FileExcelTwoTone, FileImageTwoTone, FilePdfTwoTone, FileTextTwoTone, FileTwoTone, FileWordTwoTone, FileZipTwoTone, InboxOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import dayjs from 'dayjs';
import useCurrentGsk from "./useCurrentGsk";
import IconButton from "../../components/IconButton/IconButton";
import prompt from "../../components/AntdExtensions/prompt";
import { NormalizeBytesSize } from "../../utils/fileSizeUtils";
import RowTwoCol from "../../components/RowTwoCol/RowTwoCol";
import useLoading from "../../utils/hooks/useLoading";
import { EmptyPagedResult, PagedResult } from "../../types/dto";
import { useEffectOnce } from "../../utils/hooks/useEffectOnce";
import { currentUserHasGskRoleSelector } from "../../store/common/app-slice";
import { GskService } from "../../Services/GskService";


export default function GskDocsPage() {
  const gsk = useCurrentGsk();
  return (<>{gsk && <GskDocs gsk={gsk}/>}</>)
}

interface GskDocsProps {
  gsk: Gsk;
}
const PAGE_SIZE = 10;
const GskDocs = ({ gsk }: GskDocsProps) => {
  const [filesPage, setFilesPage] = useState<PagedResult<FileModel>>(EmptyPagedResult<FileModel>(PAGE_SIZE));
  const [isLoading, load] = useLoading();
  const [search, setSearch] = useState('');
  const canUpload = useAppSelector(currentUserHasGskRoleSelector(GskRole.Chairman))

  const handlePageChange = async (page: number, pageSize: number) => {
    const resp = await GskService.getFilesPage(gsk.id, {page, pageSize, type: FileType.GskDocument, searchString: search});
    if (resp) {
      setFilesPage(resp);
    }
  }

  const refreshPage = () => handlePageChange(filesPage.page, filesPage.pageSize);

  const handleFileClick = async (file: FileModel) => {
    await load (GskService.downloadFile(gsk.id, file.id));
  }
  
  const handleUpload = async (file: File | null) => {
    if (!file) {
      return;
    }
    await load(GskService.addFile(gsk.id, {
      data: file,
      name: file.name,
      type: FileType.GskDocument
    }));
    await load(refreshPage);
  }

  const handleEditClick = async (file: FileModel) => {
    try {
      let newFileName = await prompt({
        title: <>Переименовать файл <b>{file.name}</b>?</>,
        placeholder: file.name,
        value: file.name,
        rules: [
          {
            required: true,
            message: "Введите имя файла"
          }
        ],
      });
      if (newFileName && newFileName !== file.name) {
        const resp = await load(GskService.renameFile(gsk.id, file.id, {name: newFileName}));
        if (resp) {
          const newItems = [...filesPage.items];
          newItems.splice(newItems.findIndex(p => p.id === resp.id), 1, resp)
          setFilesPage(prev => ({...prev, items: newItems}));
        }
      }
    } catch (e) {}
  }
  
  const handlDeleteClick = (file: FileModel) => {
    Modal.confirm({
      title: 'Удаление',
      content: <><p>Вы действительно хотите удалить файл <b>{file.name}</b>?</p><p>Восстановить его будет невозможно</p></>,
      icon: <ExclamationCircleOutlined />,
      okText: 'Удалить',
      cancelText: 'Отмена',
      okButtonProps: {style: {backgroundColor: '#ff765e'}},
      onOk: async () => {
        await load(GskService.deleteFile(gsk.id, file.id));
        await load(refreshPage());
      }
    })
  }

  useEffectOnce(() => { refreshPage(); })

  return (
    <Spin spinning={isLoading}>
      <Space direction="vertical" style={{width: '100%'}}>
      <Card>
        {canUpload ?
        <Upload.Dragger 
          accept="*"
          multiple={false}
          beforeUpload={file => {
            handleUpload(file)
            return false; // Prevent upload
          }}
          maxCount={1}
          disabled={!canUpload}
          showUploadList={false}
          onRemove={() => handleUpload(null)}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Нажмите или перетащите файл в эту область для загрузки</p>
        </Upload.Dragger> 
        : "Загружать файлы может только председатель или администратор"
        }
      </Card>
      <Card
        title={
          <RowTwoCol
            left={<Input 
              placeholder="Поиск"
              value={search}
              onChange={e => setSearch(e.target.value)}
              style={{maxWidth: 300}}
              prefix={<SearchOutlined />}
              onKeyUp={e => {if (e.key === 'Enter') {handlePageChange(1, filesPage.pageSize);}}}
              title="Поиск по названию файла и по ФИО добавившего пользователя"
            />}
            right={<IconButton icon={<SyncOutlined />} title="Обновить" onClick={refreshPage}/>}
            rightSize={25}
          />}
      >
        <List
          itemLayout="horizontal"
          dataSource={filesPage.items}
          renderItem={(item, index) => 
            <List.Item key={item.id}>
              <List.Item.Meta
                avatar={<span style={{cursor: 'pointer'}} onClick={() => handleFileClick(item)}>{GetFileIcon(item.name)}</span>}
                title={<Flex align="center"><Row style={{width: '100%'}}>
                  <Col style={{width: 'calc(100% - 50px)'}}>
                    <div style={{cursor: 'pointer'}} onClick={() => handleFileClick(item)}>{item.name}</div>
                  </Col>
                  <Col style={{textAlign: 'right', width: 50}}>
                    <Space>
                      <IconButton title='Переименовать' icon={<EditOutlined />} onClick={() => handleEditClick(item)} disabled={!canUpload}/>
                      <IconButton title='Удалить' icon={<DeleteOutlined />} onClick={() => handlDeleteClick(item)} disabled={!canUpload}/>
                    </Space>
                  </Col>
                </Row></Flex>}
                description={<>
                  Размер: {NormalizeBytesSize(item.size)}. Добавил {item.createdBy} {dayjs(item.created).format('DD.MM.YYYY')}. Количество скачиваний: {item.downloadCount}</>}
              />
            </List.Item>
          }
          pagination={{
            current: filesPage.page,
            total: filesPage.totalCount,
            onChange: handlePageChange,
            pageSize: filesPage.pageSize,
            defaultPageSize: PAGE_SIZE,
          }}
        />
      </Card>
      </Space>
  </Spin>
  )
};

const GetFileIcon = (name: string) => {
  let ext = '';
  if (name.includes('.')) {
    ext = name.split('.').at(-1)?.toLowerCase() ?? '';
  }
  const style = {fontSize: 40};
  switch(ext) {
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'png':
    case 'tiff':
      return <FileImageTwoTone style={style} />;
    case 'doc':
    case 'docx':
      return <FileWordTwoTone style={style} />;
    case 'xls':
    case 'xlsx':
    case 'xlsm':
      return <FileExcelTwoTone style={style} />;
    case 'pdf':
      return <FilePdfTwoTone style={style} />;
    case 'txt':
      return <FileTextTwoTone style={style} />;
    case 'zip':
    case '7zip':
    case '7z':
    case 'rar':
    case 'tar':
    case 'bz2':
      return <FileZipTwoTone style={style} />;
    default: 
      return <FileTwoTone style={style} />
  }
}