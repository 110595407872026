import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import 'react-notifications-component/dist/theme.css'
import { Content, Footer, Header } from 'antd/es/layout/layout';
import { Flex, Grid, Layout, Menu, Space } from 'antd';
import { UserRole } from '../types/UserTypes';
import { MenuProps } from 'antd/lib';
import { useEffect, useMemo, useRef } from 'react';
import { CreditCardOutlined, DollarOutlined, FileDoneOutlined, HistoryOutlined, InfoCircleOutlined, MailOutlined, PercentageOutlined, RocketOutlined, TeamOutlined, UnorderedListOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import { ArticleIcon, BoxesIcon, MagicHatIcon, PriceIcon, RabbitMqIcon, SimIcon } from './icons';
import RowTwoCol from './RowTwoCol/RowTwoCol';
import { ItemType } from "antd/lib/menu/hooks/useItems";
import './Layout.scss'
import { RefType, setAppRef } from '../store/enum/RefType';
import { HeaderOneRow, WithUnderLine } from './Headers/HeaderOne';
import { currentUserIsGlobalAdminSelector, useCurrentUser } from '../store/common/app-slice';
import { useEffectOnce } from '../utils/hooks/useEffectOnce';
import { useAppDispatch, useAppSelector } from '../store/redux';
import { clearCurrentGskData } from '../store/common/gsk-slice';

interface Props {
	renderUserItems: (gskId?: number) => ItemType[];
}
export default function LayoutPage ({ renderUserItems }: Props) {
	const { sm } = Grid.useBreakpoint();
	const dispatch = useAppDispatch();
	const currentUser = useCurrentUser();
	const isGlobalAdmin = useAppSelector(currentUserIsGlobalAdminSelector);
	const mainPageLinkRef = useRef(null);
	const blogLinkRef = useRef(null);
	const nav = useNavigate();

	const items: MenuProps['items'] = useMemo(() => [
		{
			label: <span className='label'>Цены</span>,
			onClick: () => nav('Pricing'),
			key: 'pricing',
			icon: <PriceIcon />,
      className: 'menu-item-short',
		},
		{
			label: <span className='label'>Возможности</span>,
			onClick: () => nav('Promo'),
			key: 'promo',
			icon: <MagicHatIcon />,
			style: {display: currentUser ? 'unset' : 'none'},
      className: 'menu-item-short',
		},
		{
			label: <span className='label'>Купить\продать</span>,
			onClick: () => nav('Market'),
			key: 'market',
			disabled: true,
			style: {display: 'none'},
			icon: <CreditCardOutlined />,
      className: 'menu-item-short',
		},
		{
			label: <span className='label' ref={blogLinkRef}>Блог</span>,
			onClick: () => nav('Blog'),
			key: 'blog',
			icon: <ArticleIcon />,
      className: 'menu-item-short',
		},
		{
			label: <span className='label'>Реестр ГСК</span>,
			onClick: () => nav('GskList'),
			key: 'gsklist',
      className: 'menu-item-short',
			style: {display: isGlobalAdmin ? 'unset' : 'none'},
			icon: <UnorderedListOutlined />,
		},
		{
			label: <span className='label'>О проекте</span>,
			onClick: () => nav('About'),
			key: 'about',
			icon: <InfoCircleOutlined />,
      className: 'menu-item-short',
		},
		{
			label: <span className='label'>Админка</span>,
			key: 'admin',
			icon: <RocketOutlined />,
			style: {display: currentUser?.role === UserRole.Admin ? 'unset' : 'none', paddingInline: 5},
      className: 'menu-item-short',
			children: [
				{
					label: <span className='label'>Пользователи</span>,
					onClick: () => nav('Admin/Users'),
					key: 'users',
					icon: <TeamOutlined />,
				},
				{
					label: <span className='label'>Биллинг</span>,
					key: 'billing',
					icon: <DollarOutlined/>,
					className: 'menu-item-with-childs',
					children: [
						{
							label: <span className='label'>Тарифы</span>,
							onClick: () => nav('Admin/Billing/Tariffs'),
							key: 'tariffs',
							icon: <SimIcon />,
						},
						{
							label: <span className='label'>Скидки</span>,
							onClick: () => nav('Admin/Billing/Discounts'),
							key: 'discounst',
							icon: <PercentageOutlined />,
						},
					]
				},
				{
					label: <span className='label'>Файлы</span>,
					onClick: () => nav('Admin/Files'),
					key: 'files',
					icon: <FileDoneOutlined />,
				},
				{
					label: <span className='label'>Рассылки</span>,
					onClick: () => nav('Admin/Mailing'),
					key: 'mailing',
					icon: <MailOutlined />,
				},
				{
					label: <span className='label'>Rabbit MQ</span>,
					onClick: () => nav('Admin/Rabbit'),
					key: 'rabbit',
					icon: <RabbitMqIcon />,
				},
				{
					label: <span className='label'>Логи</span>,
					onClick: () => nav('Admin/History'),
					key: 'logs',
					icon: <HistoryOutlined />,
				},
			],
		},
		...renderUserItems(undefined),
	], [currentUser, isGlobalAdmin, nav, renderUserItems]);

	let location = useLocation();
  const current = useMemo(() => {
    const parts = location.pathname.split('/');
    if (parts.length > 1) {
      return parts[1].toLowerCase();
    }
    return '';

  }, [location]);

	useEffect(() => {
		setAppRef(RefType.MainPageLink, mainPageLinkRef);
	}, [mainPageLinkRef]);
	
	useEffect(() => {
		setAppRef(RefType.MenuBlogLink, blogLinkRef);
	}, [blogLinkRef]);

	useEffectOnce(() => { 
		dispatch(clearCurrentGskData()); 
	});

	return (
		<>
			<Header style={{ backgroundColor: 'white', padding: 0}}>
				<Flex justify='center'> 
					<Layout style={{maxWidth: 1200}}>
						<RowTwoCol
							style={{width: '100%', backgroundColor: 'white'}}
							left={<Title style={{margin: 'auto'}}><NavLink ref={mainPageLinkRef} to="/">
								<Space>
									<BoxesIcon style={{with: 60, heigth: 60, color: 'hsl(261, 98%, 33%)'}}/>
									{sm && <HeaderOneRow>Мой<WithUnderLine>ГСК</WithUnderLine>.рф</HeaderOneRow>}
								</Space>
							</NavLink></Title>}
							right={
									<Menu 
										inlineIndent={0}
										selectedKeys={[current]}
										style={{
											justifyContent: 'right',
											width:'100%',
										}} 
										mode="horizontal" 
										items={items}
									/>
							}
							leftSize={sm ? 200 : 60 }
						/>
					</Layout>
				</Flex>
			</Header>
      <Content style={{paddingTop:10}}>
        <Flex justify='center'> 
					<Layout style={{maxWidth: 1200}}>
						<Outlet />
					</Layout>
        </Flex>
			</Content>
			<DefaultFooter/>
		</>
	)
}

export function DefaultFooter() {
	return (
		<Footer>
			<div style={{textAlign: 'center', color: 'gray'}}>
				<Space>
					<Link style={{color: 'gray'}} to='/agreement'>Пользовательское соглашение</Link>
					<Link style={{color: 'gray'}} to='/contacts'>Контакты</Link>
					<Link style={{color: 'gray'}} to='/help'>Справка</Link>
				</Space>

				<div>{new Date().getFullYear()} МойГСК.рф</div>
			</div>
		</Footer>
	)
}