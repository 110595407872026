import { Col, Grid, Row } from "antd";
import { useMemo } from "react";
import './promocard.scss'

interface Props {
  title?: React.ReactNode | string ;
  image: React.ReactNode;
  description: React.ReactNode | undefined;
  imageLeft?: boolean
  imageColSpan?: number
}
export default function PromoCard({title = ' ', description, image, imageLeft = true, imageColSpan = 12} : Props) {
  const {lg} = Grid.useBreakpoint();
  const mobileView = !lg
  const titleColSpan = 24 -  imageColSpan;
  const imageCol = useMemo(() => <Col span={mobileView ? 24 : imageColSpan} className="image-col" style={{textAlign: mobileView ? 'center' : (imageLeft ? 'left' : 'right')}}>
    {image}
  </Col>, [image, imageColSpan, imageLeft, mobileView])
  const titleCol = useMemo(() => <Col  span={mobileView ? 24 : titleColSpan}  className="title-col">
    <h3>{title}</h3>
    {description}
  </Col>, [description, mobileView, title, titleColSpan])
  return (<Row className="promo-card">
    {imageLeft && !mobileView ? <>{imageCol}{titleCol}</> : <>{titleCol}{imageCol}</>}
  </Row>)
}