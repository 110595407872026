import { useCallback } from "react";
import { PostModel, VoteConfigurationModel, VoteModel } from "../../types/PostTypes";
import { PostService } from "../../Services/PostsService";
import VoteBlockCommon from "./Base/VoteBlockCommon";

interface Props {
  postId: number;
  voteConfiguration: VoteConfigurationModel;
  setPost: (v: React.SetStateAction<PostModel | undefined>) => void;
}

export default function VoteBlock({postId, voteConfiguration, setPost}: Props) {
  const voteHandler = useCallback(async (req: VoteModel) => {
    const resp = await PostService.vote(req);
    if (resp) {
      setPost(resp);
    }
    return !!resp;
  }, [setPost])
  
  return (<VoteBlockCommon postId={postId} voteConfiguration={voteConfiguration} voteHandler={voteHandler} />)
}