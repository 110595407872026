import { CommentOutlined, DislikeFilled, DislikeOutlined, DownOutlined, EyeOutlined, LikeFilled, LikeOutlined, UpOutlined } from "@ant-design/icons"
import RowTwoCol from "../../RowTwoCol/RowTwoCol"
import { useMemo, useState } from "react";
import Link from "antd/es/typography/Link";
import { Flex, List, Space } from "antd";
import IconButton from "../../IconButton/IconButton";
import AddCommentBlock from "../../Gsk/Post/AddCommentBlock";
import PostComment from "./PostComment";
import { PostCommentModelBase } from "../../../types/PostBaseTypes";

interface CommentsBlockProps {
  post: {
    id: number;
    comments: PostCommentModelBase[];
    commentsEnabled: boolean;
    isCurrentUserLiked: boolean | null;
    likesCnt: number;
    dislikesCnt: number;
    viewsCount: number;
    isViewed: boolean;
  }
  likePostHandler: (isLike: boolean) => void;
  addCommentHandler: (comment: string, parentCommentId: number | null) => void;
  editCommentHandler: (comment: PostCommentModelBase) => void;
  likeCommentHandler: (isLike: boolean, comment: PostCommentModelBase) => void;
  deleteCommentHandler: (commnetId: number) => void;
}

export const CommentsBlockCommon = ({post, likePostHandler, addCommentHandler, likeCommentHandler, editCommentHandler, deleteCommentHandler} : CommentsBlockProps) => {
  const allComments = useMemo(() => 
    [...post.comments].sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()) 
  , [post.comments]);

	const [showComments, setShowComments] = useState(false);
  return (<><RowTwoCol style={{maxWidth: '100%'}}
    rightSize={130}
    left={
      <div style={{color: 'gray', display: 'inline-block'}}>
        <CommentOutlined />&nbsp;
        {post.commentsEnabled ? 
          <Link onClick={() => setShowComments(!showComments)}>
            Комментарии ({post.comments.filter(c => !c.isDeleted).length}) {showComments ? <DownOutlined />: <UpOutlined />}
          </Link>
          :
          <>Комментарии отключены</>
        }
      </div>
    }
    right={
      <Space>
        <EyeOutlined /><span style={{marginRight: 20}}>{post.viewsCount}</span>
        <IconButton 
          icon={post.isCurrentUserLiked === true ? <LikeFilled /> : <LikeOutlined />} 
          onClick={() => likePostHandler(true)} 
        />{post.likesCnt} &nbsp;
        <IconButton
          icon={post.isCurrentUserLiked === false ? <DislikeFilled /> :<DislikeOutlined />} 
          onClick={() => likePostHandler(false)} 
        />{post.dislikesCnt}
      </Space>
    }
  />
  
  {showComments && <Flex justify="flex-start" >
    <List style={{minWidth: 600, paddingLeft: 40}}>
      <AddCommentBlock submitHandler={addCommentHandler} style={{marginBottom: 20}}/>
      {allComments.filter(c => !c.parentCommentId).map(c => 
        <PostComment
          key={c.id}
          allComments={allComments}
          comment={c}
          addCommentHandler={addCommentHandler}
          editCommentHandler={editCommentHandler}
          likeCommentHandler={likeCommentHandler}
          deleteCommentHandler={deleteCommentHandler}
        />)}
    </List>
  </Flex>}
  </>)
}