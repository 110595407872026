import { useState } from "react";
import { Radio, Tour, TourProps } from "antd";
import { getAppRef, RefType } from "../../store/enum/RefType";

export function HomePageTour() {
  const [tourOpen, setTourOpen] = useState(false);
  const mainPageLinkRef = getAppRef(RefType.MainPageLink);
  const blogRef = getAppRef(RefType.MenuBlogLink);
  const accountRef = getAppRef(RefType.MenuAccountLink);
  const myGskBlockRef = getAppRef(RefType.MainPageGskListBlock);
	const steps: TourProps['steps'] = [
    {
      title: 'Главная страница',
      description: <>Вы сейчас на главной странице. Нажмите сюда чтобы попасть на главную страницу</>,
      target: () => mainPageLinkRef.current,
    },
    {
      title: 'Список Ваших ГСК',
      description: <>Список гаражных кооперативов, в которых Вы состоите (Заргистрируйте свой ГСК если вы председатель, либо попросите приглашение у своего председателя)</>,
      target: () => myGskBlockRef.current,
    },
    {
      title: 'Блог',
      description: <>Тут можно найти статьи на гаражную и оклогаражную тему</>,
      target: () => blogRef.current,
    },
    {
      title: 'Управление Вашим аккаунтом',
      description: <>Тут можно изменить параметры Вашего аккаунта</>,
      target: () => accountRef.current,
    },
  ];
  return (<>
    <Radio.Button value="firstVisit" onClick={() => setTourOpen(true)}>Я тут впервые</Radio.Button>
    <Tour open={tourOpen} onClose={() => setTourOpen(false)} steps={steps} />
  </>)
}