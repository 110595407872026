import { Button, Flex, Form, List, Modal, Space, Spin, Tooltip } from "antd";
import GroupBox from "../../GroupBox/GroupBox";
import dayjs from 'dayjs'
import { GskSubscriptionsModel, PaymentState, SubscriptionModel, getPaymentStateName } from "../../../types/BillingTypes";
import useLoading from "../../../utils/hooks/useLoading";
import { BillingService } from "../../../Services/BillingService";
import { GskModalMode, GskModalTab } from "./GskModal";
import { Declension } from "../../../utils/DeclensionGenerator";
import { useMemo } from "react";
import RowTwoCol from "../../RowTwoCol/RowTwoCol";

interface Props {
  gskSubscriptions: GskSubscriptionsModel;
  loadPayment: (subscriptionId: number) => Promise<void>;
  setGskSubscriptions: React.Dispatch<React.SetStateAction<GskSubscriptionsModel>>;
  setMode: React.Dispatch<React.SetStateAction<GskModalMode | undefined>>;
  setTab: React.Dispatch<React.SetStateAction<GskModalTab>>;
}


export const getCurrentSubscriptionDaysToEnd = (gskSubscriptions: GskSubscriptionsModel) => gskSubscriptions.current?.endDate ? dayjs(gskSubscriptions.current.endDate).diff(dayjs(new Date()), "day") : undefined;

export default function GskSubscriptionsTab({gskSubscriptions, loadPayment, setGskSubscriptions, setTab, setMode} : Props) {
  const [isLoading, load] = useLoading();
  const currentSubscriptionDaysToEnd = getCurrentSubscriptionDaysToEnd(gskSubscriptions);

  const handlePayClick = async (s: SubscriptionModel) => {
    await loadPayment(s.id);
    setTab('pay');
    setMode('addSubscription')
  }

  const handleNewSubscriptionClick = () => {
    setTab('chooseTariff');
    setMode('addSubscription');
  }

  const handleDeleteSubscription = async (id: number, from: string) => {
    Modal.confirm({
      title: 'Удаление не оплаченной подписки',
      content: 'Восстановление будет невозможно',
      onOk: async () => {
        const resp = await load(BillingService.deleteSubscription(id));
        if (resp) {
          switch (from){
            case 'next':
              setGskSubscriptions({...gskSubscriptions, next: undefined});
              break;
            case 'current':
              setGskSubscriptions({...gskSubscriptions, current: undefined});
              break;
            case 'old':
              setGskSubscriptions({...gskSubscriptions, old: gskSubscriptions.old.filter(o => o.id !== id)});
              break;
          }
        }
      },
      okText: 'Удалить',
      okButtonProps: {danger: true}
    })
    
  }

  return (<Spin spinning={isLoading}>
    {gskSubscriptions.next && <GroupBox label='Следующая подписка'>
      <SubscriptionCard 
        s={gskSubscriptions.next}
        handlePayClick={handlePayClick}
        handleDelete={() => handleDeleteSubscription(gskSubscriptions.next!.id, 'next')}
      />
    </GroupBox>}
    <GroupBox label='Текущая подписка'>
      {gskSubscriptions.current ? <>
        <SubscriptionCard
          s={gskSubscriptions.current}
          handlePayClick={handlePayClick} 
          handleDelete={() => handleDeleteSubscription(gskSubscriptions.current!.id, 'current')}
          extraButtons={gskSubscriptions.current.isActive && !!currentSubscriptionDaysToEnd && currentSubscriptionDaysToEnd < 30 && !gskSubscriptions.next && 
            <Button style={{backgroundColor: 'lightgreen'}} onClick={handleNewSubscriptionClick}>Продлить</Button>}
        />
        
      </>: <RowTwoCol
        left={'Нет подписки'}
        right={<Button style={{backgroundColor: 'lightgreen'}}  onClick={handleNewSubscriptionClick}>Оформить</Button> }
        rightSize={100}
      />}
    </GroupBox> 
    <GroupBox label='Прошлые подписки'>
      <List 
        size="small"
        dataSource={gskSubscriptions.old}
        renderItem={s => <List.Item key={s.id} ><SubscriptionCard s={s} 
        handleDelete={() => handleDeleteSubscription(gskSubscriptions.current!.id, 'old')}/></List.Item>}
      />
    </GroupBox>
  </Spin>)
}

interface SubscriptionCardProps {
  s: SubscriptionModel;
  handlePayClick?: (s: SubscriptionModel) => void;
  handleDelete: (id: number) => void;
  extraButtons?: React.ReactNode;
}
const SubscriptionCard = ({s, handlePayClick, handleDelete, extraButtons}: SubscriptionCardProps) => {
  const daysToEnd = dayjs(s.endDate).diff(dayjs(new Date()), "day");
  const controls = useMemo(() => <>
    {!s.isActive && handlePayClick && s.paymentState === PaymentState.Pending && 
      <Button style={{width: '100%', background: 'lightgreen'}} onClick={() => handlePayClick(s)}>
        Оплатить
      </Button>}
    {(s.paymentState === PaymentState.Canceled || s.paymentState === PaymentState.Pending) && 
      <Button style={{width: '100%'}} danger onClick={() => handleDelete(s.id)}>
        Удалить
      </Button>}
  </>, [handleDelete, handlePayClick, s])
  return (
    <Form
      style={{width: '100%'}}
      size="small" 
      labelCol={{ span: 8, style:{ fontWeight:600 }}}
      wrapperCol={{ span: 16}}
      layout={'horizontal' } 
    >
      <Form.Item label='Состояние' style={{marginBottom: 0}}>
        <Tooltip title={'#'+s.id}>
          {s.isActive ? 'Активна' : 'Не активна'} 
          {s.paymentState !== undefined && s.paymentState !== null && <> ({getPaymentStateName(s.paymentState)})</>}
        </Tooltip>
      </Form.Item>
      <Form.Item label='Тариф' style={{marginBottom: 0}}><Tooltip title={s.description}>{s.tariff.name}</Tooltip></Form.Item >
      <Form.Item label='Период' style={{marginBottom: 0}}>
        <Tooltip title={s.isActive && daysToEnd > 0 && <>осталось {Declension(daysToEnd, 'день')}</>}>
          {s.startDate ? dayjs(s.startDate).format('DD.MM.YYYY') : '-'} - {s.endDate ? dayjs(s.endDate).format('DD.MM.YYYY') : '∞'}
        </Tooltip>
      </Form.Item >
      <Flex justify="flex-end"><Space>{controls}{extraButtons}</Space></Flex>
    </Form>
  )
}