import { useState } from 'react'
import ModalBase from '../ModalBase/ModalBase';
import { GskMapModel } from '../../types/GskTypes';
import { MapService } from '../../Services/MapService';
import { Button, Form, Input, Upload } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { UploadOutlined } from '@ant-design/icons';
import { ShowSuccess } from '../Notifications/Notifications';
import useLoading from '../../utils/hooks/useLoading';

interface FormData{
	name: string;
	mapImage: File;
}

interface OwnProps {
	gskId: number;
	onMapAdded: (v: GskMapModel) => void;
	closeDialog: CallableFunction;
}
export default function NewMapModal({ closeDialog, onMapAdded, gskId }: OwnProps) {
	const [mapImage, setMapImage] = useState<File | null>(null);
	const [isLoading, load] = useLoading();
	const [form] = useForm<FormData>();

	const handleUpload = async (file: File | null) => {
		setMapImage(file);
	};

	const submit = async (data: FormData) => {
		if (!mapImage) {
			form.setFields([{name: 'mapImage', errors: ['Выберите карту']}])
			return;
		}
		const newMap = await load(MapService.newMap(data.name, mapImage, gskId));
		if (newMap) {
			onMapAdded(newMap)
			ShowSuccess("Карты ГСК", "Новая карта ГСК добавлена");
			closeDialog()
		}
	}

	return (
		<ModalBase closeDialog={closeDialog} title="Добавить карту ГСК" okButtonHandler={() => form.submit()} isLoading={isLoading} width={500}>
			<Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
				form={form}
				onFinish={submit}
			>
				<Form.Item label='Название карты' name='name' rules={[{required: true, message: 'Выберите название'}]}>
					<Input/>
				</Form.Item>
				<Form.Item label="Карта ГСК" rules={[{required: true, message: 'Выберите изображение карты'}]}>
					<Upload<File>
						accept='image/png, image/gif, image/jpeg'
						multiple={false}
						beforeUpload={file => {
							handleUpload(file)
							// Prevent upload
							return false;
						}}
						maxCount={1}
						onRemove={() => handleUpload(null)}
					>
						<Button icon={<UploadOutlined />}>Выбрать</Button>
					</Upload>
				</Form.Item>
			</Form>
		</ModalBase>
	);
}
