import { Button, Spin } from "antd";
import { PaymentModel } from "../../../types/BillingTypes";
import { GskModel } from "../../../types/GskTypes";
import { toMoneyString } from "../../../utils/moneyHelper";

interface PayTabProps {
  isNewGsk: boolean;
  gsk: GskModel;
  payment?: PaymentModel;
}
export default function GskPayTab({isNewGsk, gsk, payment} : PayTabProps) {
  return (
  <Spin spinning={!payment}>
    <div style={{textAlign: 'center'}}>
      {isNewGsk && <>
        <h3><b>{gsk.name}</b> зарегистрирован!</h3>
      </>}
      <p>Осталось оплатить подписку</p>
      {payment && <>
        <p>К оплате: {toMoneyString(payment.price)}</p>
        <Button style={{background: 'lightgreen'}} onClick={() => window.open(payment.confirmationUrl, '_self')}>Оплатить</Button>
      </>}
    </div>
  </Spin>)
}