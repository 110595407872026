import { Form } from "antd";
import { MaskedInput } from "antd-mask-input";

export interface Props {
  path?: string[] | string;
  required?: boolean;
}
export default function PhoneInput({path = 'phone', required = true} : Props) {
  const checkPhone = (_: any, value: string ) => {
    if (!!value && value.replaceAll('_', '').replaceAll('+', '').replaceAll(' ','').replaceAll('(','').replaceAll(')','').length === 11) {
      return Promise.resolve();
    }
    if (!required && !value) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Введите корректный телефон'));
  };

  return (
  <Form.Item label='Моб. телефон' name={path} rules={[{required: required}, {validator: checkPhone }]} >
    <MaskedInput mask={[{mask: '+7 (000) 000 00 00', lazy: false}]} style={{paddingLeft: 5, paddingBottom: 0, paddingTop: 0 }}/>
  </Form.Item>
)}