import { GskHistoryFilter, GskHistoryModel, GskHistoryRequest } from "../types/GskHistoryTypes";
import { Fetch } from "../utils/fetch";
import { PagedResult } from "../types/dto";

export const GskHistoryService = {
	getPage: async (gskId: number, filter: GskHistoryRequest): Promise<PagedResult<GskHistoryModel> | undefined> => {
		return await Fetch<PagedResult<GskHistoryModel>>(`/api/gsks/${gskId}/logs/`, "POST", filter);
	},
	getAdminHistory: async (gskId: number, filter: GskHistoryFilter): Promise<PagedResult<GskHistoryModel> | undefined> => {
		return await Fetch<PagedResult<GskHistoryModel>>(`/api/gsks/${gskId}/logs/all`, "POST", filter);
	},
}