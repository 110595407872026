import { useState } from 'react'
import ModalBase from '../ModalBase/ModalBase';
import { InputNumber, Form, Switch, Input } from 'antd'
import { useForm } from 'antd/es/form/Form';
import RowTwoCol from '../RowTwoCol/RowTwoCol';
import { BoxesService } from '../../Services/BoxesService';
import useLoading from '../../utils/hooks/useLoading';
import { GskBoxWithUser } from '../../types/GskTypes';

interface FormModel {
  boxNum: string;
  rangeStart: number;
  rangeEnd: number;
}

interface OwnProps {
  gskId: number;
  closeDialog: CallableFunction;
  onAdded: (v: GskBoxWithUser[]) => void;
}

const AddBoxModal = ({ closeDialog, onAdded, gskId }: OwnProps) => {
  const [isLoading, load] = useLoading();
  const [isMultiply, setIsMultiply] = useState(false);
  const [form] = useForm<FormModel>();

  const submit = async (data: FormModel) => {
    if (isMultiply) {
      const resp = await load(BoxesService.addBoxes(gskId, data.rangeStart, data.rangeEnd));
      if (resp) {
        onAdded(resp);
        closeDialog();
      }
    } else {
      const resp = await load(BoxesService.addBox(gskId, data.boxNum));
      if (resp) {
        onAdded([resp]);
        closeDialog();
      }
    }
  }

  const checkRangeStart = (value?: number) => {
    if (!value) {
      return Promise.reject('Введите значеие');
    }
    const rangeEnd = Number.parseInt(form.getFieldValue('rangeEnd') ?? -1);

    if (value < 0 || value >= rangeEnd) {
      return Promise.reject('Неверный диапазон');
    }

    return Promise.resolve();
  }

  const checkRangeEnd = (value?: number) => {
    if (!value) {
      return Promise.reject('Введите значеие');
    }
    const rangeStart = Number.parseInt(form.getFieldValue('rangeStart') ?? -1);

    if (value < 0 || value <= rangeStart) {
      return Promise.reject('Неверный диапазон');
    }

    return Promise.resolve();
  }

  return (
    <ModalBase closeDialog={closeDialog} title="Добавить бокс" okButtonHandler={() => form.submit()} isLoading={isLoading} width={400}>
      <Form
        size='small'
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
          boxNum: '',
          rangeStart: 1,
          rangeEnd: 5,
        }}
        onFinish={submit}
      >
        <Form.Item label='Несколько'>
          <Switch checked={isMultiply} onChange={setIsMultiply}/>
        </Form.Item>
        {isMultiply ? 
        <RowTwoCol
          left={<Form.Item 
            name='rangeStart'
            label='С'
            rules={[{required: isMultiply, validator: (_, v) => checkRangeStart(v)}]}    
          >
            <InputNumber />
          </Form.Item>}
          right={<Form.Item 
            name='rangeEnd'
            label='По'
            rules={[{required: isMultiply, validator: (_, v) => checkRangeEnd(v)}]} 
          >
            <InputNumber />
          </Form.Item>}
        />
        :
        <Form.Item 
          name='boxNum' 
          label='Номер бокса'
          rules={[{required: !isMultiply, message: 'Введите занчение'}]} 
        >
          <Input />
        </Form.Item>}
      </Form>
    </ModalBase>
  );
}

export default AddBoxModal;