import { AddressModel } from "../types/AddressTypes";
import { getAddressCache, setAddressCashe } from "../store/common/address-slice";
import { AppThunkAction } from "../store/configureStore";
import { Fetch } from "../utils/fetch";

export const AddressService = {
	suggest: (address: string): AppThunkAction<any,  Promise<AddressModel[]>> => async (dispatch, getState) => {
		address = address.trim().toLocaleLowerCase();
		const cached = getAddressCache(address)(getState());
		if (cached !== null) {
			return cached;
		}

		const resp = await Fetch<AddressModel[]>('/api/Address/Suggest', "GET", {address});
		if (resp) {
			dispatch(setAddressCashe({key: address, addresses: resp}));
			return resp;
		}
		return [];
	},
}