import { useEffect, useState } from "react";
import RowTwoCol from "../RowTwoCol/RowTwoCol";
import { Input, Spin } from "antd";
import IconButton from "../IconButton/IconButton";
import { SyncOutlined } from "@ant-design/icons";

interface Props {
  captchaKey: string;
  value?: string;
  onChange?: (v: string) => void;
  refreshKey: () => void;
}
const CAPTCHA_TIMOUT_SECONDS = 55;
export default function Captcha({captchaKey, value, onChange = () => null, refreshKey}: Props) {
  const [seconds, setSeconds] = useState(CAPTCHA_TIMOUT_SECONDS);

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds <= 0) {
        refreshKey();
        setSeconds(CAPTCHA_TIMOUT_SECONDS);
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  })
  return (<>
    <RowTwoCol style={{width: 170}}
      left={
        <Spin spinning={!captchaKey}>
          {captchaKey && <img src={`/api/captcha/${captchaKey}/image`} alt="captcha"></img>}
        </Spin>
      }
      right={
        <IconButton icon={<SyncOutlined/>} onClick={() => {refreshKey(); setSeconds(CAPTCHA_TIMOUT_SECONDS)}} title="Обновить" />
      }
      rightSize={20}
    />
    <Input size="small" style={{width: 180}} value={value} onChange={e => onChange(e.target.value)} addonAfter={<span title="Время до обновления">0:{seconds}</span>} /> 
  </>)
}