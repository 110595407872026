import { useForm } from "antd/es/form/Form";
import { NewPostModel, PostCoverModel, PostModel } from "../../types/PostTypes";
import dayjs from 'dayjs'
import { PostService } from "../../Services/PostsService";
import ModalBase from "../ModalBase/ModalBase";
import { Form, Input, Switch } from "antd";
import EditVoteConfigurationCommon from "./Base/EditVoteConfigurationPartial";
import { VotePosition } from "../../types/PostBaseTypes";
import { useState } from "react";
import TagsBlock from "./TagsBlock";
import { PostCoverEditFields } from "./Base/PostCoverBlock";
import TipTapEditor from "../RichTextEditor/TipTapEditor";
import { PagedResult } from "../../types/dto";
import useLoading from "../../utils/hooks/useLoading";

interface OwnProps {
  post: PostModel | null;
  setPostCoversPage?: React.Dispatch<React.SetStateAction<PagedResult<PostCoverModel>>>;
  setPost?: (v: React.SetStateAction<PostModel | undefined>) => void;
  closeDialog: CallableFunction;
}

interface FormData extends NewPostModel {
  voteEnabled: boolean;
  coverImageId?: number;
}

export default function EditPostModal({ post, setPostCoversPage, setPost, closeDialog }: OwnProps) {
	const [isLoading, load] = useLoading();
  const [form] = useForm<FormData>();
	const [coverImage, setCoverImage] = useState<string | undefined>(undefined);

  const submit = async (data: FormData) => {
    const req: NewPostModel = {
      commentsEnabled: data.commentsEnabled,
      data: data.data,
      title: data.title,
      voteConfiguration: data.voteConfiguration,
      coverImage: coverImage,
      coverText: data.coverText,
      tags: data.tags,
    }
    if (req.voteConfiguration) {
      req.voteConfiguration.dateFinish = dayjs(data.voteConfiguration?.dateFinish, "DD.MM.YYYY").toDate()
    }
    if (post) {
      data.coverImage = coverImage;
      if ((data.coverImage || data.coverImageId) && !data.coverText){
        data.coverText = '';
      }
      const resp = await load(PostService.editPost(post.id, data));
      if (resp) {
        if (setPost) {
          setPost(resp);
        }
        closeDialog();
      }
    } else {
      const resp = await load(PostService.addPost(req));
      if (resp) {
        if (setPostCoversPage) {
          setPostCoversPage(prev => ({...prev, items: [toCover(resp), ...prev.items] }));
        }
        closeDialog();
      }
    }
  }
  
  return (
    <ModalBase 
      closeDialog={closeDialog}
      title={post ? 'Изменить пост' : 'Добавить пост'}
      okButtonHandler={() => form.submit()}
      isLoading={isLoading}
      width={1000}
    >
      <Form
        layout={'horizontal'}
        labelCol={{ span: 6 }}
        form={form}
        size={'small'}
        initialValues={{
          title: post?.title ?? '',
          commentsEnabled: post?.commentsEnabled ?? true,
          data: post?.data ?? '',
          voteEnabled: !!post?.voteConfiguration,
          voteConfiguration: {...post?.voteConfiguration,
            dateFinish: post?.voteConfiguration?.dateFinish ? dayjs(post.voteConfiguration.dateFinish) : undefined,
            position: post?.voteConfiguration?.position ?? VotePosition.BeforePost,
            isAnonymous: post?.voteConfiguration?.isAnonymous ?? false,
            showResultsBeforeFinish: post?.voteConfiguration?.showResultsBeforeFinish ?? true,
          },
          coverImageId: post?.coverImageId,
          coverText: post?.coverText,
          tags: post?.tags,
        }}
        onFinish={submit}
      >
        <Form.Item name='coverImageId' noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item label='Заголовок' name='title' rules={[{required: true, message: 'Введите заголовок'}]}>
          <Input />
        </Form.Item>
        <PostCoverEditFields form={form} setCoverImage={setCoverImage} />
        <Form.Item label='Теги' name='tags'>
          <TagsBlock editMode/>
        </Form.Item>
        <Form.Item 
          label='Комментарии' 
          name='commentsEnabled'
          valuePropName='checked'
        >
          <Switch />
        </Form.Item>
        <EditVoteConfigurationCommon form={form} />
        <Form.Item name='data' rules={[{required: true, message: 'Введите содержание'}]} >
          <TipTapEditor editable={true} />
        </Form.Item>
      </Form>
    </ModalBase>
  );
}


const toCover = (post: PostModel): PostCoverModel => {
  return {
    created: post.created,
    createdBy: post.createdBy,
    id: post.id,
    tags: post.tags, 
    title: post.title,
    coverImageId: post.coverImageId,
    coverText: post.coverText,
  }
}