import { Button, Card, Col, Flex, Form, Grid, Input, Radio, Row, Select, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import GroupBox from "../../components/GroupBox/GroupBox";
import { MailingService } from "../../Services/MailingService";
import TextArea from "antd/lib/input/TextArea";
import { getEnumValues } from "../../utils/enumUtils";
import { GetNotificationEventName, NotificationEvent } from "../../types/UserTypes";
import { MailingRequest, MainingType } from "../../types/MailingTypes";
import GskRolesSelect from "../../components/Gsk/GskRolesSelect";
import { useForm } from "antd/es/form/Form";
import { GskRole } from "../../types/GskTypes";
import { ShowSuccess } from "../../components/Notifications/Notifications";
import useLoading from "../../utils/hooks/useLoading";

const shortcats = [
	{shortcat: '{%user.name%}', value: 'Иван'},
	{shortcat: '{%user.surname%}', value: 'Иванов'},
	{shortcat: '{%user.patronymic%}', value: 'Иванович'},
]

const applyShortcats = (str: string) => {
	let result = str;
	if (!result) {
		return '';
	}
	shortcats.forEach(s => {
		result = result.replaceAll(s.shortcat, s.value);
	});
	return result;
}

export default function MailingPage () {
	const [isLoading, load] = useLoading();
	const [email, setEmail] = useState('dima.trifonov@mail.ru');
	const [form] = useForm<MailingRequest>();
	const type = Form.useWatch('type', form);
	const title = Form.useWatch('title', form);
	const body = Form.useWatch('body', form);
	
	useEffect(() => {
		
	}, [title, body])


	const {sm} = Grid.useBreakpoint();
	const handleSendTestMail = async () => {
		await load(MailingService.sendTestMail(email));
	}

	const handleMailingSubmit = async (data: MailingRequest) => {
		if (await load(MailingService.manualSend(data))) {
			ShowSuccess("MailingService", "Рассылка добавлена в очередь отправки");
			form.setFieldValue('email', '');
			form.setFieldValue('roles', []);
		}
	}

	return (<Spin spinning={isLoading} style={{width: '100%'}}>
		<Card>			
			<GroupBox label='Тестовое письмо'>
				<Space>
					<Input value={email} onChange={e => setEmail(e.target.value)} />
					<Button onClick={handleSendTestMail}>Отправить</Button>
				</Space>
			</GroupBox>
			<GroupBox label='Рассылка'>

				<Form
					form={form}
					initialValues={{
						event: NotificationEvent.PortalNews,
						roles: [GskRole.Admin, GskRole.Chairman],
						type: MainingType.Roles,
					}}
					layout="vertical"
					onFinish={handleMailingSubmit}
				>
					<Form.Item label='Категория рассылки' name='event' rules={[{required: true, message: 'Введите значение'}]}>
						<Select style={{maxWidth: '100%'}} maxTagTextLength={10} options={getEnumValues(NotificationEvent).map(e =>
						({
							label: GetNotificationEventName(e),
							value: e,
						}))
					} />
					</Form.Item>
					<Row>
						<Form.Item label='Получатели' name='type' rules={[{required: true, message: 'Введите значение'}]}>
							<Radio.Group>
								<Radio value={MainingType.Roles}>Роли</Radio>
								<Radio value={MainingType.Email}>Email</Radio>
							</Radio.Group>
						</Form.Item>
					</Row>
					{type === MainingType.Email && <Form.Item name='email' rules={[{required: true, message: 'Введите значение'}]}>
						<Input placeholder="user@mail.com"/>
					</Form.Item>}
					{type === MainingType.Roles && <Form.Item name='roles' rules={[{required: true, message: 'Введите значение'}]}>
						<GskRolesSelect/>
					</Form.Item>}
					<Flex vertical={!sm}>
						<Col style={{width: sm ? 'calc(100% - 300px)' : '100%'}}>
							<Form.Item label='Заголовок' name='title' rules={[{required: true, message: 'Введите значение'}]}>
								<Input/>
							</Form.Item>
							<Form.Item label='Содержание' name='body' rules={[{required: true, message: 'Введите значение'}]}>
								<TextArea rows={10}/>
							</Form.Item>
						</Col>
						<Col style={{width: sm ? '300px' : '100%', paddingLeft: sm ? 10 : 0, paddingBottom: 10}}
						>
							<GroupBox label='Ярлыки'>
								{shortcats.map(s => 
									<HelpRow key={s.shortcat} shortcat={s.shortcat} value={s.value}/>
								)}
							</GroupBox>
						</Col>
					</Flex>
					<Form.Item label='Предпросмотр'>
						<div dangerouslySetInnerHTML={{__html: applyShortcats(title) + '<br/><br/>' + applyShortcats(body)}}></div>
					</Form.Item>
					<Flex justify="flex-end">
						<Button disabled={isLoading} onClick={() => form.submit()}>Отправить</Button>
					</Flex>
				</Form>
			</GroupBox>
		</Card>
	</Spin>)
}

interface HelpRowProps{
	shortcat: string;
	value: string;
}

const HelpRow = ({shortcat, value}: HelpRowProps) => {
	return (<Row><b>{shortcat}</b>&nbsp;-&nbsp;<span style={{color: 'gray'}}>{value}</span></Row>)
}