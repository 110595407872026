import { useEffectOnce } from "../../../utils/hooks/useEffectOnce";
import { Checkbox, Form, FormInstance, Input } from "antd";
import { GskModel } from "../../../types/GskTypes";
import Link from "antd/es/typography/Link";
import { GskBillingService } from "../../../Services/GskBillingService";
import { useState } from "react";
import { GskBillingSettingsModel } from "../../../types/GskBillingTypes";
import FloatInput from "../../Controls/FloatInput";
import { NumInputValidator } from "../../../utils/NumInputValidator";

interface Props {
  form: FormInstance;
  gsk?: GskModel | null;
  isActiveTab: boolean;
  load: <T>(input: Promise<T> | (() => Promise<T>)) => Promise<T | undefined>;
}

export default function GskBillingTab({form, isActiveTab, gsk, load} : Props) {
  const [settings, setSettings] = useState<GskBillingSettingsModel | undefined>();

  const enabled = Form.useWatch('enabled', form);

  useEffectOnce(() => {
    if (!gsk?.id){
      return;
    }
    const fetch = async () => {
      const resp = await load(GskBillingService.getBillingSettings(gsk.id));
      if (resp) {
        setSettings(resp);
        form.setFieldsValue(resp);
      }
    }
    fetch();
  })

  return (<>
    <Form.Item
      labelCol={{span: 10}}
      wrapperCol={{span: 14}}
      label='ShopId'
      name='shopId'
      tooltip={settings && 'В целях безопасности мы не показываем полностью эту информацию'}
      rules={[{required: isActiveTab, message: 'Введите значение'}]}
    >
      <Input disabled={!enabled}/>
    </Form.Item>
    <Form.Item
      labelCol={{span: 10}}
      wrapperCol={{span: 14}}
      label='Секретный ключ'
      name='secretKey'
      tooltip={settings && 'В целях безопасности мы не показываем полностью эту информацию'}
      rules={[{required: isActiveTab, message: 'Введите значение'}]}
    >
      <Input disabled={!enabled}/>
    </Form.Item>
    <Form.Item
      labelCol={{span: 10}}
      wrapperCol={{span: 14}}
      label='Комиссия, %'
      tooltip='Это значение будет добавлено к платежу для компенсации комиссии сервися ЮКасса'
      name='commissionPercent'
      rules={[NumInputValidator(0, 100, isActiveTab)]}
    >
      <FloatInput addonAfter='%' style={{width: '100%'}} disabled={!enabled}/>
    </Form.Item>
    <Form.Item
      labelCol={{span: 10}}
      wrapperCol={{span: 14}}
      label='Включено'
      name='enabled'
      initialValue={false}
      valuePropName="checked"
    >
      <Checkbox />
    </Form.Item>
    <center>
      <Link target="_blank" href={'/help/gskBilling/yookassa?gskName='+gsk?.name+'&gskId='+gsk?.id}>Инструкции по настройке</Link>
    </center>
  </>)
}