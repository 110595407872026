import { useParams } from "react-router-dom";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/redux";
import { useDialogWithParameter } from "../../utils/useDialog";
import { GskPostModel } from "../../types/GskPostTypes";
import { GskPostService } from "../../Services/GskPostsService";
import PostBlock from "../../components/Gsk/Post/PostBlock";
import EditPostModal from "../../components/Gsk/Post/EditPostModal";
import { GskRole } from "../../types/GskTypes";
import useLoading from "../../utils/hooks/useLoading";
import { currentUserHasGskRoleSelector } from "../../store/common/app-slice";
import useCurrentGsk from "./useCurrentGsk";

export default function GskPostPage () {
  const params = useParams();
	const postId: number = parseInt(params.postId ?? "-1");
  const [isLoading, load] = useLoading();
  const gsk = useCurrentGsk();
  const [post, setPost] = useState<GskPostModel | undefined>(undefined);
	const isChairman = useAppSelector(currentUserHasGskRoleSelector(GskRole.Chairman));

	const [editPostModal, openEditPostModal] = useDialogWithParameter<GskPostModel | null>(
		(post, closeDialog) => gsk && <EditPostModal gsk={gsk} closeDialog={closeDialog} post={post} setPost={setPost}/>
	);

  useEffect(() => {  
    const loadPost = async () => {
      if (postId > 0 && gsk?.id) {
        const resp = await load(GskPostService.getPost(gsk.id, postId));
        if (resp) {
          setPost(resp);
        }
      }
    }
    loadPost();
  }, [postId, gsk?.id, load]);
  
  return (
    <Spin spinning={isLoading}>
      {post && gsk && <PostBlock gsk={gsk} canEdit={isChairman} openEditPostModal={openEditPostModal} setPost={setPost} post={post}/>}
      {editPostModal}
    </Spin>
  )
}