import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import 'react-notifications-component/dist/theme.css'
import { useAppSelector } from '../../store/redux'
import { useEffect, useMemo, useRef } from 'react'
import { Content, Header } from 'antd/es/layout/layout'
import { Flex, Layout, Menu, Tooltip } from 'antd'
import {  GskRole } from '../../types/GskTypes'
import { MenuProps } from 'antd/lib'
import { AppstoreOutlined, CopyOutlined, DollarOutlined, EnvironmentOutlined, HistoryOutlined, TeamOutlined } from '@ant-design/icons'
import Title from 'antd/es/typography/Title'
import { ConisIcon, MeterIcon } from '../icons'
import RowTwoCol from '../RowTwoCol/RowTwoCol'
import { UserRole } from '../../types/UserTypes'
import { ItemType } from "antd/lib/menu/hooks/useItems";
import '../Layout.scss'
import useCurrentGsk from '../../pages/Gsk/useCurrentGsk'
import { RefType, setAppRef } from '../../store/enum/RefType'
import { HeaderOneRow } from '../Headers/HeaderOne'
import { DefaultFooter } from '../LayoutPage'
import { currentUserHasGskRoleSelector, useCurrentUser } from '../../store/common/app-slice'
import SubscriptionWarning from '../Billing/SubscriptionWarnig'
import { OpenGskModalProps } from './GskModal/GskModal'

interface Props{
	renderUserItems: (gskId?: number) => ItemType[];
	openGskModal: (p: OpenGskModalProps) => void;
}
export default function GskLayout ({ renderUserItems, openGskModal }: Props) {
  const currentUser = useCurrentUser();
  const gsk = useCurrentGsk();
  const currentUserIsAccountant = useAppSelector(currentUserHasGskRoleSelector(GskRole.Accountant));
  const currUserIsChairman = useAppSelector(currentUserHasGskRoleSelector(GskRole.Chairman));
  const currUserIsMember = useAppSelector(currentUserHasGskRoleSelector(GskRole.Member));
  const nav = useNavigate();

	const boxesRef = useRef(null);
	const casheRef = useRef(null);
	const accrualsRef = useRef(null);

  let location = useLocation();
  const current = useMemo(() => {
    const parts = location.pathname.split('/');
    if (parts.length > 3) {
      return parts[3].toLowerCase();
    }
    return '';
  }, [location]);
  

	useEffect(() => {
		setAppRef(RefType.GskMenuBoxesLink, boxesRef);
	}, [boxesRef]);

	useEffect(() => {
		setAppRef(RefType.GskMenuCasheLink, casheRef);
	}, [casheRef]);
  
	useEffect(() => {
		setAppRef(RefType.GskMenuAccrualsLink, accrualsRef);
	}, [accrualsRef]);

  const items: MenuProps['items'] = useMemo(() => [
    {
      icon: <AppstoreOutlined />,
      label: <Tooltip title={currUserIsMember ? undefined : 'Вы не являетесь членом этого ГСК'}><Link to="boxes"><span className='label' ref={boxesRef}>Боксы</span></Link></Tooltip>,
      key: 'boxes',
      disabled: !currUserIsMember,
      className: 'menu-item-short',
      children: [{
        icon: <MeterIcon />,
        label: <Link to='meters'><span className='label'>Счетчики</span></Link>,
        key: 'meters',
        // onclick: () => nav('Meters'), //TODO разобрать почему не работает
        style: {display: currentUserIsAccountant ? 'inherit' : 'none'},
      }]
    },
    {
      icon: <TeamOutlined />,
      label: <Tooltip title={currUserIsMember ? undefined : 'Вы не являетесь членом этого ГСК'}><span className='label'>Пользователи</span></Tooltip>,
      onClick: () => nav('Users'),
      disabled: !currUserIsMember,
      key: 'users',
      className: 'menu-item-short',
      style: {display: currentUser?.role === UserRole.Admin ? 'inherit' : 'none'},
    },
    {
      icon: <EnvironmentOutlined />,
      label: <Tooltip title={currUserIsMember ? undefined : 'Вы не являетесь членом этого ГСК'}><span className='label'>Карта</span></Tooltip>,
      onClick: () => nav('Maps'),
      disabled: !currUserIsMember,
      key: 'maps',
      className: 'menu-item-short',
      style: {display: 'none'},
    },
    {
      icon: <DollarOutlined />,
      label: <Tooltip title={currUserIsMember ? undefined : 'Вы не являетесь членом этого ГСК'}><span className='label' ref={casheRef}>Взносы</span></Tooltip>,
      onClick: () => nav('Cash'),
      disabled: !currUserIsMember,
      key: 'cash',
      className: 'menu-item-short',
    },
    {
      icon: <ConisIcon />,
      label: <Tooltip title={currUserIsMember ? undefined : 'Вы не являетесь членом этого ГСК'}><span className='label' ref={accrualsRef}>Начисления</span></Tooltip>,
      onClick: () => nav('Accruals'),
      disabled: !currUserIsMember,
      key: 'accruals',
      className: 'menu-item-short',
      style: {display: currentUserIsAccountant ? 'inherit' : 'none'},
    },
    {
      label: <Tooltip title={currUserIsMember ? undefined : 'Вы не являетесь членом этого ГСК'}><span className='label'>Документы</span></Tooltip>,
      onClick: () => nav('Docs'),
      disabled: !currUserIsMember,
      title: 'Документы и образцы заявлений',
      key: 'docs',
      icon: <CopyOutlined />,
      className: 'menu-item-short',
    },
    {
      icon: <HistoryOutlined />,
      label: <Tooltip title={currUserIsMember ? undefined : 'Вы не являетесь членом этого ГСК'}><span className='label'>Логи</span></Tooltip>,
      onClick: () => nav('Logs'),
      disabled: !currUserIsMember,
      key: 'logs',
      className: 'menu-item-short',
      style: {display: currUserIsChairman ? 'inherit' : 'none'},
    },
    ...renderUserItems(gsk?.id),
  ], [currUserIsChairman, currUserIsMember, currentUser?.role, currentUserIsAccountant, gsk?.id, nav, renderUserItems]);

  return (
    <>
      <Header style={{ backgroundColor: 'white', padding: 0 }}>
        <Flex justify='center'> 
					<Layout style={{maxWidth: 1200, backgroundColor: 'white'}}>
            <RowTwoCol
              left={<Title style={{margin: 'auto'}}><NavLink to=""><HeaderOneRow>{gsk?.name}</HeaderOneRow></NavLink></Title>}
              leftSize={200}
              right={
                <Menu 
                  selectedKeys={[current]}
                  style={{
                    justifyContent: 'right',
                    width:'100%',
                  }} 
                  mode="horizontal" 
                  items={items}
                />
              }
            />
          </Layout>
        </Flex>
      </Header>
      <Content style={{paddingTop:10}}>
        <Flex justify='center'> 
					<Layout style={{maxWidth: 1200}}>
			      {gsk && <SubscriptionWarning gsk={gsk} openGskModal={openGskModal}/>}
            <Outlet />
          </Layout>
        </Flex>
      </Content>
			<DefaultFooter/>
    </>
  )
}