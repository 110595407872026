import { Select, Tooltip } from "antd";
import { SelectProps } from "antd/lib";
import { useState } from "react";
import { GskService } from "../../Services/GskService";
import { Gsk } from "../../types/GskTypes";
import { useEffectOnce } from "../../utils/hooks/useEffectOnce";

interface GskSelectProps extends SelectProps {

}
let currentValue: string;
let timeout: ReturnType<typeof setTimeout> | null;
export default function GskSelect (props: GskSelectProps) {
  const [data, setData] = useState<Gsk[]>([]);

  const fetch = (value: string, callback: Function) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
  
    const load = async () => {
      const resp = await GskService.find(value);
      if (currentValue === value) {
        callback(resp);
      }
    };
    timeout = setTimeout(load, 500);
  };

  const handleSearch = (newValue: string) => {
    fetch(newValue, setData);
  };

  useEffectOnce(() => fetch('', setData))

  return (<Select
    {...props}
    allowClear={true}
    showSearch
    placeholder={props.placeholder}
    style={props.style}
    defaultActiveFirstOption={false}
    suffixIcon={null}
    filterOption={false}
    onSearch={handleSearch}
    notFoundContent={null}
    optionRender={o => <Tooltip title={o.data.desc}><b>{o.data.label}</b> {o.data.desc}</Tooltip> }
    options={(data || []).map((d) => ({
      value: d.id,
      label: d.name,
      desc: d.address.value
    }))}
  />)
}