import { useMemo, useState } from "react";
import { UserGskData, UserModel, getUserFullName } from "../../types/UserTypes";
import { ColumnsType } from "antd/es/table";
import { Card, Flex, Space, Tooltip } from "antd";
import IconButton from "../IconButton/IconButton";
import { EditOutlined, SyncOutlined } from "@ant-design/icons";
import { Table } from "antd/lib";

interface UsersTableProps {
  isLoading: boolean;
  usersFiltered: UserModel[];
  renderRoles: (gsks: { [gskId: number]: UserGskData }) => React.ReactNode;
  totalCount: number;
  pageSize?: number;
  onChangePage?: (page: number) => void;
  openProfileModal: (user: UserModel) => void;
}
export default function UsersTable ({isLoading, usersFiltered, renderRoles, totalCount, pageSize = 15, onChangePage = () => null, openProfileModal}: UsersTableProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const columns: ColumnsType<UserModel> = useMemo(() => {
    const t: ColumnsType<UserModel> = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'ФИО',
      key: 'fio',
      render: (_, row) => getUserFullName(row),
      showSorterTooltip: false,
    },
    {
      title: 'Логин',
      key: 'login',
      dataIndex: 'login',
      responsive: ['sm'],
    },
    {
      title: 'Телефон',
      key: 'phone',
      dataIndex: 'phone',
    },
    {
      title: 'E-mail',
      key: 'email',
      dataIndex: 'email',
      responsive: ['md'],
    },
    {
      title: 'Права',
      key: 'gskroles',
      render: (_, row) => renderRoles(row.gsks)
    },
    {
      key: 'actions',
      title: <Flex justify="end"><IconButton icon={<SyncOutlined/>} onClick={() => onChangePage(currentPage)} /></Flex>,
      render: (_, row) => <Flex justify="flex-end">
        <Space>
          <IconButton icon={<EditOutlined />} onClick={() => openProfileModal(row)} />
        </Space>
      </Flex>,
    },
  ]
  return t;
  }, [currentPage, onChangePage, openProfileModal, renderRoles])

  return (
    <Card>
      <Table
        loading={isLoading}
        size="small"
        columns={columns}
        dataSource={usersFiltered}
        pagination={{
          hideOnSinglePage: true,
          pageSize: pageSize,
          
          total: totalCount,
          onChange: (page) => {onChangePage(page); setCurrentPage(page);} ,
        }}
        rowKey='id'
        footer={() => <Flex justify="flex-end">
          <Tooltip title='Всего'>{totalCount}</Tooltip></Flex>}
      />
    </Card>
  )
}