import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Card, Flex, Spin } from "antd";
import { UserService } from "../Services/UserService";
import { ShowSuccess } from "../components/Notifications/Notifications";
import useLoading from "../utils/hooks/useLoading";


export default function ConfirmRegisterPage () {
	const params = useParams();
  const navigate = useNavigate();
	const key = params.key;
  const [confirmResult, setConfirmResult] = useState('Подтверждение учетной записи....');
  const [confirmSended, setConfirmSended] = useState(false)
  const [isLoading, load] = useLoading();

  useEffect(() => {
   if (key && !confirmSended) {
      setConfirmSended(true);
      (async () => {
        const resp = await load(UserService.confirmRegister(key));
        if (resp) {
          setConfirmResult("Учетная запись подтверждена");
          ShowSuccess("", "Учетная запись подтверждена");
          navigate('/');
        } else {
          setConfirmResult("Ошибка при подтверждении учетной записи");
        }
      })();
    }
  }, [confirmSended, key, load, navigate]);

	return (<Flex justify="center" align="center" style={{paddingTop: 20}}>
    <Spin spinning={isLoading}>
      <Card style={{ width: 400 }}>
        {confirmResult}
      </Card>
    </Spin>
  </Flex>)
}