import { Form, FormInstance, Row, Spin } from "antd";
import GroupBox from "../GroupBox/GroupBox";
import { EditUserModel, UserRole } from "../../types/UserTypes";
import { GskUserPermissionsModel } from "../../types/GskTypes";
import GskRolesSelect from "../Gsk/GskRolesSelect";
import { useState } from "react";
import { useCurrentUser } from "../../store/common/app-slice";
import { useEffectOnce } from "../../utils/hooks/useEffectOnce";
import { UserService } from "../../Services/UserService";

interface Props {
  userId: number;
  form: FormInstance<EditUserModel>;
  isLoading: boolean;
  load: <T>(input: Promise<T> | (() => Promise<T>)) => Promise<T | undefined>;
}

export default function GskTab({ userId, form, isLoading, load } : Props) {
  const currentUser = useCurrentUser();
  const currentUserIsGlobalAdmin = currentUser?.role === UserRole.Admin;
  
  // const gskInfo: GskUserPermissionsModel[] = Form.useWatch('gsks', form) ?? [];
  const [gskInfo, setGskInfo] = useState<GskUserPermissionsModel[]>([]);

  useEffectOnce(() => {
    if (!currentUserIsGlobalAdmin && userId !== currentUser?.id) {
      return;
    }
    const fetch = async() => {
      var resp = await load(UserService.getUserGskList(userId)) ?? [];
      form.setFieldValue('gsks', resp);
      setGskInfo(resp);
    }
    fetch();
  });

  return <Spin spinning={isLoading}>
    {gskInfo.map(gsk => 
      <GroupBox key={gsk.id} label={gsk.name} >
        <Form.Item label='Роли' labelCol={{span: 5}} >
          <GskRolesSelect disabled={true} value={gsk.gskRoles} />
        </Form.Item>
        <Form.Item label='Боксы' labelCol={{span: 5}}>
          {gsk.boxes.map(b => <Row key={b.num}>{b.num}</Row>)}
        </Form.Item>
      </GroupBox>
    )}
    {/* <Form.List name={['gsks']}>
      {(items) => 
        <>{items.map(i => 
            <GroupBox key={i.name} label={gskInfo[i.name]?.name}>
              <Form.Item label='Роли' name={[i.name, 'gskRoles']} rules={[{required: true, message: 'Выберите хотя бы одну роль'}]} labelCol={{span: 5}} >
                <GskRolesSelect disabled={true} />
              </Form.Item>
              <Form.Item label='Боксы' labelCol={{span: 5}}>
                {gskInfo[i.name]?.boxes.map(b => <Row key={b.num}>{b.num}</Row>)}
              </Form.Item> 
            </GroupBox>
          )}
        </>}
    </Form.List> */}
  </Spin>
}