import { Card, List, Modal, Space } from "antd";
import IconButton from "../../IconButton/IconButton";
import { DeleteOutlined, DislikeFilled, DislikeOutlined, EditOutlined, LikeFilled, LikeOutlined } from "@ant-design/icons";
import dayjs from 'dayjs'
import AddCommentBlock from "../../Gsk/Post/AddCommentBlock";
import { useCallback, useMemo, useState } from "react";
import EditCommentBlock from "../../Gsk/Post/EditCommentBlock";
import RowTwoCol from "../../RowTwoCol/RowTwoCol";
import { PostCommentModelBase } from "../../../types/PostBaseTypes";
import { useCurrentUser } from "../../../store/common/app-slice";

interface OwnProps {
  allComments: PostCommentModelBase[];
  comment: PostCommentModelBase;
  addCommentHandler: (v: string, parentCommentId: number | null) => void;
  likeCommentHandler: (isLike: boolean, comment: PostCommentModelBase) => void;
  editCommentHandler: (comment: PostCommentModelBase) => void;
  deleteCommentHandler: (commnetId: number) => void;
}

export default function PostComment({allComments, comment, addCommentHandler, likeCommentHandler, editCommentHandler, deleteCommentHandler,}: OwnProps) {
  const currentUser = useCurrentUser();
  const canEdit = currentUser?.id === comment.createdById && !comment.isDeleted;
  const [editMode, setEditMode] = useState(false);
  const childs = useMemo(() => allComments.filter(c => comment.id === c.parentCommentId), [allComments, comment.id]);

  const submitEdit = useCallback((newValue: string) => {
    if (comment.comment !== newValue) {
      editCommentHandler({...comment, comment: newValue});
    }
    setEditMode(false);
  }, [comment, editCommentHandler]);

  const handleDelete = async() => {
    Modal.confirm({
      title: "Удаление комментария",
      content: <>Вы действительно хотите удалить комментарий 
        <br/>
        <br/>
        <i>{comment.comment}</i>?
        <br/>
      </>,
      okText: "Удалить",
      cancelText: 'Отмена',
      okButtonProps: { color: "red" },
      onOk: async () => {
        await deleteCommentHandler(comment.id);
      }
    });
  }

  return (
    <div style={{marginTop: 3}}>
      {comment.isDeleted && childs.length === 0 ? <></> :<Card
        size="small"
        type="inner"
        key={comment.id}
        title={<>
          <div style={{display: 'inline-block'}}>{comment.createdBy} </div>
          <div style={{fontWeight: 'normal', fontSize: '0.8em', color: 'gray', display: 'inline-block'}}>
            &nbsp;{dayjs(comment.created).format("DD.MM.YYYY HH.mm")}
            {comment.edited && <> (изменено {dayjs(comment.edited).format("DD.MM.YYYY HH.mm")})</>}
          </div>
        </>}
        styles={{
          header: {padding: 3},
          body: {padding: 3}
        }}
      >
        {comment.isDeleted ? 
        <div style={{color: 'gray', fontStyle: 'italic'}}>Комментарий удален</div>
        :
        <>
          <RowTwoCol
            rightSize={50}
            left={editMode ? 
                <EditCommentBlock submitHandler={submitEdit} comment={comment.comment} onCancel={() => setEditMode(false)}/>
              : comment.comment
            }
            right={!editMode && canEdit &&
              <Space>
                <IconButton icon={<EditOutlined />} onClick={() => setEditMode(true)}/>
                <IconButton icon={<DeleteOutlined />} onClick={handleDelete}/>
              </Space>}
          />
         
          <RowTwoCol
            rightSize={100}
            left={
              <AddCommentBlock parentCommentId={comment.id} submitHandler={addCommentHandler}/>
            }
            right={
              <Space>
                <IconButton 
                  icon={comment.isCurrentUserLiked === true ? <LikeFilled /> : <LikeOutlined />} 
                  onClick={() => likeCommentHandler(true, comment)} 
                />{comment.likesCnt} &nbsp;
                <IconButton
                  icon={comment.isCurrentUserLiked === false ? <DislikeFilled /> :<DislikeOutlined />} 
                  onClick={() => likeCommentHandler(false, comment)} 
                />{comment.dislikesCnt}
              </Space>
            }
          />
        </>}
      </Card>}

      <List style={{paddingLeft: 20}}>
        {childs.map(c => 
          <PostComment 
            key={c.id} 
            allComments={allComments}
            comment={c}
            addCommentHandler={addCommentHandler}
            likeCommentHandler={likeCommentHandler}
            editCommentHandler={editCommentHandler}
            deleteCommentHandler={deleteCommentHandler}
          />)}
      </List>
    </div>
  )
}