import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Gsk, GskModel } from "../../types/GskTypes";
import { RootState } from "../configureStore";
import { PaymentType } from "../../types/GskChargeTypes";

export interface GskState {
	gsk?: GskModel;
	commissionPercent?: number;
	paymentTypes?: PaymentType[];
}

export function initGskState(): GskState {
	return {
		gsk: undefined,
		commissionPercent: undefined,
		paymentTypes: undefined,
	};
}

export const gskSlice = createSlice({
	name: 'gsk',
	initialState: initGskState(),
	reducers: {
		setCurrentGsk(state, action: PayloadAction<GskModel>) {
			state.gsk = action.payload
		},
		clearCurrentGskData(state) {
			state.gsk = undefined;
			state.commissionPercent = undefined;
			state.paymentTypes = undefined;
		},
		updateCurrentGskProps(state, action: PayloadAction<Gsk>) {
			if (!state.gsk) {
				return;
			}
			state.gsk.address = action.payload.address;
			state.gsk.name = action.payload.name;
			state.gsk.isPublic = action.payload.isPublic;
		},
		setCommissionPercent(state, action: PayloadAction<number>) {
			state.commissionPercent = action.payload;
		},
		setPaymentTypes(state, action: PayloadAction<PaymentType[] | undefined>) {
			state.paymentTypes = action.payload;
		},
	},
});

export const getGskState = (state: RootState) => state.gskSlise;
export const currentGskSelector = createSelector([getGskState], state => state.gsk);
export const gskBillingCommissionPercentSelector = createSelector([getGskState], state => state.commissionPercent);
export const gskPaymentTypesSelector = createSelector([getGskState], state => state.paymentTypes ?? []);

export default gskSlice.reducer;
export const {
	updateCurrentGskProps,
	setCurrentGsk,
	setCommissionPercent,
	clearCurrentGskData,
	setPaymentTypes,
} = gskSlice.actions;