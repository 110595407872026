import { LoginUserModel, UserModel, UsersFilterModel, RestorePasswordRequest, RestorePasswordConfirmModel, NewUserModel, EditUserModel, NotificationRule, VkUserModel, NewVkUserModel, VkAuthData } from "../types/UserTypes";
import { Fetch, FetchNoResp } from "../utils/fetch";
import { PagedResult } from "../types/dto";
import { CaptchaVerifyResponse, VerifyState } from "../types/CaptchaTypes";
import { GskUserPermissionsModel } from "../types/GskTypes";

export const UserService = {
	register: async (data: NewUserModel): Promise<VerifyState | undefined> => {
		return (await Fetch<CaptchaVerifyResponse>('/api/users', "PUT", data))?.verifyState;
	},
	confirmRegister: async (key: string):Promise<boolean> => {
		return await FetchNoResp('/api/users/register/confirm', "GET", {key});
	},
	logout: async (): Promise<boolean> => {
		const result = await FetchNoResp('/api/users/logout', "GET");
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('accessToken');
		return result;
	},
	getUserGskList: async (userId: number): Promise<GskUserPermissionsModel[] | undefined> => {
		return await Fetch<GskUserPermissionsModel[]>(`/api/users/${userId}/gsks`, "GET");
	},
	login: async (data: LoginUserModel): Promise<VerifyState | undefined> => {
		return (await Fetch<CaptchaVerifyResponse>('/api/users/login', "POST", data))?.verifyState;
	},
	getYandexClientId: async (): Promise<string | undefined> => {
		return await Fetch<string>('/api/users/yandexClientId', "GET");
	},
	loginYandex: async (accessToken: string): Promise<boolean> => {
		return await FetchNoResp('/api/users/loginYandex', "GET", { accessToken });
	},
	getVkAuthData: async (): Promise<VkAuthData | undefined> => {
		return await Fetch<VkAuthData>('/api/users/vkAuthData', "GET");
	},
	loginVk: async (code: string, deviceId: string, state: string): Promise<VkUserModel | null | undefined> => {
		return await Fetch<VkUserModel | null>('/api/users/loginVK', "GET", { code, deviceId, state });
	},
	registerVkUser: async (data: NewVkUserModel): Promise<boolean> => {
		return await FetchNoResp('/api/users/registerVkUser', "PUT", data);
	},
	delete: async (id: number): Promise<boolean> => {
		return await FetchNoResp(`/api/users/${id}`, "DELETE");
	},
	edit: async (userId: number, data: EditUserModel): Promise<UserModel | undefined> => {
		return await Fetch<UserModel>(`/api/users/${userId}`, "PATCH", data);
	},
	getUsers: async (filter: UsersFilterModel) => {
		return await Fetch<PagedResult<UserModel>>('/api/users/find', 'POST', filter);
	},
	getNotificationRules: async (userId: number): Promise<NotificationRule[] | undefined> => {
		return await Fetch<NotificationRule[]>(`/api/users/${userId}/notificationRules`, "GET", );
	},
	restorePasswordRequest: async (data: RestorePasswordRequest) => {
		return await Fetch<CaptchaVerifyResponse>('/api/users/restorePasswordRequest', 'POST', data);
	},
	restorePasswordConfirm: async (data: RestorePasswordConfirmModel) => {
		return await FetchNoResp('/api/users/restorePasswordConfirm', 'POST', data);
	},
}