import { Checkbox, Form, FormInstance, Spin } from "antd";
import { EditUserModel, GetNotificationEventName, NotificationEvent, UserRole } from "../../types/UserTypes";
import { UserService } from "../../Services/UserService";
import { useCurrentUser } from "../../store/common/app-slice";
import useLoading from "../../utils/hooks/useLoading";
import { useEffectOnce } from "../../utils/hooks/useEffectOnce";

interface Props {
  form: FormInstance<EditUserModel>;
}

export default function NotificationsTab({ form } : Props) {
  const userId = form.getFieldValue(['profile', 'id']);
  const [isLoading, load] = useLoading();
  
  const currentUser = useCurrentUser();
  const currentUserIsGlobalAdmin = currentUser?.role === UserRole.Admin;
  
  useEffectOnce(() => {
    if (!currentUserIsGlobalAdmin && userId !== currentUser?.id) {
      return;
    }
    const fetch = async() => {
      var resp = await load(UserService.getNotificationRules(userId));
      form.setFieldValue('notificationRules', resp?.filter(r => r.event !== NotificationEvent.ImportantNews));
    }
    fetch();
  });

  return <Spin spinning={isLoading}>
    <Form.List
      name={'notificationRules'}
    >
      {(fields) => (
        <>
          {fields.map((field) => (
            <Form.Item
              label={GetNotificationEventName(form.getFieldValue(['notificationRules', field.name]).event)}
              name={[field.name, 'enabled']}
              labelCol={{span: 22}}
              valuePropName="checked"
              key={field.key}
            >
              <Checkbox />
            </Form.Item>
          ))}
        </>
      )}
    </Form.List>
  </Spin>
}