import { Button, Form, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/es/form/Form";

interface FormData {
  comment: string;
}

interface OwnProps {
  comment: string,
  submitHandler: (v: string) => void;
  onCancel: () => void;
}

export default function EditCommentBlock({comment, submitHandler, onCancel}: OwnProps) {
  const [form] = useForm<FormData>();

  const submit = (data: FormData) => {
    try {
      submitHandler(data.comment);
    } catch {} 
  }

  return (
    <Form
      form={form}
      onFinish={submit}
      initialValues={{comment: comment}}
      style={{marginBottom:15}}
    >
      <Form.Item 
        name='comment'
        rules={[{required: true, message: 'Введите значение'}]}
      >
        <TextArea placeholder="Ваш комментарий"/>
      </Form.Item>
      <Space>
        <Button size="small" onClick={() => form.submit()}>Сохранить</Button>
        <Button size="small" onClick={() => onCancel()}>Отмена</Button>
      </Space>
    </Form>
  )
}