export interface INewPost{}
export interface IPostCreateInfo {
  createdBy: string;
  createdById: number;
  created: Date;
}
export interface NewPostModelBase<TVoteConfiguration> extends INewPost {
  commentsEnabled: boolean;
  title: string;
  data: string;
  voteConfiguration: TVoteConfiguration | null;
  coverImage?: string;
  coverText: string;
}

export interface PostModelBase<TCommentPostModel> extends IPostCreateInfo {
  id: number;
  likesCnt: number;
  dislikesCnt: number;
  isCurrentUserLiked: boolean | null;
  comments: TCommentPostModel[];
  viewsCount: number;
  isViewed: boolean;
  coverImageId?: number;
}

export interface NewPostCommentModelBase {
  parentCommentId: number | null;
  comment: string;
}

export interface EditPostCommentModelBase {
  comment: string;
}

export interface PostCommentModelBase {
  id: number;
  postId: number;
  parentCommentId: number | null;
  comment: string;
  createdBy: string;
  createdById: number;
  likesCnt: number;
  dislikesCnt: number;
  isCurrentUserLiked: boolean | null;
  created: Date;
  edited: Date | null;
  isDeleted: boolean;
}

export enum VotePosition
{
  BeforePost = 0,
  AfterPost = 1,
}

export interface VoteConfigurationBase<TQuestion> {
  position: VotePosition;
  isAnonymous: boolean;
  isCurrentUserVoited: boolean;
  isVoteFinished: boolean;
  showResultsBeforeFinish: boolean;
  dateFinish: Date;
  votersTotalCount: number;
  votedCount: number;
  questions: TQuestion[];
} 

export interface QuestionModelBase<TOption> {
  id: number | null;
  question: string;
  options: TOption[];
  allowedToChoiceQty: number;
  order: number;
}

export interface OptionModelBase {
  id: number | null;
  name: string;
  order: number;
  votesCount: number | null;
  isCurentUserVoted: boolean;
  voters: string[];
}

export interface IVoteModel{};
export interface VoteModelBase<TVoteAnswer> extends IVoteModel {
  voteId: number;
  answers: TVoteAnswer[];
}

export interface VoteAnswerBase {
  questionId: number;
  optionsIds: number[];
}
