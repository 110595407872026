import { Grid, Space } from "antd"
import dayjs from 'dayjs'
import IconButton from "../../IconButton/IconButton";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

interface PostBlockEditButtonsProps {
  post: {createdBy: string, created: Date};
  openEditModal: () => void;
  handleDelete: () => void;
  canEdit: boolean;
}
export const PostBlockEditButtons = ({post, openEditModal, handleDelete, canEdit}: PostBlockEditButtonsProps) => {
  const {sm} = Grid.useBreakpoint();
  return (<Space >
    <div style={{color: 'gray', fontWeight: 'normal', display: 'inline-block', paddingLeft: 20}}>
      {sm && <>Добавил {post.createdBy} {dayjs(post.created).format("DD.MM.YYYY HH:mm") }</> }
    </div>
    {canEdit && <>
      <IconButton icon={<EditOutlined />} onClick={openEditModal} disabled={!canEdit}/>
      <IconButton icon={<DeleteOutlined />} onClick={handleDelete} disabled={!canEdit}/>
    </>}
  </Space>)
}