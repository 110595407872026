import './HeaderOne.scss'

interface HeaderOneRowProps {
  children: React.ReactNode | string;
  style?: React.CSSProperties;
}
export function HeaderOneRow({style = undefined, children} : HeaderOneRowProps) {
  return (<div style={style} className='header-one-container'>
    <h1>{children}</h1>
  </div>
  )
}

interface WithUnderLineProps {
  children: React.ReactNode | string;
  underlineColor?: 'green' | 'red'
}

export function WithUnderLine({children, underlineColor = 'green'} : WithUnderLineProps) {
  return(
    <span className={"underlined " + underlineColor}>{children}</span>
  )
}