import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/redux";
import { clearCurrentGskData, currentGskSelector } from "../../store/common/gsk-slice";
import { useEffect } from "react";
import { GskService } from "../../Services/GskService";

export default function useCurrentGsk () {
  const dispatch = useAppDispatch();
	const params = useParams();
	const gskId: number = parseInt(params.id ?? "-1");
	const gsk = useAppSelector(currentGskSelector);
  
  useEffect(() => {
    if (gskId > 0) {
      dispatch(GskService.loadCurrentGsk(gskId, gsk?.id !== gskId));
    }
  }, [dispatch, gsk, gskId]);

  if ((gsk && gsk.id !== gskId) || gskId < 1) {
    dispatch(clearCurrentGskData());
    return undefined
  }
  return gsk;
}