import { useEffect, useState } from "react";
import { useAppDispatch } from "../store/redux";
import { FileService } from "../Services/FileService";

interface Props extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  fileId?: number;
}

export default function ProtectedImage(props: Props) {
  const {fileId, ...imageProps} = props;
  const dispatch = useAppDispatch();
  const [imageSrc, setImageSrc] = useState<string | undefined>();
  useEffect(() => {
    const fetch = async() => {
      if (fileId && !imageSrc) {
        setImageSrc(await FileService.loadGetProtectedImage(fileId));
      }
    }
    fetch();
  }, [dispatch, fileId, imageSrc]);

  return (
    <img {...imageProps} src={fileId ? imageSrc : props.src} alt={props.alt}/>
  )
}