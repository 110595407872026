import { useCallback } from "react";
import { NewPostCommentModel, PostModel } from "../../types/PostTypes";
import { PostService } from "../../Services/PostsService";
import { Card, Modal, Row, Space } from "antd";
import { PostBlockEditButtons } from "./Base/PostBlockEditButtons";
import { CommentsBlockCommon } from "./Base/CommentsBlockCommon";
import { PostCommentModelBase, VotePosition } from "../../types/PostBaseTypes";
import { UserRole } from "../../types/UserTypes";
import VoteBlock from "./VoteBlock";
import { Link, useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import TagsBlock from "./TagsBlock";
import TipTapEditor from "../RichTextEditor/TipTapEditor";
import { likeComment, likePost } from "./Base/PostBlockCommon";
import { useMetaTags } from "react-metatags-hook";
import { useCurrentUser } from "../../store/common/app-slice";

interface PostBlockProps {
	post: PostModel;
	openEditModal: (post: PostModel | null) => void;
  setPost: (v: React.SetStateAction<PostModel | undefined>) => void;
}
export const PostBlock = ({ post, setPost, openEditModal }: PostBlockProps) => {
	const currentUser = useCurrentUser();
  const canEdit = currentUser?.id === post.createdById || currentUser?.role === UserRole.Admin;
  const nav = useNavigate();
  useMetaTags({
		title: post.title + (post.coverText && ' - ' + post.coverText),
		metas: [
			{name: 'keywords', content: post.tags.join(', ')}
  ]}, []);

  const handleDelete = useCallback(async() => {
    Modal.confirm({
      title: "Удаление поста",
      content: <>Вы действительно хотите удалить пост <b>{post.title}</b>?</>,
      okText: "Удалить",
      cancelText: 'Отмена',
      okButtonProps: { color: "red" },
      onOk: async () => {
        if (await PostService.deletePost(post.id)) {
          nav('/blog');
        }
      }
    });
  }, [nav, post.id, post.title]);

  const addCommentHandler = useCallback(async (comment: string, parentCommentId: number | null = null) => {
    const req: NewPostCommentModel = {
      comment: comment,
      parentCommentId: parentCommentId
    }
    const newComment = await PostService.addComment(post.id, req);
    if (newComment) {
      setPost({...post, comments: [newComment, ...post.comments]});
    }
  }, [post, setPost]);

  const likePostHandler = useCallback(async (isLike: boolean) => {
    if (await PostService.likePost(post.id, isLike)) {
      setPost({...post, ...likePost(post, isLike)});
    }; 

  }, [post, setPost]);

  const likeCommentHandler = useCallback(async (isLike: boolean, comment: PostCommentModelBase) => {
    if (await PostService.likeComment(comment.id, isLike)) {
      setPost({...post, comments: [...post.comments.filter(c => c.id !== comment.id) ?? [], likeComment(comment, isLike)]});
    };
  }, [post, setPost]);

  const editCommentHandler = useCallback(async (comment: PostCommentModelBase) => {
    if (await PostService.editComment(comment.id, { comment: comment.comment })) {
      setPost({...post, comments: [...post.comments.filter(c => c.id !== comment.id), comment]});
    }
  }, [post, setPost]);

  const deleteCommentHandler = useCallback(async (id: number) => {
    const resp = await PostService.deleteComment(id);
    let comments = [...post.comments.filter(c => c.id !== id)];
    if (resp) {
      comments.push(resp);
    }
    setPost({...post, comments});
  }, [post, setPost]);

	return (<Card
		title={
      <Space style={{fontSize: '1.2em', maxWidth: 'calc(100% - 50px)'}}>
        <Link to='../Blog' title="Назад"><LeftOutlined /></Link>
        {post.title}
      </Space>}
		extra={
      <PostBlockEditButtons post={post} canEdit={canEdit} handleDelete={handleDelete} openEditModal={() => openEditModal(post)} />
		}
	>
		
    {post.voteConfiguration?.position === VotePosition.BeforePost && <VoteBlock postId={post.id} voteConfiguration={post.voteConfiguration} setPost={setPost} /> }
    <TipTapEditor value={post.data} />
    {post.voteConfiguration?.position === VotePosition.AfterPost && <VoteBlock postId={post.id} voteConfiguration={post.voteConfiguration} setPost={setPost}/> }
    <Row style={{paddingBottom: 10}}>
      <TagsBlock value={post.tags} />
    </Row>
    <CommentsBlockCommon
      post={post}
      likePostHandler={likePostHandler}
      addCommentHandler={addCommentHandler}
      editCommentHandler={editCommentHandler}
      likeCommentHandler={likeCommentHandler}
      deleteCommentHandler={deleteCommentHandler}
    />
	</Card>)
}