import { useParams } from "react-router-dom";
import { useState } from "react";
import { UserService } from "../Services/UserService";
import { RestorePasswordConfirmModel } from "../types/UserTypes";
import { ShowSuccess } from "../components/Notifications/Notifications";
import { Alert, Button, Card, Flex, Form, Space, Spin } from "antd";
import PasswordInput from "../components/User/PasswordInput";
import { useForm } from "antd/es/form/Form";
import { useCurrentUser } from "../store/common/app-slice";


interface Props {
  openLoginModal: (message?:string, redirectUrl?: string) => void;
}
export default function RestorePasswordPage ({ openLoginModal }: Props) {
	const params = useParams();
	const key = params.key;
  const currentUser = useCurrentUser();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = useForm<{password: ''}>();

  const handleAcceptInviteClick = async (data: {password: string}) => {
    if (!key) {
      return;
    }
    setIsLoading(true);
    const req: RestorePasswordConfirmModel = {
      newPassword: data.password,
      key,
    }
    if (await UserService.restorePasswordConfirm(req)) {
      ShowSuccess("Смена пароля", "Пароль изменен");
      openLoginModal('', '/');
    };
    setIsLoading(false);
  }

	return (<Flex justify="center" align="center" style={{paddingTop: 20}}>
    { currentUser ?
      <Alert type="warning" message='Сбрость пароль могут только не авторизованные пользователи' />
    
    :
    <Spin spinning={isLoading}>
      <Card style={{ width: 400 }}>
        <Form
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 14 }}
          layout={'horizontal'}
          form={form}
          onFinish={handleAcceptInviteClick}
        >
          <PasswordInput required={true} label='Новый пароль' />
        </Form>
        <Flex justify="flex-end">
          <Space>
            <Button type="primary" onClick={() => form.submit()}>Сменить</Button>
          </Space>
        </Flex>
      </Card>
    </Spin>
  }
  </Flex>)
}