import { useEffect, useState, useMemo, useCallback } from "react";
import { useAppDispatch } from "../../store/redux";
import NewMapModal from "../../components/Gsk/NewMapModal";
import { useDialog, useDialogWithParameter } from "../../utils/useDialog";
import GskMap from "../../components/GskMap/GskMap";
import { Button, Row, Col, Card, Flex, Space, Select, Form } from 'antd'
import AddBoxModal from "../../components/Gsk/AddBoxModal";
import { BoxShortModel, Gsk, GskMapModel, MapBoxPolygon } from "../../types/GskTypes";
import { MapPolygon } from "../../components/GskMap/GskMapTypes";
import { EditOutlined, PlusCircleOutlined, PlusSquareOutlined, QuestionOutlined } from '@ant-design/icons';
import { useResizeDetector } from 'react-resize-detector';
import Search from "antd/es/input/Search";
import { MapService } from "../../Services/MapService";
import useCurrentGsk from "./useCurrentGsk";
import PolygonMenu from "../../components/GskMap/PolygonMenu";
import { ShowSuccess } from "../../components/Notifications/Notifications";
import EditPolygonModal from "../../components/GskMap/EditPolygonModal";
import EditMapModal from "../../components/Gsk/EditMapModal";
import { BoxesService } from "../../Services/BoxesService";
import useLoading from "../../utils/hooks/useLoading";
import { useEffectOnce } from "../../utils/hooks/useEffectOnce";
import RowTwoCol from "../../components/RowTwoCol/RowTwoCol";
import IconButton from "../../components/IconButton/IconButton";

export default function GskMapsPage() {
	const gsk = useCurrentGsk();
  return (<>{gsk ? <Page gsk={gsk}/> : <>Загрузка...</>}</>)
}
const FINISHED_BOX_COLOR = 'rgba(52, 132, 0, 0.2)';
interface PageProps{
  gsk: Gsk;
}
function Page({gsk}: PageProps)
{
  const dispatch = useAppDispatch();
  const [isLoading, load] = useLoading();
  const [selectedMapId, setSelectedMapId] = useState<number | null>(null);
  const [selectedBox, setSelectedBox] = useState<BoxShortModel | null>(null);
  const [filterBox, setFilterBox] = useState('')
  const [activePolygonId, setActivePolygonId] = useState<number | undefined>(undefined);
  const { width, height, ref } = useResizeDetector();

  const [gskBoxes, setGskBoxes] = useState<BoxShortModel[]>([]);
  const [maps, setGskMaps] = useState<GskMapModel[]>([]);
  const [selectedMapPolygons, setSelectedMapPolygons] = useState<MapBoxPolygon[]>([]);
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const selectedMap = useMemo(() => maps.find(m => m.id === selectedMapId), [maps, selectedMapId]);

  const saveActivePolygon = useCallback(async (polygon: MapPolygon) => {
    if (!selectedMapId) {
      return;
    }

    const data: MapBoxPolygon = {
      ...polygon,
      polygonId: (polygon.id < 1 ? undefined : polygon.id),
    }
    const resp = await MapService.upsertMapPolygon(gsk.id, selectedMapId, data);
    if (resp) {
      if (polygon.id < 1) {
        setSelectedMapPolygons(prev => [...prev, resp])
      } else {
        setSelectedMapPolygons(prev => [...prev.filter(p => p.polygonId !== resp.polygonId), resp])
      }
      ShowSuccess('Сохранено','Полигон бокса ' + polygon.label?.text);
    }
  }, [gsk.id, selectedMapId])

  const handleMapAdded = (map: GskMapModel) => setGskMaps([...maps, map]);

  const [newMapModal, openNewMapModal] = useDialog(
    closeDialog => <NewMapModal gskId={gsk.id} onMapAdded={handleMapAdded} closeDialog={closeDialog} />
  );

  const [newBoxModal, openBoxModal] = useDialog(
    closeDialog => <AddBoxModal gskId={gsk.id} closeDialog={closeDialog} onAdded={() => alert('not implemented') } /> // TODO fix on Add refresh
  );
  
  const [editPolygonModal, openEditPolygonModal] = useDialogWithParameter<MapPolygon>(
    (param, closeDialog) => <EditPolygonModal isLoading={isLoading} closeDialog={closeDialog} polygon={param} savePolygon={saveActivePolygon} />
  );

  const handleMapChanged = (map: GskMapModel) => setGskMaps(prev => {
    const r = [...prev];
    r.splice(prev.findIndex(p => p.id === map.id), 1, map);
    return r;
  })

  const handleMapDeleted = (id: number) => {
    setSelectedMapId(null);
    setGskMaps(prev => prev.filter(p => p.id !== id));
  }

  const [editMapModal, openEditMapModal] = useDialogWithParameter<GskMapModel>(
    (param, closeDialog) => <EditMapModal onMapChanged={handleMapChanged} onMapDeleted={handleMapDeleted} closeDialog={closeDialog} map={param}/>
  );

  const [polygonMenu, openPolygonMenu] = PolygonMenu(saveActivePolygon, openEditPolygonModal);
  
  const polygons = useMemo(() => {
      if (gskBoxes.length === 0 || !selectedMap) {
        return [];
      }
      let idVirtulalPolygon = -1;
      const mappedPolygons = [...gskBoxes].map(b => {
        const p = selectedMapPolygons.find(p => p.boxId === b.id);
        const label = p?.label ?? {
          text: b.num,
        };
        const t: MapPolygon = {
          id: p?.polygonId ?? idVirtulalPolygon--,
          isFinished: p?.isFinished ?? false,
          points: p?.points ?? [],
          fillColor: p?.fillColor ?? null,
          boxId: b.id,
          label: { ...label },
          lineColor: p?.lineColor ?? null
        };
        return t
      });
      return mappedPolygons;
    }, [gskBoxes, selectedMap, selectedMapPolygons]);

  const boxes = useMemo(() => {
    return [...gskBoxes].filter(b => filterBox === '' || b.num.includes(filterBox))
    .map(b => {
      const p = selectedMapPolygons.find(p => p.boxId === b.id);
      return {...b, polygonIsFinished: p?.isFinished ?? false}
    })
    .sort((a,b) => (parseInt(a.num) ?? 0) - (parseInt(b.num) ?? 0))
  }
  , [filterBox, gskBoxes, selectedMapPolygons])

  const handleBoxClick = useCallback(async (box: BoxShortModel | null) => {
    if (!box || box.id === selectedBox?.id) {
      setSelectedBox(null);
      setActivePolygonId(undefined);
      return
    }
    setSelectedBox(box);
    setActivePolygonId(polygons.find(p => p.boxId === box.id)?.id);
  }, [polygons, selectedBox?.id])

  const handlePolygonClick = useCallback((p: MapPolygon | null) => {
    if (!p || p.id !== activePolygonId) {
      const box = boxes.find(b => b.id === p?.boxId);
      handleBoxClick(box ?? null);
    }
  }, [activePolygonId, boxes, handleBoxClick])

  useEffect(() => {
    if (maps.length > 0 && selectedMapId === null) {
      setSelectedMapId(maps[0].id);
    }
  }, [maps, selectedMapId]);

  useEffectOnce(() => {
    const loadFunc = async () => {
      setGskMaps(await load(MapService.getMapsList(gsk.id)) ?? []);
      setGskBoxes(await load(BoxesService.getBoxesShort(gsk.id)) ?? []);
    }
    loadFunc();
  });

  useEffect(() => {
    if (!selectedMap) {
      return;
    }
    const loadFunc = async () => {
      const urlResp = await load(MapService.getMapImage(gsk.id, selectedMap.id));
      if (urlResp) {
        setImageUrl(urlResp);
      }
      const polygonsResp = await load(MapService.getPolygons(gsk.id, selectedMap.id));
      if (polygonsResp) {
        setSelectedMapPolygons(polygonsResp);
      }
    }

    loadFunc();
  }, [selectedMap, dispatch, load, gsk.id]);
  
  const finishedBoxStyle: React.CSSProperties = {backgroundColor: FINISHED_BOX_COLOR};

  return (<Space direction="vertical" style={{width: '100%'}}>
    <Card loading={isLoading}>
      <RowTwoCol
        left={
          <Form layout={'inline'}>
            <Form.Item label='Карта'>
              <Select
                style={{width: 120}}
                onChange={setSelectedMapId}
                options={maps.map(m => ({label: m.name, value: m.id}))}
                value={selectedMapId}
              />
            </Form.Item>
            <Space>
              <IconButton onClick={openNewMapModal} icon={<PlusSquareOutlined />} />
              <IconButton onClick={() => selectedMap ? openEditMapModal(selectedMap) : null} icon={<EditOutlined />} />
            </Space>
          </Form>
        }
        right={<>
            <IconButton icon={<QuestionOutlined/>} />
          </>
        }
        rightSize={30}
      />
    </Card>
    <Card loading={isLoading}>
      <Row>
        <Col span={20}>
          <div ref={ref} style={{ display: "block", border: '1px solid black', width: '100%', height: 'calc(100vh - 200px)' }}>
            {imageUrl && selectedMap &&
              <GskMap
                height={height}
                width={width}
                mapImage={imageUrl}
                polygons={polygons}
                savePolygon={saveActivePolygon}
                activePolygonId={activePolygonId}
                mapSettings={selectedMap.settings}
                onPolygonClick={handlePolygonClick}
                readonly={false}
                onPolygonRightButtonClick={openPolygonMenu}
              />
            }
          </div>
        </Col>
        <Col span={4}>
          <Card 
            title={<>
              Список боксов <Button onClick={openBoxModal} icon={<PlusCircleOutlined />}></Button>
            </>}
            styles={{body:{ height: 'calc(100vh - 400px)', overflowY: "auto" }}}
          >
            <Space direction="vertical">
              <Search placeholder="Поиск..." onSearch={(v, _) => setFilterBox(v)}></Search> 
              <Flex wrap="wrap" gap="small" justify="flex-start" align="flex-start" >
                {boxes.map(box => 
                    <Button 
                      key={box.id}
                      style={(box.polygonIsFinished && (box.id !== selectedBox?.id))? finishedBoxStyle : {}}
                      onClick={() => handleBoxClick(box)}
                      type={box.id === selectedBox?.id ? "primary" : "default"}
                    >
                      {box.num}
                    </Button>
                )}
              </Flex>
            </Space>
          </Card>
        </Col>
      </Row>
    </Card>
    {newBoxModal}
    {newMapModal}
    {polygonMenu}
    {editPolygonModal}
    {editMapModal}
  </Space>)
}