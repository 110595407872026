export const NumInputValidator = (min?: number, max?: number, required?: boolean) => {
  const checkFloat = (v: number | undefined) => {
    if (!!required && v === undefined) {
      return Promise.reject('Введите значение');
    }
    if (v === undefined) {
      return Promise.resolve();
    }
    if (min !== undefined && v < min) {
      return Promise.reject("Меньше " + min)
    }
    if (max !== undefined && v > max) {
      return Promise.reject("Больше " + max)
    }
    return Promise.resolve();
  }
  return {validator: (_: any, v: number) => checkFloat(v)}
}