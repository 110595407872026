import { useEffect, useState } from "react";
import { Alert } from "antd";
import { useSearchParams } from "react-router-dom";
import { UserService } from "../../Services/UserService";


export function YandexLoginPage() {
	const [searchParams] = useSearchParams(window.location.hash);
	const [error, setError] = useState('');

	useEffect(() => {
		if (searchParams.size === 0) {
			return;
		}

		if (Array.from(searchParams.keys()).includes('#error')) {
			setError(searchParams.get('#error') ?? '');
			return;
		}

		const fetch = async () => {
			const accessToken = searchParams.get('#access_token');
			if (accessToken) {
				await UserService.loginYandex(accessToken);
				window.location.href = '/';
			}
		}

		fetch ();
	}, [searchParams]);

	return (<>{error ? <Alert type="error" description={error} message='Ошибка авторизации'/> : 'Получение инфорации о пользователе...'}</>)
}
