import { GskRole } from "./GskTypes";
import { NotificationEvent } from "./UserTypes";

export interface MailingRequest {
    title: string;
    body: string;
    event: NotificationEvent;
    roles: GskRole[];
    type: MainingType;
    email?: string;
}

export enum MainingType {
    Email = 0,
    Roles = 1
}