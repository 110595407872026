import { Gsk, GskChairmanInviteModel, GskModel, GskOperationType, GsksRequest, MainPageData, NewGskModel } from "../types/GskTypes";
import { setCurrentGsk } from "../store/common/gsk-slice";
import { getOperationSuccededSelector } from "../store/common/operations-slice";
import { AppThunkAction } from "../store/configureStore";
import { DownloadFile, Fetch, FetchNoResp } from "../utils/fetch";
import OperationsService from "./OperationsService";
import { PagedResult } from "../types/dto";
import { UserModel } from "../types/UserTypes";
import { GskSubscriptionsModel } from "../types/BillingTypes";
import { FileModel, FilesRequest, NewFileModel, RenameFileModel } from "../types/FileTypes";
import { ShowSuccess } from "../components/Notifications/Notifications";

export const GskService = {
	newGsk: async (data: NewGskModel): Promise<GskModel | undefined> =>{
		return await Fetch<GskModel>('/api/gsks/', "PUT", data);
	},
	editGsk: async (gskId: number, data: GskModel): Promise<Gsk | undefined> => {
		return await Fetch<Gsk>(`/api/gsks/${gskId}`, "PATCH", data);
	},
	delete: async (gskId: number): Promise<boolean> => {
		return await FetchNoResp(`/api/gsks/${gskId}`, "DELETE");
	},
	loadCurrentGsk: (id: number, force: boolean = false): AppThunkAction => async (dispatch, getState) => {
		if (!force) {
			const isLoaded = getOperationSuccededSelector(GskOperationType.Load, id)(getState());
			if (isLoaded) {
				return;
			}
		}

		await OperationsService.run(dispatch, getState, GskOperationType.Load, id, force, async () => {
			const gsk = await Fetch<GskModel>(`/api/gsks/${id}/`, "GET");
			if (gsk) {
				dispatch(setCurrentGsk(gsk));
			}

		})
	},
	getGskUsers: async (gskId: number): Promise<UserModel[] | undefined> => {
		return await Fetch<UserModel[]>(`/api/gsks/${gskId}/users`, "GET");
	},
	getGskPage: async (req: GsksRequest): Promise<PagedResult<GskModel> | undefined> => {
		return await Fetch<PagedResult<GskModel>>('/api/gsks', "GET", req);
	},
	loadMainPageData: async (gskId: number): Promise<MainPageData | undefined> => {
		return await Fetch<MainPageData>(`/api/gsks/${gskId}/mainPageData`, "GET");
	},
	saveMainPageData: async (gskId: number, data: MainPageData): Promise<MainPageData | undefined> => {
		return await Fetch<MainPageData>(`/api/gsks/${gskId}/mainPageData`, "PATCH", data);
	},
	find: async (find: string) => {
		return await Fetch<Gsk[]>('/api/gsks/find', "GET", {find});
	},

	getChairmanInvite: async (gskId: number): Promise<GskChairmanInviteModel | undefined> => {
		return await Fetch<GskChairmanInviteModel>(`/api/gsks/${gskId}/chairmanInvite`, "GET");
	},
	createChairmanInvite: async (newChairmanUserId: number, gskId: number): Promise<GskChairmanInviteModel | undefined> => {
		return await Fetch<GskChairmanInviteModel>(`/api/gsks/chairmanInvite`, "PUT", { gskId, newChairmanUserId });
	},
	deleteChairmanInvite: async (key: string): Promise<boolean> => {
		return FetchNoResp(`/api/gsks/chairmanInvite/${key}`, "DELETE");
	},
	getChairmanInviteByKey: async (key: string): Promise<GskChairmanInviteModel | undefined> => {
		return await Fetch<GskChairmanInviteModel>(`/api/gsks/chairmanInvite/${key}`, "GET");
	},
	acceptChairmanInvite: async (key: string, accept: boolean): Promise<number | undefined> => {
		if (accept) {
			return (await Fetch<{gskId: number}>(`/api/gsks/chairmanInvite/${key}/accept`, "POST"))?.gskId;
		} else {
			return (await Fetch<{gskId: number}>(`/api/gsks/chairmanInvite/${key}/revoke`, "POST"))?.gskId;
		}
	},

	getSubscriptions: async (gskId: number): Promise<GskSubscriptionsModel | undefined> => {
		return await Fetch<GskSubscriptionsModel>(`/api/gsks/${gskId}/subscriptions`, "GET");
	},
	
	getFilesPage: async (gskId: number, req: FilesRequest): Promise<PagedResult<FileModel> | undefined> => {
		return await Fetch<PagedResult<FileModel>>(`/api/gsks/${gskId}/files`, "POST", req);
	},
	addFile: async (gskId: number, data: NewFileModel) => {
		const content = new FormData();
		content.append('data', data.data);
		content.append('type', data.type.toString());
		content.append('name', data.name);
		if (data.paretnId) {
			content.append('parentId', data.paretnId.toString());
		}
		const resp = await Fetch<FileModel>(`/api/gsks/${gskId}/files`, "PUT", content);
		if (resp) {
			ShowSuccess("Файл успешно загружен", data.name);
		}
	},
	renameFile: async (gskId: number, fileId: number, data: RenameFileModel): Promise<FileModel | undefined> => {
		return await Fetch<FileModel>(`/api/gsks/${gskId}/files/${fileId}`, "PATCH", data);
	},
	downloadFile: async (gskId: number, fileId: number) => {
		await DownloadFile(`/api/gsks/${gskId}/files/${fileId}`, "GET");
	},
	deleteFile: async (gskId: number, fileId: number): Promise<boolean> => {
		return await FetchNoResp(`/api/gsks/${gskId}/files/${fileId}`, "DELETE");
	},
}