import { Card, Modal, Space } from "antd";
import { GskPostModel, NewGskPostCommentModel } from "../../../types/GskPostTypes";
import { GskPostService } from "../../../Services/GskPostsService";
import { useCallback } from "react";
import VoteBlock from "./VoteBlock";
import { PostCommentModelBase, VotePosition } from "../../../types/PostBaseTypes";
import { PostBlockEditButtons } from "../../Post/Base/PostBlockEditButtons";
import { CommentsBlockCommon } from "../../Post/Base/CommentsBlockCommon";
import TipTapEditor from "../../RichTextEditor/TipTapEditor";
import { Link, useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { likeComment, likePost } from "../../Post/Base/PostBlockCommon";
import { GskModel } from "../../../types/GskTypes";

interface OwnProps {
  gsk: GskModel;
  post: GskPostModel;
  openEditPostModal: (v: GskPostModel) => void;
  canEdit: boolean;
  setPost: (v: React.SetStateAction<GskPostModel | undefined>) => void;
}

export default function PostBlock({gsk, post, openEditPostModal: openEditModal, canEdit, setPost}: OwnProps) {
  const nav = useNavigate();

  const handleDelete = async() => {
    Modal.confirm({
      title: "Удаление новости",
      content: <>Вы действительно хотите удалить новость <b>{post.title}</b>?</>,
      okText: "Удалить",
      cancelText: 'Отмена',
      okButtonProps: { color: "red" },
      onOk: async () => {
        if (await GskPostService.deletePost(gsk.id, post.id)) {
          nav('../');
        }
      }
    });
  }

  const likePostHandler = useCallback(async(isLike: boolean) => {
    if (await GskPostService.likePost(gsk.id, post.id, isLike)) {
      setPost({...post, ...likePost(post, isLike)});
    }; 
  }, [gsk.id, post, setPost]);

  const addCommentHandler = useCallback(async (comment: string, parentCommentId: number | null = null) => {
    const req: NewGskPostCommentModel = {
      comment: comment,
      parentCommentId: parentCommentId
    }
    const newComment = await GskPostService.addComment(gsk.id, post.id, req);
    if (newComment) {
      setPost({...post, comments: [newComment, ...post.comments]});
    }
  }, [gsk.id, post, setPost]);

  const likeCommentHandler = useCallback(async (isLike: boolean, comment: PostCommentModelBase) => {
    if (await GskPostService.likeComment(gsk.id, comment.id, isLike)) {
      setPost({...post, comments: [...post.comments.filter(c => c.id !== comment.id) ?? [], likeComment(comment, isLike)]});
    }; 
  }, [gsk.id, post, setPost]);

  const editCommentHandler = useCallback(async (comment: PostCommentModelBase) => {
    if (await GskPostService.editComment(gsk.id, comment.id, { comment: comment.comment })) {
      setPost({...post, comments: [...post.comments.filter(c => c.id !== comment.id), comment]});
    }
  }, [gsk.id, post, setPost]);

  const deleteCommentHandler = useCallback(async(id: number) => {
    const resp = await GskPostService.deleteComment(gsk.id, id);
    let comments = [...post.comments.filter(c => c.id !== id)];
    if (resp) {
      comments.push(resp);
    }
    setPost({...post, comments});
  }, [gsk.id, post, setPost]);

  return (<Card
    title={
      <Space style={{fontSize: '1.6em'}}>
        <Link to='../' title="Назад"><LeftOutlined /></Link>
        {post.title}
      </Space>}
    extra={
      <PostBlockEditButtons post={post} canEdit={canEdit} handleDelete={handleDelete} openEditModal={() => openEditModal(post)} />
    }
  >
    {post.voteConfiguration?.position === VotePosition.BeforePost && <VoteBlock gskId={gsk.id} postId={post.id} voteConfiguration={post.voteConfiguration} setPost={setPost} /> }
    <TipTapEditor value={post.data}/>
    {post.voteConfiguration?.position === VotePosition.AfterPost && <VoteBlock gskId={gsk.id} postId={post.id} voteConfiguration={post.voteConfiguration} setPost={setPost} /> }
    
    <CommentsBlockCommon
      post={post}
      likePostHandler={likePostHandler}
      addCommentHandler={addCommentHandler}
      editCommentHandler={editCommentHandler}
      likeCommentHandler={likeCommentHandler}
      deleteCommentHandler={deleteCommentHandler}
    />
  </Card>)
}