import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GetGskRoleName, Gsk, GskBox } from "../../types/GskTypes";
import useCurrentGsk from "./useCurrentGsk";
import { Button, Card, Checkbox, Flex, Form, Grid, Input, Space, Table } from "antd";
import { useAppSelector } from "../../store/redux";
import { ColumnsType } from "antd/es/table";
import { DollarOutlined, EditOutlined, FieldTimeOutlined, PlusCircleOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import { toMoneyString } from "../../utils/moneyHelper";
import IconButton from "../../components/IconButton/IconButton";
import { useNavigate } from "react-router-dom";
import { useDialog, useDialogWithParameter } from "../../utils/useDialog";
import AddBoxModal from "../../components/Gsk/AddBoxModal";
import { AssignedUser, EditBoxModal } from "../../components/Gsk/EditBoxModal";
import GskRolesSelect from "../../components/Gsk/GskRolesSelect";
import { getEnumValues } from "../../utils/enumUtils";
import RowTwoCol from "../../components/RowTwoCol/RowTwoCol";
import { GskHistoryModalProps } from "../../components/GskHistoryModal/GskHistoryModal";
import { RefType, setAppRef } from "../../store/enum/RefType";
import { BoxesTour } from "../../components/Tours/Gsk/BoxesTour";
import { BoxesService } from "../../Services/BoxesService";
import { EmptyPagedResult, PagedResult } from "../../types/dto";
import useLoading from "../../utils/hooks/useLoading";
import { useEffectOnce } from "../../utils/hooks/useEffectOnce";
import { GskBoxWithUser, GskRole } from "../../types/GskTypes";
import { GskHistoryEntity } from "../../types/GskHistoryTypes";
import { currentUserHasGskRoleSelector } from "../../store/common/app-slice";

interface GskBoxesPageProps{
  openGskHistoryModal: (props: GskHistoryModalProps) => void;
}
export default function GskBoxesPage({openGskHistoryModal}:GskBoxesPageProps) {
	const gsk = useCurrentGsk();
  return (<>{gsk ? <Page gsk={gsk} openGskHistoryModal={openGskHistoryModal}/> : <>Загрузка...</>}</>)
}

interface PageProps{
  gsk: Gsk;
  openGskHistoryModal: (props: GskHistoryModalProps) => void;
}

const PAGE_SIZE = 10;
function Page({gsk, openGskHistoryModal}: PageProps)
{
  const filterRef = useRef(null);
  const tableRef = useRef(null);
  const addBoxRef = useRef(null);
  const nav = useNavigate();
  const {sm} = Grid.useBreakpoint();

  const [isLoading, load] = useLoading();
  const [page, setPage] = useState<PagedResult<GskBoxWithUser>>(EmptyPagedResult<GskBoxWithUser>(PAGE_SIZE));

  const isChairman = useAppSelector(currentUserHasGskRoleSelector(GskRole.Chairman));
  const isAccountant = useAppSelector(currentUserHasGskRoleSelector(GskRole.Accountant));
  const [filterText, setFilterText] = useState('');
  const [filterWithDebtOnly, setFilterWithDebtOnly] = useState(false);
  const [filterGskRoles, setFilterGskRoles] = useState<GskRole[]>(getEnumValues(GskRole));

  const loadPage = useCallback(async (page: number, pageSize: number) => {
    const resp = await load(BoxesService.getBoxesPage(gsk.id, {
      page,
      pageSize,
      filterText,
      gskRoles: filterGskRoles,
      withDebt: filterWithDebtOnly
    }));
    if (resp) {
      setPage(resp);
    }
  }, [filterGskRoles, filterText, filterWithDebtOnly, gsk.id, load]);
  
  const refreshPage = useCallback(() => {
    loadPage(page.page, page.pageSize)
  }, [loadPage, page.page, page.pageSize]);

  const updateBox = (newBox: GskBoxWithUser | null) => {
    if (!newBox) {
      refreshPage();
    } else {
      setPage(page => {
        const items = [...page.items];
        items.splice(page.items.findIndex(i => i.id === newBox.id), 1, newBox);
        return ({...page, items});
      });
    }
  }
  
  const onFilterEnter = (e?: React.KeyboardEvent<HTMLInputElement>) => {
    if (!e || e.key === 'Enter') {
      refreshPage();
    }
  }

  const [editBoxModal, openEditBoxModal] = useDialogWithParameter<GskBoxWithUser>(
    (box, closeDialog) => <EditBoxModal 
      onSaved={updateBox}
      boxInput={box}
      closeDialog={closeDialog}
    />
  );

  const [newBoxModal, openNewBoxModal] = useDialog(
    closeDialog => <AddBoxModal
      gskId={gsk.id}
      closeDialog={closeDialog}
      onAdded={refreshPage}
    />
  );
  const columns: ColumnsType<GskBox> = useMemo(() => [
    {
      title: '№',
      key: 'num',
      dataIndex: 'num',
      width: 50,
    },
    {
      title: 'Владелец',
      key: 'ownerName',
      dataIndex: 'ownerName',
      render: (_, box) => <AssignedUser box={box} showBoxOwner={true}/>,
    },
    {
      title: 'Права',
      key: 'gskroles',
      width: 120,
      render: (_, box) => <>{box.user?.gskRoles.filter(r => r !== GskRole.Member).map(r => GetGskRoleName(r)).join(', ')}</>,
      responsive: ['sm'],
    },
    ...(isAccountant ? [{
        title: 'Долг',
        key: 'latePaymentSum',
        dataIndex: 'latePaymentSum',
        width: 100,
        render: (v: number) => <>{v > 0 && <>{toMoneyString(v)}</>}</>,
      }] 
      : 
      []
    ),
    {
      title: 'Комментарий',
      key: 'comment',
      dataIndex: 'comment',
      responsive: ['sm'],
    },
    {
      title: <Flex justify="flex-end">
        <IconButton 
          title='Обновить'
          icon={<SyncOutlined/>} 
          onClick={() => {
            refreshPage();
          }} 
        />
      </Flex>,
      key: 'actions',
      render: (_, row) => <Flex justify="flex-end">
        <Space>
          <IconButton title='Изменить' icon={<EditOutlined />} onClick={() => openEditBoxModal(row)} disabled={!isChairman}/>
          <IconButton title='История' icon={<FieldTimeOutlined />} onClick={() => openGskHistoryModal({entity: GskHistoryEntity.BOX, gskId: gsk.id, entityId: row.id})} />
          <IconButton title='Начисления' icon={<DollarOutlined />} onClick={() => nav('../Cash/' + row.id)} disabled={!isAccountant}/>
        </Space>
      </Flex>,
    },
  ], [gsk.id, isAccountant, isChairman, nav, openEditBoxModal, openGskHistoryModal, refreshPage]);

  useEffectOnce(() => { loadPage(1, PAGE_SIZE); });

  useEffect(() => { setAppRef(RefType.GskBoxesAddButton, addBoxRef); }, [addBoxRef]);
  useEffect(() => { setAppRef(RefType.GskBoxesFilter, filterRef); }, [filterRef]);
  useEffect(() => { setAppRef(RefType.GskBoxesTable, tableRef); }, [tableRef]);

  return (
    <Space direction="vertical" style={{width: '100%'}}>
      <Card size="small">
        <RowTwoCol
          left={
            <div ref={filterRef}>
              <Form layout={sm ? 'inline' : 'vertical'} >
                <Form.Item label='Фильтр'>
                  <Input value={filterText} onChange={e => setFilterText(e.target.value.trim())} onKeyUp={onFilterEnter} placeholder="№ / ФИО" />
                </Form.Item>
                <Form.Item label='Права'>
                  <GskRolesSelect value={filterGskRoles} onChange={setFilterGskRoles}  maxTagCount={1}/>
                </Form.Item>
                <Form.Item>
                  <Checkbox checked={filterWithDebtOnly} onChange={e => setFilterWithDebtOnly(e.target.checked)}>Только с долгами</Checkbox>
                </Form.Item>
                {!sm && <Flex justify="flex-end"><Space>
                  <Button icon={<SearchOutlined/>} onClick={() => onFilterEnter()}>Поиск</Button>
                  <Button ref={addBoxRef} onClick={openNewBoxModal} icon={<PlusCircleOutlined />} disabled={!isChairman}>Добавить бокс</Button>
                </Space></Flex>}
              </Form>
            </div>}
          right={ 
            <Space>
              {sm &&<>
                <Button icon={<SearchOutlined/>} onClick={() => onFilterEnter()}>Поиск</Button>
                <Button ref={addBoxRef} onClick={openNewBoxModal} icon={<PlusCircleOutlined />} disabled={!isChairman}>Добавить бокс</Button>
              </>}
              <BoxesTour />
            </Space>}
          rightSize={sm ? 300 : 25}
        />
      </Card>
      <Card ref={tableRef}>
        <Table
          rowClassName={(row, _) => (row.latePaymentSum ?? 0) > 0 ? 'late-payment' : ''}
          loading={isLoading}
          size="small"
          columns={columns}
          dataSource={page.items}
          rowKey='id'
          pagination={{
            pageSize: page.pageSize,
            current: page.page,
            defaultPageSize: PAGE_SIZE,
            total: page.totalCount,
            onChange: (page, size) => loadPage(page, size)
          }}
        />
      </Card>
    {newBoxModal}
    {editBoxModal}
    </Space>
  )
}