import { FetchBlob, FetchNoResp } from "../utils/fetch";

export const FileService = {
	removeTempFiles: async (): Promise<boolean> => {
		return await FetchNoResp('/api/files/clearTempFiles', "GET");
	},
	loadGetProtectedImage: async (fileId: number): Promise<string | undefined> => {
		const blob = await FetchBlob(`/api/files/${fileId}/blob`, 'GET');
		if (blob) {
			return URL.createObjectURL(blob);
		}
		return undefined
	},
}