import { TreeDataNode } from "antd";
import { DirectoryTreeProps } from "antd/es/tree";
import { Tree } from "antd/lib";
import { useLocation, useNavigate } from "react-router-dom";
import './HelpTree.scss'
import { useMemo } from "react";

interface Props {
  onClick: () => void;
}

const treeData: TreeDataNode[] = [
  {
    title: 'Биллинг',
    key: 'gskBilling',
    selectable: false,
    children: [
      { title: 'Комиссия', key: 'gskBilling/commission' },
      { title: 'ЮКасса', key: 'gskBilling/yookassa' },
    ],
  },
];

export default function HelpTree({ onClick }: Props) {
  const nav = useNavigate();
	const location = useLocation();


  const selectedKey = useMemo(() => {
    let start = location.pathname.indexOf("help");
    if (start) {
      start = start + 5;
    }
    else {
      start = location.pathname.length;
    }
    return location.pathname.substring(start);
  }, [location.pathname]);

  const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
    if (info.node.selectable !== false) {
      nav(info.node.key.toString());
      onClick();
    } else {
      nav('/help')
    }
  };

  return (<>
    <Tree
      style={{background: 'transparent'}}
      defaultExpandAll
      selectedKeys={[selectedKey]}
      onSelect={onSelect}
      treeData={treeData}
    />
  </>)
}