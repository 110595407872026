import { Alert, Form, Grid, Input } from "antd";
import PasswordInput from "./PasswordInput";
import PhoneInput from "./PhoneInput";
import Link from "antd/es/typography/Link";
import { showAgreement } from "./UserAgreement";
import { useAppSelector } from "../../store/redux";
import { currentUserIsGlobalAdminSelector } from "../../store/common/app-slice";

interface Props {
  isNewUser: boolean;
  passwordRequired?: boolean;
  captcha?: JSX.Element;
}
export default function ProfileTab({ isNewUser, captcha, passwordRequired=isNewUser } : Props) {
  const { sm } = Grid.useBreakpoint();
  const isGlobalAdmin = useAppSelector(currentUserIsGlobalAdminSelector);
  const checkLogin = (login: string ) => {
    if (!isNewUser) {
      return Promise.resolve();
    }

    let errors: string[] = [];
    if (login.length < 5) {
      errors.push("Быть длиннее 4 символов");
    }
    if (login.includes(' ')) {
      errors.push("Не содержать пробелов");
    }
    if (login.length > 20) {
      errors.push("Быть короче 20 символов");
    }

    if (errors.length === 0 || isGlobalAdmin) {
      return Promise.resolve();
    }
    return Promise.reject(<><b>Логин должен:</b><br/>{errors.map(e => <span key={e}>{e}<br/></span>)}</>);
  };

  const checkName = (value: string, required: boolean = true) => {
    let errors: string[] = [];
    if (!value && !required) {
      return Promise.resolve();
    }

    if (value.length < 1) {
      errors.push("Короче 1 символа");
    }
    if (value.length > 12) {
      errors.push("Длиннее 12 символов");
    }
    if (value.match("\\d")) {
      errors.push("Содержит цифры");
    }
    if (!value.match("[А-Я]{1}")) {
      errors.push("Не начинается с заглавной (большой) буквы");
    }
    if (value.match("[a-zA-Z]")) {
      errors.push("Должен содержать только русские буквы");
    }

    if (errors.length === 0) {
      return Promise.resolve();
    }
    return Promise.reject(<>{errors.map(e => <span key={e}>{e}<br/></span>)}</>);
  }

  const checkAlias = (value: string) => {
    let errors: string[] = [];
    
    if (value.length < 4) {
      errors.push("Короче 4 символа");
    }
    if (value.length > 12) {
      errors.push("Длиннее 12 символов");
    }

    if (errors.length === 0 || isGlobalAdmin) {
      return Promise.resolve();
    }
    return Promise.reject(<>{errors.map(e => <span key={e}>{e}<br/></span>)}</>);
  }


  return <>
    <Form.Item noStyle label='Id' name={['profile', 'id']}>
      <Input type="hidden" />
    </Form.Item>
    <Form.Item 
      label='Логин'
      name={['profile','login']}
      tooltip={isNewUser ? null : 'Нельзя изменить'}
      rules={[{required: isNewUser, validator: (_, v) => checkLogin(v)}]}
    >
      <Input readOnly={!isNewUser}/>
    </Form.Item>
    <Form.Item 
      label='Псевдоним'
      name={['profile','alias']}
      tooltip={'Это имя будут видеть другие пользователи. В целях безопасности мы не рекомендуем делать его таким же как Ваш логин'}
      rules={[{required: true, validator: (_, v) => checkAlias(v)}]}
    >
      <Input/>
    </Form.Item>
    <PasswordInput path={['profile','password']} required={passwordRequired} />
    <Form.Item 
      label='Email'
      name={['profile','email']}
      tooltip={isNewUser ? null : 'Нельзя изменить'}
      rules={[{required: isNewUser, type: "email", message: "Введите корректный e-mail"}]}
    >
      <Input placeholder="ivan@mail.ru" readOnly={!isNewUser}/>
    </Form.Item>
    <Alert type='info' message='Личные данные необходимы для формирования квитанций на оплату взносов ГСК'/>
    <br></br>
    <Form.Item
      label='Фамилия'
      name={['profile','surname']}
      rules={[{required: true, validator: (_, value) => checkName(value)}]}
      tooltip={<>
        Мы не показываем Ваши личные данные всем пользователям<br/><br/>
        Эта информация будет видна только <b>руководству ГСК</b>, в которых вы состоите
      </>}
    >
      <Input placeholder="Иванов"/>
    </Form.Item>
    <Form.Item label='Имя' name={['profile','name']} rules={[{required: true, validator: (_, value) => checkName(value)}]}>
      <Input placeholder="Иван"/>
    </Form.Item>
    <Form.Item label='Отчество' name={['profile','patronymic']} rules={[{required: false, validator: (_, value) => checkName(value, false)}]}>
      <Input placeholder="Иванович"/>
    </Form.Item>
    <PhoneInput path={['profile', 'phone']} required={!isGlobalAdmin} />
    {isNewUser && captcha}
    {isNewUser && 
    <p style={{textAlign: 'center', color: 'gray'}}>
      Нажимая кнопку «Зарегистрироваться», Вы принимаете условия <Link style={{textDecoration: 'Underline'}} onClick={() => showAgreement(sm)}>Пользовательского соглашения</Link>
    </p>
    }
  </>
}
