import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GskChargeService } from "../../Services/GskChargeService";
import { Button, Card, Checkbox, Flex, Grid, Modal, Space, Spin, Table } from "antd";
import EditAccrualModal from "../../components/Gsk/Charges/EditAccrualModal";
import { useDialog, useDialogWithParameter } from "../../utils/useDialog";
import { ColumnsType } from "antd/es/table";
import { AccrualType, GetAccrualTypeName, GskAccrualModel, GskAccrualModelWithStats } from "../../types/GskChargeTypes";
import dayjs from 'dayjs';
import { GskHistoryModalProps } from "../../components/GskHistoryModal/GskHistoryModal";
import { GskHistoryEntity } from "../../types/GskHistoryTypes";
import { GetRepeatIntervalTypeName } from "../../store/enum/repeatIntervalType";
import RenderGskChargesModal from "../../components/Gsk/Charges/RenderGskChargesModal";
import { toMoneyString } from "../../utils/moneyHelper";
import Link from "antd/es/typography/Link";
import GskAccrualChargesModal from "../../components/Gsk/Charges/GskAccrualChargesModal";
import useCurrentGsk from "./useCurrentGsk";
import IconButton from "../../components/IconButton/IconButton";
import { EditOutlined, FieldTimeOutlined, PlusCircleOutlined, ScheduleOutlined, SyncOutlined } from "@ant-design/icons";
import { Gsk } from "../../types/GskTypes";
import { RestoreIcon } from "../../components/icons";
import RowTwoCol from "../../components/RowTwoCol/RowTwoCol";
import { AccrualsTour } from "../../components/Tours/Gsk/AccrualsTour";
import { RefType, setAppRef } from "../../store/enum/RefType";
import useLoading from "../../utils/hooks/useLoading";
import { EmptyPagedResult, PagedResult } from "../../types/dto";
import { useEffectOnce } from "../../utils/hooks/useEffectOnce";
const { useBreakpoint } = Grid;
interface GskAccrualsPageProps {
  openGskHistoryModal: (props: GskHistoryModalProps) => void;
}
const GskAccrualsPage = ({ openGskHistoryModal }: GskAccrualsPageProps) => {
  const gsk = useCurrentGsk();
  return (<>{gsk && <Page gsk={gsk} openGskHistoryModal={openGskHistoryModal} />}</>)
}

interface Filter {
  showDeleted: boolean;
}
const PAGE_SIZE = 10;
interface PageProps {
  gsk: Gsk;
  openGskHistoryModal: (props: GskHistoryModalProps) => void;
}

const Page = ({ gsk, openGskHistoryModal }: PageProps) => {
  const [isLoading, load] = useLoading();
  const { sm, md } = useBreakpoint();
  const [filter, setFilter] = useState<Filter>({ showDeleted: false });
  const [page, setPage] = useState<PagedResult<GskAccrualModelWithStats>>(EmptyPagedResult<GskAccrualModelWithStats>(PAGE_SIZE));

  const addAccuralRef = useRef(null);
  const accuralsTableRef = useRef(null);
  const renderChargesButtonRef = useRef(null);
  const filterRef = useRef(null);

  const handlePageChange = useCallback(async (page: number, pageSize: number, showDelted: boolean = false) => {
    const resp = await load(GskChargeService.getAccrualsLPage(gsk.id, { page, pageSize, showDeleted: showDelted }));
    if (resp) {
      setPage(resp);
    }
  }, [gsk.id, load])

  const refreshPage = useCallback(async() => handlePageChange(page.page, page.pageSize, filter.showDeleted), [filter.showDeleted, handlePageChange, page.page, page.pageSize]);

  const [editAccrualModal, openEditAccrualModal] = useDialogWithParameter<GskAccrualModel | null>(
    (accrual, closeDialog) => <EditAccrualModal gskId={gsk.id} accrual={accrual} onFinished={refreshPage} closeDialog={closeDialog} />
  );

  const [renderChargesModal, openRenderChargesModal] = useDialog(
    (closeDialog) => <RenderGskChargesModal gskId={gsk.id} onFinished={refreshPage} closeDialog={closeDialog} />
  );

  const [accrualChargesModal, openAccrualChargesModal] = useDialogWithParameter<GskAccrualModelWithStats>(
    (accrual, closeDialog) => <GskAccrualChargesModal accrual={accrual} closeDialog={closeDialog} refreshAccruals={refreshPage}/>
  );

  const openHistory = useCallback((id: number) =>
    openGskHistoryModal({ entity: GskHistoryEntity.ACCRUAL, entityId: id, gskId: gsk.id })
    , [gsk.id, openGskHistoryModal]);

  const accrualColumns: ColumnsType<GskAccrualModelWithStats> = useMemo(() => {
    const handleAccrualRestore = (accrual: GskAccrualModelWithStats) => {
      Modal.confirm({
        title: "Восстановить начисление?",
        content: <>Будет восстановлено начисление <b>{accrual.name}</b>?</>,
        okText: "Восстановить",
        cancelText: "Нет",
        onOk: async () => {
          if (await GskChargeService.restoreAccrual(accrual.gskId, accrual.id)) {
            await refreshPage();
          };
        }
      });
    }

    const renderPaymentText = (v: GskAccrualModelWithStats) => {
      if (v.chargesTotalCount === undefined || v.chargesPaidCount === undefined || v.chargesPaidSum === undefined || v.chargesTotalSum === undefined || v.chargesTotalCount === 0) {
        return <>-</>
      }
      return <Link onClick={() => openAccrualChargesModal(v)}>
        <span title={"Оплачено платежей: " + v.chargesPaidCount + " из " + v.chargesTotalCount}>
          <span style={{ display: 'inline-block', maxWidth: 75 }}>{toMoneyString(v.chargesPaidSum)}</span>
          /
          <span style={{ display: 'inline-block', maxWidth: 75 }}>{toMoneyString(v.chargesTotalSum)}</span>
        </span>
      </Link>
    }

    return [
      {
        title: 'Начисление',
        key: 'name',
        dataIndex: 'name',
        render: (_, row) => renderName(sm, row),
      },
      {
        title: 'Платежи',
        key: 'chargesStat',
        width: 200,
        render: (_, record) => renderPaymentText(record)
      },
      {
        title: 'Создано',
        key: 'created',
        width: 100,
        dataIndex: 'created',
        render: (v: Date) => <>{dayjs(v).format("DD.MM.YYYY")}</>,
        responsive: ['xxl', 'xl', 'lg'],
      },
      {
        title: <Flex justify="right">
          <Space>
            <IconButton title='Обновить' icon={<SyncOutlined />} onClick={refreshPage} />
          </Space>
        </Flex>,
        key: 'operation',
        width: 60,
        fixed: 'right',
        render: (_, accrual) => <Flex justify="right">
          <Space>
            {accrual.isDeleted ?
              <IconButton icon={<RestoreIcon />} onClick={() => handleAccrualRestore(accrual)} title='Восстановить' />
              : <>
                <IconButton icon={<EditOutlined />} onClick={() => openEditAccrualModal(accrual)} title='Изменить' />
              </>}

            <IconButton icon={<FieldTimeOutlined />} onClick={() => openHistory(accrual.id)}
              title='История' />
          </Space>
        </Flex>
      }
    ]
  }, [openAccrualChargesModal, openEditAccrualModal, openHistory, refreshPage, sm]);
  
  useEffectOnce(() => { refreshPage(); });

  useEffect(() => { setAppRef(RefType.GskAccrualsAddButton, addAccuralRef); }, [addAccuralRef]);
  useEffect(() => { setAppRef(RefType.GskAccrualsRenderChargesButton, renderChargesButtonRef); }, [renderChargesButtonRef]);
  useEffect(() => { setAppRef(RefType.GskAccrualsTable, accuralsTableRef); }, [accuralsTableRef]);
  useEffect(() => { setAppRef(RefType.GskAccrualsFilter, filterRef); }, [filterRef]);

  return (
    <Spin spinning={isLoading}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Card size="small">
          <RowTwoCol
            left={
              <Space ref={filterRef} direction="vertical" style={{ width: '100%' }}>
                <Checkbox 
                  checked={filter.showDeleted}
                  onChange={e => { 
                    setFilter({ ...filter, showDeleted: e.target.checked });
                    handlePageChange(1, page.pageSize, e.target.checked);
                  }}
                >Показать удаленные</Checkbox>
              </Space>}
            right={
              <Space>
                <Button ref={addAccuralRef} icon={<PlusCircleOutlined />} onClick={() => openEditAccrualModal(null)}>Добавить</Button>
                <Button
                  ref={renderChargesButtonRef}
                  icon={<ScheduleOutlined />}
                  title="Начислить..."
                  onClick={openRenderChargesModal}
                >
                  {md && 'Начислить...'}
                </Button>
                <AccrualsTour />
              </Space>}
          />
        </Card>
        <Card ref={accuralsTableRef} size="small">
          <Table
            columns={accrualColumns}
            dataSource={page.items}
            rowKey='id'
            pagination={{
              current: page.page,
              onChange: handlePageChange,
              total: page.totalCount,
              pageSize: page.pageSize,
              defaultPageSize: PAGE_SIZE,
            }}
          />
        </Card>
      </Space>
      {editAccrualModal}
      {renderChargesModal}
      {accrualChargesModal}
    </Spin>
  )
}

const renderName = (sm: boolean | undefined, v: GskAccrualModelWithStats) => 
  <span style={{ textDecoration: v.isDeleted ? 'line-through' : 'unset' }}>
     {v.name}
     {sm && <span style={{ color: 'gray' }} >
       {v.accrualType !== AccrualType.ELECTRIC && <> {GetAccrualTypeName(v.accrualType)}</>}
       {v.accrualType === AccrualType.REPEAT && v.repeatType && <> каждый {v.repeatInterval} {GetRepeatIntervalTypeName(v.repeatType)}</>}
       , c {dayjs(v.startPeriod).format("DD.MM.YYYY")}
       {!!v.endPeriod && <> по {dayjs(v.endPeriod).format("DD.MM.YYYY")}</>}
     </span>}
   </span>

export default GskAccrualsPage;