import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../configureStore";
import { parseUserModelFromAccessToken, UserModel, UserRole } from "../../types/UserTypes";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../redux";
import { jwtDecode } from "jwt-decode";
import { GskRole, HasGskRole } from "../../types/GskTypes";
import { useEffect } from "react";

export interface AppState {
	redirect: string | null;
	currentUser?: UserModel;
  refreshUserFlag: boolean;
}

export function initAppState(): AppState {
	return {
		redirect: null,
		currentUser: undefined,
		refreshUserFlag: false,
	};
}

export const appSlice = createSlice({
	name: 'app',
	initialState: initAppState(),
	reducers: {
		setRedirect(state, action: PayloadAction<string | null>) {
			state.redirect = action.payload;
		},
		setCurrentUser(state, action: PayloadAction<UserModel | undefined>) {
      state.currentUser = action.payload;
		},
		setRefreshUserFlag(state, action: PayloadAction<boolean>) {
      state.refreshUserFlag = action.payload;
		},
	},
});
export const getAppState = (state: RootState) => state.appSlice;
const getCurrentGsk = (state: RootState) => state.gskSlise.gsk;
const getCurrentUser = (state: RootState) => state.appSlice.currentUser;

//selectors

export const getRedirect = createSelector([getAppState], state => state.redirect);
export const currentUserIsGlobalAdminSelector = createSelector([getAppState], state => state.currentUser?.role === UserRole.Admin);

export const useCurrentUser = () => {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem('accessToken')
  const refreshUserFlag = useAppSelector(state => getAppState(state).refreshUserFlag);
	const result = useAppSelector(s => s.appSlice.currentUser)
  useEffect(() => {
    if (accessToken) {
      try {
        const decoded = jwtDecode<{user: string}>(accessToken);
        if (decoded.user) {
          dispatch(setCurrentUser(parseUserModelFromAccessToken(decoded.user)))
        }
      } catch {
        localStorage.removeItem('accessToken');
      }
    } else {
			setCurrentUser(undefined);
		}
    if (refreshUserFlag) {
      dispatch(setRefreshUserFlag(false));
    }
		
  }, [accessToken, dispatch, refreshUserFlag]);
  
	if (!accessToken) {
		return undefined;
	}
  return result;
};


export const currentUserHasGskRoleSelector = (role: GskRole) =>
  createSelector([getCurrentGsk, getCurrentUser], (gsk, user) => {
    if (!gsk || !user) {
      return false;
    }
		
    if (user.role === UserRole.Admin) {
      return true;
    }
    if (!user.gsks[gsk.id]?.roles) {
      return false;
    }

    return HasGskRole(user.gsks[gsk.id].roles, role, user.role);
  });

export const { 
	setRedirect,
  setRefreshUserFlag,
	setCurrentUser,
} = appSlice.actions
export default appSlice.reducer;