import { Card, Col, Row, Spin } from "antd";
import { useState } from "react";
import { RabbitService } from "../../Services/RabbitService";
import { useEffectOnce } from "../../utils/hooks/useEffectOnce";
import { QueueCount } from "../../types/RabbitTypes";
import IconButton from "../../components/IconButton/IconButton";
import { SyncOutlined } from "@ant-design/icons";


export default function RabbitPage () {
	const [isLoading, setIsLoading] = useState(false);
	const [emailsQueue, setEmailsQueue] = useState<QueueCount | undefined>();


	const refreshEmailsQueueSize = async () => {
		try {
			setIsLoading(true);
			var emailsQ = await RabbitService.getEmailQueueSize();
			setEmailsQueue(emailsQ);
		} finally {
			setIsLoading(false);
		}
	}

	useEffectOnce(() => {
		refreshEmailsQueueSize();
	})

	return (<Spin spinning={isLoading} style={{width: '100%'}}>
		<Card 
			title='Email'
		>
			<Row>
				<Col span={20}>
					<Row><Col style={{width: 150}}>Основная очередь:</Col><Col>{emailsQueue?.count ?? 'Не загружено'}</Col></Row>
					<Row><Col style={{width: 150}}>DeadLettered:</Col><Col>{emailsQueue?.countDLX ?? 'Не загружено'}</Col></Row>
				</Col>
				<Col span={4} style={{textAlign: 'right'}}><IconButton icon={<SyncOutlined/>} onClick={refreshEmailsQueueSize} /></Col>
			</Row>
		</Card>
	</Spin>)
}