export function AddDays (date: Date, value: number): Date {
  if (!value) return date;
  date.setDate(date.getDate() + value);

  return date;
};

export function AddYears(date: Date, value: number): Date {
  if (!value) return date;
  date.setFullYear(date.getFullYear() + value);

  return date;
};