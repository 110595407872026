import { PostCommentModelBase } from "../../../types/PostBaseTypes";

export interface PostLikeInfo {
  id: number;
  likesCnt: number;
  dislikesCnt: number;
  isCurrentUserLiked: boolean | null;
}

export const likePost = (post: PostLikeInfo, isLike: boolean) => {
  if (post.isCurrentUserLiked === isLike) {
    if (isLike) {
      post.likesCnt -= 1;
    } else {
      post.dislikesCnt -= 1;
    }

    post.isCurrentUserLiked = null;
  } else {
    if (isLike) {
      post.likesCnt += 1;
      if (post.isCurrentUserLiked === false) {
        post.dislikesCnt -=1;
      }
    } else {
      post.dislikesCnt += 1;
      if (post.isCurrentUserLiked === true) {
        post.likesCnt -=1;
      }
    }

    post.isCurrentUserLiked = isLike;
  }
  return post;
}

export const likeComment = (comment: PostCommentModelBase, isLike: boolean) => {
  if (comment.isCurrentUserLiked === isLike){
    if (isLike) {
      comment.likesCnt -= 1;
    } else {
      comment.dislikesCnt -= 1;
    }
    comment.isCurrentUserLiked = null;
  } else {
    if (isLike) {
      comment.likesCnt += 1;
      if (comment.isCurrentUserLiked === false) {
        comment.dislikesCnt -=1;
      }
    } else {
      comment.dislikesCnt += 1;
      if (comment.isCurrentUserLiked === true) {
        comment.likesCnt -=1;
      }
    }
    
    comment.isCurrentUserLiked = isLike;
  }
  return comment;
}