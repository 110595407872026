import { useCallback, useState } from "react";
import { GetGskRoleName, GskRole } from "../../types/GskTypes";
import { Button, Card, Form,Input, Row, Space, Spin } from "antd";
import GskRolesSelect from "../../components/Gsk/GskRolesSelect";
import { UserGskData, UserModel, UsersFilterModel } from "../../types/UserTypes";
import { UserService } from "../../Services/UserService";
import UsersTable from "../../components/User/UsersTable";
import { SearchOutlined } from "@ant-design/icons";
import { useEffectOnce } from "../../utils/hooks/useEffectOnce";
import { useForm } from "antd/es/form/Form";
import GskSelect from "../../components/Gsk/GskSelect";
import useLoading from "../../utils/hooks/useLoading";

const PAGE_SIZE = 15;

interface UsersPageProps {
  openProfileModal: (user?: UserModel, setUsers?: React.Dispatch<React.SetStateAction<UserModel[]>>) => void;
}
export default function UsersPage({openProfileModal}: UsersPageProps) {
  const [users, setUsers] = useState<UserModel[]>([]);
  const [form] = useForm<UsersFilterModel>();
  const [isLoading, load] = useLoading();
  const [total, setTotal] = useState(0);

  const pageSize = Form.useWatch('pageSize', form);
  const fetchUsers = useCallback(async (f: UsersFilterModel) => {
    const resp = await load(UserService.getUsers(f));
    if (resp) {
      setUsers(resp.items);
      setTotal(resp.totalCount)
    }
  }, [load]);

  const onChangePage = useCallback((page: number) => {
    form.setFieldValue('page', page);
    form.submit();
  }, [form]);

  const renderRolesFunc = useCallback(
    (gsks: { [gskId: number]: UserGskData }) => {
      let rolesSet = new Set<GskRole>();
      Object.keys(gsks).forEach(gskIdstring => {
        const gskId = parseInt(gskIdstring);
        gsks[gskId].roles.forEach(r => rolesSet.add(r));
      });

      return (<>{Array.from(rolesSet).map(r => GetGskRoleName(r)).join(', ')}</>)
    }
  , [])
  
  useEffectOnce(() => { 
    form.submit();
  });

  return (
    <Spin spinning={isLoading} style={{width: '100%'}}>
      <Space direction="vertical" style={{width: '100%'}}>
        <Card size="small">
          <Row>
            <Form 
              form={form}
              initialValues={{text: '', roles: [], page: 1, pageSize: PAGE_SIZE}}
              layout="inline"
              onKeyDown={e => {if (e.key === 'Enter') {form.submit()}}}
              onFinish={fetchUsers}
            >
              <Form.Item name='page' hidden><Input/></Form.Item>
              <Form.Item name='pageSize' hidden><Input/></Form.Item>
              <Form.Item label='Фильтр' name='text'>
                <Input placeholder="Поиск.."/>
              </Form.Item>
              <Form.Item label='Права' name='roles'>
                <GskRolesSelect style={{width: 230}} maxTagCount={1}/>
              </Form.Item>
              <Form.Item label='ГСК' name='gskId'>
                <GskSelect style={{ width: 200 }}/>
              </Form.Item>
              <Form.Item>
                <Button icon={<SearchOutlined/>} onClick={() => form.submit()}>Поиск</Button>
              </Form.Item>
              </Form>
          </Row>
        </Card>
        <UsersTable 
          isLoading={isLoading}
          usersFiltered={users} 
          renderRoles={renderRolesFunc} 
          totalCount={total} 
          pageSize={pageSize} 
          onChangePage={onChangePage}
          openProfileModal={u => openProfileModal(u, setUsers)}
        />
      </Space>
    </Spin>
  )
}
