import { useDialogWithParameter } from "../../utils/useDialog";
import LoginModal from './LoginModal';
import { useAppSelector } from '../../store/redux';
import { UserService } from '../../Services/UserService';
import ProfileModal from './ProfileModal';
import { CloseCircleOutlined, LoginOutlined, LogoutOutlined, RetweetOutlined, SettingOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { NavLink, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useRef } from 'react';
import { GskRole } from '../../types/GskTypes';
import { UserModel } from '../../types/UserTypes';
import { ItemType } from "antd/lib/menu/hooks/useItems";
import ChangeChairmanModal from "../Gsk/ChangeChairmanModal";
import '../Layout.scss'
import { RefType, setAppRef } from "../../store/enum/RefType";
import GskModal, { OpenGskModalProps } from "../Gsk/GskModal/GskModal";
import { currentGskSelector } from "../../store/common/gsk-slice";
import { BoxIcon } from "../icons";
import { useCurrentUser } from "../../store/common/app-slice";
import { ShowInfo } from "../Notifications/Notifications";

export default function useUserBlock (openRestorePasswordModal: () => void): 
  [(gskId?: number) => ItemType[], (message?:string, redirectUrl?: string) => void, (user?: UserModel, setUsers?: React.Dispatch<React.SetStateAction<UserModel[]>>) => void, (p: OpenGskModalProps) => void, JSX.Element]  {
  const currentUser = useCurrentUser();
	const gsk = useAppSelector(currentGskSelector);
  const navigate = useNavigate();
  const isLogged = !!currentUser;
  const userName = currentUser?.surname + " " + currentUser?.name;
  
  const accountRef = useRef(null);

  const [loginModal, openLoginModal] = useDialogWithParameter<{message?: string, redirectUrl?: string}>(
    (param, closeDialog) => <LoginModal 
      closeDialog={closeDialog}
      message={param.message}
      redirectUrl={param.redirectUrl}
      openRestorePasswordModal={openRestorePasswordModal}
    />
  );

  
	const [editGskModal, openGskModal] = useDialogWithParameter<OpenGskModalProps>((param, closeDialog) => 
    <GskModal closeDialog={closeDialog} currentGsk={param.currentGsk} initTab={param.initTab} setGsks={param.setGsks}/>);
  
  const [profileModal, openProfileModal] = useDialogWithParameter<{user?: UserModel, setUsers?: React.Dispatch<React.SetStateAction<UserModel[]>>}>(
    (param, closeDialog) => <ProfileModal closeDialog={closeDialog} user={param.user} setUsers={param.setUsers} />
  );

  const handleOpenPropfileModal = useCallback((user?: UserModel, setUsers?: React.Dispatch<React.SetStateAction<UserModel[]>>) =>
    openProfileModal({user, setUsers})
  , [openProfileModal]);

  const [changeChairmanModal, openChangeChairmanModal] = useDialogWithParameter<number>(
    (param, closeDialog) => <ChangeChairmanModal closeDialog={closeDialog} gskId={param} />
  );

  const openLogin = useCallback((message?:string, redirectUrl?: string) => openLoginModal({message, redirectUrl}), [openLoginModal]);

  const renderItems = useCallback((gskId?: number): ItemType[] => {
    const handleLogout = async () => {
      if (await UserService.logout()) {
        navigate('/');
        ShowInfo("Вы вышли из системы", "");
      }
    }
    
    const currentUserIsGskChairman = gskId && currentUser && currentUser.gsks[gskId]?.roles?.includes(GskRole.Chairman);
    const currentUserIsGskAdmin = gskId && currentUser && currentUser.gsks[gskId]?.roles?.includes(GskRole.Admin);
    const currentUserIsGskAccountant = gskId && currentUser && currentUser.gsks[gskId]?.roles?.includes(GskRole.Accountant);
    return [
      {
        icon: <LoginOutlined />,
        label: <span className='label'>Вход</span>,
        key: 'login',
        onClick: () => openLogin(),
        className: 'menu-item-short',
        style: {display: !isLogged ? 'inherit' : 'none', paddingInline: 2},
      },
      {
        icon: <UserAddOutlined />,
        label: <span className='label'>Регистрация</span>,
        key: 'register',
        onClick: () => handleOpenPropfileModal(currentUser),
        className: 'menu-item-short',
        style: {display: !isLogged ? 'inherit' : 'none', paddingInline: 2},
      },
      {
        icon: <UserOutlined />,
        label: <span className="label" ref={accountRef}>{userName}</span>,
        key: 'user',
        style: {display: isLogged ? 'inherit' : 'none'},
        className: 'menu-item-short',
        children: [
          {
            icon: <SettingOutlined />,
            label: <span className='label'>Мой профиль</span>,
            key: 'profile',
            onClick: () => handleOpenPropfileModal(currentUser),
          },
          {
            icon: <BoxIcon />,
            label: <span className='label'>{gsk?.name}</span>,
            key: 'gsk',
            className: 'menu-item-with-childs',
            style: {display: gsk && (currentUserIsGskAdmin || currentUserIsGskAccountant || currentUserIsGskChairman) ? 'inherit' : 'none'},
            children: [
              {
                icon: <SettingOutlined />,
                label: <span className='label'>Настройки</span>,
                key: 'gskProps',
                onClick: () => gsk ? openGskModal({currentGsk: gsk}) : null,
                style: {display: gsk && (currentUserIsGskAdmin || currentUserIsGskAccountant || currentUserIsGskChairman) ? 'inherit' : 'none'},
              },
              {
                icon: <RetweetOutlined />,
                label: <span className='label'>Сменить председателя</span>,
                key: 'changeChariman',
                onClick: () => gskId ? openChangeChairmanModal(gskId) : null,
                style: {display: gsk && currentUserIsGskChairman ? 'inherit' : 'none'},
              },
            ]
          },
          {
            type: 'divider',
          },
          {
            label: <NavLink to="/"><span className='label'>Выйти из ГСК</span></NavLink>,
            key: 'exitGsk',
            icon: <CloseCircleOutlined />,
            style: {display: !!gskId ? 'inherit' : 'none'},
          },
          {
            icon: <LogoutOutlined />,
            label: <span className='label'>Выход</span>,
            key: 'exit',
            onClick: () => handleLogout(),
          },
        ],
      },
    ]
  }, [currentUser, gsk, handleOpenPropfileModal, isLogged, navigate, openChangeChairmanModal, openGskModal, openLogin, userName]);

  useEffect(() => {
    setAppRef(RefType.MenuAccountLink, accountRef);
  }, [accountRef]);

  return [renderItems, openLogin, handleOpenPropfileModal, openGskModal,
  <>
    {profileModal}
    {loginModal}
    {changeChairmanModal}
    {editGskModal}
  </>]
}